<template>
  <div class="clips">
    <div class="clips__wrapper">
      <v-btn
        text
        color="black"
        class="clips__back-button mr-3"
        @click="$router.go(-1)"
      >
        <span class="font-weight-bold text-capitalize text-subtitle-1"
          ><v-icon color="black" class="mr-2">mdi-arrow-left</v-icon>back</span
        >
      </v-btn>
      <div class="clips__header" v-if="folder.uuid">
        <div class="ml-auto" v-if="folder.videos.length > 0">
          <v-btn
            text
            :ripple="false"
            width="170"
            height="48"
            @click="showUploadDialog(true)"
          >
            <span class="font-weight-normal text-capitalize">Upload video</span>
          </v-btn>
        </div>
      </div>
      <div class="new-folder-wrapper" v-if="folder.uuid">
        <v-icon color="#FFC145" class="mt-4">mdi-folder</v-icon>
        <div
          class="new-folder-wrapper__label-container text-h5 font-weight-light"
          @click="isEditMode = !isEditMode"
          v-if="!isEditMode"
        >
          <label for="Folder Name">{{ folderName }}</label>
        </div>
        <v-text-field
          v-else
          autofocus
          label="Type Folder Name"
          single-line
          outlined
          class="text-h5 font-weight-light"
          :error-messages="folderNameErrors"
          v-model.trim="folderName"
          @input="onFocus"
          @blur="onBlur"
          @keydown.enter="onBlur"
        ></v-text-field>
      </div>
      <UploadVideo
        v-if="folder.videos && folder.videos.length === 0"
        :showUploadDialog="showUploadDialog"
      />
      <VideosGrid
        v-if="folder.uuid"
        :videos="folder.videos"
        :folder="folder"
        :clipSpaceLg="3"
        :clipSpaceMd="6"
        @deleted="removeVideo"
      />
      <UploadVideoDialog
        :isUploadVideoDialogVisible="isUploadVideoDialogVisible"
        :showUploadDialog="showUploadDialog"
        :showFloatingUploadWindow="showFloatingUploadWindow"
        @videoUploaded="fetchFolderContent"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import types from "../store/types";
import { required, minLength } from "vuelidate/lib/validators";
import VideosGrid from "../components/Videos/VideosGrid.vue";
import UploadVideoDialog from "../components/Videos/UploadVideoDialog.vue";
import UploadVideo from "../components/Videos/UploadVideo.vue";

const folderNameMinLength = 2;

export default {
  components: {
    VideosGrid,
    UploadVideo,
    UploadVideoDialog,
  },
  data() {
    return {
      isUploadVideoDialogVisible: false,
      isEditMode: false,
      folderName: null,
      folder: {},
    };
  },
  computed: {
    folderNameErrors() {
      const errors = [];
      if (!this.$v.folderName.$dirty) return errors;
      !this.$v.folderName.required && errors.push("Folder Name is required");
      !this.$v.folderName.minLength &&
        errors.push(
          `Folder Name must contains at least ${folderNameMinLength} characters`
        );
      return errors;
    },
  },
  methods: {
    ...mapMutations({
      showFloatingUploadWindow:
        types.project.mutations.SET_IS_FLOATING_UPLOAD_WINDOW_VISIBLE,
    }),
    ...mapActions({
      updateFolder: types.project.actions.UPDATE_FOLDER,
      fetchFolder: types.project.actions.FETCH_FOLDER,
    }),
    showUploadDialog(flag) {
      this.isUploadVideoDialogVisible = flag;
    },
    onFocus() {
      this.$v.folderName.$touch();
    },
    onBlur: async function () {
      this.$v.folderName.$touch();
      if (!this.$v.folderName.$invalid) {
        this.isEditMode = !this.isEditMode;
        try {
          const payload = { name: this.folderName, uuid: this.folder.uuid };
          await this.updateFolder(payload);
        } catch (error) {
          // handled in store
        }
      }
    },
    removeVideo(uuid) {
      const updatedFolder = this.folder;
      updatedFolder.videos = updatedFolder.videos.filter(
        (video) => video.uuid !== uuid
      );
      this.folder = { ...updatedFolder };
    },
    fetchFolderContent: async function () {
      const { uuid } = this.$route.params;
      this.folder = await this.fetchFolder({ uuid });
      this.folderName = this.folder.name;
    },
  },
  validations: {
    folderName: {
      required,
      minLength: minLength(folderNameMinLength),
    },
  },
  async mounted() {
    this.fetchFolderContent();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/views/clips.scss";
</style>
