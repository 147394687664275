<template>
  <div class="subtitles-wrapper">
    <div
      class="subtitles-wrapper__format--row"
      :style="{ alignItems: 'center' }"
    >
    <div @click="openFontsUploader">
      <v-icon>mdi-cog</v-icon>
    </div>
      <div class="subtitles-wrapper__format--font-family">
        <v-select
          v-model="fontFamily"
          :items="listOfFonts"
          menu-props="auto"
          label="Select"
          hide-details
          single-line
          solo
          flat
          @change="updateFontFamily"
        ></v-select>
      </div>
      <div class="subtitles-wrapper__format--font-size">
        <v-select
          v-model="fontSize"
          :items="fontSizeList"
          menu-props="auto"
          label="Select"
          hide-details
          single-line
          solo
          flat
          item-text="label"
          item-value="null"
          @change="updateFontSize"
        ></v-select>
      </div>
      <div class="subtitles-wrapper__format--words-per-line">
        <v-text-field
          v-model="words"
          type="number"
          outlined
          prefix="Words per line"
          @change="setWordsPerLine"
        ></v-text-field>
      </div>
    </div>
    <div
      class="subtitles-wrapper__format--row justify-space-between"
      :style="{ alignItems: 'baseline' }"
    >
      <v-btn
          class="ma-2"
          outlined
          color="indigo"
          @click="openAdvancedSettings = true"
        >
          Advanced Settings
          
        </v-btn>
        <div class="d-flex ">
      <button @click="sendSubtitleUpdates" class="subtitles-wrapper__button">
        {{isSaving?'saving ...':'submit changes'}}
      </button>
      </div>
    </div>

    <v-row class="d-flex justify-space-between align-baseline mr-2 mb-2">
      <div>
      </div>
    </v-row>

    <div
      class="subtitles-wrapper__content"
      id="subtitles-text"
      v-html="currentSubtitle"
      contenteditable="false"
    ></div>
    <context-menu ref="menu" v-outside-click="closeMenu">
      <ul style="padding: 0; list-style: none;">
        <li>
        <v-btn
          class="ma-2"
          small
          outlined
          color="#7367f0"
          style="font-weight: 600"
          @click="setCreateClip($event)"
        >
          <v-icon dark> mdi-plus-box-outline </v-icon>
          Create New Clip
        </v-btn>
      </li>
      <li>
        <v-btn
          small
          class="ma-2"
          outlined
          color="#7367f0"
          style="font-weight: 600"
          @click="cutSubtitiles($event)"
        >
          <v-icon dark> mdi-content-cut </v-icon>
          Trim Video
        </v-btn>
      </li>
      </ul>
    </context-menu>
    <context-menu ref="undoMenu" v-outside-click="closeMenu">
      <ul style="padding: 0; list-style: none;">
      <li>
        <v-btn
          class="ma-2"
          outlined
          color="#7367f0"
          style="font-weight: 600"
          @click="undoTrimmedText($event)"
        >
          <v-icon dark> mdi-undo </v-icon>
          Undo
        </v-btn>
      </li>
      </ul>
    </context-menu>
    <context-menu ref="addToMenu" v-outside-click="closeAddTo">
      <ul style="padding: 0; list-style: none;">
      <li>
        <v-btn
          class="ma-2"
          outlined
          color="#7367f0"
          style="font-weight: 600"
          @click="addToClip($event)"
        >
          <v-icon dark> mdi-plus </v-icon>
          Add to clip
        </v-btn>
      </li>
      <li>
        <v-btn
          class="ma-2"
          outlined
          color="#7367f0"
          style="font-weight: 600"
          @click="setCreateClip($event)"
        >
          <v-icon dark> mdi-plus-box-outline </v-icon>
          Create New Clip
        </v-btn>
      </li>
      </ul>
    </context-menu>
    <v-dialog v-if="openAdvancedSettings" v-model="openAdvancedSettings" persistent max-width="500">
    <AdvancedSettings @close="openAdvancedSettings = false" :fetchCurrentClip="fetchCurrentClip"/>
    </v-dialog>
    <v-dialog v-model="showFontsUpload" persistent max-width="500" >
      <UploadFonts @close="showFontsUpload = false"/>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import types from "../../store/types";
import convertDictionaryToSRT from "../../utils/ConvertDictionaryToSRT";
import LoadFontInRunTime from "../../utils/LoadFontInRunTime";
import ContextMenu from "../../shared/ContextMenu.vue";
import AdvancedSettings from "../../components/Videos/AdnvacedSettings.vue"
import UploadFonts from "../UploadFonts.vue";
import {fonstSizes} from "../../utils/fontSizes";

export default {
  components: {
    ContextMenu,
    AdvancedSettings,
    UploadFonts
  },
  data() {
    return {
      fontFamily: "",
      fontSize: 0,
      words: 0,
      fontSizeList:fonstSizes,
      alignment: "",
      firstWordIndex: -1,
      lastWordIndex: -1,
      removedSubtitleIndexes: [],
      newSubtitleWords: [],
      addedWordCount: 0,
      openAdvancedSettings:false,
      historyStep : 0,
      subtitlesHistory:[],
      cuts:[],
      isReady:false,
      trimedSubtitles:[],
      isMouseDown:false,
      isSaving:false,
      currentClipSubtitleStartId:null,
      currentClipSubtitleEndId:null,
      showFontsUpload:false
    };
  },
  props: {
    createNewClip: Function,
    video: Object,
    fetchCurrentClip: Function,
    realClipDim: Object,
  },
  methods: {
    ...mapActions({
      updateClipStyle: types.project.actions.UPDATE_CLIP_STYLE,
      updateVideoSubtitle: types.project.actions.UPDATE_VIDEO_SUBTITLE,
      saveTrimedSubtitleDurations: types.project.actions.UPDATE_TRIMED_DURATIONS,
      autoCrop: types.autoCrop.actions.AUTO_CROP,
      fetchAutocropClip: types.autoCrop.actions.FETCH_AUTO_CROP_CLIP,
      updateClip: types.project.actions.UPDATE_CLIP,
      updateAutoCropClip:types.autoCrop.actions.UPDATE_AUTO_CROP_CLIP

    }),   
    ...mapMutations({
      setVideoSubtitle: types.project.mutations.SET_VIDEO_SUBTITLE,
      setNumberOfWordsPerLine: types.project.mutations.SET_WORDS_PER_LINE,
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE,
      setErrorMessage: types.app.mutations.SET_ERROR_MESSAGE,
      setCurrentSubtitle: types.project.mutations.SET_CURRENT_SUBTITLE,
      reloadPLayer: types.project.mutations.RELOAD_PLAYER,
      setIsAutoCropping:types.autoCrop.mutations.AUTO_CROPPING,
      setAutoCropProgress:types.autoCrop.mutations.AUTO_CROPPING_PROGRESS,
      setShowAutoCropPlayer:types.autoCrop.mutations.SHOW_AUTO_CROP,
      setIsAutocropStarted:types.autoCrop.mutations.SET_IS_AUTO_CROP_STARTED
    }),
    updateFontFamily: async function () {
      try {
        const { clipUuid } = this.$route.params;
        const payload = {
          clipUuid,
          font: this.fontFamily,
        };
        await this.updateClipStyleAction(payload);
        const fontFamilyURL = this.fonts.filter(
          (font) => font.name === this.fontFamily
        )[0].url;
        LoadFontInRunTime(this.fontFamily, fontFamilyURL);
        this.fetchCurrentClip()
        this.setSuccessMessage("Font updated successfully");
      } catch (error) {
        // handled in store
      }
    },
    updateFontSize: async function (e) {
      try {
        const { clipUuid } = this.$route.params;

        const payload = {
          clipUuid,
          size: e,
        };

        await this.updateClipStyleAction(payload);
        this.fetchCurrentClip()
        this.setSuccessMessage("Font size updated successfully");
      } catch (error) {
        // handled in store
      }
    },
    setAlignment: async function (axis, pos_string) {
      try {
        const { clipUuid } = this.$route.params;
        const payload = {
          clipUuid,
          [`${axis}_pos_string`]: pos_string,
          [`${axis}_pos_decimal`]: null,
        };

        await this.updateClipStyleAction(payload);
        this.setSuccessMessage("Subtitle aligned successfully");
      } catch (error) {
        // handled in store
      }
    },
    setWordsPerLine: async function () {
      try {
        const { clipUuid } = this.$route.params;
        const payload = {
          clipUuid,
          max_words_per_line: this.words,
        };
        this.setVideoSubtitle([]);
        await this.updateClipStyleAction(payload);
        this.setNumberOfWordsPerLine(this.words);
        const formattedWords = convertDictionaryToSRT(
          this.subtitleWords,
          this.words
        );
        this.fetchCurrentClip()
        this.setVideoSubtitle(formattedWords);
        this.setSuccessMessage("Words per line updated successfully");
        if(this.clip && this.clip.clip_cuts && this.clip.clip_cuts.length){
          this.setSubtitle()
        }
      } catch (error) {
        console.log("error======>")
        // handled in store
      }
    },

    async updateClipStyleAction(payload) {
      if (!payload.clipUuid) {
        const clip = await this.createNewClip({
          start_time: 0,
          end_time: this.video.duration,
        });
        payload.clipUuid = clip.uuid;
      }
      await this.updateClipStyle(payload);
      return;
    },

    isNullWord(word) {
      return word != "" && word.trim() != "";
    },

    addNewWord(subtitle, prevIndex) {
      let words = subtitle.split(" ").filter((word) => {
        this.isNullWord(word);
      });

      let startTime = this.subtitleWords[prevIndex].startTime;
      let endTime = this.subtitleWords[prevIndex].endTime;
      let prevSpeakerTag = this.subtitleWords[prevIndex].speakerTag;
      words.forEach((word) => {
        let newWord = {
          // startTime: startTime + index * (endTime - startTime) / words.length,
          // endTime: startTime + (index + 1) * (endTime - startTime) / words.length - 0.1,
          startTime: startTime,
          endTime: endTime,
          word: word,
          speakerTag: prevSpeakerTag,
        };
        this.newSubtitleWords.splice(prevIndex + 1, 0, newWord);
        prevIndex++;
      });

      this.addedWordCount += words.length;
    },
    sendSubtitleUpdates: async function () {
      try {
        this.newSubtitleWords = this.subtitleWords.slice();
        const subtitleSpans = document.querySelectorAll("#subtitles-text span");
        const updatedSubtitles = []
        subtitleSpans.forEach(span=>{
          if(span.style['text-decoration-line'] !== 'line-through')
            updatedSubtitles.push({id:span.id, word:span.innerText.trim()})
        })

        updatedSubtitles.forEach(s=>{
          this.newSubtitleWords[parseInt(s.id)].word = s.word
        })
        const { uuid } = this.$route.params;
        const payload = {
          uuid,
          data: { words: this.newSubtitleWords },
        };
        const formattedWords = convertDictionaryToSRT(
          this.newSubtitleWords,
          this.words
        );
        this.setVideoSubtitle(formattedWords);
        this.isSaving = true
        await this.updateVideoSubtitle(payload);
        this.isSaving = false
        this.fetchCurrentClip();
        this.setSuccessMessage("Subtitle updated successfully");
        this.reloadPLayer(true)
        setTimeout(() => {
          this.reloadPLayer(false)
        }, 1000);
        // window.location.reload();
      } catch (error) {
        // handled in store
      }
    },
    addListenersToSubtitleSpans() {
      setTimeout(() => {
        const spans = document
          .querySelector(".subtitles-wrapper__content")
          .querySelectorAll("span");

        if (spans && spans.length > 0) {
          this.firstWordIndex = parseInt(spans[0].id);
          this.lastWordIndex = parseInt(spans[spans.length - 1].id);
          this.removedSubtitleIndexes = [];

          // Prevent Enter key in content
          document
            .querySelector(".subtitles-wrapper__content")
            .addEventListener("keydown", (event) => {
              if (event.keyCode === 13) {
                event.preventDefault();
                return false;
              }
            });
          document.querySelector(".subtitles-wrapper__content")
            .addEventListener("mousedown", () => {
              this.isMouseDown = true
            });
          document.querySelector(".subtitles-wrapper__content")
            .addEventListener("mouseup", () => {
              this.isMouseDown = false
            });
            for (const span of spans) {
                span.setAttribute('contentEditable', false);
            }
            for (const span of spans) {
                span.addEventListener('mouseover', ()=>{
                  span.classList.add('over-span')
                });
                span.addEventListener('mouseleave', ()=>{
                  span.classList.remove('over-span')
                });
                span.addEventListener('mousedown', ()=>{
                  if(span.style.textDecoration === ''){
                    span.setAttribute('contentEditable', true)
                    span.classList.add('focused')
                  }
                  
                })
                span.addEventListener('mousemove', ()=>{
                  if(this.isMouseDown){
                      span.setAttribute('contentEditable', false)
                  }
                  
                })
                span.addEventListener('blur', ()=>{
                  // span.setAttribute('contentEditable', false)
                  span.classList.remove('focused')
                })
            }

        const subtitleSpans = document.querySelectorAll("#subtitles-text span");
        const observerConfig = { characterData: true, subtree: true };
        
        subtitleSpans.forEach(span => {
            if(span.innerText.trim() === ''){
              span.classList.add('empty-span');
            }
            const observer = new MutationObserver(mutationsList => {
              mutationsList.forEach(mutation => {
                if (
                mutation.type === 'characterData' &&
                mutation.target.parentElement.nodeName === 'SPAN'
              ) {
                const span = mutation.target.parentElement;
                const prevSpan = span.previousElementSibling;
                const selection = window.getSelection();
                const cursorPosition = selection.focusOffset;
                const charactersToLeft = span.textContent.slice(0, cursorPosition).trim();
                if (charactersToLeft.length === 0) {
                  if (prevSpan) {
                    span.textContent.trim()
                    span.classList.add('empty-span');
                    prevSpan.setAttribute('contentEditable', true)
                      this.focusAndMoveCursor(prevSpan)
                  }
                }
                else{
                  span.classList.remove("empty-span")
                }
              }
              });
            });
            observer.observe(span, observerConfig);
          });
          
        }
        let el = document.getElementById('subtitles-text')
        this.subtitlesHistory.push({step:this.historyStep, subtitles:el.innerHTML})
        const subtitlesWrapper = document.getElementById("subtitles-text");

        // Find all the span elements within the div
        const allSpans = subtitlesWrapper.querySelectorAll("span");
        let firstIDWithoutLineThrough = null;
        let lastIDWithoutLineThrough = null;
        for (const span of allSpans) {
            if (span.style.textDecoration == "") {
              if (firstIDWithoutLineThrough === null) {
                  firstIDWithoutLineThrough = span.id;
                  }
                // Always update the lastIDWithoutLineThrough to the current ID
                lastIDWithoutLineThrough = span.id;
            }
          }
        this.currentClipSubtitleStartId = parseInt(firstIDWithoutLineThrough)
        this.currentClipSubtitleEndId = parseInt(lastIDWithoutLineThrough)
      }, 100);
      
    },

    focusAndMoveCursor(element) {
      element.focus();
      const range = document.createRange();
      range.selectNodeContents(element);
      range.collapse(false); // Set to true if you want to collapse to the start
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
  },
    calculateFontSize() {
      this.fontSize = this.clipStyle.size;
    },
    //decide which menu should open create and trim oprions menu or create and add to clip menu
    openActionMenu(e) {
      const selectedText = window.getSelection().toString();
      if (selectedText.length) {
        const text = window.getSelection();
        const selectedRange = text.getRangeAt(0);
        const firstIndex = parseInt(selectedRange.startContainer.parentElement.id)
        const lastIndex = parseInt(selectedRange.endContainer.parentElement.id)
        let firstSpan = document.getElementById(firstIndex)
        let lastSpan = document.getElementById(lastIndex)
        const firstSpanStyle = firstSpan.getAttribute('style') || ''
        const lastSpanStyle = lastSpan.getAttribute('style') || ''
        if(firstSpanStyle.includes('text-decoration: line-through red') || lastSpanStyle.includes('text-decoration: line-through red')){
          this.$refs.undoMenu.open(e)
          return
        }
        if((firstIndex > this.currentClipSubtitleStartId && lastIndex <= this.currentClipSubtitleEndId) || firstIndex===this.currentClipSubtitleStartId && lastIndex <= this.currentClipSubtitleEndId){
          this.$refs.menu.open(e);
        } 
        else {
          this.$refs.addToMenu.open(e)
        }
      }
    },
    //call on add to clip button click ti add the subtitles in current clip
    async addToClip(e) {
      this.$refs.addToMenu.close(e);
      const selectedText = window.getSelection().toString();
      if (selectedText.length) {
        const text = window.getSelection();
        const selectedRange = text.getRangeAt(0);
        const firstIndex = parseInt(selectedRange.startContainer.parentElement.id)
        const lastIndex = parseInt(selectedRange.endContainer.parentElement.id)
        let payload = {}
        let trimmed = []

        // selection starts after the ends  of current clip subtitles
          if(firstIndex === this.currentClipSubtitleEndId+1){
           
            payload = {
              start_time: this.subtitleWords[this.currentClipSubtitleStartId].startTime,
              end_time: this.subtitleWords[lastIndex].endTime,
            }
          }
        // selection starts and ends before the start of current clip subtitles
          if(lastIndex === this.currentClipSubtitleStartId-1){
  
            payload = {
              start_time: this.subtitleWords[firstIndex].startTime,
              end_time: this.subtitleWords[this.currentClipSubtitleEndId].endTime,
            }
          }
          // selection starts before the start of current and ends somwhere withing current clip subtitles
          if(firstIndex < this.currentClipSubtitleStartId - 1  && lastIndex < this.currentClipSubtitleStartId - 1){
  
            for (let index = lastIndex+1; index < this.currentClipSubtitleStartId; index++) {
                trimmed.push(index)
            }
            payload = {
              start_time: this.subtitleWords[firstIndex].startTime,
              end_time: this.subtitleWords[this.currentClipSubtitleEndId].endTime,
            }
          }
          // selection starts some where after the end of current clip subtitles
          if(firstIndex > this.currentClipSubtitleEndId + 1){
  
            for (let index = this.currentClipSubtitleEndId+1; index < firstIndex; index++) {
                trimmed.push(index)
            }
            payload = {
              start_time: this.subtitleWords[this.currentClipSubtitleStartId].startTime,
              end_time: this.subtitleWords[lastIndex].endTime,
            }
          }
          // selection starts from the center of current clip subtitles and ends after the current clip subtitles
          if(firstIndex > this.currentClipSubtitleStartId && firstIndex < this.currentClipSubtitleEndId && lastIndex>this.currentClipSubtitleEndId){
  
            payload = {
              start_time: this.subtitleWords[this.currentClipSubtitleStartId].startTime,
              end_time: this.subtitleWords[lastIndex].endTime,
            }
          }
         // selection starts before the current clip subtitles and ends somewhere in center of current clip subtitles
          if(lastIndex < this.currentClipSubtitleEndId && lastIndex > this.currentClipSubtitleStartId && firstIndex< this.currentClipSubtitleStartId){
  
            payload = {
              start_time: this.subtitleWords[firstIndex].startTime,
              end_time: this.subtitleWords[this.currentClipSubtitleEndId].endTime,
            }
          }
        // selection starts before the current clip subtitles and ends after the end of current clip subtitles
          if(firstIndex < this.currentClipSubtitleStartId && lastIndex > this.currentClipSubtitleEndId){
  
              payload = {
                start_time: this.subtitleWords[firstIndex].startTime,
                end_time: this.subtitleWords[lastIndex].endTime,
              }
            }
          // selection start right from start span of currentClip and ends before the start of current clip  
          if(lastIndex === this.currentClipSubtitleStartId && firstIndex < this.currentClipSubtitleStartId){
  
            payload = {
              start_time: this.subtitleWords[firstIndex].startTime,
              end_time: this.subtitleWords[this.currentClipSubtitleEndId].endTime,
            }
          } 
        // selection start right from end span of currentClip and ends after the end of current clip
        if(firstIndex === this.currentClipSubtitleEndId && lastIndex > this.currentClipSubtitleEndId){

          payload = {
            start_time: this.subtitleWords[this.currentClipSubtitleStartId].startTime,
            end_time: this.subtitleWords[lastIndex].endTime,
          }
        }   
          await this.createNewClip(payload)
          if(trimmed.length){
            let cut = {
              start: this.subtitleWords[trimmed[0]].startTime,
              end: this.subtitleWords[trimmed[trimmed.length - 1]].endTime,
            }
            if(this.clip.clip_cuts && this.clip.clip_cuts.length){
                this.cuts = [...this.clip.clip_cuts, cut]
            }
            else{
              this.cuts = [cut]
            }
           await this.saveTrimedSubtitleDurations({data:{clip_cuts:this.cuts},clipUuid:this.$route.params.clipUuid})
           await this.fetchCurrentClip()
           if(this.showAutoCrop){
              await this.fetchCurrentClip()
              const autoCropCuts = this.mapAutoCropCuts(this.cuts)
              await this.updateAutoCropClip({clipUuid:this.$route.params.clipUuid, aspectRatio:this.autoCropping.dimension, clip_cuts:autoCropCuts})
           }
           else{
            this.reloadPLayer(true)
            setTimeout(() => {
              this.reloadPLayer(false)
            }, 1000);
            this.setCurrentAndVideoSubtitles(payload)
           }
          }
          else{
              this.setCurrentAndVideoSubtitles(payload)
          }
        }
    },
    checkAutoCropStatus(payload, result){
     const interval = setInterval(async ()=>{
       const status = await this.fetchAutocropClip({clipUuid:this.$route.params.clipUuid, ratio:result})
       this.setAutoCropProgress(50)
       if(status.status === 'succeeded'){
            this.setAutoCropProgress(100)
            this.setIsAutocropStarted(false)
            this.setIsAutoCropping({processing:false, dimension:result})
            this.setCurrentAndVideoSubtitles(payload)
            this.setAutoCropProgress(10)
            window.location.reload()
        clearInterval(interval)
       }
      }, 5000)
    },
    setCurrentAndVideoSubtitles(range) {
      let clipSubtitle = ""
      const data = this.subtitleWords.filter(
      (s) => s.startTime >= range.start_time &&
          s.startTime <= range.end_time
      );
      const formattedWords = convertDictionaryToSRT(
        data,
        this.words
      );
      this.setVideoSubtitle(formattedWords);
      this.subtitleWords.forEach((subtitle, i) => {
      if (
        subtitle.startTime >= range.start_time &&
        subtitle.startTime <= range.end_time
      )
        clipSubtitle = `${clipSubtitle}<span id="${i}" ref="subtitle${i}"> ${subtitle.word}</span>`;
      else{
        clipSubtitle = `${clipSubtitle}<span id="${i}" ref="subtitle${i}" contentEditable="false" style="text-decoration:line-through;color:#d6d8d9; text-decoration-color: #d6d8d9;"> ${subtitle.word}</span>`
      }
      
    });
    this.setCurrentSubtitle(clipSubtitle)
    },

    setCreateClip(e) {
      if(this.$route.path.includes('demo')){
        const signupPageURL = "https://app.chopcast.io/signup"; // Replace with your signup page URL
        window.open(signupPageURL, "_blank");
        return
      }
      this.$store.commit("video/setHasSelectedText", true);
      this.$refs.menu.close(e);
    },
    undoTrimmedText(e) {
      const selectedText = window.getSelection();
      const selectedRange = selectedText.getRangeAt(0);
      const firstIndex = parseInt(selectedRange.startContainer.parentElement.id)
      const lastIndex = parseInt(selectedRange.endContainer.parentElement.id)
  
      // Remove style information from the DOM elements
      for (let i = firstIndex; i <= lastIndex; i++) {
        const spanElement = document.getElementById(i);
        spanElement.style.textDecoration = '';
        spanElement.style.color = '';
      }

      const parentElement = document.querySelector('.subtitles-wrapper__content');
      const spans = parentElement.querySelectorAll('span');
      const ranges = [];

      let currentRange = null;

      spans.forEach(span => {
        const style = span.getAttribute('style') || '';
        const spanId =parseInt(span.getAttribute('id')); // Extract the numeric part of the ID
        if (style.includes('text-decoration: line-through red')) {
          if (currentRange === null) {
            currentRange = [spanId, spanId];
          } else {
            currentRange[1] = spanId;
          }
        } else {
          if (currentRange !== null) {
            ranges.push([...currentRange]);
            currentRange = null;
          }
        }
      });

      // Check if the last range needs to be added
      if (currentRange !== null) {
        ranges.push([...currentRange]);
      }
      const updatedCuts = []
      ranges.forEach(range=>{
        let cut = {start:this.subtitleWords[range[0]].startTime,  end:this.subtitleWords[range[1]].endTime}
        updatedCuts.push(cut)
      })
      this.updateSubtitleFormatting(updatedCuts)
      this.saveTrimedSubtitleDurations({data:{clip_cuts:updatedCuts},clipUuid:this.$route.params.clipUuid} )
      const autoCropCuts = this.mapAutoCropCuts(updatedCuts)
      this.updateAutoCropClip({clipUuid:this.$route.params.clipUuid, aspectRatio:this.autoCropping.dimension, clip_cuts:autoCropCuts})
      this.$refs.undoMenu.close(e);
    },
    closeMenu(e) {
      this.$refs.menu.close(e);
    },
    closeAddTo(e) {
      this.$refs.addToMenu.close(e);
    },
    openFontsUploader(){
      this.showFontsUpload = true
    },
    mapAutoCropCuts(cuts) {
    // Calculate the duration of the first subclip and the second subclip
    const subClipDuration2 = this.autoCropClip.clip_end_time_relatedto_new_buffered_clip - this.autoCropClip.clip_start_time_relatedto_new_buffered_clip;

    // Initialize an array to hold the mapped cuts for the second subclip
    const mappedCuts = [];

    // Iterate over each cut in the first subclip
    for (const cut of cuts) {
        const cutStart1 = cut.start;
        const cutEnd1 = cut.end;

        // Calculate the cut duration and its relative start position in the first subclip
        const cutDuration1 = cutEnd1 - cutStart1;
        const cutRelativeStart1 = cutStart1 - this.clip.start_time;

        // Calculate the relative start position of the cut in the second subclip
        const cutRelativeStart2 = (cutRelativeStart1 / this.clip.clip_duration_before_cuts) * subClipDuration2;

        // Calculate the start and end times of the cut in the second subclip
        const cutStart2 = this.autoCropClip.clip_start_time_relatedto_new_buffered_clip + cutRelativeStart2;
        const cutEnd2 = cutStart2 + cutDuration1;

        // Add the mapped cut to the array
        mappedCuts.push({ start: cutStart2, end: cutEnd2 });
    }

    return mappedCuts;
},
    async cutSubtitiles(e) {
      const selectedText = window.getSelection();
      const selectedRange = selectedText.getRangeAt(0);
      const firstIndex = parseInt(selectedRange.startContainer.parentElement.id)
      const lastIndex = parseInt(selectedRange.endContainer.parentElement.id)
      // removing selected subtitle spans
      for(let id = firstIndex; id<=lastIndex;id++){
        let el = document.getElementById(`${id}`)
        if(el){
          el.style.textDecoration = 'line-through'
          el.style.color = 'red'
          el.style.textDecorationColor = 'red'
        }
        
      }
      this.historyStep +=1
      let el = document.getElementById('subtitles-text')
      this.subtitlesHistory.push({step:this.historyStep, subtitles:el.innerHTML})
      this.setCurrentSubtitle(el.innerHTML)
      let cut = {
        start: this.subtitleWords[firstIndex].startTime,
        end: this.subtitleWords[lastIndex].endTime,
      }
      
      if(this.clip.clip_cuts && this.clip.clip_cuts.length){
        this.cuts = [...this.clip.clip_cuts, cut]
      }
      else{
        this.cuts.push(cut)
      }
      this.updateSubtitleFormatting(this.cuts)
      
      const clipUuid = this.$route.params.clipUuid;
      // calling endpoint with cutted time durations
      await this.saveTrimedSubtitleDurations({data:{clip_cuts:this.cuts},clipUuid:clipUuid} )
      if(this.showAutoCrop){
        const autoCropCuts = this.mapAutoCropCuts(this.cuts)
        await this.updateAutoCropClip({clipUuid:this.$route.params.clipUuid, aspectRatio:this.autoCropping.dimension, clip_cuts:autoCropCuts})
      }
      this.$refs.menu.close(e);
      await this.fetchCurrentClip()
            this.reloadPLayer(true)
            setTimeout(() => {
              this.reloadPLayer(false)
            }, 1000);
    },
    // undo subtitle changes
    async undo(){
      const clipUuid = this.$route.params.clipUuid;
      if (this.historyStep === 0) {
        return;
      }
      this.historyStep -= 1
      let subtitles = this.subtitlesHistory[this.historyStep].subtitles;
      let cuts = this.cuts.slice(0, this.historyStep)
      await this.saveTrimedSubtitleDurations({data:{clip_cuts:cuts},clipUuid:clipUuid} )
      if(this.showAutoCrop){
        const autoCropCuts = this.mapAutoCropCuts(cuts)
        await this.updateAutoCropClip({clipUuid:this.$route.params.clipUuid, aspectRatio:this.autoCropping.dimension, clip_cuts:autoCropCuts})
      }
      this.setCurrentSubtitle(subtitles)
    },
    // redo subtitile changes
    async redo(){
      const clipUuid = this.$route.params.clipUuid;
      if (this.historyStep === this.subtitlesHistory.length-1) {
        return; 
      }
      this.historyStep += 1;
      let subtitles = this.subtitlesHistory[this.historyStep].subtitles
      let cuts = this.cuts.slice(0, this.historyStep)
      await this.saveTrimedSubtitleDurations({data:{clip_cuts:cuts},clipUuid:clipUuid} )
      if(this.showAutoCrop){
        const autoCropCuts = this.mapAutoCropCuts(cuts)
        await this.updateAutoCropClip({clipUuid:this.$route.params.clipUuid, aspectRatio:this.autoCropping.dimension, clip_cuts:autoCropCuts})
      }
      this.setCurrentSubtitle(subtitles)
    },
    isSubtitleWithinCuts(subtitle, cuts) {
      return cuts.some((cut) => subtitle.startTime >= cut.start && subtitle.startTime <= cut.end);
    },

    setSubtitle(){
      const cuts = this.clip.clip_cuts
      if(this.clip.uuid ===this.$route.params.clipUuid && cuts && cuts.length){
          setTimeout(()=>{
          cuts.forEach(c=>{
          const subtitleStart = this.subtitleWords.find(s=>s.startTime === c.start)
          const subtitleEnd = this.subtitleWords.find(s=>s.endTime === c.end)
          const startIndex = this.subtitleWords.indexOf(subtitleStart)
          const endIndex = this.subtitleWords.indexOf(subtitleEnd)
          for(let id = parseInt(startIndex); id<=parseInt(endIndex);id++){
              let el = document.getElementById(`${id}`)
              if(el){
                el.style.textDecoration = 'line-through'
                el.style.color = 'red'
                el.style.textDecorationColor = 'red'
              }
            }
          })
          
          const subtitlesText = document.getElementById('subtitles-text')
          this.setCurrentSubtitle(subtitlesText.innerHTML)
            this.updateSubtitleFormatting(cuts)
        })

      }
    },
    updateSubtitleFormatting(cuts){
      // Filter the subtitles that do not lie within any cut range
      const filteredSubtitles = this.subtitleWords.filter((subtitle) => !this.isSubtitleWithinCuts(subtitle, cuts));
      const formattedWords = convertDictionaryToSRT(
          filteredSubtitles,
          this.words
        );
      this.setVideoSubtitle(formattedWords);
    },
   async undoRemovedText(){
    const clipUuid = this.$route.params.clipUuid;
    await this.saveTrimedSubtitleDurations({data:{clip_cuts:[]},clipUuid:clipUuid, audio_cleaned:"no"} )
    if(this.showAutoCrop){
        await this.updateAutoCropClip({clipUuid:this.$route.params.clipUuid, aspectRatio:this.autoCropping.dimension, clip_cuts:[]})
      }
    this.cuts = []
    // await this.fetchCurrentClip()
    let clipSubtitle = ""
      this.subtitleWords.forEach((subtitle, i) => {
          if (
            subtitle.startTime >= this.clip.start_time &&
            subtitle.startTime <= this.clip.end_time
          )
          clipSubtitle = `${clipSubtitle}<span id="${i}" ref="subtitle${i}"> ${subtitle.word}</span>`;
          else{
            clipSubtitle = `${clipSubtitle}<span id="${i}" ref="subtitle${i}" contentEditable="false" style="text-decoration:line-through;color:#d6d8d9; text-decoration-color: #d6d8d9;"> ${subtitle.word}</span>`
          }
      });
      this.setCurrentSubtitle(clipSubtitle)
      const spans = document
          .querySelector(".subtitles-wrapper__content")
          .querySelectorAll("span");
      spans.forEach(s=>{
        s.setAttribute("contentEditable", true)
      })
    },
    setEditorScrollPosition(){
      const subtitlesWrapper = document.getElementById("subtitles-text");

      // Find all the span elements within the div
      const spans = subtitlesWrapper.querySelectorAll("span");

      let firstNonStrikeThroughSpan = null;
        if(spans.length){
          // Iterate through the spans to find the first one without text-decoration: line-through
          for (const span of spans) {
          const computedStyle = window.getComputedStyle(span);
          if (computedStyle.getPropertyValue("text-decoration").indexOf('line-through') === -1) {
            firstNonStrikeThroughSpan = span;
            break;
          }
        }
        // Set the scroll position to the top of the first non-strike-through span
        if (firstNonStrikeThroughSpan) {
          const offsetTop = firstNonStrikeThroughSpan.offsetTop;
          subtitlesWrapper.scrollTop = offsetTop - 120
        }
      }
     
    },
  },
  computed: {
    ...mapState({
      fonts: (state) => state.project.fonts,
      currentSubtitle: (state) => state.project.currentSubtitle,
      clipStyle: (state) => state.project.clipStyle,
      clip: (state) => state.project.clip,
      wordsPerLine: (state) => state.project.wordsPerLine,
      subtitleWords: (state) => state.project.subtitleWords,
      trimedClip: (state) => state.project.trimedClip,
      showAutoCrop:(state) => state.autoCrop.showAutoCrop,
      autoCropping: (state) => state.autoCrop.autoCropping,
      autoCropClip: (state) => state.autoCrop.autoCropClip
    }),
    listOfFonts() {
      return this.fonts.map((font) => font.name);
    },
  },
  mounted() {
    this.addListenersToSubtitleSpans();
    if (this.fonts.length)
    this.fontFamily = this.clipStyle.font;
    this.words = this.clipStyle.max_words_per_line || this.wordsPerLine;
    this.setNumberOfWordsPerLine(this.words);
    this.$store.commit("video/setHasSelectedText", false);
    document
      .querySelector(".subtitles-wrapper__content")
      .addEventListener("mouseup", (e) => {
        e.preventDefault()
        setTimeout(() => {
          this.openActionMenu(e);
        }, 50);
      });
    if(this.currentSubtitle){
      this.setEditorScrollPosition()
    }
  },
  watch: {
    fonts() {
      this.fontFamily = this.clipStyle.font || this.fonts[0].name;
    },
    currentSubtitle() {     
      this.addListenersToSubtitleSpans();
      setTimeout(()=>{
        this.setEditorScrollPosition()
      })
      this.isReady = true
    },
    isReady(){
      setTimeout(()=>{
        let el = document.getElementById('subtitles-text')
        this.subtitlesHistory.push({step:this.historyStep, subtitles:el.innerHTML})
      })
      
    },
    clipStyle() {
      this.calculateFontSize();
      this.words = this.clipStyle.max_words_per_line
      const formattedWords = convertDictionaryToSRT(
          this.subtitleWords,
          this.words
        );
        this.setVideoSubtitle(formattedWords)
      this.fontFamily = this.clipStyle.font;
      const fontFamilyURL = this.fonts.filter(
          (font) => font.name === this.fontFamily
        )[0].url;
      LoadFontInRunTime(this.fontFamily, fontFamilyURL);
    },
    "$route.params.clipUuid"(){
      this.words = this.wordsPerLine;
      this.setWordsPerLine();
    },
    clip () {
      if(this.clip && this.clip.clip_cuts && this.clip.clip_cuts.length){
        this.trimedSubtitles = [...this.currentSubtitle]
        setTimeout(()=>{
          this.setSubtitle()
        })
        
      }
    }
  },
  beforeDestroy() {
    if (this.$route.name.includes("Edit")) return;
    this.setVideoSubtitle([]);
  },
  updated() {
    if(this.currentSubtitle && this.clip)
    this.setSubtitle()
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/components/videos/subtitles.scss";
.create_btn {
  background-color: #7367f0;
  padding: 8px;
  color: white;
  border-radius: 4px;
  font-weight: 800;
}
.empty-span:before {
    content: "\2022 ";
    padding: 2px;
    color: #2d66e8;
}
#subtitles-text{
  /* Change the text selection color */
  ::selection {
  background-color: #b4d7ff !important; /* Choose your desired background color */
  color: gray !important; /* Choose your desired text color */
}

/* Change the text selection color for Firefox */
::-moz-selection {
  background-color: #b4d7ff !important;
  color: gray !important;
}
::-ms-selection {
  background-color: #b4d7ff !important;
  color: gray !important;
}
}

</style>
