import Vue from "vue";
import VueRouter from "vue-router";

import SignIn from "../views/SignInPage.vue";
import SignUp from "../views/SignUpPage.vue";
import Verify from "../views/VerifyPage.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Videos from "../views/Videos.vue";
import Settings from "../views/Settings.vue";
import VideoEdit from "../views/VideoEdit.vue";
import FolderDetails from "../views/FolderDetails.vue";
import Subscription from "../views/Payment/Subscription.vue";
import SuccessSubscription from "../views/Payment/Success.vue";
import FailureSubscription from "../views/Payment/Failure.vue";
import Subtitles from "../components/Videos/Subtitles.vue";
import Text from "../components/Videos/Text.vue";
import Images from "../components/Videos/Images.vue";
import Templates from "../components/Videos/Templates.vue";
import Elements from "../components/Videos/Elements.vue";
import DemoVideoEdit from "../components/Demo/DemoVideoEdit.vue";
import LoadDemo from "../components/Demo/LoadDemo.vue";
import VideoClips from "../components/VideoClips.vue";
import DemoVideoClips from "../components/Demo/DemoVideoClips.vue";
import NotFound from '../components/NotFound.vue'
import Apikey from '../components/Subscription/ApiKey.vue'
import {
  getUserRefreshTokenCookie,
  removeAccessTokenFromCookie,
} from "../utils/Cookies";
import store from "../store";
import types from "../store/types";
import Editor from "../views/Editor.vue";

Vue.use(VueRouter);

const isAuth = () => (to, from, next) => {
  if(from.path.includes('demo')|| to.path.includes('demo')){
    next({ name: "NotFound" });
  }
  const { refreshToken } = store.state.user;
  const userToken = getUserRefreshTokenCookie() || refreshToken;
  const notGoToHome = to.name !== "Home";
  const comeFromSignInOrUp = from.name === "SignUp" || from.name === "SignIn";

  /* 
    this logic guard the routing from accessing banned routes
    1- in case unauthorized user: SignIn and SignUp are permitted to access
    2- in case authorized user: SignIn and SignUp are not allowed to access until logout 
  */

  if (!userToken && comeFromSignInOrUp) next({ name: from.name });
  else if (!userToken && !comeFromSignInOrUp) {
    removeAccessTokenFromCookie();
    store.commit(types.app.mutations.SET_REDIRECT_PAGE, to);
    next({ name: "SignIn" });
  } else if (userToken && notGoToHome && (!from.name || comeFromSignInOrUp))
    next();
  else next();
};
const isDemo = () => (to, from, next) => {
  const token = getUserRefreshTokenCookie();
  const { user } = store.state.user;
  if (token && user) next({ name: "Home" });
  else next()
};

const isNotAuth = () => (to, from, next) => {
  const token = getUserRefreshTokenCookie();
  const { user } = store.state.user;
  if (token && user) next({ name: "Home" });
  else next();
};

const routes = [
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
    beforeEnter: isNotAuth(),
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
    beforeEnter: isNotAuth(),
  },
  {
    path: "/verify/:uuid/:token",
    name: "Verify",
    component: Verify,
    beforeEnter: isNotAuth(),
  },
  {
    path: "/password/forgot",
    name: "ForgotPassword",
    component: ForgotPassword,
    beforeEnter: isNotAuth(),
  },
  {
    path: "/password/reset/:uuid/:token",
    name: "ResetPassword",
    component: ResetPassword,
    beforeEnter: isNotAuth(),
  },
  {
    path: "/folder/:uuid",
    name: "FolderDetails",
    component: FolderDetails,
    beforeEnter: isAuth(),
  },
  {
    path: "/video/:uuid",
    name: "Video",
    component: VideoClips,
    beforeEnter: isAuth(),
  },
  {
    path: "/folder/:folderUuid/video/:uuid",
    name: "VideoInsideFolder",
    component: VideoClips,
    beforeEnter: isAuth(),
  },
  {
    path: "/video/:uuid/clips",
    name: "ClipsForVideo",
    component: VideoClips,
    beforeEnter: isAuth(),
  },
  {
    path: "/folder/:folderUuid/video/:uuid/clips",
    name: "ClipsForVideoInsideFolder",
    component: VideoClips,
    beforeEnter: isAuth(),
  },
  {
    path: "/video/:uuid/clip/:clipUuid",
    name: "ClipForVideo",
    component: VideoClips,
    beforeEnter: isAuth(),
  },
  {
    path: "/folder/:folderUuid/video/:uuid/clip/:clipUuid",
    name: "ClipForVideoInsideFolder",
    component: VideoClips,
    beforeEnter: isAuth(),
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: isAuth(),
  },
  {
    path: "/upgrade",
    name: "Subscription",
    component: Subscription,
    beforeEnter: isAuth(),
  },
  {
    path: "/upgrade/success/:sessionId",
    name: "SuccessSubscription",
    component: SuccessSubscription,
    beforeEnter: isAuth(),
  },
  {
    path: "/upgrade/failure",
    name: "FailureSubscription",
    component: FailureSubscription,
    beforeEnter: isAuth(),
  },
  {
    path: "/profile",
    name: "Profile",
    component: Subscription,
    beforeEnter: isAuth(),
  },
  {
    path: "/api-key",
    name: "ApiKey",
    component: Apikey,
    beforeEnter: isAuth(),
  },
  {
    path: "/invite-a-friend",
    name: "InviteFriend",
    component: Subscription,
    beforeEnter: isAuth(),
  },
  {
    path: "/editor",
    name: "EditClip",
    component: Editor,
    beforeEnter: isAuth(),
    children: [
      {
        path: "video-edit/:uuid/clip/:clipUuid",
        name: "EditClipForVideo",
        component: VideoEdit,
        children: [
          {
            path: "subtitles",
            name: "EditClipForVideoSubtitles",
            component: Subtitles,
          },
          {
            path: "text",
            name: "EditClipForVideoText",
            component: Text,
          },
          {
            path: "images",
            name: "EditClipForVideoImages",
            component: Images,
          },
          {
            path: "templates",
            name: "EditClipForVideoTemplates",
            component: Templates,
          },
          {
            path: "elements",
            name: "EditClipForVideoElements",
            component: Elements,
          },
        ],
      },
    ]
  },
  {
    path: "/editor",
    name: "EditClipOfFolder",
    component: Editor,
    beforeEnter: isAuth(),
    children: [
      {
        path: "video-edit/:folderUuid/:uuid/clip/:clipUuid",
        name: "EditClipForVideoInsideFolder",
        component: VideoEdit,
        children: [
          {
            path: "subtitles",
            name: "EditClipForVideoInsideFolderSubtitles",
            component: Subtitles,
          },
          {
            path: "text",
            name: "EditClipForVideoInsideFolderText",
            component: Text,
          },
          {
            path: "images",
            name: "EditClipForVideoInsideFolderImages",
            component: Images,
          },
          {
            path: "templates",
            name: "EditClipForVideoInsideFolderTemplates",
            component: Templates,
          },
          {
            path: "elements",
            name: "EditClipForVideoInsideFolderElements",
            component: Elements,
          },
        ],
      },
    ]

  },
  {
    path: "/video-edit/:uuid",
    name: "EditVideoOnly",
    component: VideoEdit,
    beforeEnter: isAuth(),
    children: [
      {
        path: "subtitles",
        name: "EditVideoOnlySubtitles",
        component: Subtitles,
      },
      {
        path: "text",
        name: "EditVideoOnlyText",
        component: Text,
      },
      {
        path: "images",
        name: "EditVideoOnlyImages",
        component: Images,
      },
      {
        path: "templates",
        name: "EditVideoOnlyTemplates",
        component: Templates,
      },
      {
        path: "elements",
        name: "EditVideoOnlyElements",
        component: Elements,
      },
    ],
  },
  {
    path: "/video-edit/:folderUuid/:uuid",
    name: "EditVideoInsideFolder",
    component: VideoEdit,
    beforeEnter: isAuth(),
    children: [
      {
        path: "subtitles",
        name: "EditVideoInsideFolderSubtitles",
        component: Subtitles,
      },
      {
        path: "text",
        name: "EditVideoInsideFolderText",
        component: Text,
      },
      {
        path: "images",
        name: "EditVideoInsideFolderImages",
        component: Images,
      },
      {
        path: "templates",
        name: "EditVideoInsideFolderTemplates",
        component: Templates,
      },
      {
        path: "elements",
        name: "EditVideoInsideFolderElements",
        component: Elements,
      },
    ],
  },
  {
    path: "/new-clip/:uuid",
    name: "NewClipForVideo",
    component: VideoEdit,
    beforeEnter: isAuth(),
    children: [
      {
        path: "subtitles",
        name: "NewClipForVideoSubtitles",
        component: Subtitles,
      },
      {
        path: "text",
        name: "NewClipForVideoText",
        component: Text,
      },
      {
        path: "images",
        name: "NewClipForVideoImages",
        component: Images,
      },
      {
        path: "templates",
        name: "NewClipForVideoTemplates",
        component: Templates,
      },
      {
        path: "elements",
        name: "NewClipForVideoElements",
        component: Elements,
      },
    ],
  },
  {
    path: "/new-clip/:folderUuid/:uuid",
    name: "NewClipForVideoInsideFolder",
    component: VideoEdit,
    beforeEnter: isAuth(),
    children: [
      {
        path: "subtitles",
        name: "NewClipForVideoInsideFolderSubtitles",
        component: Subtitles,
      },
      {
        path: "text",
        name: "NewClipForVideoInsideFolderText",
        component: Text,
      },
      {
        path: "images",
        name: "NewClipForVideoInsideFolderImages",
        component: Images,
      },
      {
        path: "templates",
        name: "NewClipForVideoInsideFolderTemplates",
        component: Templates,
      },
      {
        path: "elements",
        name: "NewClipForVideoInsideFolderElements",
        component: Elements,
      },
    ],
  },
  {
    path: "/demo",
    name: "LoadDemo",
    component: LoadDemo,
  },
  {
    path: "/demo-edit/:uuid/clip/:clipUuid",
    name: "DemoEditClipForVideo",
    component: DemoVideoEdit,
    beforeEnter: isDemo(),
    children: [
      {
        path: "subtitles",
        name: "DemoEditClipForVideoSubtitles",
        component: Subtitles,
      },
      {
        path: "text",
        name: "DemoEditClipForVideoText",
        component: Text,
      },
      {
        path: "images",
        name: "DemoEditClipForVideoImages",
        component: Images,
      },
      {
        path: "templates",
        name: "DemoEditClipForVideoTemplates",
        component: Templates,
      },
      {
        path: "elements",
        name: "EditClipForVideoElements",
        component: Elements,
      },
    ],
  },
  {
    path: "/demo-edit/:uuid",
    name: "EditVideoOnly",
    component: DemoVideoEdit,
    beforeEnter: isDemo(),
    children: [
      {
        path: "subtitles",
        name: "DemoEditVideoOnlySubtitles",
        component: Subtitles,
      },
      {
        path: "text",
        name: "DemoEditVideoOnlyText",
        component: Text,
      },
      {
        path: "images",
        name: "DemoEditVideoOnlyImages",
        component: Images,
      },
      {
        path: "templates",
        name: "DemoEditVideoOnlyTemplates",
        component: Templates,
      },
      {
        path: "elements",
        name: "DemoEditVideoOnlyElements",
        component: Elements,
      },
    ],
  },
  {
    path: "/demo-video/:uuid",
    name: "DemoVideo",
    component: DemoVideoClips,
    beforeEnter: isDemo(),
  },
  {
    path: "/demo-video/:uuid/clips",
    name: "DemoClipsForVideo",
    component: DemoVideoClips,
    beforeEnter: isDemo(),
  },
  {
    path: "/demo-video/:uuid/clip/:clipUuid",
    name: "DemoClipForVideo",
    component: DemoVideoClips,
    beforeEnter: isDemo(),
  },
  {
    path: "/",
    name: "Home",
    component: Videos,
    beforeEnter: isAuth(),
  },
  {
    path: "*",
    name: "Home",
    component: Videos,
    beforeEnter: isAuth(),
  },
  {
    path: "/notfound",
    name: "NotFound",
    component: NotFound,
    beforeEnter: isAuth(),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
