import types from "../types";

const state = {
  messages:[],
  showChat:false
};


const mutations = {
  [types.chatWithVideo.mutations.ADD_MESSAGE]: (state, payload) => {
    state.messages = [...state.messages, payload];
  },
  [types.chatWithVideo.mutations.SHOW_CHAT_WINDOW]: (state, payload) => {
    state.showChat = payload;
  },
};
export default {
  state,
  mutations
};
