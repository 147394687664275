<template>
  <v-snackbar
    :style="{ marginTop: index * 60 + 'px' }"
    v-model="message"
    :timeout="timeout"
    top
    min-width="fit-content"
  >
    {{ errorMessage }}
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ErrorMessage",
  props: {
    errorMessage: {
      type: String,
      default: String,
    },
    snackbar: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      timeout: (state) => state.app.errorMessageTimeout,
    }),
    message: {
      get() {
        return !!this.errorMessage;
      },
      set(val) {
        return val;
      },
    },
  },
};
</script>
