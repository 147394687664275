import types from "../store/types";

const setLoadingAndError = (commit, isLoaderVisible, errors) => {
  commit(types.app.mutations.SET_IS_LOADER_VISIBLE, isLoaderVisible);
  if (errors && errors.response){
    const keys = Object.keys(errors.response.data)
    const error = errors.response.data[keys[0]][0]
    commit(types.app.mutations.SET_ERROR_MESSAGE, [error]);
  }
  else if(errors && errors.detail){
    commit(types.app.mutations.SET_ERROR_MESSAGE, [errors.detail])
  }
  else{
    if (errors && !errors.response){
      commit(types.app.mutations.SET_ERROR_MESSAGE, ['Error - Please try again or contact us.']);
    }
  } 
};

export default setLoadingAndError;
