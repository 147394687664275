var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"videos"},[_c('div',{staticClass:"videos__wrapper"},[_c('div',{staticClass:"videos__wrapper__header"},[_vm._m(0),(!_vm.isEmptyAccount)?_c('div',{staticClass:"videos__wrapper__create--btn ml-auto"},[_c('v-menu',{attrs:{"offset-y":"","nudge-top":-12,"rounded":"","nudge-left":60},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","ripple":false,"width":"148","height":"48","id":"no-background-hover"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"font-weight-bold text-capitalize"},[_vm._v("New")])])]}}],null,false,1486268180)},[_c('v-list',{staticClass:"videos__wrapper__create--menu"},[_c('v-list-item',{staticClass:"videos__wrapper__create--menu-item",on:{"click":function($event){return _vm.showNewFolderDialog(true)}}},[_c('v-list-item-title',[_vm._v("Folder")])],1),_c('v-divider'),_c('v-list-item',{staticClass:"videos__wrapper__create--menu-item",on:{"click":function($event){return _vm.showUploadDialog(true)}}},[_c('v-list-item-title',[_vm._v("Upload video")])],1)],1)],1)],1):_vm._e()]),_c('div',{staticClass:"videos__playground"},[_c('NewFolder',{attrs:{"isNewFolderDialogVisible":_vm.isNewFolderDialogVisible,"showNewFolderDialog":_vm.showNewFolderDialog}})],1),(_vm.isUploadVideoDialogVisible)?_c('UploadVideoDialog',{attrs:{"isUploadVideoDialogVisible":_vm.isUploadVideoDialogVisible,"showUploadDialog":_vm.showUploadDialog,"showFloatingUploadWindow":_vm.showFloatingUploadWindow}}):_vm._e(),(!_vm.isEmptyAccount)?_c('div',[_c('FoldersGrid',{attrs:{"folders":_vm.folders.results}}),_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
          callback: _vm.fetchMoreFolders,
          intersection: {
            threshold: 0.3,
          },
        }),expression:"{\n          callback: fetchMoreFolders,\n          intersection: {\n            threshold: 0.3,\n          },\n        }"}]}),_c('VideosGrid',{attrs:{"videos":_vm.videos,"clipSpaceLg":3,"clipSpaceMd":6},on:{"deleted":_vm.removeVideo}})],1):_vm._e(),(_vm.isEmptyAccount)?_c('div',{staticClass:"videos__empty-playground"},[_c('UploadVideo',{attrs:{"showUploadDialog":_vm.showUploadDialog,"text":_vm.emptyFolderText}})],1):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[(_vm.isHomePageLoaderVisible)?_c('v-progress-circular',{attrs:{"width":4,"size":40,"color":"#fbd458","indeterminate":""}}):_vm._e()],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"videos__wrapper__heading"},[_c('p',{staticClass:"videos__wrapper__heading_title mb-2"},[_vm._v("My Folders")]),_c('p',{staticClass:"videos__wrapper__heading_subtitle"},[_vm._v(" Access your videos and clips all in one place ")])])}]

export { render, staticRenderFns }