<template>
  <div class="upload-video-wrapper">
    <div class="upload-video-wrapper__content">
      <img class="mb-3" src="../../assets/images/new-folder.svg" alt="logo" />
      <div class="text-h5 font-weight-light mb-5">
        <label>{{ text || "Your Folder is empty" }}</label>
      </div>
      <v-btn
        text
        :ripple="false"
        width="214"
        height="48"
        @click="showUploadDialog(true)"
      >
        <span class="font-weight-bold text-capitalize">Upload Video</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showUploadDialog", "text"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/videos/upload-video.scss";
</style>
