import axios from "axios";
import {
  setUserTokenCookie,
  getAccessTokenFromCookie,
  getUserRefreshTokenCookie,
} from "./Cookies";
import router from "../router/router";
import store from "../store";
import types from "../store/types";
import * as APIs from "../constants/APIs";

const CancelToken = axios.CancelToken;
let cancelTokenSource = CancelToken.source();

function setCancelTokenSource() {
  cancelTokenSource = CancelToken.source();
}

async function userSignUp(payload) {
  const response = await post(APIs.USERS, payload);

  return response.data;
}

async function activateUser(payload) {
  const response = await post(APIs.ACTIVATE_USER, payload);

  return response.data;
}

async function resetPassword(payload) {
  const response = await post(APIs.RESET_PASSWORD, payload);

  return response.data;
}

async function resetPasswordConfirm(payload) {
  const response = await post(APIs.RESET_PASSWORD_CONFIRM, payload);

  return response.data;
}

async function userLogin(payload) {
  const response = await post(APIs.USER_LOGIN, payload);

  return response.data;
}

async function userProfile(token) {
  const response = await get(APIs.USER_PROFILE, authorizationHeader(token));

  return response.data;
}

async function resendVerificationToUser(payload) {
  const response = await post(APIs.RESEND_VERIFICATION_TO_USER, payload);

  return response.data;
}

async function updateUser(payload) {
  const response = await patch(
    `${APIs.UPDATE_USER}`,
    payload,
    authorizationHeader()
  );

  return response.data;
}

async function createFolder(payload) {
  const response = await post(
    APIs.CREATE_FOLDER,
    payload,
    authorizationHeader()
  );

  return response.data;
}

async function updateFolder(uuid, payload) {
  const response = await patch(
    `${APIs.UPDATE_FOLDER}${uuid}/`,
    payload,
    authorizationHeader()
  );

  return response.data;
}

async function fetchFolders(page = 1) {
  const response = await get(APIs.FETCH_FOLDERS, {
    params: { page },
    ...authorizationHeader(),
  });

  return response.data;
}

async function fetchFolder(uuid) {
  const response = await get(
    `${APIs.FETCH_FOLDERS}${uuid}/`,
    authorizationHeader()
  );

  return response.data;
}

async function fetchVideos(page = 1) {
  const response = await get(APIs.FETCH_VIDEOS, {
    params: { page },
    ...authorizationHeader(),
  });

  return response.data;
}

async function fetchVideo(uuid) {
  const response = await get(
    `${APIs.FETCH_VIDEOS}${uuid}/`,
    authorizationHeader()
  );

  return response.data;
}

async function fetchSubtitlesForVideo(uuid) {
  const subtitlesStorage = await get(
    `${APIs.FETCH_SUBTITLES}${uuid}/subtitles/json/`,
    authorizationHeader()
  );

 const response =  await get(subtitlesStorage.data.subtitle_url)
  return response.data;
}

const onUploadProgress = (payload, progressEvent) => {
  var percentCompleted = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total
  );
  store.commit(types.project.mutations.UPDATE_UPLOADING_VIDEO_PERCENTAGE, {
    name: payload.get("video_file").name,
    percentage: percentCompleted,
    uploadedSize: progressEvent.loaded,
  });
};
const uploadMultipleVideos = async (files)=> {
  const uploadPromises = files.map(async (file) => {
    return uploadVideo(file.file); // Pass file name for identification
  });

  const uploadedVideos = await Promise.all(uploadPromises);

  return uploadedVideos;
}


const onUploadImageLayerProgress = (payload, progressEvent) => {
  var percentCompleted = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total
  );

  store.commit(types.project.mutations.UPDATE_UPLOADING_VIDEO_PERCENTAGE, {
    name: payload.image.name,
    percentage: percentCompleted,
    uploadedSize: progressEvent.loaded,
  });
};

async function uploadVideo(payload) {
  const response = await postMultipart(
    APIs.UPLOAD_VIDEO,
    payload,
    multiPartAuthorizationHeader(),
    onUploadProgress.bind(this, payload)
  );

  return response.data;
}

async function uploadYoutubeVideo(payload) {
  const response = await post(
    APIs.UPLOAD_YOUTUBE_VIDEO,
    payload,
    authorizationHeader()
  );

  return response.data;
}

async function preUploadVideo(payload) {
  try {
    const response = await post(
      APIs.PRE_UPLOAD_VIDEO,
      payload,
      authorizationHeader()
    );
    return response;
  } catch (error) {
    if('youtube_url' in error){
      throw {detail:'Enter a valid URL.'};
    }
    else
    throw error;
  }
}

async function updateVideo(payload) {
  const response = await patch(
    `${APIs.UPDATE_VIDEO}${payload.uuid}/`,
    { ...payload },
    authorizationHeader()
  );

  return response.data;
}

async function googleAuthUser(payload) {
  const response = await post(APIs.GOOGLE_AUTH_USER, payload);

  return response.data;
}

async function fetchFonts() {
  const response = await get(APIs.FONTS, authorizationHeader());

  return response.data;
}
async function uploadFonts(payload) {
  const response = await post(APIs.USER_FONTS, payload.formData,
    authorizationHeader());

  return response.data;
}

async function fetchPlans() {
  const response = await get(APIs.PLANS, authorizationHeader());

  return response.data;
}

async function buyPlan(payload) {
  const response = await post(APIs.BUY_PLAN, payload, authorizationHeader());

  return response.data;
}

async function exportClip(uuid, payload) {
  const response = await post(
    `${APIs.CLIPS}${uuid}/export/`,
    payload,
    authorizationHeader()
  );

  return response.data;
}

async function fetchExportedClip(uuid) {
  if(uuid){
    const response = await get(
      `${APIs.CLIPS}${uuid}/export/`,
      authorizationHeader()
    );
    return response.data;
  }
}

async function fetchExportesByStatus(status) {
  const response = await get(
    `${APIs.EXPORT}?status=${status}`,
    authorizationHeader()
  );
  return response.data;
}


async function createNewClip(payload) {
  const response = await post(APIs.CLIPS, payload, authorizationHeader());

  return response.data;
}

async function fetchVideoClips(videoUuid, page) {
  const response = await get(`${APIs.CLIPS}?video=${videoUuid}`, {
    params: { page },
    ...authorizationHeader(),
  });

  return response.data;
}

async function fetchVideoClip(clipUuid, ratio) {
  const response = await get(ratio?`${APIs.CLIPS}${clipUuid}/?aspect_ratio=${ratio}`:`${APIs.CLIPS}${clipUuid}/`
    ,
    authorizationHeader()
  );

  return response.data;
}

async function updateClip(payload) {
  const { clipUuid, ...rest } = payload;
  const response = await patch(
    `${APIs.CLIPS}${clipUuid}/`,
    rest,
    authorizationHeader()
  );

  return response.data;
}

async function patchClip(payload) {
  const { clipUuid, ...rest } = payload;
  const response = await patch(
    `${APIs.CLIPS}${clipUuid}/`,
    rest,
    authorizationHeader()
  );

  return response.data;
}

async function fetchClipStyle(clipUuid) {
  const response = await get(
    `${APIs.CLIPS}${clipUuid}/layers/subtitles/`,
    authorizationHeader()
  );

  return response.data;
}

async function fetchVideoWaveform(videoUuid) {
  const waveStroage = await get(
    `${APIs.VIDEO_WAVEFORM}${videoUuid}/audio/waveform/json/`,
    authorizationHeader()
  );

  const response =  await get(waveStroage.data.waveform_url)
  return response.data;
}

async function updateClipStyle(payload) {
  const { clipUuid, ...rest } = payload;
  const response = await put(
    `${APIs.CLIPS}${clipUuid}/layers/subtitles/`,
    rest,
    authorizationHeader()
  );

  return response.data;
}

async function updateVideoSubtitle(payload) {
  const { uuid, data } = payload;
  const response = await put(
    `${APIs.UPDATE_SUBTITLES}${uuid}/subtitles/`,
    data,
    authorizationHeader()
  );

  return response.data;
}


async function updateSubtitleTrimedDutations(payload) {
  const { clipUuid, data } = payload;
  const response = await put(
    `${APIs.CLIPS}${clipUuid}/trim/`,
    data,
    authorizationHeader()
  );

  return response.data;
}

async function downloadVideoSRT(uuid) {
  const response = await get(
    `${APIs.VIDEOS}${uuid}/subtitles/srt/`,
    authorizationHeader()
  );

  return response;
}

async function downloadVideoText(uuid) {
  const response = await get(
    `${APIs.VIDEOS}${uuid}/subtitles/text/`,
    authorizationHeader()
  );

  return response;
}

async function downloadClipSRT(uuid) {
  const response = await get(
    `${APIs.CLIPS}${uuid}/subtitles/srt/`,
    authorizationHeader()
  );

  return response;
}

async function downloadClipText(uuid) {
  const response = await get(
    `${APIs.CLIPS}${uuid}/subtitles/text/`,
    authorizationHeader()
  );

  return response;
}

async function deleteClip(uuid) {
  const response = await deletion(
    `${APIs.CLIPS}${uuid}/`,
    authorizationHeader()
  );

  return response;
}

async function deleteVideo(uuid) {
  const response = await deletion(
    `${APIs.VIDEOS}${uuid}/`,
    authorizationHeader()
  );

  return response;
}

async function deleteFolder(uuid) {
  const response = await deletion(
    `${APIs.FOLDERS}${uuid}/`,
    authorizationHeader()
  );

  return response;
}

async function fetchTextLayer(uuid) {
  const response = await get(
    `${APIs.TEXT_LAYER}?clip=${uuid}`,
    authorizationHeader()
  );

  return response.data;
}

async function fetchImageLayers(uuid) {
  const response = await get(
    `${APIs.CLIPS}image-layers/?clip=${uuid}`,
    authorizationHeader()
  );

  return response.data;
}

async function createImageLayer(payload) {
  const response = await postMultipart(
    `${APIs.CLIPS}image-layers/`,
    payload.formData,
    multiPartAuthorizationHeader(),
    onUploadImageLayerProgress.bind(this, payload)
  );

  return response.data;
}

async function createTextLayer(payload) {
  const response = await post(
    `${APIs.CLIPS}text-layers/`,
    payload,
    authorizationHeader()
  );

  return response.data;
}

async function updateTextLayer(payload) {
  const { uuid } = payload;

  const response = await put(
    `${APIs.TEXT_LAYER}${uuid}/`,
    payload,
    authorizationHeader()
  );

  return response.data;
}

async function updateImageLayer(payload) {
  const { uuid } = payload;

  const response = await patch(
    `${APIs.CLIPS}image-layers/${uuid}/`,
    payload,
    authorizationHeader()
  );

  return response.data;
}

async function deleteTextLayer(uuid) {
  const response = await deletion(
    `${APIs.TEXT_LAYER}${uuid}/`,
    authorizationHeader()
  );

  return response;
}

async function deleteImageLayer(uuid) {
  const response = await deletion(
    `${APIs.CLIPS}image-layers/${uuid}/`,
    authorizationHeader()
  );

  return response;
}

async function fetchVideoSpeakers(uuid) {
  const response = await get(
    `${APIs.VIDEOS}${uuid}/speakers/`,
    authorizationHeader()
  );

  return response.data;
}

async function setPassword(payload) {
  const response = await post(
    `${APIs.USERS}set_password/`,
    payload,
    authorizationHeader()
  );

  return response.data;
}

async function clipResizeCropSquare(payload) {
  const {x, uuid} = payload
  const response = await post(
    `${APIs.CLIPS}${uuid}/resize/crop/square/`,
    {x:x},
    authorizationHeader()
  );

  return response.data;
}
async function clipResizeCropVertical(payload) {
  const {x, uuid} = payload
  const response = await post(
    `${APIs.CLIPS}${uuid}/resize/crop/vertical/`,
    {x:x},
    authorizationHeader()
  );

  return response.data;
}
async function clipResizeFitSquare(uuid) {
  const response = await post(
    `${APIs.CLIPS}${uuid}/resize/fit/square/`,
    {},
    authorizationHeader()
  );

  return response.data;
}
async function clipResizeFitVertical(uuid) {
  const response = await post(
    `${APIs.CLIPS}${uuid}/resize/fit/vertical/`,
    {},
    authorizationHeader()
  );

  return response.data;
}

async function cleanClipAudio(uuid) {
  const response = await post(
    `${APIs.CLIPS}${uuid}/clean-audio/`,
    {},
    authorizationHeader()
  );

  return response.data;
}
async function clipResizeCropStandard4by5(payload) {
  const response = await post(
    `${APIs.CLIPS}${payload.uuid}/resize/crop/standard_4_5/`,
    {x:payload.x},
    authorizationHeader()
  );

  return response.data;
}
async function clipResizeFitStandard4by5(uuid) {
  const response = await post(
    `${APIs.CLIPS}${uuid}/resize/fit/standard_4_5/`,
    {},
    authorizationHeader()
  );

  return response.data;
}
async function clipResizeReset(uuid) {
  const response = await post(
    `${APIs.CLIPS}${uuid}/resize/reset/`,
    {},
    authorizationHeader()
  );

  return response.data;
}
async function cloneClip(uuid) {
  const response = await post(
    `${APIs.CLIPS}${uuid}/clone/`,
    {},
    authorizationHeader()
  );

  return response.data;
}

async function reportError(payload) {
  const response = await post(
    `${APIs.FEEDBACK}/bug-report/`,
    payload,
    authorizationHeader()
  );

  return response.data;
}

async function fetchSubtitles(uuid) {

  const response = await get(
    `${APIs.FETCH_SUBTITLES}${uuid}/subtitles/`,
    authorizationHeader()
  );
  return response.data;
}

async function fetchWaveform(videoUuid) {
  const response = await get(
    `${APIs.VIDEO_WAVEFORM}${videoUuid}/audio/waveform/`,
    authorizationHeader()
  );

  return response.data;
}
async function fetchkeys() {
  const response = await get(
    `${APIs.API_KEY}list/`,
    authorizationHeader()
  );

  return response.data;
}
async function removeKey(prefix) {
  console.log('prefixwewewe', prefix)
  const response = await post(
    `${APIs.API_KEY}revoke/${prefix}/`,
    {},
    authorizationHeader()
  );

  return response.data;
}
async function createKey(payload) {
  const response = await post(
    `${APIs.API_KEY}create/`,
    {payload},
    authorizationHeader()
  );

  return response.data;
}
async function autoCrop(payload) {
  const response = await post(
    `${APIs.AUTO_CROP}${payload.clipUuid}/auto-crop/?aspect_ratio=${payload.aspectRatio}`,payload.clip_cuts?{clip_cuts:payload.clip_cuts}:
    {},
    authorizationHeader()
  );

  return response.data;
}
async function getAutoCropStatus(payload) {
  const response = await get(
    `${APIs.AUTO_CROP}${payload.clipUuid}/auto-crop/?aspect_ratio=${payload.ratio}`,
    authorizationHeader()
  );

  return response.data;
}
async function updateAutoCrop(payload) {
  let data = null
  if(payload.clip_cuts){
    data = {clip_cuts: payload.clip_cuts}
  }
  else if(payload.clip_start_time_relatedto_new_buffered_clip) {
    data ={
      clip_start_time_relatedto_new_buffered_clip:payload.clip_start_time_relatedto_new_buffered_clip,
      clip_end_time_relatedto_new_buffered_clip: payload.clip_end_time_relatedto_new_buffered_clip
    }
  }
  const response = await patch(
    `${APIs.AUTO_CROP}${payload.clipUuid}/auto-crop/?aspect_ratio=${payload.aspectRatio}`,data?data:
    {},
    authorizationHeader()
  );
  return response.data
}

async function cancelSubsctiption() {
  const response = await post(
    `${APIs.CANCEL_SUBSCRIPTION}cancel/`,
    {},
    authorizationHeader()
  );

  return response.data;
}

async function fetchAutocropClip(payload){
  const response = await get(`${APIs.AUTO_CROP}${payload.clipUuid}/auto-crop/?aspect_ratio=${payload.ratio}`
    ,
    authorizationHeader()
  );

  return response.data;
}
async function chatWithVideo(payload){
  const response = await post(`${APIs.CHAT_WITH_VIDEO}`,
  {video:`${payload.video}`,
    question:`${payload.question}`
  },
  authorizationHeader())
  return response.data
}

export {
  userLogin,
  userProfile,
  userSignUp,
  createFolder,
  updateFolder,
  fetchFolders,
  fetchVideos,
  fetchSubtitlesForVideo,
  uploadVideo,
  activateUser,
  resetPassword,
  resetPasswordConfirm,
  resendVerificationToUser,
  googleAuthUser,
  setCancelTokenSource,
  cancelTokenSource,
  uploadYoutubeVideo,
  fetchPlans,
  buyPlan,
  exportClip,
  createNewClip,
  fetchVideoClips,
  fetchVideoClip,
  updateClip,
  patchClip,
  fetchFonts,
  fetchClipStyle,
  updateClipStyle,
  fetchExportedClip,
  preUploadVideo,
  fetchVideoWaveform,
  updateVideoSubtitle,
  downloadVideoSRT,
  downloadVideoText,
  deleteClip,
  deleteVideo,
  deleteFolder,
  updateVideo,
  fetchTextLayer,
  createTextLayer,
  updateTextLayer,
  deleteTextLayer,
  updateUser,
  downloadClipSRT,
  downloadClipText,
  fetchImageLayers,
  createImageLayer,
  updateImageLayer,
  deleteImageLayer,
  fetchVideoSpeakers,
  setPassword,
  fetchFolder,
  fetchVideo,
  clipResizeCropSquare,
  clipResizeCropVertical,
  clipResizeFitSquare,
  clipResizeFitVertical,
  clipResizeReset,
  cloneClip,
  updateSubtitleTrimedDutations,
  clipResizeCropStandard4by5,
  clipResizeFitStandard4by5,
  reportError,
  cleanClipAudio,
  fetchSubtitles,
  fetchWaveform,
  uploadFonts,
  fetchkeys,
  removeKey,
  createKey,
  uploadMultipleVideos,
  fetchExportesByStatus,
  cancelSubsctiption,
  autoCrop,
  getAutoCropStatus,
  fetchAutocropClip,
  chatWithVideo,
  updateAutoCrop
};

async function post(url, payload, header = {}, config) {
  try {
    const response = await axios.post(url, payload, header, config);
    return response;
  } catch (error) {
    if (error.response.status === 500)
      throw new Error("Something went wrong, Please try again!");
    else throw error.response.data;
  }
}

async function postMultipart(url, payload, headers = {}, onUploadProgress) {
  try {
    const response = await axios({
      method: "POST",
      url,
      data: payload,
      headers,
      onUploadProgress,
      cancelToken: cancelTokenSource.token,
    });
    return response;
  } catch (error) {
    if (axios.isCancel(error)) console.log(error.message);
    if (error.response.status === 500)
      throw new Error("Something went wrong, Please try again!");
    else throw error.response.data;
  }
}

async function patch(url, payload, header = {}) {
  try {
    const response = await axios.patch(url, payload, header);
    return response;
  } catch (error) {
    if (error.response.status === 500)
      throw new Error("Something went wrong, Please try again!");
    else throw error.response.data;
  }
}

async function put(url, payload, header = {}) {
  try {
    const response = await axios.put(url, payload, header);
    return response;
  } catch (error) {
    if (error.response.status === 500)
      throw new Error("Something went wrong, Please try again!");
    else throw error;
  }
}

async function get(url, params) {
  try {
    const response = await axios.get(url, params);
    return response;
  } catch (error) {
    throw error.response.data;
  }
}

async function deletion(url, params) {
  try {
    const response = await axios.delete(url, params);
    return response;
  } catch (error) {
    throw error.response.data;
  }
}

function authorizationHeader(accessToken) {
  const token = getAccessTokenFromCookie() || accessToken;
  if(window.location.href.includes('demo')){
    return {
      headers: { Authorization: `Api-Key XSFbYgi3.G5AXgDNE5GtyAwF0uInJVbyUWIaqlP9W` },
    };
  }
  return {
    headers: { Authorization: `JWT ${token}` },
  };
}

function multiPartAuthorizationHeader() {
  const token = getAccessTokenFromCookie();
  if(window.location.href.includes('demo')){
    return {
      "Content-Type": "multipart/form-data",
      headers: { Authorization: `Api-Key XSFbYgi3.G5AXgDNE5GtyAwF0uInJVbyUWIaqlP9W` },
    };
  }
  return {
    "Content-Type": "multipart/form-data",
    Authorization: `JWT ${token}`,
  };
}

axios.interceptors.request.use(async (req) => {
  const axiosInstance = axios.create();
  const accessToken = getAccessTokenFromCookie();
  const refreshToken =
    getUserRefreshTokenCookie() || store.state.user.refreshToken;
  const isResetPasswordPath =
    router.history._startLocation.includes("/password/reset");
  const demoPath =
    router.history._startLocation.includes("/demo");
    if(demoPath){
      return req
    }
  if (!accessToken && refreshToken) {
    const response = await axiosInstance.post(APIs.USER_AUTO_LOGIN, {
      refresh: refreshToken,
    });
    const { access: token } = response.data;
    setUserTokenCookie(token);
    req.headers.Authorization = `JWT ${token}`;
  }
  if (!refreshToken && !isResetPasswordPath && !demoPath) {
    store.dispatch(types.user.actions.USER_LOGOUT);
    router.push("/sign-in").catch(() => {});
  }
  return req;
});

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (!originalConfig.url.includes("/auth/jwt/create/") && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        // checking is refresh token is expire then logout the user
          if(err.response.config.url.includes("auth/jwt/refresh/")){

            // message is wrapped in settimeout function so it keep showing after the return statement
            setTimeout(()=>{
              store.commit(types.app.mutations.SET_ERROR_MESSAGE, ["Your session has expired, please login again"])
            })
            
            //redirect user to signin page after 3 seconds
            setTimeout(()=>{
              store.dispatch(types.user.actions.USER_LOGOUT);
              router.push("/sign-in").catch(() => {});
            }, 3000)
            return
          }
        try {
          const refreshToken =
          getUserRefreshTokenCookie() || store.state.user.refreshToken;
          if(refreshToken) {
            const response = await axios.post(APIs.USER_AUTO_LOGIN, {
              refresh: refreshToken,
            });
            const { access: token } = response.data;
            setUserTokenCookie(token);
            originalConfig.headers.Authorization = `JWT ${token}`
            return axios(originalConfig);
          }
         
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);
