<template>
  <div>
  <HeaderLogo />
  <div class="sign-in-page">
    <div class="sign-in-page">
      <div class="sign-in-page__right">
        <SignUp />
      </div>
      <div class="sign-in-page__right sign-in-page__testimonials">
        <Testimonials/>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import SignUp from "../components/Signup.vue";
import Testimonials from "./Testimonials.vue";
import HeaderLogo from "../components/HeaderLogo.vue";
export default {
  components: { SignUp, Testimonials, HeaderLogo },
  name: "SignUpPage",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/views/signin.scss";
</style>
