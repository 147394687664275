import { render, staticRenderFns } from "./Avatar.vue?vue&type=template&id=ad4afb98&scoped=true&"
import script from "./Avatar.vue?vue&type=script&lang=js&"
export * from "./Avatar.vue?vue&type=script&lang=js&"
import style0 from "./Avatar.vue?vue&type=style&index=0&id=ad4afb98&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad4afb98",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VAvatar,VBtn,VIcon,VList,VListItem,VListItemTitle,VMenu})
