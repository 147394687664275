<template>
  <div>
  <v-toolbar
    dense
    elevation="0"
    height="56"
    max-height="56">
    <div
      class="navbar__logo"
      @click="$router.push('/').catch(() => {})">
      <img src="@/assets/images/logo.png" alt="logo" />
    </div>
    <v-spacer />
    <v-btn class="d-lg-none" @click="showMenu"><v-icon> {{mobileMenu?'mdi-close':'mdi-menu'}} </v-icon></v-btn>
    <div class="d-flex menu-wrapper flex-column flex-lg-row align-center" :class="[{ 'show-menu': mobileMenu }]">
      <div class="minutes-stats" v-if="user.uuid && !notSubscribed()" :title="'Click to see minutes history'" @click="openHistory">
        <span class="text-subtitle-2 font-weight-light">
          (
          {{ getUsedMinutes() }}
          of
          {{ getTotalMinutes() }} mins used )
        </span>
        <v-progress-linear class="progressbar" height="5" :value="percentageRange"></v-progress-linear>
      </div>
      <div v-if="isTrialEnded() && !notSubscribed()">
        <p class="text-subtitle-2 mb-0 ml-2 font-weight-bold">
          Trial Ended, Please
        </p>
      </div>
      <v-btn
        class="outer-link mr-3 ml-3"
        text
        outlined
        color="primary"
        href="https://www.chopcast.io/content-repurposing-service"
        target="_blank"
      >
        <span class="font-weight-bold text-capitalize text-subtitle-1"
          >Try human editors? (1 week free)</span
        >
      </v-btn>
      <v-btn
        class="outer-link"
        text
        outlined
        color="primary"
        @click="$router.push('/upgrade').catch(() => {})"
      >
        <span class="font-weight-bold text-capitalize text-subtitle-1"
          >upgrade</span
        >
      </v-btn>
      <Avatar />
    </div>
  </v-toolbar>
  <v-dialog v-model="showHistory" max-width="1100">
    <v-card>
      <v-card-title  class="d-flex justify-space-between align-center">
          <span>Upload history</span>
          <v-btn icon @click="showHistory = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="uploadHisotry"
          :items-per-page="5"
          class="elevation-1"
        >
        <template v-slot:item._youtube_url="{ item }">
            {{item._youtube_url?'YouTube upload':'System Upload'  }}
        </template>
        <template v-slot:item.duration="{ item }">
            {{ secondsToMinutesString(item.duration)}}
        </template>
        <template v-slot:item.created_at="{ item }">
            {{ dateFormat(item.created_at)}}
        </template>
      </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import { mapState } from "vuex";
import Avatar from "../shared/Avatar";

export default {
  name: "Navbar",
  data() {
    return {
      showHistory: false,
      headers: [
          {
            text: 'Source',
            align: 'start',
            sortable: false,
            value: '_youtube_url',
          },
          { text: 'Title', value: 'name' },
          { text: 'Minutes Used', value: 'duration' },
          { text: 'Upload Time', value: 'created_at' },
        ],
        mobileMenu:false
      }
  },
  components: {
    Avatar,
  },
  mounted() {
    if (this.user.plan) {
      let classNumber = 0;

      const { classList } = document.querySelector(
        ".v-progress-linear__determinate"
      );
      classList.remove("primary");
      if (this.percentageRange >= 0 && this.percentageRange < 33)
        classNumber = 0;
      else if (this.percentageRange >= 33 && this.percentageRange < 66)
        classNumber = 1;
      else if (this.percentageRange >= 66 && this.percentageRange <= 100)
        classNumber = 2;

      classList.add(`v-progress-linear__gradient-${classNumber}`);
    }
  },
  methods: {
    showMenu(){
      this.mobileMenu = !this.mobileMenu
    },
    isTrialEnded() {
      const TrialDateIsPassed =
        new Date().getTime() > new Date(this.user.trial_ends).getTime();

      return !this.user.in_trial && TrialDateIsPassed && !this.user.plan;
    },
    notSubscribed() {
      return !this.user.in_trial && !this.user.trial_ends && !this.user.plan;
    },
    getTotalMinutes() {
      let totalMinutes = 0;
      if (this.user && this.user?.seconds_limit) {
        totalMinutes = Math.ceil(this.user.seconds_limit / 60);

        if (this.user?.plan) {
          if (this.user?.plan?.s_recurring_interval === "year")
            totalMinutes = totalMinutes / 12;
        }
      }
      return totalMinutes.toFixed(0);
    },
    getUsedMinutes() {
      let usedMinutes = 0;
      if (this.user?.plan_used_seconds)
        usedMinutes = Math.ceil(this.user.plan_used_seconds / 60);

      return usedMinutes;
    },
    openHistory() {
      this.showHistory = true
    },
    secondsToMinutesString(seconds) {
    // Calculate the number of minutes and seconds
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      
      // Format the result as "minute:second"
      const formattedTime = minutes + ":" + (remainingSeconds < 10 ? "0" : "") + remainingSeconds;
      
      return formattedTime;
    },

    dateFormat(date) {
      const inputDateString = date;

      // Parse the input date string into a JavaScript Date object
      const inputDate = new Date(inputDateString);

      // Specify the desired options for formatting
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      };

      // Create a DateTimeFormat object and format the date
      return new Intl.DateTimeFormat('en-US', options).format(inputDate);
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      uploadHisotry: (state) =>{
        return state.user.user.seconds_used_per_video?state.user.user.seconds_used_per_video:[]}
    }),
    percentageRange() {
      const { plan_used_seconds, seconds_limit } = this.user;
      return (plan_used_seconds / seconds_limit) * 100;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/shared/navbar.scss";
.minutes-stats{
  cursor: pointer;
  .progressbar {
    border: 1px solid lightgray;
    border-radius: 4px;
}
}
.menu-wrapper{
    @media screen and (max-width:1263.98px){
      position: fixed;
      top: 56px;
      left:0;
      width: 100%;
      background-color: white;
      height: calc(100vh - 56px);
      padding-top: 60px;
      transform:translateX(-100%);
      transition: transform 0.3s ease;
      gap: 16px;
    }
  }
.show-menu{
  transform:translateX(0);
}
.v-toolbar{
  z-index: 99;
}
</style>
