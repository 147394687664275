const VUE_APP_BASE_API = process.env.VUE_APP_BASE_API;
const VUE_APP_API_VERSION = "/api/v1";
const VUE_APP_API = `${VUE_APP_BASE_API}${VUE_APP_API_VERSION}`;

const USERS = `${VUE_APP_API}/auth/users/`;
const JWT = `${VUE_APP_API}/auth/jwt/`;
const SOCIAL = `${VUE_APP_API}/auth/social/`;
const FOLDERS = `${VUE_APP_API}/folders/`;
const VIDEOS = `${VUE_APP_API}/videos/`;
const CLIPS = `${VUE_APP_API}/clips/`;
const SUBSCRIPTIONS = `${VUE_APP_API}/subscriptions/`;
const FONTS = `${VUE_APP_API}/fonts/`;
const USER_FONTS = `${VUE_APP_API}/user-fonts/`;
const TEXT_LAYER = `${CLIPS}text-layers/`;

const USER_SIGNUP = `${USERS}users/`;
const USER_LOGIN = `${JWT}create/`;
const USER_AUTO_LOGIN = `${JWT}refresh/`;
const USER_TOKEN_VERIFY = `${JWT}verify/`;
const USER_PROFILE = `${USERS}me/`;
const UPDATE_USER = `${USERS}me/`;
const ACTIVATE_USER = `${USERS}activation/`;
const RESET_PASSWORD = `${USERS}reset_password/`;
const RESET_PASSWORD_CONFIRM = `${USERS}reset_password_confirm/`;
const RESEND_VERIFICATION_TO_USER = `${USERS}resend_activation/`;

const CREATE_FOLDER = FOLDERS;
const UPDATE_FOLDER = FOLDERS;
const FETCH_FOLDERS = FOLDERS;

const UPLOAD_VIDEO = VIDEOS;
const UPLOAD_YOUTUBE_VIDEO = `${VIDEOS}youtube/`;
const FETCH_VIDEOS = VIDEOS;
const FETCH_SUBTITLES = VIDEOS;
const UPDATE_SUBTITLES = VIDEOS;
const VIDEO_WAVEFORM = VIDEOS;
const UPDATE_VIDEO = VIDEOS;

const GOOGLE_AUTH_USER = `${SOCIAL}google/`;
const PLANS = `${SUBSCRIPTIONS}products/`;
const BUY_PLAN = `${SUBSCRIPTIONS}checkout/session/`;

const PRE_UPLOAD_VIDEO = `${VIDEOS}preupload/check/`;
const FEEDBACK = `${VUE_APP_API}/feedback`;
const API_KEY =`${VUE_APP_API}/key/`;
const EXPORT = `${VUE_APP_API}/export/`;
const CANCEL_SUBSCRIPTION =`${VUE_APP_API}/subscriptions/`
const AUTO_CROP =`${VUE_APP_API}/auto-crop/`
const CHAT_WITH_VIDEO =`${VUE_APP_API}/chat-with-video`

export {
  USERS,
  USER_LOGIN,
  USER_AUTO_LOGIN,
  USER_PROFILE,
  USER_SIGNUP,
  FOLDERS,
  CREATE_FOLDER,
  UPDATE_FOLDER,
  FETCH_FOLDERS,
  VIDEOS,
  FETCH_VIDEOS,
  UPDATE_VIDEO,
  FETCH_SUBTITLES,
  UPDATE_SUBTITLES,
  ACTIVATE_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_CONFIRM,
  RESEND_VERIFICATION_TO_USER,
  GOOGLE_AUTH_USER,
  UPLOAD_VIDEO,
  UPLOAD_YOUTUBE_VIDEO,
  USER_TOKEN_VERIFY,
  PLANS,
  BUY_PLAN,
  CLIPS,
  FONTS,
  PRE_UPLOAD_VIDEO,
  VIDEO_WAVEFORM,
  TEXT_LAYER,
  UPDATE_USER,
  FEEDBACK,
  USER_FONTS,
  API_KEY,
  EXPORT,
  CANCEL_SUBSCRIPTION,
  AUTO_CROP,
  CHAT_WITH_VIDEO
};
