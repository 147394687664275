<template>
    <div class="colors-wrapper">
        <div
          v-for="color in colors"
          :key="color"
          :class="[
            'color-option',
            { 'transparent-color': color === '#FFFFFF00' },
          ]"
          :style="{
            backgroundColor: color !== '#FFFFFF00'? color:'',
            border: color === '#FFFFFF' ? '1px solid #000000' : 'none',
          }"
         @click="onSelectColor(color)"
        ></div>
        <div class="color-text">
          hex:
          <input
            id="color-hex"
            style="padding: 4px"
            v-model="selectedColor"
            @input="updateSelectedColor"
            @keydown.enter="closeInput"
          />
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import types from "../store/types";
export default {
    props: {
        colors: Array,
        color:String
    },
    data(){
        return {
            selectedColor:""
        }
    },
    mounted(){
        this.selectedColor = this.color
    },
    methods:{
        ...mapMutations({
            setErrorMessage: types.app.mutations.SET_ERROR_MESSAGE,
        }),
        onSelectColor(color){
            this.selectedColor = color
            this.$emit('onSelectColor', color)
        },
        updateSelectedColor(e){
            if(e.target.value.length >7){
                if(!this.isValidHexCode(e.target.value)){
                this.setErrorMessage(["Invalid color code"]);
                }
                else{
                    this.selectedColor = e.target.value
                    this.$emit('onSelectColor', this.selectedColor)
                }
            } 
        },
        closeInput(){
            if(!this.isValidHexCode(this.selectedColor)){
                this.setErrorMessage(["Invalid color code"]);
            }
            else{
                this.$emit('onSelectColor', this.selectedColor)
            }
        },
        isValidHexCode(colorCode) {
        // Define a regular expression pattern for a valid hexadecimal color code
        const hexPattern = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;

        // Test the color code against the pattern
        return hexPattern.test(colorCode);
        
    }
    }
}
</script>
<style lang="scss" scoped>
    .colors-wrapper {
        margin-top: 0px;
        margin-left: 0px;
        padding: 15px;
        .color-option{
            padding: 10px;
            width: 26px;
            height: 26px;
            float: left;
            border-radius: 13px;
            margin-right: 13px;
            cursor: pointer;
        }
        .color-text {
            display: flex;
            align-items: baseline;
            border: 1px solid #f2f2f2;
            border-radius: 10px;
            color: rgba(50,50,50,.8);
            font-weight: 400;
            font-size: 12px;
            padding: 0px 10px;
            margin: 0;
        }
        .transparent-color{
            background-image: url(".../../../assets/images/icons/transparent.svg");
        }
    }
</style>