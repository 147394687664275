<template>
  <div
    class="edit--navbar">
    <div
      class="edit--navbar__links">
      <div
        v-if="showReturnToClip"
        class="edit--navbar__links-item"
        @click="goToPrevious">
        <v-icon size="x-large" color="#949494">mdi-arrow-left-top</v-icon>
        <p>{{ isClip() }}</p>
      </div>
      <hr v-if="showReturnToClip" class="divider" />
      <router-link v-if="!showReturnToClip" :to="`/demo-video/${$route.params.uuid}`">
        <div class="edit--navbar__links-item">
          <v-icon
            size="x-large"
            :color="isActive('Subtitles') ? '#FFC145' : '#949494'"
            >mdi-video</v-icon
          >
          <p>Clips</p>
        </div>
      </router-link>
      <router-link :to="`${baseUrl}/subtitles`">
        <div class="edit--navbar__links-item">
          <v-icon
            size="x-large"
            :color="isActive('Subtitles') ? '#FFC145' : '#949494'"
            >mdi-subtitles</v-icon
          >
          <p>Subtitles</p>
        </div>
      </router-link>
      <router-link :to="`${baseUrl}/text`">
        <div class="edit--navbar__links-item">
          <v-icon
            size="x-large"
            :color="isActive('Text') ? '#FFC145' : '#949494'"
            >mdi-format-textbox</v-icon>
          <p>Add text</p>
        </div>
      </router-link>
      <router-link :to="`${baseUrl}/images`">
        <div class="edit--navbar__links-item">
          <v-icon
            size="x-large"
            :color="isActive('Images') ? '#FFC145' : '#949494'"
            >mdi-camera-plus</v-icon>
          <p>Add images</p>
        </div>
      </router-link>
      <div v-if="showEnhanceAudio" class="edit--navbar__links-item" style="position: relative;" @click="onOpenEnhanceAudio">
        <v-progress-circular
        v-if="showButtonLoader"
        indeterminate
        color="amber"
        style="position: absolute;"
        ></v-progress-circular>
          <v-icon
            :disabled="showButtonLoader"
            size="x-large"
            :color="isActive('Images') ? '#FFC145' : '#949494'"
            >mdi-tune</v-icon>
          <p>Enhance Audio</p>
        </div>
        <div class="edit--navbar__links-item" style="position: relative;" @click="openPartnerPage">
            <v-icon
              size="x-large"
              :color="isActive('Images') ? '#FFC145' : '#949494'"
              >mdi-coin</v-icon>
            <p>Refer a friend</p><p style="padding-top: 5px;"> (40% payouts)</p>
        </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import types from "../../store/types";
export default {
  name: 'EditFlowNavbar',
  props: [
    'baseUrl',
    'onClickLogo',
    'previousRoute'
  ],
  data() {
    return {
      progress: 75
    };
  },
  computed:{
    ...mapState({
      showButtonLoader:(state) => state.app.showButtonLoader,
    }),
    showEnhanceAudio(){
      return this.$route.params.clipUuid ? true: false 
    },
    showReturnToClip(){
      return this.$route.path.includes('demo') ? false: true 
    }
  },
  methods: {
    ...mapMutations({
      openEnhanceAudio: types.project.mutations.OPEN_AUDIO_ENHANCE_DIALOG,
    }),
    goToPrevious () {
        this.$router.push(this.previousRoute);
    },
    isMainActive () {
      const mainTabsName = [
        'EditClipForVideo',
        'EditClipForVideoInsideFolder',
        'EditVideoOnly',
        'EditVideoInsideFolder',
        'NewClipForVideo',
        'NewClipForVideoInsideFolder',
      ];
      return mainTabsName.includes(this.$route.name);
    },
    isActive (name) {
      return this.$route.name.includes(name);
    },
    isClip () {
      return this.$route.params.clipUuid ? 'Return to Clips' : 'Return to View';
    },
    onClickHandler () {
      this.onClickLogo();
    },

    onOpenEnhanceAudio () {
      this.openEnhanceAudio(true)
    },
    openPartnerPage(){
     const url = 'https://tribetactics-limited.getrewardful.com/signup?_gl=1*18v3xtt*_gcl_aw*R0NMLjE2ODI2MjE2Mjgu[…]775683.1437349985.1687429051-1785727313.1682416845';
      // Open the URL in a new tab
      window.open(url, '_blank');
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/components/videos/edit-navbar.scss";
</style>
