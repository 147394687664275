<template>
  <div class="sign-up">
    <div class="sign-up__wrapper text-center">
      <div class="d-flex flex-row justify-center align-center">
      </div>
      <div class="sign-up__wrapper__title">
        <h3>Sign Up</h3>
        <p>Join us now for free!</p>
        <p>Let’s get <b>chopping</b>.</p>
      </div>
      <div class="sign-up__wrapper__form" v-if="isSignUpFormVisible">
        <div class="sign-up__wrapper__form__field">
          <label for="fullName">Full Name</label>
          <v-text-field
            label="Type your Full Name"
            single-line
            outlined
            :error-messages="fullNameErrors"
            v-model.trim="fullName"
            @input="$v.fullName.$touch()"
            @blur="$v.fullName.$touch()"
          ></v-text-field>
        </div>
      </div>
      <div class="sign-up__wrapper__form__field" v-if="isSignUpFormVisible">
        <label for="email">Email</label>
        <v-text-field
          label="Type your Email"
          single-line
          outlined
          :error-messages="emailErrors"
          v-model.trim="email"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
          ><v-icon v-if="!$v.email.$invalid" color="#28B446" slot="append"
            >mdi-checkbox-marked-circle</v-icon
          ></v-text-field
        >
      </div>
      <div class="sign-up__wrapper__form__field" v-if="isSignUpFormVisible">
        <label for="password">Password</label>
        <v-text-field
          label="Type your password"
          single-line
          outlined
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          v-model="password"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
        >
          <v-icon
            @click="showPassword = !showPassword"
            v-if="showPassword"
            slot="append"
            >mdi-eye</v-icon
          >
          <v-icon @click="showPassword = !showPassword" v-else slot="append"
            >mdi-eye-off</v-icon
          >
          <v-icon
            v-if="password && !$v.password.$invalid"
            color="#28B446"
            slot="append"
            >mdi-checkbox-marked-circle</v-icon
          >
        </v-text-field>
        <div
          v-if="validate"
          class="sign-up__wrapper__form__field__validation-popup"
        >
          <div v-for="rule in passwordRules" :key="rule.key">
            <v-icon v-if="$v.password[rule.key]" color="#28B446" slot="append"
              >mdi-checkbox-marked-circle</v-icon
            >
            <v-icon v-else color="#C1D5E2" slot="append"
              >mdi-checkbox-marked-circle-outline</v-icon
            >
            <p>{{ rule.statement }}</p>
          </div>
        </div>
      </div>
      <div class="sign-up__wrapper__google" v-else>
        <SignInWithGoogle />
      </div>

      <div
        class="sign-up__wrapper__form__switch__to__form__button"
        @click="signUp"
        v-if="getStartedButton && isSignUpFormVisible"
      >
        Submit
      </div>
      <div
        v-if="isSignUpFormVisible"
        class="sign-up__wrapper__form__button"
        @click="isSignUpFormVisible = !isSignUpFormVisible"
      >
        <span>Switch to Google</span>
      </div>
      <div
        v-if="!isSignUpFormVisible"  
        @click="showGetStarted" 
        class="sign-up__wrapper__form__button"
      >
        <span>Continue with email</span>
      </div>

      <div class="sign-up__wrapper__form__login">
        Have an account? <span @click="toSignup">Login</span>
      </div>
      <div class="sign-up__wrapper__form__privacy">
        <v-checkbox
          :value="privacy"
          hide-details
          @click="privacy = !privacy"
          color="#fbd458"
        ></v-checkbox>
        <div class="description text-center" style="font-size: 14px;">
          I've read and agree to the
          <a
            href="https://www.chopcast.io/hubfs/Tribetactics%20Limited%20T_A%20Chopcast%20Terms%20of%20Service%20-%20Jan%202023.pdf"
            target="_blank"
            >Terms of Service</a
          >
          ,
          <a href="https://www.chopcast.io/privacy-policy" target="_blank"
            >Privacy Policy</a
          >
          and
          <a href="https://www.chopcast.io/hubfs/Tribetactics%20Limited%20T_A%20Chopcast%20Fair%20Use%20Policy%20-%20Jun%205%202023.pdf" target="_blank">Fair Use Policy</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, helpers, email, minLength } from "vuelidate/lib/validators";
import { mapActions, mapMutations } from "vuex";
import SignInWithGoogle from "../shared/SignInWithGoogle.vue";
import types from "../store/types";

const regexAZ = helpers.regex("regex", /(?=.*[A-Z])/gm);
const regexaz = helpers.regex("regex", /(?=.*[a-z])/gm);
const regexNumber = helpers.regex("regex", /(?=.*[0-9])/gm);
const fullNameMinLength = 2;
const passwordMinLength = 8;

export default {
  name: "Signup",
  components: {
    SignInWithGoogle,
  },

  data() {
    return {
      privacy: false,
      showPassword: false,
      isSignUpFormVisible: false,
      getStartedButton: false,
      fullName: "",
      email: "",
      password: "",
      passwordRules: [
        {
          key: "minLength",
          statement: "contains at least 8 characters",
        },
        {
          key: "regexaz",
          statement: "contains lower (a-z) case letter",
        },
        {
          key: "regexAZ",
          statement: "contains upper (A-Z) case letter",
        },
        {
          key: "regexNumber",
          statement: "contains at least one number (0-9)",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      userSignUp: types.user.actions.USER_SIGN_UP,
    }),
    ...mapMutations({
      setErrorMessage: types.app.mutations.SET_ERROR_MESSAGE,
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE,
    }),
    toSignup() {
      this.$router.push("/sign-in").catch(() => {});
    },
    async signUp() {
      if (
        this.$v.fullName.$invalid ||
        this.$v.email.$invalid ||
        this.$v.password.$invalid ||
        !this.privacy
      ) {
        this.setErrorMessage([
        "Please ensure that you agree to our Terms of Service, Privacy Policy & Fair Use Policy before proceeding",
        ]);
        return;
      }
      const signUpData = {
        email: this.email,
        full_name: this.fullName,
        password: this.password,
      };
      try {
        await this.userSignUp(signUpData);
        this.setSuccessMessage(
          "Almost there! Check your email for a verification code to start using chopcast."
        );
        setTimeout(() => {
          this.setSuccessMessage("");
          this.$router.push("/sign-in").catch(() => {});
        }, 5000);
      } catch (error) {
        // handled in store
      }
    },
    showGetStarted() {
      this.getStartedButton = true;
      this.isSignUpFormVisible = true
    },
  },
  computed: {
    fullNameErrors() {
      const errors = [];
      if (!this.$v.fullName.$dirty) return errors;
      !this.$v.fullName.required && errors.push("Full Name is required");
      !this.$v.fullName.minLength &&
        errors.push(
          `Full Name must contains at least ${fullNameMinLength} characters`
        );
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("Email is required");
      return errors;
    },
    validate() {
      const isValid =
        this.$v.password.regexaz &&
        this.$v.password.minLength &&
        this.$v.password.regexAZ &&
        this.$v.password.regexNumber;
      if (this.password.length && !isValid) return true;

      if (isValid) return false;

      return false;
    },
  },
  validations: {
    fullName: {
      required,
      minLength: minLength(fullNameMinLength),
    },
    email: {
      email,
      required,
    },
    password: {
      regexAZ,
      regexaz,
      regexNumber,
      minLength: minLength(passwordMinLength),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/signup.scss";
</style>
