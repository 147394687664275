<template>
  <div class="centered-content">Loading demo .......</div>
</template>

<script>
import { mapActions } from "vuex";
import types from "../../store/types";
export default {
  async mounted() {
    const videoUuid = "14d87a47-8ee2-442f-8615-f62d3e9982ef";
    this.fetchVideoSpeakersAction(videoUuid);
    this.fetchVideoWaveform(videoUuid);
    await this.fetchSubtitlesForVideo(videoUuid);
    this.$router
      .push(
        "/demo-edit/14d87a47-8ee2-442f-8615-f62d3e9982ef/clip/86a244ca-812a-482f-8b5f-32f433a797e1/subtitles"
      )
      .catch(() => {});
  },

  methods: {
    ...mapActions({
      fetchSubtitlesForVideo: types.project.actions.FETCH_VIDEO_SUBTITLE,
      fetchVideoWaveform: types.project.actions.FETCH_VIDEO_WAVEFORM,
      fetchFonts: types.project.actions.FETCH_FONTS,
      fetchVideoSpeakersAction: types.project.actions.FETCH_VIDEO_SPEAKERS,
    }),
  },
};
</script>
<style>
.centered-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 800;
}
</style>
