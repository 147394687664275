<template>
  <div class="settings">
    <div class="settings__title">
      <h2>Videos</h2>
    </div>
    <div class="settings__inner">
      <div class="settings__inner-title">Your Account</div>
      <div class="settings__inner-content">
        <div class="settings__inner-content-title">Avatar</div>
        <div class="settings__inner-content-avatar">
          <img src="@/assets/images/icons/user.svg" alt="" />
          <button class="upload">Upload</button>
          <button class="remove">Remove</button>
        </div>
        <div class="settings__inner-content-about">
          <div class="settings__inner-content-about-item mb-32">
            <label for="name">Name</label>
            <input id="name" type="text" placeholder="Steve Jobs" />
          </div>
          <div class="settings__inner-content-about-item">
            <label for="email">Name</label>
            <input id="email" type="email" placeholder="steve@apple.com" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Settings",
};
</script>

<style lang="scss" scoped>
.mb-32 {
  margin-bottom: 32px;
}
.settings {
  background-color: #f3f5f7;
  width: 100%;
  padding: 34px;
  &__title {
    h2 {
      font-weight: 700;
      font-family: "Satoshi Bold";
      font-size: 48px;
      line-height: 65px;
      display: flex;
      align-items: center;
      letter-spacing: -0.045em;
      color: #fbd458;
    }
  }
  &__inner {
    margin-top: 92px;
    &-title {
      font-weight: 700;
      font-family: "Satoshi Bold";
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.045em;
      color: #1b1c20;
    }
    &-content {
      background: #ffffff;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 34px;
      margin-top: 66px;
      &-title {
        color: #000000;
        font-size: 18px;
        line-height: 25px;
      }
      &-avatar {
        display: flex;
        align-items: center;
        margin-top: 16px;
        img {
          width: 60px;
          height: 60px;
          margin-right: 22px;
        }
        .upload {
          width: 73px;
          height: 24px;
          background: #f3f5f7;
          border: 1px solid #eaebef;
          box-sizing: border-box;
          box-shadow: 0px 4px 3px rgba(160, 160, 160, 0.1);
          border-radius: 5px;
          color: #3e58fa;
          font-weight: bold;
          font-family: "Satoshi Bold";
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 16px;
        }
        .remove {
          background: #f3f5f7;
          width: 73px;
          height: 24px;
          border: 1px solid #eaebef;
          box-sizing: border-box;
          box-shadow: 0px 4px 3px rgba(160, 160, 160, 0.1);
          border-radius: 5px;
          font-weight: bold;
          font-family: "Satoshi Bold";
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #90989c;
        }
      }
      &-about {
        margin-top: 39px;
        &-item {
          display: flex;
          flex-direction: column;
          label {
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            color: #1b1c20;
            margin-bottom: 16px;
          }
          input {
            border: 1px solid #eaebef;
            box-sizing: border-box;
            border-radius: 5px;
            width: 300px;
            height: 54px;
            padding: 16px;
            color: #1b1c20;
            opacity: 0.8;
            font-size: 16px;
            line-height: 22px;
            &:focus-within {
              outline: none;
            }
          }
        }
      }
    }
  }
}
</style>
