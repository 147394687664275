<template>
  <v-card class="pa-5">
    <v-card-title class="justify-center">
      <div class="d-flex justify-space-between align-baseline">
        <p class="text-center font-weight-bold">Upload Font File</p>
      </div>
      <v-icon class="close-icon" @click="close">mdi-close</v-icon>
    </v-card-title>
    <div class="d-flex justify-center">
      <v-card-actions>
        <v-text-field
          placeholder="Font name"
          single-line
          outlined
          type="text"
          @input="fontNameErrors=[]"
          :error-messages="fontNameErrors"
          v-model="fontName"
        />
      </v-card-actions>
    </div>
    <div class="d-flex justify-center">
      <v-card-actions>
        <div class="file-upload">
          <div
            class="upload-box"
            @dragover.prevent="handleDragOver"
            @drop.prevent="handleDrop"
          >
            <p>Drag & Drop your file here</p>
            <button @click="triggerFileInput">or Click to Upload</button>
            <input
              ref="fileInput"
              type="file"
              style="display: none"
              @change="handleFileChange"
            />
          </div>
        </div>
      </v-card-actions>
    </div>
    <div class="file-info" v-if="uploadedFileName">
      <p>Uploaded File: {{ uploadedFileName }}</p>
    </div>

    <button class="upload-button" @click="upload">Upload</button>
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import types from "../store/types";
export default {
  data() {
    return {
      uploadedFileName: "",
      file:null,
      fontName:'',
      fontNameErrors:[]
    };
  },
  methods: {
    ...mapActions({
      uploadUserFontAction: types.project.actions.UPLOAD_USER_FONTS,
      fetchFonts: types.project.actions.FETCH_FONTS,
    }),
    ...mapMutations({
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE
    }),
    handleDragOver(event) {
      event.dataTransfer.effectAllowed = "copy";
      event.dataTransfer.dropEffect = "copy";
    },

    handleDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.handleFileUpload(files);
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const files = event.target.files;
      this.handleFileUpload(files);
    },

    handleFileUpload(files) {
      this.uploadedFileName = files[0].name;
      this.file = files[0]
    },
    async upload(){
      if(!this.fontName){
        this.fontNameErrors.push('Font name is required')
      }
      if(this.file && this.fontName){
        const formData = new FormData()
        formData.append("font_file ", this.file);
        formData.append("name ", this.fontName);
       await this.uploadUserFontAction({formData:formData})
        this.close()
        this.setSuccessMessage(
          "Fonts uploaded successfully!"
        );
        setTimeout(()=>{
          this.fetchFonts()
        }, 2000)
      }
    },
    close() {
      this.$emit("close");
    },
    
  },
};
</script>
<style scoped>
.file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
}

.upload-box {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.upload-box p {
  margin: 0;
}

.upload-box button {
  margin-top: 10px;
}
.file-info {
  text-align: center;
  margin-top: 20px;
}

.file-info p {
  margin: 0;
}
.close-icon {
  position: absolute;
  top: 22px;
  right: 18px;
  cursor: pointer;
}
.upload-button {
  display: block;
  margin: 0px auto;
  padding: 10px 20px;
  background-color: #4caf50; /* Primary color */
  color: #fff; /* Text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.font-input {
  height: 36px;
  width: 300px;
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 5px;
}
</style>
