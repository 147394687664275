<template>
    <div class="list-item-border py-2 flex-fill">
  <v-row class="flex-nowrap ma-0">
    <div class="">
      <div style="position: relative">
        <img
          :src="clip.thumbnail"
          @click="onClickAction(clipIndex)"
          :class="['square-thumbnail', isPlayed?'image-opacity':'']"
          style="display: block"
        />
        <v-chip
          class="video-wrapper__auto auto-badge"
          color="#7367f0"
          label
          v-if="clip && clip.is_acg"
          >Auto</v-chip
        >
        <v-chip class="clip-length" label>{{
          secondsToTime(clip.end_time - clip.start_time)
        }}</v-chip>
        <v-icon
          class="equalizer"
          center
          size="30"
          color="white"
          v-if="isPlayed"
        >
          mdi-equalizer
        </v-icon>
      </div>
    </div>
    <div class="pl-3" style="flex:1 1 100%">
        <div class="clip-info"><span class="clip-title" @click="editVideo">{{ clip.name }}</span>
      </div>
    </div>
    <div class="ml-auto">
      <div v-if="clip && clip.is_acg" class="vs-score">
        <div class="score px-0">
          <v-chip
            class="ma-2 mt-0"
            :color="scoreColor"
            label
            text-color="white"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  {{ clip.score }}
                </div>
              </template>
              <span>Virality Score</span>
            </v-tooltip>
          </v-chip>
        </div>
      </div>
    </div>
    <div class="">
      <div class="clip-actions">
        <v-btn
          class="action-btn mb-1"
          text
          :ripple="false"
          style="padding: 10px"
          @click="onExport"
        >
          <v-icon left size="20" class="mt-1"> mdi-download </v-icon>
          <span class="font-weight-normal text-capitalize">Export</span>
        </v-btn>
        <v-btn
          class="action-btn mb-1 pa-4"
          text
          :ripple="false"
          @click="editVideo"
        >
          <v-icon left size="18" class="mt-1 pa-1"> mdi-movie-filter </v-icon>
          <span class="font-weight-normal text-capitalize edit">Edit</span>
        </v-btn>
        <div style="display: flex; flex-direction: row">
          <v-btn
            elevation="0"
            class="mr-2"
            color="default"
            x-small
            @click="onClickLike()"
            ><v-icon color="pink" x-small> {{like?'mdi-heart':'mdi-heart-outline'}} </v-icon>
          </v-btn>
          <v-btn
            elevation="0"
            x-small
            :color="dislike ? '#fbd458' : 'default'"
            @click="onDeleteClip()"
            ><v-icon x-small> mdi-delete </v-icon>
          </v-btn>
          </div>
      </div>
    </div>
  </v-row>
  <v-divider class="divider-border mt-3"></v-divider>
</div>
</template>
<script>
import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import types from "../../store/types";
import trimString from "../../utils/TrimString";
import secondsToTime from '../../utils/SecondsToTime'

const clipNameMinLength = 3;

export default {
    props: ["isPlayed", "clip", "onClickAction", "clipIndex"],
    data() {
        return {
            isEditMode: false,
            contentName: null,
        };
    },
    computed: {
        contentNameErrors() {
            const errors = [];
            if (!this.$v.contentName.$dirty) return errors;
            !this.$v.contentName.required &&
                errors.push("Type Clip Name is required");
            !this.$v.contentName.minLength &&
                errors.push(
                    `Clip Name must contains at least ${clipNameMinLength} characters`
                );
            return errors;
        },
        scoreColor(){
            if(this.clip.score>90){
                return "green"
            }
            else if(this.clip.score>=80 && this.clip.score<=90){
                return "light-green"
            }
            else if(this.clip.score>=70 && this.clip.score<=79){
                return "#ccbb2b"
            }
            else if(this.clip.score>=60 && this.clip.score<=69){
                return "yellow"
            }
            else if(this.clip.score<60){
                return "red"
            }
            return "red"
        },
        like(){
            return this.clip.like
        },
        dislike(){
            return this.clip.dislike
        }
    },
    methods: {
        ...mapActions({
            updateClip: types.project.actions.UPDATE_CLIP,
            patchClip:types.project.actions.PATCH_CLIP
        }),
        trimText(text) {
            return trimString(text, 40);
        },
        secondsToTime(seconds) {
            return secondsToTime(seconds)
        },
        onFocus() {
            this.$v.contentName.$touch();
        },
        convertToMin(time) {
            const totalMinutes = Math.floor(time / 60);
            const totalSeconds = time % 60;
            return `${totalMinutes}.${(totalSeconds).toFixed(0)}`
        },
        onBlur: async function () {
            if (this.clip.name === this.contentName) {
                this.isEditMode = !this.isEditMode;
                return;
            }

            const videoUuid = this.$route.params.uuid;
            const clipUuid = this.clip.uuid;

            this.$v.contentName.$touch();
            if (!this.$v.contentName.$invalid) {
                this.isEditMode = !this.isEditMode;
                this.clip.name = this.contentName;
                try {
                    let payload;

                    // Update Clip Name
                    payload = {
                        name: this.contentName,
                        video: videoUuid,
                        start_time: this.clip.start_time,
                        end_time: this.clip.end_time,
                    };
                    await this.updateClip({ ...payload, clipUuid });
                } catch (error) {
                    // handled in store
                }
            }
        },
        editVideo() {
            const { uuid } = this.$route.params;

            let url = `/demo-edit/${uuid}`;
            if (this.clip && this.clip.uuid) {
                url += `/clip/${this.clip.uuid}`;
            }
            url += "/subtitles";
            this.$router.push(url).catch(() => {});
        },
        onExport() {
            const signupPageURL = 'https://app.chopcast.io/signup'; // Replace with your signup page URL
            window.open(signupPageURL, '_blank');
            
        },
        onClickLike(){
            if(!this.clip.like){
                this.clip.like = true
                this.clip.dislike = false
            }
            else{
                this.clip.like =false
            }
            this.patchClip({clipUuid:this.clip.uuid, like:this.like, dislike:this.dislike})
        },
        async onClickDislike () {
            if(!this.clip.dislike){
                this.clip.dislike = true
                this.clip.like = false
            }
            else{
                this.clip.dislike =false
            }
            this.patchClip({clipUuid:this.clip.uuid, like:this.like, dislike:this.dislike})
        },
        onDeleteClip() {
          const signupPageURL = 'https://app.chopcast.io/signup'; // Replace with your signup page URL
          window.open(signupPageURL, '_blank');
        }
    },
    validations: {
        contentName: {
            required,
            minLength: minLength(clipNameMinLength),
        },
    },
    mounted() {
        this.contentName = this.clip.name;
    },
};
</script>
<style lang="scss" scoped>
.list-item-border {
    margin: 0 10px 10px;
    padding: 0;

    .divider-border {
        height: 3px;
        background: #fbd458;
        max-height: 5px;
    }

    .auto-badge {
        background: blue;
        color: white;
        padding: 0 8px;
        border: 5px;
        border-radius: 5px;
        font-size: 12px;
        position: absolute;
        top: 4px;
        right: 4px;
    }


    .clip-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .vs-score {

        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        .score{
            padding: 0px 18px;
            font-size: 12px;
        }
    }

    .clip-actions {
        display: flex;
        flex-direction: column;
        height: 100%;
        /* background-color: red; */
        align-items: center;

        .action-btn {
            border: 1px solid;
            font-size: 11px;
            font-weight: 800;
        }
    }

    .square-thumbnail {
        width: 160px !important;
        height: 100px !important;
        object-fit: cover !important;
        border-radius: 0px !important;
        cursor: pointer;
        /* This ensures the image covers the square container */
    }
    .equalizer{
        position: absolute;
        top: 40%;
        left: 40%;
    }

    .clip-length {
        position: absolute;
        bottom: 5px;
        font-size: 13px;
        padding: 5px;
        border-radius: 4px;
        left: 5px;
        color: black;
    }

    .font-12 {
        font-size: 12px;
    }

    .clip-title {
        font-size: 15px;
        font-weight: 800;
        cursor: pointer;
    }
}
  .edit{
      padding: 1px !important;
  }
  .image-opacity{
    opacity: 0.6;
  }
</style>