import { render, staticRenderFns } from "./SideMenu.vue?vue&type=template&id=7423e6f5&scoped=true&"
import script from "./SideMenu.vue?vue&type=script&lang=js&"
export * from "./SideMenu.vue?vue&type=script&lang=js&"
import style0 from "./SideMenu.vue?vue&type=style&index=0&id=7423e6f5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7423e6f5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VDialog,VList,VListItem,VListItemContent,VListItemGroup,VListItemTitle,VSubheader})
