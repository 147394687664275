import types from "../types";
import { fetchExportedClip } from "../../utils/APIs";
import setLoadingAndError from "../../utils/LoaderAndError";

const state = {
  allExports:[],
  export: {},
};

const setExportedClip = async ({ commit, state }, payload) => {
    
    try {
    const response = await fetchExportedClip(payload.clipUuid);
      commit(types.download.mutations.SET_EXPORT_CLIP_STATE, {clipUuid:payload.clipUuid, clipName:payload.clipName, clip:payload.clip, response:response, audioLink:payload.audioLink, videoLink:payload.videoLink, isDownloading:state.export.isDownloading && state.export.clipUuid === payload.clipUuid?true:false, showDialog:payload.showDialog});
    } catch (error) {
      setLoadingAndError(commit, false, error);
      throw error;
    }
};

const mutations = {
  [types.download.mutations.SET_EXPORT_CLIP_STATE]: (state, payload) => {
    state.export = payload;
  },
  [types.download.mutations.ADD_TO_EXPORT_LIST]: (state, payload) => {
      state.allExports = [...state.allExports, payload];
  },
  [types.download.mutations.REMOVE_FROM_EXPORT_LIST]: (state, uuid) => {
    const exports = state.allExports.filter(c => c.clipUuid !== uuid);
      state.allExports = exports;
  },
};
const actions = {
    [types.download.actions.SET_EXPORT_CLIP]: setExportedClip,
  };

export default {
  state,
  mutations,
  actions
};
