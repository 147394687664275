<template>
  <div class="floating-upload-window-wrapper">
    <v-expansion-panels
      accordion
      class="floating-upload-window-wrapper__item"
      @change="showTotalProgress"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div :style="{ width: '95%' }">
            <v-icon v-if="uploadingVideos.length" class="mr-4">mdi-cloud-upload-outline</v-icon>
            <span v-if="!isUploadingYoutubeVideo">
              Uploading ({{
                totalProgress
              }}%)
            </span>
            <p v-if="isUploadingYoutubeVideo">Your video is uploading....</p>
            <div
              class="d-flex align-center justify-center floating-upload-window-wrapper__total-progress"
              v-if="isTotalProgressVisible"
            >
              <v-progress-linear
                v-if="!isUploadingYoutubeVideo"
                :value="totalProgress"
                color="yellow"
                background-color="white"
                rounded
              ></v-progress-linear>
              <v-progress-circular
                v-if="isUploadingYoutubeVideo"
                :width="4"
                :size="40"
                color="#fbd458"
                indeterminate
              ></v-progress-circular>

              <v-icon color="#28B446" class="ml-2" v-if="totalProgress === 100"
                >mdi-checkbox-marked-circle</v-icon
              >
              <v-icon color="red" class="ml-2" v-if="totalProgress < 100 && !isUploadingYoutubeVideo">mdi-close</v-icon>
            </div>
          </div></v-expansion-panel-header
        >
        <v-expansion-panel-content
          v-for="video in uploadingVideos"
          :key="video.name"
        >
          {{ trimText(video.name) }}
          <div class="d-flex align-center">
            <v-progress-linear
              :value="video.percentage"
              color="yellow"
              background-color="white"
              rounded
            ></v-progress-linear>
            <v-icon color="#28B446" class="ml-2" v-if="video.percentage === 100"
              >mdi-checkbox-marked-circle</v-icon
            >
            <v-icon
              color="red"
              class="ml-2"
              @click="cancelUpload(video.name)"
              v-if="video.percentage < 100"
              >mdi-close</v-icon
            >
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import types from "../../store/types";
import trimString from "../../utils/TrimString";

export default {
  data() {
    return {
      isTotalProgressVisible: true,
      totalProgress: 0,
    };
  },
  computed: {
    ...mapState({
      uploadingVideos: (state) => state.project.uploadingVideos,
      isUploadingYoutubeVideo: (state) => state.video.isUploadingYoutubeVideo
    }),
  },
  methods: {
    ...mapActions({
      cancelUploadVideo: types.project.actions.CANCEL_UPLOAD_VIDEO,
    }),
    cancelUpload(videoName) {
      this.cancelUploadVideo(videoName);
    },
    trimText(text) {
      return trimString(text, 40);
    },
    showTotalProgress(id) {
      this.isTotalProgressVisible = id === undefined;
    },
    setTotalPercentage() {
      let percentage = 0;
      this.uploadingVideos.forEach((video) => {
        percentage = percentage + video.percentage;
      });
      this.totalProgress = (percentage / this.uploadingVideos.length).toFixed(0);
    },
  },
  watch: {
    uploadingVideos() {
      this.setTotalPercentage();
    },
  },
  mounted() {
    this.setTotalPercentage();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/videos/floating-upload-window.scss";
</style>
