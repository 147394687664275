export const testimonials = [
  {
    name: "Tommy F.",
    testimonial:
      "Before Chopcast, we had to take notes during our recordings of possible clips to extract. After the recording, we would sift through potential clips and crop them - entirely manual process. Chopcast took 99% of the manual labor out of extracting clips.",
    title: "Marketing manager",
    solution: "Product",
    image: "https://www.chopcast.io/hubfs/8-1.png",
  },
  {
    name: "Sarah S.",
    testimonial:
      "I've tried a few different tools for video clips, but nothing has been as seamless as Chopcast. I use it to create short clips out of long form podcast videos for YouTube, Instagram, Facebook, Twitter, and TikTok.",
    title: "CEO and Podcaster",
    solution: "Product",
    image: "https://www.chopcast.io/hubfs/7-1.png",
  },
  {
    name: "Marie O.",
    testimonial:
      "Chopcast helped me to identify tips I was sharing with clients anyway and to repurpose those on social media. I've had a couple of clients connect back in with me because I have been sharing videos.",
    title: "Business owner",
    solution: "Product",
    image: "https://www.chopcast.io/hubfs/aslihan-3-1.png",
  },
  {
    name: "Brad C.",
    testimonial:
      "I've looked at several different solutions to use AI to generate shorts and derivative content from long-form videos and Chopcast has the most versatile and easy-to-use solution I've found.",
    title: "Head of Strategy & Chief AI Officer @ Brandetize",
    solution: "Product",
    image: "https://www.chopcast.io/hubfs/8-2.png",
  },
  {
    name: "Joanna H.",
    testimonial:
      "chopcast helps us easily search our videos by topic and create social clips based on them. Saved us a lot of time to create engaging content versus trying to do everything manually.",
    title: "Marketing Manager @ Eppione",
    solution: "Product",
    image: "https://www.chopcast.io/hubfs/joanna-2.jpg",
  },
  {
    name: "Irene B.",
    testimonial:
      "When we uploaded a video to chopcast, it created auto-clips that overlapped 70-80% with ones we manually picked before, except this was done in minutes!",
    title: "Marketer @ Embodied Philosophy",
    solution: "Product",
    image: "https://www.chopcast.io/hubfs/irene%20b.png",
  },
  {
    name: "Chris F.",
    testimonial:
      "I'm paying and love the tool. It has saved so much time editing shot clips from the High Tech Sales Podcast",
    title: "Podcast host",
    solution: "Product",
    image: "https://www.chopcast.io/hubfs/chris%20f.png",
  },
  {
    name: "Aslihan Y.",
    testimonial:
      "Game changer. We just upload our longer videos and turn them into social media savvy content in just a few clicks. Subtitles and all!",
    title: "Marketing @ DX Compliance",
    solution: "Product",
    image:
      "https://www.chopcast.io/hubfs/Testimonial%20Pictures/1695153516332.jpeg",
  },
];
