<template>
  <div class="clips-list">
    <v-row v-for="(clip, i) in clips" :key="clip.uuid" class="ma-0">
      <ClipListItem
        :isPlayed="playedClip === i"
        :clip="clip"
        :clipIndex="i"
        :onClickAction="isVisibleInVideoEdit ? setVideoTimeRange : openClipPage"
        @showDownloadDialogVisible="$emit('showDownloadDialogVisible', true)"
      />
    </v-row>
  </div>
</template>

<script>
import ClipListItem from "./DemoClipListItem.vue";
export default {
  components: { ClipListItem },
  props: [
    "clips",
    "openClipPage",
    "setVideoTimeRange",
    "isVisibleInVideoEdit",
    "playedClip",
    "isLoading",
    "fetchMoreClips",
    "showDownloadDialogVisible",
  ],
};
</script>
<style lang="scss" scoped>
.clips-list {
    min-height: auto;
    overflow-y: scroll !important;
    height: calc(100vh - 156px) !important;
}
</style>
