<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <use :href="require('../assets/svg/icons.svg') + `#${iconName}`" />
  </svg>
</template>

<script lang="js">
export default {
  name: 'Icon',
  props: {
    iconName: {
      type: String,
      required: true
    }
  },
  data(){
      return {
          path:"../assets/svg/icons.svg",
      }
  }
}
</script>
