<template>
  <div class="container container--fluid pt-0">
    <div v-if="isProcessing">
      <div class="row ma-0">
      <div class="col-xl-8 col-lg-8 col-md-7">
        <v-row class="justify-space-between align-baseline flex-nowrap mt-0 ma-0">
          <div class="d-flex">
            <v-btn
              text
              color="black"
              class="clips__back-button mr-3 mt-n1"
              @click="$router.push('/')"
            >
              <span class="font-weight-bold text-capitalize text-subtitle-1">
                <v-icon color="black" class="mr-2">mdi-arrow-left</v-icon>
                back
              </span>
            </v-btn>
            <span class="video-title pa-0">{{
              (currentClip && currentClip.name) || videoName
            }}</span>
          </div>
        </v-row>
        
      </div>
      <div class="clips__waiting-text d-flex flex-column justify-center" style="flex: inherit; margin-top: 100px;">
          <p v-if="video.acg_status ==='notrequested'" class="text-h5 font-weight-normal text-center mr-5">
            Uploading and transcribing!<br>You'll get an email once they're ready. Video usually take 5-10 mins to generate.
          </p>
          <p v-if="video.acg_status !=='notrequested'" class="text-h5 font-weight-normal text-center mr-5">
            Generating clips!<br>You'll get an email once it ready. Clips usually take 5-10 mins to generate.
          </p>
        </div>
      </div>
      <div v-if="video.acg_status !=='notrequested'" class="steps mt-2 mb-8">
          <div>
          <div v-for="step in steps" :key="step.name" class="step-description">
            <v-progress-circular v-if="!step.isCompleted" indeterminate color="amber" class="mr-8"></v-progress-circular>
            <v-icon
              v-else
              class="mr-8"
              color="success"
              size="34"
            >mdi-check-circle
          </v-icon>
            
              <span class="step-description font-weigh-bold text-title">{{ step.name }}</span>
          </div>
          </div>
      </div>
      <div v-if="video.acg_status ==='notrequested'" class="steps mt-2 mb-8">
          <div>
          <div v-for="step in transcribeSteps" :key="step.name" class="step-description">
            <v-progress-circular v-if="!step.isCompleted" indeterminate color="amber" class="mr-8"></v-progress-circular>
            <v-icon
              v-else
              class="mr-8"
              color="success"
              size="34"
            >mdi-check-circle
          </v-icon>
            
              <span class="step-description font-weigh-bold text-title">{{ step.name }}</span>
          </div>
          </div>
      </div>
      <div style="padding-left: 20%;padding-right: 20%;">
        <v-progress-linear
          v-if="progress"
          color="success"
          v-model="agcProgress"
          height="25"
      >
        <strong>{{ Math.ceil(agcProgress) }}%</strong>
      </v-progress-linear>
        <v-progress-linear
        v-else
        color="success"
        v-model="agcProgress"
        height="25"
      >
        <strong>{{ Math.ceil(agcProgress) }}%</strong>
      </v-progress-linear>
    </div>
  </div>
  <div v-else class="row ma-0">
    <div class="col-xl-8 col-lg-8 col-md-7 pt-0">
      <v-row class="justify-space-between align-baseline flex-nowrap mt-0 ma-0">
        <div class="d-flex">
          <v-btn
            text
            color="black"
            class="clips__back-button mr-3 mt-n1"
            @click="$router.push('/')"
          >
            <span class="font-weight-bold text-capitalize text-subtitle-1">
              <v-icon color="black" class="mr-2">mdi-arrow-left</v-icon>
              back
            </span>
          </v-btn>
          <span class="video-title pa-0">
            <v-breadcrumbs :items="items" class="pa-0" style="align-items: flex-start">
              <template v-slot:divider>
                <v-icon class="pb-3">mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
        </span>
        </div>
        
        <div class="clips__video d-flex flex-nowrap">
          <v-btn
            class="action-button ml-2"
            text
            :ripple="false"
            @click="onExport"
          >
            <v-icon left size="20" class="mt-1"> mdi-download </v-icon>
            <span class="font-weight-normal text-capitalize">Export</span>
          </v-btn>
          <v-btn
            class="edit-action ml-2"
            text
            :ripple="false"
            @click="editVideo"
          >
            <v-icon left> mdi-movie-filter </v-icon>
            <span class="font-weight-normal text-capitalize">Edit</span>
          </v-btn>
        </div>
      </v-row>
      <div class="centered-video" v-if="video.video_file">
        <VideoPlayer
          v-if="video.video_file"
          :src="video.video_file"
          :timeRange="timeRange"
          :videoDuration="video.duration"
          :videoSubtitle="videoSubtitle"
          :videoWidth="videoWidth"
          :videoHeight="videoHeight"
          :video="video"
          :clips="viewedClips"
          :setResizeDimension="setResizeDimension"
          :resizeDimension="resizeDimension"
          :isCrop="isCrop"
          :setIsCrop="setIsCrop"
          :clipsList="clips.results"
          :selectedClip="clip"
          :seekTo="seekTo"
          @updateTimeRange="updateTimeRange"
        />
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-5 pt-0">
      <v-list class="pb-0 pt-0 d-flex">
          <v-list-item v-if="!$route.params.clipUuid" class="new-clip-card" @click="openChatWithVideo">
            <div class="new-clip">
              <v-icon class="mr-2" size="30" color="#fbd458">mdi-chat</v-icon>
              <span>Chat with video</span>
            </div>
          </v-list-item>
          <v-list-item class="new-clip-card" @click="editVideo">
            <div class="new-clip">
              <v-icon class="mr-2" size="30" color="#fbd458">mdi-movie-filter</v-icon>
              <span>Create new clip</span>
            </div>
          </v-list-item>
        </v-list>
      <ClipsList
        v-if="clips.results && clips.results.length"
        :clips="viewedClips"
        :openClipPage="openClipPage"
        :playedClip="playedClip"
        :isLoading="isLoading"
        :fetchMoreClips="fetchMoreClips"
        @showDownloadDialogVisible="onExport"
        @deleted="onDelete"
      />
      <p v-else class="mt-8 pa-4 text-weigh-bold">
        We weren't able to auto-generate any clips for this video. Rest assured that no upload minutes were deducted. Please try re-uploading your video and if that fails please contact us!
      </p>
    </div>
    <AfterExportDialog
      :isAfterExportDialogVisible="isAfterExportDialogVisible"
      :showAfterExportDialogVisible="showAfterExportDialogVisible"
    />
    <v-dialog v-model="showWarning" max-width="300px">
        <Warning :message="'Last export in progress. Cancel and start new?'" :yes="onWarningYes" :no="onWarningNo"/>
    </v-dialog>
    <VideoChatWindow :onClickTimeStamp="onClickTimeStamp"/>
  </div>
</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import VideoPlayer from "../components/VideoPlayer.vue";
import types from "../store/types";
import trimString from "../utils/TrimString";
import ClipsList from "./Videos/ClipsList.vue";
import AfterExportDialog from "../components/Videos/AfterExportDialog.vue";
import Warning from "../shared/Warning.vue";
import VideoChatWindow from "./VideoChatWindow.vue";
export default {
  components: {
    VideoPlayer,
    ClipsList,
    AfterExportDialog,
    Warning,
    VideoChatWindow
  },
  data() {
    return {
      items: [],
      isProcessing:false,
      intervalId:null,
      isDownloadDialogVisible: false,
      isAfterExportDialogVisible: false,
      video: {},
      clips: { results: [] },
      folder: {},
      timeRange: {},
      playedClip: null,
      subtitles: [],
      videoLink: "",
      audioLink: "",
      videoName: "",
      videoWidth: null,
      videoHeight: null,
      exportedClip: null,
      isCrop: false,
      resizeDimension: "vjs-16-9",
      clipName:"",
      isLoading: false,
      steps:[
        {name:'Uploading Video', isCompleted:false}, 
        {name:'Extracting Audio', isCompleted:false},
        {name:'Transcribing', isCompleted:false}, 
        {name:'Generating Clips', isCompleted:false}, 
        {name:'Finalizing Clips', isCompleted:false} 
      ],
      transcribeSteps:[
        {name:'Uploading Video', isCompleted:false}, 
        {name:'Extracting Audio', isCompleted:false},
        {name:'Transcribing', isCompleted:false}, 
      ],
      agcProgress:10,
      showWarning:false,
      seekTo:0
    };
  },
  computed: {
    ...mapState({
      videoSubtitle: (state) => state.project.videoSubtitle,
      selectedExport:(state)=>  state.download.export,
      uploadProgresses: (state) => state.progress.uploadProgresses,
      clip:(state)=> state.project.clip,
      showChat: (state) => state.chatWithVideo.showChat
    }),
    viewedClips() {
      return [...this.clips.results].sort(function (a, b) {
        return b.score - a.score;
      });
    },
    currentClip() {
      return this.playedClip ? this.viewedClips[this.playedClip] : null;
    },
    clip() {
      return this.clips && this.clips.results? this.clips.results.find(c=>c.uuid === this.$route.params.clipUuid):null
    },
    progress() {
      if(this.uploadProgresses.length){
        const p = this.uploadProgresses.find(p=>p.uuid === this.$route.params.uuid)
        if(p){
          return p.progress
        }
      }
      return null
    },
    stepsStatuses() {
      if(this.uploadProgresses.length){
        const p = this.uploadProgresses.find(p=>p.uuid === this.$route.params.uuid)
        if(p){
          return p.steps
        }
      }
      return null
    }
  },
  watch: {
    $route() {
      this.buildBreadcrumbTree();
      const { clipUuid } = this.$route.params;
      if (clipUuid) {
        this.showChatWindow(false)
        this.setPlayedClip(clipUuid);
      } else {
        this.timeRange = {
          startTime: 0,
          endTime: this.video.duration,
        };
        this.playedClip = null;
        this.setVideoDownloadLinks();
      }
    },
    exportedClip(newValue, oldValue) {
      if (
        newValue?.clip == oldValue?.clip &&
        newValue?.status != oldValue?.status &&
        newValue?.status == "succeeded"
      ) {
        this.downloadFileAfterExporting();
      }
    },
    progress() {
      this.agcProgress = this.progress
    }
  },
  methods: {
    init(){
        this.getTargetVideo().then(() => {
        const videoUuid = this.$route.params.uuid;
        const { clipUuid } = this.$route.params;
        this.buildBreadcrumbTree();
        if(!this.video.clips && this.video.deduct_credit && this.video.acg_status !=='notrequested' ){
          this.isProcessing = true
          if(this.progress){
            this.agcProgress = this.progress
            this.steps = this.stepsStatuses
          }
          this.checkingVideoStatus()
        }
        else if(this.video.deduct_credit && this.video.acg_status ==='notrequested' && this.video.transcription_status !== 'success' ){
          this.isProcessing = true
          if(this.progress){
            this.agcProgress = this.progress
            this.steps = this.stepsStatuses
          }
          this.checkingVideoStatus()
        }
        else{
          if(this.progress){
            this.removeUploadProgressOfStore()
          }
          this.fetchSubtitlesForVideo(videoUuid);
        }
        if (clipUuid) {
          this.setPlayedClip(clipUuid);
        } else {
          this.setVideoDownloadLinks();
          this.timeRange = {
            startTime: 0,
            endTime: this.video.duration,
          };
        }
        this.setVideoDimensions(this.video.width, this.video.height);
      });
    },
    goToPrevious() {
      this.$router.push(this.prevRoute);
    },

    ...mapActions({
      fetchSubtitlesForVideo: types.project.actions.FETCH_VIDEO_SUBTITLE,
      exportClip: types.project.actions.EXPORT_CLIP,
      fetchExportedClip: types.project.actions.FETCH_EXPORTED_CLIP,
      fetchFolder: types.project.actions.FETCH_FOLDER,
      fetchVideo: types.project.actions.FETCH_VIDEO,
      fetchClips: types.project.actions.FETCH_VIDEO_CLIPS,
      createClip: types.project.actions.CREATE_NEW_CLIP,
      fetchFonts: types.project.actions.FETCH_FONTS,
      fetchClipStyle: types.project.actions.FETCH_CLIP_STYLE,
      setExportedClip: types.download.actions.SET_EXPORT_CLIP,
      fetchClip: types.project.actions.FETCH_CLIP
    }),
    ...mapMutations({
      setErrorMessage: types.app.mutations.SET_ERROR_MESSAGE,
      setDownloadDialogVisibility:types.app.mutations.SET_DOWNLOAD_DIALOG_VISIBILITY,
      showChatWindow:types.chatWithVideo.mutations.SHOW_CHAT_WINDOW,
      reloadEditorPage:types.app.mutations.RELOAD_EDITOR_PAGE
    }),
    showDownloadDialogVisible(flag) {
      this.isDownloadDialogVisible = flag;
    },
    onExport(){
      const { clipUuid } = this.$route.params;
          this.setExportedClip({clipUuid:clipUuid, clipName:this.videoName, clip:this.currentClip?this.currentClip:this.clip, videoLink:this.videoLink, audioLink:this.audioLink})
        this.setDownloadDialogVisibility(true)
    },
     showWarningBox() {
      this.showWarning = true;
    },
    onWarningYes() {
      const { clipUuid } = this.$route.params;
      this.showWarning = false;
      this.setDownloadDialogVisibility(true)
      this.setExportedClip({clipUuid:clipUuid, clipName:this.videoName, clip:this.currentClip, videoLink:this.videoLink, audioLink:this.audioLink})
    },
    onWarningNo() {
      this.showWarning = false;
    },
    showAfterExportDialogVisible(flag) {
      this.isAfterExportDialogVisible = flag;
    },
    setIsCrop(isCrop) {
      this.isCrop = isCrop;
    },
    setResizeDimension(dimension) {
      this.resizeDimension = dimension;
    },
    async exportClipAction(burnSubtitles) {
      const { clipUuid } = this.$route.params;

      if (clipUuid || this.playedClip)
        try {
          const uuid = clipUuid || this.viewedClips[this.playedClip].uuid;

          const payload = { clipUuid: uuid, burn_subtitles: burnSubtitles };
          const response = await this.exportClip(payload);

          this.exportedClip = response;
        } catch (err) {
          // handled in store
        }
    },
    setVideoTimeRange(index) {
      const { uuid, start_time, end_time } = this.viewedClips[index];
      this.playedClip = index;
      this.timeRange = {
        startTime: start_time,
        endTime: end_time,
      };
      this.fetchExportedClipAction(uuid);
    },
    openClipPage(index) {
      const clip = this.viewedClips[index];
      const { uuid, clipUuid, folderUuid } = this.$route.params;

      if (clipUuid !== clip.uuid) {
        let clipURL = `/video/${uuid}/clip/${clip.uuid}`;

        if (folderUuid) {
          clipURL = `/folder/${folderUuid}` + clipURL;
        }

        this.setVideoTimeRange(index);

        this.$router.push(clipURL);
      }
    },
    isCurrentFolderEmpty() {
      return !!Object.keys(this.folder || {}).length;
    },
    async buildBreadcrumbTree() {
      const { clipUuid, folderUuid } = this.$route.params;
      let newItems = [];
      if(clipUuid){
        this.clipName = this.clips.results.find(c=>c.uuid === this.$route.params.clipUuid).name
      }
      
      if (folderUuid) {
        if (!this.folder.uuid) {
          this.folder = await this.fetchFolder({ uuid: folderUuid });
        }

        newItems = [
          {
            text: "Folders",
            disabled: false,
            href: "/",
          },
          {
            text: trimString(this.folder.name, 20),
            disabled: false,
            href: `/folder/${this.folder.uuid}`,
          },
          {
            text: trimString(this.video.name, 20),
            disabled: false,
            href: `/folder/${this.folder.uuid}/video/${this.video.uuid}`,
          },
        ];
      } else {
        newItems = [
          {
            text: !this.clipName?this.video.name:trimString(this.video.name, 20),
            disabled: false,
            href: `/video/${this.video.uuid}`,
          },
        ];
      }

      if (clipUuid) {
        newItems = [
          ...newItems,
          {
            text: trimString(this.clipName, 40),
            disabled: true,
          },
        ];
      }

      this.items = newItems;
    },
    checkingVideoStatus(){
      this.intervalId = setInterval(async()=>{
        const { uuid: videoUuid } = this.$route.params;
        const response = await this.fetchVideo({ uuid: videoUuid })
        if(response.clips && response.deduct_credit){
          this.setUploadStatues(response)
          setTimeout(()=>{
            this.isProcessing = false
            this.removeUploadProgressOfStore()
            clearInterval(this.intervalId)
            this.init();
          }, 3000)
        }
        else if(!response.clips && !response.deduct_credit){
          this.setErrorMessage(["No clips found"])
          setTimeout(()=>{
            clearInterval(this.intervalId)
            this.isProcessing = false
            this.removeUploadProgressOfStore()
            this.init();
          }, 2000)
        }
        else if(!response.clips && !response.deduct_credit && (response.transcription_status === 'error' || response.acg_status === 'error')){
              setTimeout(()=>{
                clearInterval(this.intervalId)
                this.isProcessing = false
                this.removeUploadProgressOfStore()
                this.init();
              }, 2000)
        }
        else if(response.acg_status === 'notrequested' && response.transcription_status === 'success'){
          setTimeout(()=>{
                clearInterval(this.intervalId)
                this.isProcessing = false
                this.removeUploadProgressOfStore()
              }, 2000)
        }
        else{
          this.setUploadStatues(response)
        }
      
    }, 10000)
    },
    getTargetVideo() {
      const { uuid: videoUuid } = this.$route.params;

      return new Promise((resolve) => {
        Promise.all([
          this.fetchClips({ uuid: videoUuid }),
          this.fetchVideo({ uuid: videoUuid }),
        ]).then((values) => {
          const [clips, video] = values;
          this.video = video;
          this.clips = clips;
          if(this.$route.params.clipUuid){
            this.clipName = this.clips.results.find(c=>c.uuid === this.$route.params.clipUuid).name
          }
          resolve();
        });
      });
    },
    removeUploadProgressOfStore() {
      this.$store.commit('progress/removeUploadProgress', {uuid:this.$route.params.uuid})
    },
    setVideoDownloadLinks() {
      this.videoLink = this.video.video_file;
      this.audioLink = this.video.audio_file;
      this.videoName = this.video.name;
    },
    setUploadStatues(response) {
      if(response.acg_status !== 'notrequested'){
        if(response.video_file){
        this.steps[0].isCompleted = true
          this.agcProgress = 20
        }
        if(response.audio_file){
          this.steps[1].isCompleted = true
          this.agcProgress = 50
        }
        if(response.transcription_status === 'success'){
          this.steps[2].isCompleted = true
          this.agcProgress = 85
        }
        if(response.acg_status === 'success'){
          this.steps[3].isCompleted = true
          this.agcProgress = 95
        }
        if(response.clips && response.deduct_credit){
          this.steps[4].isCompleted = true
          this.agcProgress = 100
        }
      }
      else {
        if(response.video_file){
          this.transcribeSteps[0].isCompleted = true
          this.agcProgress = 20
        }
        if(response.audio_file){
          this.transcribeSteps[1].isCompleted = true
          this.agcProgress = 50
        }
        if(response.transcription_status === 'success'){
          this.transcribeSteps[2].isCompleted = true
          this.agcProgress = 100
        }
      }
      this.$store.commit('progress/setUploadProgresses', {uuid:response.uuid, progress:this.agcProgress, steps:response.acg_status !== 'notrequested'?this.steps:this.transcribeSteps})
    },
    setVideoDimensions(width, height) {
      this.videoWidth = width;
      this.videoHeight = height;
    },
    async editVideo () {
      this.reloadEditorPage(false)
      const { clipUuid, folderUuid, uuid  } = this.$route.params;

      let url = `/editor/video-edit/${this.video.uuid}`;
      if(folderUuid){
        url = `/editor/video-edit/${folderUuid}/${uuid}`
      }
      if (clipUuid) {
        url += `/clip/${clipUuid}`;
        this.goToEdit(url)
      }
      else {
        let clip = this.clips.results.find(c=>c.end_time === this.video.duration)
        if(clip){
          url = folderUuid ? `/editor/video-edit/${folderUuid}/${uuid}/clip/${clip.uuid}`:`/editor/video-edit/${uuid}/clip/${clip.uuid}`
          this.goToEdit(url)
        }
        else {
            const payload = {
            start_time:0,
            end_time:this.video.duration,
            video: this.video.uuid,
            folderUuid: this.$route.params.folderUuid,
          };
        const res = await this.createClip({...payload, is_last_state_auto_cropped:false})
        url = folderUuid ? `/editor/video-edit/${folderUuid}/${this.$route.params.uuid}/clip/${res.uuid}`:`/editor/video-edit/${this.$route.params.uuid}/clip/${res.uuid}`
        this.goToEdit(url)
        }
      }
      
    },
    goToEdit(url) {
      url += "/subtitles";
      this.$router.push(url).catch(() => {});
    },
    goToPreviousPage() {
      const { uuid, clipUuid, folderUuid } = this.$route.params;

      let URL = "/";

      if (folderUuid) {
        URL = `/folder/${folderUuid}` + URL;
      }

      if (clipUuid) {
        URL += `/video/${uuid}/clips/`;
      }

      this.$router.push(URL);
    },
    async fetchExportedClipAction(uuid) {
      const { clipUuid } = this.$route.params;

      if (clipUuid || uuid) {
        try {
          const payload = { clipUuid: uuid || clipUuid };
          const response = await this.fetchExportedClip(payload);

          this.setClipDownloadLinks(
            response?.results[0] || {},
            this.viewedClips[this.playedClip].name
          );
        } catch (err) {
          // handled in store
        }
      }
    },
    setClipDownloadLinks(clip, clipName) {
      this.videoName = clipName;
      this.videoLink = clip.clip_file;
      this.audioLink = clip.clip_audio_file;
      this.exportedClip = clip;
    },
    setPlayedClip(clipUuid) {
      const clipIndex = this.viewedClips.findIndex(
        (clip) => clip.uuid == clipUuid
      );
      this.setVideoTimeRange(clipIndex);
    },
    downloadFileAfterExporting() {
      const downloadDialogRef = this.$refs["downloadDialogRef"];
      if (downloadDialogRef) {
        const extension = this.exportClip.exportingExtension;
        if (extension) {
          this.getchCurrentVideo();
          const link = extension == "mp4" ? this.videoLink : this.audioLink;
          downloadDialogRef.downloadFile(link, extension);
        }
      }
    },
    getchCurrentVideo() {
      const { uuid: videoUuid } = this.$route.params;

      this.fetchClips({
        uuid: videoUuid,
        noLoading: true,
      }).then((clips) => {
        this.clips.results = [...clips];
      });
    },
    async fetchMoreClips(isVisible) {
      if (!isVisible) return;
      if (this.clips.next) {
        const { uuid: videoUuid } = this.$route.params;
        const page = this.clips.next.split("page=")[1].split("&video")[0];
        this.isLoading = true;

        return new Promise((resolve) =>
          this.fetchClips({ uuid: videoUuid, page }).then((values) => {
            const clips = values;

            if (!Object.keys(this.clips).length) this.clips = clips;
            else if (clips.next !== this.clips.next) {
              const { results, ...rest } = clips;
              this.clips = {
                ...rest,
                results: [...this.clips.results, ...results],
              };
            }
            this.isLoading = false;
            resolve();
          })
        );
      }
    },
    goToNewClip() {
      this.goTo(
        this.isCurrentFolderEmpty()
          ? `/new-clip/${this.folder.uuid}/${this.$route.params.uuid}/subtitles`
          : `/new-clip/${this.$route.params.uuid}/subtitles`
      );
    },
    goTo(url) {
      this.$router.push(url).catch(() => {});
    },
    setClipToExport(clip) {
      this.clipToExport = clip;
    },
    onDelete(clipId){
      this.clips.results = this.clips.results.filter(c=>c.uuid!==clipId)
    },
    updateTimeRange(){
      this.timeRange = {
        startTime:0,
        endTime:this.video.duration
      }
    },
    openChatWithVideo(){
      this.showChatWindow(true)
    },
    onClickTimeStamp(ts){
        this.seekTo = ts
    }
  },
  async mounted() {
    await this.fetchFonts()
    if(this.$route.params.clipUuid){
      let noLoading = false
      await this.fetchClipStyle({ uuid: this.$route.params.clipUuid, noLoading })
    }
    if(this.$route.params.clipUuid){
      this.fetchClip({clipUuid:this.$route.params.clipUuid})
    }
    
    this.init()
  },
  beforeDestroy() {
    clearInterval(this.intervalId) // Clear the interval before the component is destroyed
  }
};
</script>

<style scoped>
.centered-video {
  padding-left: 20px;
	/* Adjust the margin as needed */
	text-align: center;
}
 .centered-video .vjs-control-bar {
  display: flex !important;
  visibility: visible !important;
}
 .edit-action {
  background: white;
  border: 1px solid;
  font-weight: 800;
}
 .action-button {
  font-weight: 800;
  background: #fbd458;
}
 .action-button .close {
  padding-right: 0px;
  padding-left: 12px;
}
 .new-clip-card {
  border: 2px dotted orange;
  border-radius: 5px;
  margin: 0 10px;
}
 .new-clip-card .new-clip {
  margin: 0 10px;
  padding: 10px;
  height: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
 .video-title {
  padding: 25px;
  font-size: 20px;
  font-weight: 600;
}
 .steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
 .steps .step-description {
  text-align: left;
  margin: 10px;
  font-weight: 800;
  font-size: 20px;
}
@media (min-width: 1255px) and (max-width: 1255px){
  .new-clip-card {
    padding: 0 !important;
    font-size: 12px;
  }
}
@media (min-width: 1365px) and (max-width: 1365px){
  .new-clip-card {
    padding: 0 !important;
    font-size: 12px;
  }
}
</style>
