const convertDictionaryToSRT = (words, maxWords) => {
  let lastWordIndex = 0;
  let sentenceIndex = 0;
  const sentences = [];

  for (let i = 0; i < words.length; i++) {
    lastWordIndex += 1;
    const targetWord = words[i];
    const { word, startTime, endTime } = targetWord;
    const subtitle = word;

    if (lastWordIndex >= maxWords) {
      // add and stop building sentence
      if (sentences[sentenceIndex]) {
        sentences[sentenceIndex] = {
          ...sentences[sentenceIndex],
          subtitle: sentences[sentenceIndex].subtitle + " " + word,
          endTime: endTime,
        };
        if(sentences[sentenceIndex].endTime - sentences[sentenceIndex].startTime < 0.200)
          sentences[sentenceIndex].endTime = sentences[sentenceIndex].endTime + 0.200
      } else {
        sentences.push({
          index: sentenceIndex,
          startTime: startTime,
          endTime: endTime,
          subtitle,
        });
        if(sentences[sentenceIndex].endTime - sentences[sentenceIndex].startTime < 0.150)
        sentences[sentenceIndex].endTime = sentences[sentenceIndex].endTime + 0.200
      }
      sentenceIndex += 1;
      lastWordIndex = 0;
    } else {
      // add and continue building sentence
      if (!sentences[sentenceIndex]) {
        // new sentence
        sentences.push({
          index: sentenceIndex,
          startTime: startTime,
          subtitle,
        });
      } else {
        // existing sentence
        sentences[sentenceIndex] = {
          ...sentences[sentenceIndex],
          subtitle: sentences[sentenceIndex].subtitle + " " + word,
        };
      }
    }
  }

  return sentences;
};

export default convertDictionaryToSRT;
