<template>
  <v-row justify="center">
    <v-dialog v-model="isDownloadDialogShown" width="409">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon @click="showDownloadDialogVisible(false)">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="download-dialog-wrapper px-0 pt-0">
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <p class="download-dialog-wrapper__title font-weight-bold">
                  {{ videoName }}
                </p>
              </v-col>
            </v-row>

            <v-row v-if="this.clip">
              <v-col cols="12" v-if="!isClipNotExportSucceeded()">
                <p class="download-dialog-wrapper__status font-weight-bold">
                  Last export ran
                  <span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-on="on" v-bind="attrs">{{
                          formateDate(exportingDate)
                        }}</span>
                      </template>
                      <span>{{
                        new Date(exportingDate).toLocaleString()
                      }}</span>
                    </v-tooltip>
                  </span>
                </p>
              </v-col>
              <v-col cols="12" v-else>
                <p class="download-dialog-wrapper__status font-weight-bold">
                  {{ handleStatus(exportingStatus) }}
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="d-flex download-dialog-wrapper__section text-subtitle-1 font-weight-bold justify-space-between"
              >
                <div
                  class="download-dialog-wrapper__section__download-container"
                >
                  .mp4
                  <v-progress-linear
                    v-if="videoLoading"
                    class="download-dialog-wrapper__section__progress"
                    color="#fbd458"
                    :value="videoPercentage"
                  ></v-progress-linear>
                </div>
                <div class="d-flex" v-if="isOptimized">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-switch
                          v-model="burnSubtitles"
                          color="success"
                          hide-details
                        ></v-switch>
                      </div>
                    </template>
                    <span>Burn subtitle on/off</span>
                  </v-tooltip>
                  <v-icon
                    v-if="videoLoading"
                    class="download-dialog-wrapper__section__loading"
                    >mdi-loading
                  </v-icon>
                  <v-icon
                    v-else-if="isProcessing()"
                    class="mdi mdi-reload mdi-spin"
                  >
                  </v-icon>
                  <v-tooltip top v-else :disabled="!mustExport(videoLink)">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-on="on"
                        v-bind="attrs"
                        @click="
                          !mustExport(videoLink)
                            ? downloadFile(videoLink, 'mp4')
                            : exportClip('mp4')
                        "
                        :color="!mustExport(videoLink) ? 'secondary' : ''"
                        >mdi-cloud-download-outline</v-icon
                      >
                    </template>
                    <span>Start export to download</span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-on="on"
                        v-bind="attrs"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span>Clips need to be under 10 minutes in length</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>

            <v-row
              ><v-col
                class="d-flex download-dialog-wrapper__section text-subtitle-1 font-weight-bold justify-space-between"
              >
                <div
                  class="download-dialog-wrapper__section__download-container"
                >
                  .mp3
                  <v-progress-linear
                    v-if="audioLoading"
                    class="download-dialog-wrapper__section__progress"
                    color="#fbd458"
                    :value="audioPercentage"
                  ></v-progress-linear>
                </div>
                <div v-if="isOptimized">
                <v-icon
                  v-if="audioLoading"
                  class="download-dialog-wrapper__section__loading"
                  >mdi-loading
                </v-icon>
                <v-icon
                  v-else-if="isProcessing()"
                  class="mdi mdi-reload mdi-spin"
                >
                </v-icon>
                <v-tooltip top v-else :disabled="!mustExport(audioLink)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      v-bind="attrs"
                      @click="
                        !mustExport(audioLink)
                          ? downloadFile(audioLink, 'mp3')
                          : exportClip('mp3')
                      "
                      :color="!mustExport(audioLink) ? 'secondary' : ''"
                      >mdi-cloud-download-outline</v-icon
                    >
                  </template>
                  <span>Start export to download</span>
                </v-tooltip>
              </div>
              <div class="d-flex" v-else>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-on="on"
                        v-bind="attrs"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span>Clips need to be under 10 minutes in length</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <v-row
              ><v-col
                class="d-flex download-dialog-wrapper__section text-subtitle-1 font-weight-bold justify-space-between"
              >
                <div
                  class="download-dialog-wrapper__section__download-container"
                >
                  .srt
                  <v-progress-linear
                    v-if="srtLoading"
                    class="download-dialog-wrapper__section__progress"
                    color="#fbd458"
                    :value="srtPercentage"
                  ></v-progress-linear>
                </div>
                <v-icon
                  v-if="!srtLoading"
                  @click="handleDownloadSRT"
                  color="secondary"
                  >mdi-cloud-download-outline</v-icon
                >
                <v-icon v-else class="download-dialog-wrapper__section__loading"
                  >mdi-loading</v-icon
                >
              </v-col>
            </v-row>
            <v-row
              ><v-col
                class="d-flex download-dialog-wrapper__section text-subtitle-1 font-weight-bold justify-space-between"
              >
                <div
                  class="download-dialog-wrapper__section__download-container"
                >
                  .txt
                  <v-progress-linear
                    v-if="textLoading"
                    class="download-dialog-wrapper__section__progress"
                    color="#fbd458"
                    :value="textPercentage"
                  ></v-progress-linear>
                </div>
                <v-icon
                  v-if="!textLoading"
                  @click="handleDownloadText"
                  color="secondary"
                  >mdi-cloud-download-outline</v-icon
                >
                <v-icon v-else class="download-dialog-wrapper__section__loading"
                  >mdi-loading</v-icon
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapActions, mapMutations, mapState } from "vuex";
import types from "../../store/types";

export default {
  components:{
  },
  props: [
    "isDownloadDialogVisible",
    "showDownloadDialogVisible",
    "videoLink",
    "audioLink",
    "videoName",
    "videoUuid",
    "exportingStatus",
    "fetchExportedClipAction",
    "exportClipAction",
    "exportingDate",
    "lastExportedBurnSubtitle",
    "clip",
    "onUpdateExportedClip"
  ],
  computed: {
    isDownloadDialogShown: {
      get: function () {
        return this.isDownloadDialogVisible;
      },
      set: function () {
        return this.showDownloadDialogVisible(false);
      },
    },
    ...mapState({
      exportedClip: (state) => state.download.export,
      allExports:(stats) => stats.download.allExports
    }),
    isOptimized(){
      if(this.exportedClip && this.exportedClip.clip){
        return (this.exportedClip.clip.clip_duration/ 60).toFixed(1) <=10
      }
      else{
        return true
      }
    }
  },
  data() {
    return {
      audioLoading: false,
      videoLoading: false,
      srtLoading: false,
      textLoading: false,
      audioPercentage: 0,
      videoPercentage: 0,
      srtPercentage: 0,
      textPercentage: 0,
      burnSubtitles: true,
      exportingExtension: ""
    };
  },
  mounted() {
      this.fetchExportedClipEach2Seconds();
  },
  methods: {
    ...mapActions({
      downloadVideoSRTAction: types.project.actions.DOWNLOAD_VIDEO_SRT,
      downloadVideoTextAction: types.project.actions.DOWNLOAD_VIDEO_TEXT,
      downloadClipSRTAction: types.project.actions.DOWNLOAD_CLIP_SRT,
      downloadClipTextAction: types.project.actions.DOWNLOAD_CLIP_TEXT,
      fetchExportsByStatusAction: types.project.actions.FETCH_EXPORTS_BY_STATUS,
      fetchExportedClip: types.project.actions.FETCH_EXPORTED_CLIP,
    }),
    ...mapMutations({
      updateExportState:types.download.mutations.SET_EXPORT_CLIP_STATE,
      addToExportList:types.download.mutations.ADD_TO_EXPORT_LIST,
      removeFromExportList:types.download.mutations.REMOVE_FROM_EXPORT_LIST,
      setErrorMessage: types.app.mutations.SET_ERROR_MESSAGE,
    }),
    onMinimize(){
      this.showDownloadDialogVisible(false)
    },
    exportClip(extension) {
      this.exportingExtension = extension;
      if(!this.$route.params.clipUuid){
        this.downloadFile(this.videoLink, extension)
      }
      else{
        // this.updateExportState({...this.exportedClip, isDownloading:true, exportingExtension:extension})
        const exportClip = this.allExports.find(c=>c.clipUuid === this.$route.params.clipUuid)
        if(!exportClip){
          this.addToExportList({...this.exportedClip, isDownloading:true, exportingExtension:extension})
          this.exportClipAction(this.burnSubtitles);
        }
        
      }
    },
    mustExport(link) {
      return (
        !link ||
        this.clip?.is_dirty ||
        (this.clip && this.burnSubtitles != this.lastExportedBurnSubtitle)
      );
    },
    downloadFile: function (link, extension, name ) {
      if (link) {
        this.setDownloadLoading(extension, true);
        axios
          .get(link, {
            responseType: "arraybuffer",
            onDownloadProgress: (progressEvent) => {
              const totalLength = progressEvent.lengthComputable
                ? progressEvent.total
                : progressEvent.target.getResponseHeader("content-length") ||
                  progressEvent.target.getResponseHeader(
                    "x-decompressed-content-length"
                  );

              if (totalLength !== null) {
                this.setDownloadProgress(
                  extension,
                  Math.round((progressEvent.loaded * 100) / totalLength)
                );
              }
            },
          })
          .then((response) => {
            this.forceFileDownload(response, extension, name);
          })
          .catch(console.error)
          .finally(() => {
            this.setDownloadLoading(extension, false);
          });
      }
    },
    forceFileDownload: function (response, extension, name) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name?name:this.videoName}.${extension}`);
      document.body.appendChild(link);
      link.click();
    },
    setDownloadLoading: function (extension, loading) {
      if (extension === "mp3") {
        this.audioLoading = loading;
      } else if (extension === "mp4") {
        this.videoLoading = loading;
      } else if (extension === "srt") {
        this.srtLoading = loading;
      } else if (extension === "txt") {
        this.textLoading = loading;
      }
    },
    setDownloadProgress: function (extension, progress) {
      if (extension === "mp3") {
        this.audioPercentage = progress;
      } else if (extension === "mp4") {
        this.videoPercentage = progress;
      } else if (extension === "srt") {
        this.srtPercentage = progress;
      } else if (extension === "txt") {
        this.textPercentage = progress;
      }
    },
    handleDownloadSRT: function () {
      let action = this.downloadVideoSRTAction;
      let uuid = this.videoUuid;

      if (this.clip) {
        action = this.downloadClipSRTAction;
        uuid = this.clip.uuid;
      }

      this.setDownloadLoading("srt", true);

      action(uuid)
        .then((response) => {
          this.forceFileDownload(response, "srt");
        })
        .catch(console.error)
        .finally(() => {
          this.setDownloadLoading("srt", false);
        });
    },
    handleDownloadText: function () {
      let action = this.downloadVideoTextAction;
      let uuid = this.videoUuid;

      if (this.clip) {
        action = this.downloadClipTextAction;
        uuid = this.clip.uuid;
      }

      this.setDownloadLoading("txt", true);

      action(uuid)
        .then((response) => {
          this.forceFileDownload(response, "txt");
        })
        .catch(console.error)
        .finally(() => {
          this.setDownloadLoading("txt", false);
        });
    },
    handleStatus(status) {
      if (status == "pending") {
        return "Your clip is processing. We’ll send you an email once it’s ready to download.";
      } else if (status == "started") {
        return "Your clip is processing. We’ll send you an email once it’s ready to download.";
      } else if (status == "failed") {
        return "Your job failed";
      } else if (status == "cancelled") {
        return "Your job cancelled";
      }

      return "";
    },
    isProcessing() {
      return (
        this.exportingStatus == "pending" || this.exportingStatus == "started"
      );
    },
    fetchExportedClipEach2Seconds() {
      setInterval(async() => {
        if (this.allExports.length) {
          const res = await this.fetchExportsByStatusAction('succeeded');
          const exports = this.allExports.find(e => res.results.some(r => r.clip === e.clipUuid));
          const filedResponse = await this.fetchExportsByStatusAction('failed');
          const failedExports = this.allExports.find(e => filedResponse.results.some(r => r.clip === e.clipUuid));
          if(failedExports){
            this.removeFromExportList(failedExports.clipUuid)
            this.setErrorMessage([`Export failed for ${failedExports.clipName}`]);
          }
          if(exports){
            this.onReadForDownload(exports)
          }
        }
      }, 10000);
    },

   async onReadForDownload(downloads){
      const res = await this.fetchExportedClip({ clipUuid: downloads.clipUuid })
      this.onUpdateExportedClip(res)
     this.downloadFile(res.results[0].clip_file, downloads.exportingExtension, downloads.clipName)
     this.removeFromExportList(downloads.clipUuid)
    

    },
    isClipNotExportSucceeded() {
      return this.clip && this.exportingStatus != "succeeded";
    },
    formateDate(date) {
      const exportDate = new Date(date);
      const diffHours = this.getDifferentHouresFromNow(date);

      return this.getDateFormate(exportDate, diffHours);
    },
    getDifferentHouresFromNow(date) {
      const exportDate = new Date(date);
      return Math.abs(new Date().getTime() - exportDate.getTime()) / 36e5;
    },
    getDateFormate(exportDate, diffHours) {
      if (diffHours < 1) {
        return `${Math.ceil(diffHours * 60)} minutes ago`;
      } else if (diffHours < 24) {
        return `${Math.ceil(diffHours)} hour(s) ago`;
      } else {
        return `${Math.ceil(diffHours / 24)} day(s) ago`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/videos/download-dialog.scss";
</style>
<style lang="scss">
.download-dialog-wrapper {
  &__export {
    .theme--light.v-icon:focus::after {
      opacity: 0 !important;
    }
  }

  .mdi-spin:before {
    animation: mdi-spin 1s infinite linear;
  }
}
</style>
