import types from "../types";
import {
  removeAccessTokenFromCookie,
  removeRefreshTokenFromCookie,
} from "../../utils/Cookies";
import {
  userLogin,
  userProfile,
  userSignUp,
  activateUser,
  resetPassword,
  resetPasswordConfirm,
  resendVerificationToUser,
  googleAuthUser,
  fetchPlans,
  buyPlan,
  updateUser,
  setPassword,
} from "../../utils/APIs";
import setLoadingAndError from "../../utils/LoaderAndError";

const initialState = {
  user: {},
  plans: [],
  refreshToken: "",
};

const state = {
  ...initialState,
};

const mutations = {
  [types.user.mutations.SET_USER]: (state, payload) => {
    state.user = { ...state.user, ...payload };
  },
  [types.user.mutations.SET_REFRESH_TOKEN]: (state, payload) => {
    state.refreshToken = payload;
  },
  [types.user.mutations.RESET_STATE]: (state) => {
    Object.assign(state, initialState);
  },
  [types.user.mutations.SET_PLANS]: (state, payload) => {
    state.plans = payload;
  },
};

const userLoginAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const response = await userLogin(payload);
    setLoadingAndError(commit, false);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const userProfileAction = async ({ commit }, token) => {
  try {
    const response = await userProfile(token);
    commit(types.user.mutations.SET_USER, response);
  } catch (error) {
    setLoadingAndError(commit, false);
    throw error;
  }
};

const userLogoutAction = async ({ commit }) => {
  removeAccessTokenFromCookie();
  removeRefreshTokenFromCookie();
  commit(types.user.mutations.RESET_STATE);
  commit(types.project.mutations.RESET_STATE);
};

const userSignUpAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const response = await userSignUp(payload);
    commit(types.user.mutations.SET_USER, response);
    setLoadingAndError(commit, false);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const updateUserAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const response = await updateUser(payload);
    commit(types.user.mutations.SET_USER, response);
    setLoadingAndError(commit, false);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const activateUserAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    await activateUser(payload);
    setLoadingAndError(commit, false);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const resetPasswordAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    await resetPassword(payload);
    setLoadingAndError(commit, false);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const resetPasswordConfirmAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    await resetPasswordConfirm(payload);
    setLoadingAndError(commit, false);
  } catch (error) {
    if (Object.keys(error)[0] === "token" || Object.keys(error)[0] === "uid")
      setLoadingAndError(commit, false, {
        error:
          "Password reset link maybe already used or expired, please request a new one",
      });
    else setLoadingAndError(commit, false, error);
    throw error;
  }
};

const resendVerificationToUserAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    await resendVerificationToUser(payload);
    setLoadingAndError(commit, false);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const googleAuthUserAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const response = await googleAuthUser(payload);
    setLoadingAndError(commit, false);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const fetchPlansAction = async ({ commit }, token) => {
  setLoadingAndError(commit, true);
  try {
    const response = await fetchPlans(token);
    commit(types.user.mutations.SET_PLANS, response.results);
    setLoadingAndError(commit, false);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const buyPlanAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const response = await buyPlan(payload);
    setLoadingAndError(commit, false);
    window.open(response.session_url, "_self");
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const setPasswordAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const response = await setPassword(payload);
    setLoadingAndError(commit, false);

    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const actions = {
  [types.user.actions.USER_LOGIN]: userLoginAction,
  [types.user.actions.USER_LOGOUT]: userLogoutAction,
  [types.user.actions.USER_PROFILE]: userProfileAction,
  [types.user.actions.USER_SIGN_UP]: userSignUpAction,
  [types.user.actions.UPDATE_USER]: updateUserAction,
  [types.user.actions.ACTIVATE_USER]: activateUserAction,
  [types.user.actions.RESET_PASSWORD]: resetPasswordAction,
  [types.user.actions.RESET_PASSWORD_CONFIRM]: resetPasswordConfirmAction,
  [types.user.actions.RESEND_VERIFICATION_TO_USER]:
    resendVerificationToUserAction,
  [types.user.actions.GOOGLE_AUTH_USER]: googleAuthUserAction,
  [types.user.actions.FETCH_PLANS]: fetchPlansAction,
  [types.user.actions.BUY_PLAN]: buyPlanAction,
  [types.user.actions.SET_PASSWORD]: setPasswordAction,
};

export default {
  state,
  actions,
  mutations,
};
