<template>
  <v-container fluid class="mt-8">
    <v-row>
      <v-col
        v-for="folder in folders"
        xl="auto"
        lg="3"
        md="6"
        cols="12"
        class="d-flex"
        :key="folder.uuid"
      >
        <Folder :goToFolderDetails="goToFolderDetails" :folder="folder" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Folder from "./Folder.vue";

export default {
  props: ["folders"],
  components: { Folder },
  data() {
    return {
      sourceRoute: [
        {
          text: "Folders",
          disabled: false,
          href: "/",
        },
      ],
    };
  },
  methods: {
    goToFolderDetails(uuid) {
      this.$router.push(`/folder/${uuid}`).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
