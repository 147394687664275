import f from "../assets/images/icons/fb.png";
import l from "../assets/images/icons/ln.png";
import i from "../assets/images/icons/insta.png";
import y from "../assets/images/icons/yt.png";
import yt from "../assets/images/icons/yt.png";
import t from "../assets/images/icons/tiktok.webp";
import x from "../assets/images/icons/twitter.png";
export const videoForPlatForms = [
  { title: "Facebook", desc: "Facebook", img: f },
  { title: "YT Shorts", desc: "YT Shorts", img: yt },
  { title: "LinkedIn", desc: "Linkedin", img: l },
  { title: "Instagram", desc: "Instagram", img: i },
  { title: "YouTube", desc: "YouTube", img: y },
  { title: "TikTok", desc: "TikTok", img: t },
  { title: "X (Twitter)", desc: "X (Twitter)", img: x },
];

export const videoTopics = ["Health", "Technology", "Politics"];

export const languages = [
  "English",
  "Spanish",
  "French",
  "Arabic",
  "German",
  "Chinese",
  "Italian",
  "Portuguese",
  "Indonesian",
  "Dutch",
  "Javanese",
  "Norwegian",
  "Swedish",
  "Danish",
  "Romanian",
  "Hausa",
  "Slovak",
  "Turkish",
  "Polish",
  "Czech",
  "Lithuanian",
  "Japanese",
  "Korean",
  "Hungarian",
  "Russian",
  "Vietnamese",
  "Bulgarian",
  "Persian",
  "Greek",
  "Marathi",
  "Hindi",
  "Urdu",
  "Bengali",
  "Gujarati",
  "Tamil",
  "Telugu",
];
