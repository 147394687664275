<template>
  <div
    :class="[
      'folder-wrapper',
      { 'folder-wrapper__highlight': isDraggingOverFolder },
    ]"
    @click="redirectTo"
    @dragover.prevent="handleDraggingOverFolder"
    @dragleave.prevent="handleDraggingLeaveFolder"
    @drop.prevent="handleDroppedVideo"
  >
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          icon
          v-bind="attrs"
          v-on="on"
          class="folder-wrapper__drop-down-menu"
        >
          <v-icon color="black">mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="handleClickAction(item.title)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <div class="folder-wrapper__thumbnails-grid">
      <img
        v-for="video in folder.videos.slice(0, thumbnailsLength)"
        :src="video.thumbnail"
        :key="video.uuid"
      />
    </div>
    <img
      class="folder-wrapper__folder-image"
      src="@/assets/images/colored-folder.svg"
      alt="logo"
    />
    <div class="folder-wrapper__name">{{ folder.name }}</div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import types from "../../store/types";
export default {
  props: ["goToFolderDetails", "folder"],
  data() {
    return {
      thumbnailsLength: 8,
      items: [{ title: "Delete" }],
      isDraggingOverFolder: false,
    };
  },
  methods: {
    ...mapActions({
      deleteFolderAction: types.project.actions.DELETE_FOLDER,
    }),
    ...mapMutations({
      setDraggedFolderUUid: types.project.mutations.SET_DRAGGED_FOLDER_UUID,
    }),
    redirectTo() {
      this.goToFolderDetails(this.folder.uuid);
    },
    handleClickAction(action) {
      if (action === "Delete") {
        this.deleteFolder();
      }
    },
    deleteFolder() {
      this.deleteFolderAction(this.folder.uuid);
    },
    handleDroppedVideo() {
      this.setDraggedFolderUUid(this.folder.uuid);
    },
    handleDraggingOverFolder() {
      this.isDraggingOverFolder = true;
    },
    handleDraggingLeaveFolder() {
      this.isDraggingOverFolder = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";
.folder-wrapper {
  cursor: pointer;
  width: fit-content;
  position: relative;
  padding: 5px;
  border: 3px solid transparent;
  &__name {
    font-family: "Satoshi" !important;
    font-size: 18px;
    word-break: break-all;
    font-weight: 700;
    color: $black;
  }
  &__thumbnails-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 10px;
    position: absolute;
    top: 78px;
    left: 28px;
    width: 250px;
    height: 120px;
    overflow: hidden;
    img {
      width: 70px;
      border-radius: 4px;
      max-height: 40px;
      object-fit: cover;
    }
  }
  &__folder-image {
    max-width: 100%;
  }
  &__drop-down-menu {
    position: absolute;
    top: 30px;
    right: 15px;
  }
  &__highlight {
    border: 3px solid $yellow;
    border-radius: 8px;
  }
  /* Media Query for Mobile Screens */
  @media (max-width: 768px) {
    &__thumbnails-grid {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      width: 150px; /* Adjust width for smaller screens */
      height: 90px; /* Adjust height for smaller screens */
      left: 30px; /* Adjust position for smaller screens */
      top:60px;
      overflow: hidden;
    }
  }
}
</style>
