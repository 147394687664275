import Cookies from "universal-cookie";

function setUserCookie(key, value, cookieLifeTime) {
  const cookies = new Cookies();
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + cookieLifeTime);
  cookies.set(key, value, {
    path: "/",
    expires: expiryDate,
  });
}

function setUserTokenCookie(Authorization) {
  setUserCookie("chopcast_accessToken", Authorization, 1);
}

function setUserRefreshTokenCookie(Authorization) {
  setUserCookie("chopcast_refreshToken", Authorization, 30);
}

function getAccessTokenFromCookie() {
  const cookies = new Cookies();
  return cookies.get("chopcast_accessToken");
}

function getUserRefreshTokenCookie() {
  const cookies = new Cookies();
  return cookies.get("chopcast_refreshToken");
}

function removeAccessTokenFromCookie() {
  const cookies = new Cookies();
  cookies.remove("chopcast_accessToken");
}

function removeRefreshTokenFromCookie() {
  const cookies = new Cookies();
  cookies.remove("chopcast_refreshToken");
}

export {
  setUserTokenCookie,
  setUserRefreshTokenCookie,
  getAccessTokenFromCookie,
  getUserRefreshTokenCookie,
  removeAccessTokenFromCookie,
  removeRefreshTokenFromCookie,
};
