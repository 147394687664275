<template>
  <div class="text-center ml-1">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs, value }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
          :ripple="false"
          id="no-background-hover"
        >
          <v-avatar
            tile
            class="rounded mr-3"
            width="40"
            height="40"
            max-width="40"
            min-width="40"
          >
            <img src="@/assets/images/icons/user.svg" alt="user" />
          </v-avatar>
          <span class="text-capitalize text-subtitle-1 mr-10">{{
            user.full_name
          }}</span>
          <v-icon v-if="value" color="#B7B8B9">mdi-chevron-up</v-icon>
          <v-icon v-else color="#B7B8B9">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title @click="goToProfile">Settings</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import types from "../store/types";

export default {
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    ...mapActions({
      logoutUser: types.user.actions.USER_LOGOUT,
    }),
    async logout() {
      try {
        await this.logoutUser();
        this.$router.push("/sign-in").catch(() => {});
      } catch (err) {
        // handled in store
      }
    },
    goToProfile() {
      this.$router.replace("/profile").catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/shared/avatar.scss";
</style>
