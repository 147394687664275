var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-wrapper"},[(_vm.selectedTitleUuid)?_c('div',{staticClass:"title-wrapper__format--row d-flex",style:({ alignItems: 'center' })},[_c('div',{staticClass:"title-wrapper__format--font-family"},[_c('v-select',{attrs:{"items":_vm.listOfFonts,"menu-props":"auto","label":"Font","hide-details":"","prepend-icon":"mdi-format-color-text","single-line":"","solo":"","flat":""},on:{"change":_vm.updateFontFamily},model:{value:(_vm.fontFamily),callback:function ($$v) {_vm.fontFamily=$$v},expression:"fontFamily"}})],1),_c('div',{staticClass:"title-wrapper__format--font-size"},[_c('v-select',{attrs:{"items":_vm.fontSizeList,"menu-props":"auto","label":"Size","hide-details":"","single-line":"","solo":"","flat":""},on:{"change":_vm.updateFontSize},model:{value:(_vm.fontSize),callback:function ($$v) {_vm.fontSize=$$v},expression:"fontSize"}})],1)]):_vm._e(),(_vm.selectedTitleUuid)?_c('div',{ref:"titleContent",staticClass:"title-wrapper__content",attrs:{"id":"title-content","contenteditable":"true"},domProps:{"innerHTML":_vm._s(_vm.titleText)},on:{"blur":_vm.updateTitleText}}):_vm._e(),_c('div',{staticClass:"title-wrapper__add-text"},[_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{staticClass:"title-wrapper__add-text__title d-flex",attrs:{"cols":"12"}},[_c('img',{staticClass:"mt-1",attrs:{"src":require("@/assets/images/icons/text-vector.svg"),"alt":"text-vector","width":"19","height":"15"}}),_c('p',{staticClass:"mb-0"},[_vm._v("Select text Style")])]),_vm._l((_vm.titleExamples),function(example){return _c('v-col',{key:example.font,staticClass:"px-0 py-0",attrs:{"md":"6","cols":"12"}},[_c('p',{staticClass:"title-wrapper__add-text__example mb-0",style:({
            color: example.color,
            fontFamily: example.font + ' !important',
            fontSize: '20px',
          }),on:{"click":function($event){return _vm.createTitle(example)}}},[_vm._v(" Cool Text Goes Here ")])])})],2)],1),(_vm.selectedTitleUuid)?_c('div',{staticClass:"title-wrapper__font-size"},[_c('p',{staticClass:"title-wrapper__font-size__header mt-0"},[_vm._v("Select text color")]),_c('v-row',{staticClass:"title-wrapper__fonts-wrapper"},[_vm._l((_vm.colors),function(color){return _c('div',{key:color,staticClass:"title-wrapper__font-size__color",style:({
          backgroundColor: color,
          border: color === '#FFFFFF' ? '1px solid #000000' : 'none',
        }),on:{"click":function($event){return _vm.updateTitleColor(color)}}})}),_c('div',{staticClass:"title-wrapper__font-size__color-text"},[_vm._v(" hex: "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedColor),expression:"selectedColor"}],staticClass:"title-wrapper__font-size__color-text__hex pl-0 d-inline-block",attrs:{"id":"color-hex","contenteditable":"true"},domProps:{"value":(_vm.selectedColor)},on:{"change":_vm.updateTitleColor,"input":function($event){if($event.target.composing){ return; }_vm.selectedColor=$event.target.value}}})])],2)],1):_vm._e(),(_vm.selectedTitleUuid)?_c('div',{staticClass:"title-wrapper__font-size"},[_c('p',{staticClass:"title-wrapper__font-size__header"},[_vm._v("Add background color")]),_c('v-row',{staticClass:"title-wrapper__fonts-wrapper"},[_vm._l((_vm.backgroundColors),function(color){return _c('div',{key:color,class:[
          'title-wrapper__font-size__color',
          { 'transparent-color': color === 'transparent' } ],style:({
          backgroundColor: color,
          border: color === '#FFFFFF' ? '1px solid #000000' : 'none',
        }),on:{"click":function($event){return _vm.updateTitleBackground(color)}}})}),_c('div',{staticClass:"title-wrapper__font-size__color-text"},[_vm._v(" hex: "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedBackgroundColor),expression:"selectedBackgroundColor"}],staticClass:"title-wrapper__font-size__color-text__hex d-inline-block pl-0",attrs:{"id":"background-hex","contenteditable":"true"},domProps:{"value":(_vm.selectedBackgroundColor)},on:{"change":_vm.updateTitleBackground,"input":function($event){if($event.target.composing){ return; }_vm.selectedBackgroundColor=$event.target.value}}})])],2)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }