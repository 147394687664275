<template>
  <div>
  <HeaderLogo />
  <div class="reset"> 
    <div class="reset__wrapper">
      <div class="reset__wrapper__title">
        <h3>Type Your Email</h3>
        <p>We will send you an email with a link to reset your password!</p>
      </div>
      <div class="reset__wrapper__form">
        <div class="reset__wrapper__form__submit">
          <div class="reset__wrapper__form__field">
            <label for="email">Email</label>
            <v-text-field
              label="Type your Email"
              single-line
              full-width
              outlined
              id="email"
              type="email"
              :error-messages="emailErrors"
              v-model.trim="email"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
              @keydown.enter="sendEmail"
              ><v-icon v-if="!$v.email.$invalid" color="#28B446" slot="append"
                >mdi-checkbox-marked-circle</v-icon
              ></v-text-field
            >
          </div>
          <div class="reset__wrapper__form__submit">
            <button id="submit-btn" @click="sendEmail">Send</button>
          </div>
        </div>
      </div>
    </div>
    <transition name="slide">
      <ErrorMessage :errorMessage="errorMessage" />
    </transition>
  </div>
</div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import types from "../store/types";
import ErrorMessage from "../components/ErrorMessage";
import HeaderLogo from "../components/HeaderLogo.vue";

export default {
  name: "Forgot",
  components: {
    ErrorMessage,
    HeaderLogo
  },
  data() {
    return {
      email: "",
    };
  },
  computed: {
    ...mapState({
      errorMessage: (state) => state.user.errorMessage,
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("Email is required");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      resetPassword: types.user.actions.RESET_PASSWORD,
      logoutUser: types.user.actions.USER_LOGOUT,
    }),
    ...mapMutations({
      setErrorMessage: types.app.mutations.SET_ERROR_MESSAGE,
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE,
      setSuccessMessageTimeout:
        types.app.mutations.SET_SUCCESS_MESSAGE_TIME_OUT,
    }),
    async sendEmail() {
      if (this.$v.email.$invalid) {
        this.setErrorMessage(["Please enter your email"]);
        return;
      }
      try {
        const payload = {
          email: this.email,
        };
        await this.resetPassword(payload);
        this.setSuccessMessageTimeout(7000);
        this.setSuccessMessage(
          "Thanks! If we find a match, you'll get an email to reset your password (please check spam folder just in case)"
        );
        this.$router.push("/sign-in").catch(() => {});
      } catch (err) {
        // handled in store
      }
    },
  },
  validations: {
    email: {
      email,
      required,
    },
  },
  beforeDestroy() {
    this.setSuccessMessageTimeout(5000);
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/components/reset.scss";
.logo-signin-wrapper {
    padding: 25px 25px 0;
    padding-left: 0;
    padding-bottom: 25px;
}

.logo-signin-wrapper img {
    height: 50px;
}
</style>
