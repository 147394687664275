import types from "../types";
import {
  createFolder,
  updateFolder,
  fetchFolders,
  fetchVideos,
  fetchSubtitlesForVideo,
  updateVideoSubtitle,
  setCancelTokenSource,
  cancelTokenSource,
  uploadYoutubeVideo,
  exportClip,
  createNewClip,
  updateClip,
  patchClip,
  fetchFonts,
  fetchClipStyle,
  updateClipStyle,
  fetchExportedClip,
  preUploadVideo,
  fetchVideoWaveform,
  downloadVideoSRT,
  downloadVideoText,
  deleteClip,
  deleteVideo,
  deleteFolder,
  fetchTextLayer,
  createTextLayer,
  updateTextLayer,
  deleteTextLayer,
  updateVideo,
  downloadClipSRT,
  downloadClipText,
  createImageLayer,
  updateImageLayer,
  deleteImageLayer,
  fetchImageLayers,
  fetchVideoSpeakers,
  fetchFolder,
  fetchVideo,
  clipResizeCropSquare,
  clipResizeCropVertical,
  clipResizeFitSquare,
  clipResizeFitVertical,
  clipResizeReset,
  fetchVideoClips,
  fetchVideoClip,
  cloneClip,
  updateSubtitleTrimedDutations,
  clipResizeFitStandard4by5,
  clipResizeCropStandard4by5,
  reportError,
  cleanClipAudio,
  fetchSubtitles,
  fetchWaveform,
  uploadFonts,
  removeKey,
  createKey,
  fetchkeys,
  uploadMultipleVideos,
  fetchExportesByStatus,
  cancelSubsctiption,
} from "../../utils/APIs";
import setLoadingAndError from "../../utils/LoaderAndError";
import router from "../../router/router";
import convertDictionaryToSRT from "../../utils/ConvertDictionaryToSRT";
import store from "../index";

const initialState = {
  folders: {},
  videos: {},
  currentSubtitle: "",
  clipStyle: {},
  videoSubtitle: [],
  videoWaveform: [],
  uploadingVideos: [],
  isFloatingUploadWindowVisible: false,
  fonts: [],
  wordsPerLine: 3,
  subtitleWords: [],
  titles: [],
  selectedTitleUuid: "",
  imageLayers: [],
  selectedImageLayerUuid: "",
  videosSearchKeywords: [],
  videoSpeakers: [],
  isHomePageLoaderVisible: false,
  draggedFolderUuid: "",
  clip: null,
  trimedClip:null,
  subtitleHeight:null,
  openAudioEnhanceDialog:false,
  userFonts:[],
  apiKeys:[],
  reload:false,
};

const state = {
  ...initialState,
};

const getters = {
  [types.project.getters.IS_VIDEO_UPLOADING_EXIST]: (state) => (videoName) => {
    return !!state.uploadingVideos.find((video) => video.name === videoName)
      .length;
  },
  [types.project.getters.GET_VIDEOS]: (state) =>{
    return Object.keys(state.videos).length > 0
      ? state.videos.results.filter((video) => video?.folder === null)
      : []
    }};

const mutations = {
  [types.project.mutations.SET_FOLDERS]: (state, payload) => {
    if (!Object.keys(state.folders).length) state.folders = payload;
    else if (payload.next !== state.folders.next) {
      const { results, ...rest } = payload;
      state.folders = {
        ...rest,
        results: [...state.folders.results, ...results],
      };
    }
  },
  [types.project.mutations.SET_FOLDER]: (state, payload) => {
    if (state.folders.results) {
      state.folders.results = [payload, ...state.folders.results];
    } else {
      state.folders.results = [payload];
    }
  },
  [types.project.mutations.SET_VIDEO_IN_FOLDER]: (state, payload) => {
    const folderIndex = state.folders.results.findIndex(
      (folder) => folder.uuid == payload.folder
    );
    const folders = [...state.folders.results];
    folders[folderIndex].videos.unshift(payload);
    state.folders.results = [...folders];
  },
  [types.project.mutations.REMOVE_FOLDER]: (state, uuid) => {
    state.folders.results = state.folders.results.filter(
      (folder) => folder.uuid != uuid
    );
  },
  [types.project.mutations.REMOVE_VIDEO]: (state, uuid) => {
    state.videos.results = state.videos.results.filter(
      (video) => video.uuid != uuid
    );
  },
  [types.project.mutations.ADD_FOLDER]: (state, payload) => {
    const folderIndex = state.folders.results.findIndex(
      (folder) => folder.uuid === payload.uuid
    );
    state.folders.results = [...state.folders.results, payload];

    state.folders.results.map((folder, index) => {
      if (index !== folderIndex) return folder;
      return payload;
    });
  },
  [types.project.mutations.RESET_VIDEOS]: (state) => {
    state.videos = {};
  },
  [types.project.mutations.RESET_FOLDERS]: (state) => {
    state.folders = {};
  },
  [types.project.mutations.SET_VIDEOS]: (state, payload) => {
    if (!Object.keys(state.videos).length) state.videos = payload;
    else if (payload.next !== state.videos.next) {
      const { results, ...rest } = payload;
      state.videos = {
        ...rest,
        results: [...state.videos.results, ...results],
      };
    }
  },
  [types.project.mutations.UPDATE_VIDEOS_LIST]:(state, payload) =>{
    payload.forEach(updatedVideo => {
      const videoIndex = state.videos.results.findIndex(video => video.uuid === updatedVideo.uuid);
      if (videoIndex !== -1) {
        state.videos.results.splice(videoIndex, 1); // Remove value at the index
        state.videos.results.splice(videoIndex, 0, updatedVideo); // Insert updatedResult at the same index
      }
    });
  },
  [types.project.mutations.SET_IS_FLOATING_UPLOAD_WINDOW_VISIBLE]: (
    state,
    payload
  ) => {
    state.isFloatingUploadWindowVisible = payload;
  },
  [types.project.mutations.ADD_UPLOADING_VIDEOS]: (state, payload) => {
    state.uploadingVideos = payload;
  },
  [types.project.mutations.UPDATE_UPLOADING_VIDEO_PERCENTAGE]: (
    state,
    payload
  ) => {
    const newUploadingVideos = [...state.uploadingVideos];
    const videoIndex = newUploadingVideos.findIndex(
      (video) => video.name === payload.name
    );
    newUploadingVideos[videoIndex] = {
      ...newUploadingVideos[videoIndex],
      percentage: payload.percentage,
      uploadedSize: payload.uploadedSize,
    };
    state.uploadingVideos = [...newUploadingVideos];
  },
  [types.project.mutations.REMOVE_UPLOADING_VIDEOS]: (state, videoName) => {
    const filteredUploadingVideos = state.uploadingVideos.filter((video) => {
      // remove file extension for video names
      const vidName =
        video.name.substring(0, video.name.lastIndexOf(".")) || video.name;
      const targetVidName =
        videoName.substring(0, videoName.lastIndexOf(".")) || videoName;
      return vidName !== targetVidName;
    });

    state.uploadingVideos = [...filteredUploadingVideos];
  },
  [types.project.mutations.SET_VIDEO_SUBTITLE]: (state, payload) => {
    state.videoSubtitle = payload;
  },
  [types.project.mutations.SET_VIDEO_WAVEFORM]: (state, payload) => {
    state.videoWaveform = payload;
  },
  [types.project.mutations.SET_TRIMED]: (state, payload) => {
    state.trimedClip = payload;
  },
  [types.project.mutations.SET_FONTS]: (state, payload) => {
    state.fonts = payload;
  },
  [types.project.mutations.SET_USER_FONTS]: (state, payload) => {
    state.userFonts = payload;
  },
  [types.project.mutations.SET_CURRENT_SUBTITLE]: (state, payload) => {
    state.currentSubtitle = payload;
  },
  [types.project.mutations.SET_SUBTITLE_WORDS]: (state, payload) => {
    state.subtitleWords = payload;
  },
  [types.project.mutations.SET_CLIP_STYLE]: (state, payload) => {
    state.clipStyle = { ...payload };
  },
  [types.project.mutations.SET_CLIP]: (state, payload) => {
    state.clip = { ...payload };
  },
  [types.project.mutations.SET_WORDS_PER_LINE]: (state, payload) => {
    state.wordsPerLine = payload;
  },
  [types.project.mutations.RESET_STATE]: (state) => {
    Object.assign(state, initialState);
  },
  [types.project.mutations.SET_TEXT_LAYER]: (state, payload) => {
    state.titles = [...payload];
    state.selectedTitleUuid = "";
  },
  [types.project.mutations.ADD_TEXT_LAYER]: (state, payload) => {
    state.titles = [...state.titles, { ...payload }];
    state.selectedTitleUuid = payload.uuid;
  },
  [types.project.mutations.UPDATE_TEXT_LAYER]: (state, payload) => {
    state.titles = state.titles.map((title) => {
      if (title.uuid == payload.uuid) {
        return payload;
      }

      return title;
    });
  },
  [types.project.mutations.SET_IMAGE_LAYERS]: (state, payload) => {
    state.imageLayers = [...payload];
    state.selectedImageLayerUuid = "";
  },
  [types.project.mutations.ADD_IMAGE_LAYER]: (state, payload) => {
    state.imageLayers = [...state.imageLayers, { ...payload }];
    state.selectedImageLayerUuid = payload.uuid;
  },
  [types.project.mutations.UPDATE_IMAGE_LAYER]: (state, payload) => {
    state.imageLayers = state.imageLayers.map((imageLayer) => {
      if (imageLayer.uuid == payload.uuid) {
        return {
          ...imageLayer,
          ...payload,
        };
      }

      return imageLayer;
    });
  },
  [types.project.mutations.SET_SELECTED_TITLE_UUID]: (
    state,
    selectedTitleUuid
  ) => {
    state.selectedTitleUuid = selectedTitleUuid;
  },
  [types.project.mutations.REMOVE_TEXT_LAYER]: (state, uuid) => {
    state.titles = state.titles.filter((title) => title.uuid != uuid);
  },
  [types.project.mutations.REMOVE_IMAGE_LAYER]: (state, uuid) => {
    state.imageLayers = state.imageLayers.filter(
      (imageLayer) => imageLayer.uuid != uuid
    );
  },
  [types.project.mutations.SET_SELECTED_IMAGE_LAYER_UUID]: (
    state,
    selectedImageLayerUuid
  ) => {
    state.selectedImageLayerUuid = selectedImageLayerUuid;
  },
  [types.project.mutations.SET_SEARCH_KEYWORD]: (state, payload) => {
    const newSearchKeywords = [...state.videosSearchKeywords];
    const { uuid, word } = payload;
    const videoIndex = newSearchKeywords.findIndex(
      (videoWords) => videoWords.uuid == uuid
    );

    if (videoIndex > -1) {
      const addedWordIndex = newSearchKeywords[videoIndex].words.findIndex(
        (videoWord) => videoWord.toUpperCase() == word.toUpperCase()
      );

      if (addedWordIndex == -1) {
        newSearchKeywords[videoIndex].words.push(word);
      }
    } else {
      newSearchKeywords.push({
        uuid,
        words: [word],
      });
    }

    state.videosSearchKeywords = [...newSearchKeywords];
  },
  [types.project.mutations.SET_VIDEO_SPEAKERS]: (state, speakers) => {
    state.videoSpeakers = speakers;
  },
  [types.project.mutations.SET_HOME_LOADER]: (state, value) => {
    state.isHomePageLoaderVisible = value;
  },
  [types.project.mutations.SET_DRAGGED_FOLDER_UUID]: (state, uuid) => {
    state.draggedFolderUuid = uuid;
  },
  [types.project.mutations.REMOVE_TEMPRORY_TEXT_LAYER]: (state) => {
    state.titles = state.titles.filter((t) => t.uuid !== undefined);
  },
  [types.project.mutations.UPDATE_TEXT_LAYER_FONTSIZE]: (state, payload) => {
    let title = state.titles.find((t) => t.uuid === payload.uuid);
    title.font_size = payload.font_size;
  },
  [types.project.mutations.SET_SUBTITLES_HEIGHT]: (state, payload) => {
   state.subtitleHeight=  payload.height;
  },
  [types.project.mutations.OPEN_AUDIO_ENHANCE_DIALOG]: (state, payload) => {
    state.openAudioEnhanceDialog=  payload;
   },
   [types.project.mutations.SET_KEYS]: (state, payload) => {
    state.apiKeys=  payload;
   },
   [types.project.mutations.REMOVE_KEY]: (state, payload) => {
    state.apiKeys =  state.apiKeys.filter(k=>k.prefix !== payload);
   },
   [types.project.mutations.RELOAD_PLAYER]: (state, payload) => {
    state.reload = payload;
  },
};

const createFolderAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const response = await createFolder(payload);
    setLoadingAndError(commit, false);
    commit(types.project.mutations.SET_FOLDER, response);
    router.push(`/folder/${response.uuid}`).catch(() => {});
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const updateFolderAction = async ({ commit }, payload) => {
  const { uuid, name } = payload;
  setLoadingAndError(commit, true);
  try {
    const response = await updateFolder(uuid, { name });
    setLoadingAndError(commit, false);
    commit(types.project.mutations.ADD_FOLDER, response);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const updateVideoAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const { updateFolder, content } = payload;
    const response = await updateVideo(content);
    if (updateFolder) {
      commit(types.project.mutations.SET_VIDEO_IN_FOLDER, response);
      commit(types.project.mutations.REMOVE_VIDEO, response.uuid);
    }
    setLoadingAndError(commit, false);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const uploadVideoAction = async ({ commit, state }, payload) => {
  try {
    const response = await uploadMultipleVideos(payload);
    store.dispatch(types.user.actions.USER_PROFILE);
    for (let index = 0; index < response.length; index++) {
      const video = response[index];
      commit(types.project.mutations.REMOVE_UPLOADING_VIDEOS, video.name);
    }
    
    if (!state.uploadingVideos.length)
      commit(
        types.project.mutations.SET_IS_FLOATING_UPLOAD_WINDOW_VISIBLE,
        false
      );
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const uploadYoutubeVideoAction = async ({ commit }, payload) => {
  try {
    const response = await uploadYoutubeVideo(payload);

    store.dispatch(types.user.actions.USER_PROFILE);

    setLoadingAndError(commit, false);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const preUploadVideoAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const response = await preUploadVideo(payload);

    setLoadingAndError(commit, false);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const fetchHomePageAPIsActions = async ({ commit }) => {
  commit(types.project.mutations.SET_HOME_LOADER, true);
  try {
    const foldersResponse = await fetchFolders();
    commit(types.project.mutations.SET_FOLDERS, foldersResponse);

    const videosResponse = await fetchVideos();
    commit(types.project.mutations.SET_VIDEOS, videosResponse);

    commit(types.project.mutations.SET_HOME_LOADER, false);
  } catch (error) {
    commit(types.project.mutations.SET_HOME_LOADER, false);
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const fetchHomePageVideosActions = async ({ commit }, payload) => {
  commit(types.project.mutations.SET_HOME_LOADER, true);
  try {
    const videosResponse = await fetchVideos(payload);
    commit(types.project.mutations.SET_VIDEOS, videosResponse);

    commit(types.project.mutations.SET_HOME_LOADER, false);
  } catch (error) {
    commit(types.project.mutations.SET_HOME_LOADER, false);
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const fetchHomePageFoldersActions = async ({ commit }, payload) => {
  commit(types.project.mutations.SET_HOME_LOADER, true);
  try {
    const foldersResponse = await fetchFolders(payload);
    commit(types.project.mutations.SET_FOLDERS, foldersResponse);

    commit(types.project.mutations.SET_HOME_LOADER, false);
  } catch (error) {
    commit(types.project.mutations.SET_HOME_LOADER, false);
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const fetchFolderAction = async ({ commit }, payload) => {
  if (!payload.noLoading) {
    setLoadingAndError(commit, true);
  }
  try {
    const folderResponse = await fetchFolder(payload.uuid);
    setLoadingAndError(commit, false);

    return folderResponse;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const fetchVideoAction = ({ commit }, payload) => {
  return new Promise((resolve) => {
    if (!payload.noLoading) {
      setLoadingAndError(commit, true);
    }

    fetchVideo(payload.uuid)
      .then((videoResponse) => {
        setLoadingAndError(commit, false);

        resolve(videoResponse);
      })
      .catch((error) => {
        setLoadingAndError(commit, false, error);
        throw error;
      });
  });
};

const fetchVideoClipsAction = async ({ commit }, payload) => {
  return new Promise((resolve) => {
    if (!payload.noLoading) {
      setLoadingAndError(commit, true);
    }
    const { uuid, page } = payload;
    fetchVideoClips(uuid, page)
      .then((response) => {
        setLoadingAndError(commit, false);
        resolve(response);
      })
      .catch((error) => {
        setLoadingAndError(commit, false, error);
        throw error;
      });
  });
};

const fetchVideoClipAction = async ({ commit }, payload) => {
  return new Promise((resolve) => {
    fetchVideoClip(payload.clipUuid, payload.ratio)
      .then((response) => {
        commit(types.project.mutations.SET_CLIP, response);
        resolve(response);
      })
      .catch((error) => {
        setLoadingAndError(commit, false, error);
        throw error;
      });
  });
};

const fetchSubtitlesForVideoActions = async ({ commit, state }, payload) => {
  setLoadingAndError(commit, true);
  let response = null;
  try {
    
    if(window.location.pathname.includes('demo')){
      const res = await fetchSubtitles(payload);
      response = res.words
    }
    else{
      response = await fetchSubtitlesForVideo(payload);
    }
    
    const reformatedSubtitles = convertDictionaryToSRT(
      response || [],
      state.wordsPerLine
    );
    commit(types.project.mutations.SET_SUBTITLE_WORDS, response);
    commit(types.project.mutations.SET_VIDEO_SUBTITLE, reformatedSubtitles);
    setLoadingAndError(commit, false);
  } catch (error) {
    setLoadingAndError(commit, false, ['Subtitles are not available']);
  }
};

const updateVideoSubtitleActions = async ({ commit }, payload) => {
  setLoadingAndError(commit, false);
  try {
    const response = await updateVideoSubtitle(payload);
    const reformatedSubtitles = convertDictionaryToSRT(
      response.words || [],
      state.wordsPerLine
    );
    commit(types.project.mutations.SET_SUBTITLE_WORDS, response.words);
    commit(types.project.mutations.SET_VIDEO_SUBTITLE, reformatedSubtitles);
    setLoadingAndError(commit, false);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const saveTrimedDurationsActions = async ({commit}, payload)=>{
  try {
    await updateSubtitleTrimedDutations(payload);
    if(payload.audio_cleaned){
      await patchClip({clipUuid:payload.clipUuid, audio_cleaned:payload.audio_cleaned === 'yes'?true:false})
    }
    await fetchVideoClipAction({commit:commit}, {clipUuid:payload.clipUuid})
    setLoadingAndError(commit, false);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
}

const fetchWaveformForVideoActions = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    let response = null;
    if(window.location.pathname.includes('demo')){
      const res = await fetchWaveform(payload);
      response = res.data
    }
    else {
     const res = await fetchVideoWaveform(payload);
      response = res.data
    }
    

    commit(types.project.mutations.SET_VIDEO_WAVEFORM, response);
    setLoadingAndError(commit, false);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const exportClipAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  const { clipUuid, burn_subtitles, highlight_subtitle_word, preview_height, preview_width, auto_speaker_crop   } = payload;
  try {
    const response = await exportClip(clipUuid, { burn_subtitles, highlight_subtitle_word, preview_height, preview_width, auto_speaker_crop});
    setLoadingAndError(commit, false);

    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const fetchExportedClipAction = async ({ commit }, payload) => {
  const { clipUuid } = payload;
  try {
    const response = await fetchExportedClip(clipUuid);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const createNewClipAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const response = await createNewClip(payload);
    setLoadingAndError(commit, false);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const updateClipAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const response = await updateClip(payload);
    setLoadingAndError(commit, false);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const cancelUploadVideos = ({ commit }, payload) => {
  cancelTokenSource.cancel("Video upload is canceled");
  commit(types.project.mutations.REMOVE_UPLOADING_VIDEOS, payload);
  setCancelTokenSource();
};

const fetchFontsAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const response = await fetchFonts(payload);
    commit(types.project.mutations.SET_FONTS, response.fonts);

    setLoadingAndError(commit, false);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const fetchClipStyleAction = async ({ commit }, { uuid, noLoading }) => {
  if (!noLoading) {
    setLoadingAndError(commit, true);
  }
  try {
    const response = await fetchClipStyle(uuid);
    commit(types.project.mutations.SET_CLIP_STYLE, response);
    commit(
      types.project.mutations.SET_WORDS_PER_LINE,
      response.max_words_per_line
    );

    setLoadingAndError(commit, false);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const updateClipStyleAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const response = await updateClipStyle(payload);
    commit(types.project.mutations.SET_CLIP_STYLE, response);

    setLoadingAndError(commit, false);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const downloadVideoSRTAction = async ({ commit }, uuid) => {
  try {
    const response = await downloadVideoSRT(uuid);

    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const downloadVideoTextAction = async ({ commit }, uuid) => {
  try {
    const response = await downloadVideoText(uuid);

    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const fetchTextLayerAction = async ({ commit }, uuid) => {
  try {
    const response = await fetchTextLayer(uuid);

    commit(types.project.mutations.SET_TEXT_LAYER, response.results);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const createTextLayerAction = async ({ commit }, payload) => {
  try {
    const newLayer = {
      x_pos_string: "center",
      y_pos_decimal: 0.1,
      color: payload.color,
      font: payload.font,
      font_size: 20,
      background_color: "#323232",
      box_width: payload.box_width,
      box_height: payload.box_height,
      text: "Cool Text Goes Here",
      duration: null,
      start_at: 0,
      clip: payload.clipUuid,
    };
    const response = await createTextLayer(newLayer);
    commit(types.project.mutations.ADD_TEXT_LAYER, response);
    commit(types.project.mutations.REMOVE_TEMPRORY_TEXT_LAYER);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const createTemproryTextLayerAction = async ({ commit }, payload) => {
  const newLayer = {
    x_pos_string: "center",
    y_pos_decimal: 0.1,
    color: payload.color,
    font: payload.font,
    font_size: 20,
    background_color: "#323232",
    box_width: payload.box_width,
    box_height: payload.box_height,
    text: "Cool Text Goes Here",
    duration: null,
    start_at: 0,
    clip: payload.clipUuid,
  };
  commit(types.project.mutations.ADD_TEXT_LAYER, newLayer);
};

const updateTextLayerFontsize = ({ commit }, payload) => {
  commit(types.project.mutations.UPDATE_TEXT_LAYER_FONTSIZE, payload);
};

const updateTextLayerAction = async ({ commit }, payload) => {
  try {
    const data = await updateTextLayer(payload);
    commit(types.project.mutations.UPDATE_TEXT_LAYER, data);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const downloadClipSRTAction = async ({ commit }, uuid) => {
  try {
    const response = await downloadClipSRT(uuid);

    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const downloadClipTextAction = async ({ commit }, uuid) => {
  try {
    const response = await downloadClipText(uuid);

    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const deleteClipAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const response = await deleteClip(payload.uuid);
    setLoadingAndError(commit, false);

    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const deleteVideoAction = async ({ commit }, payload) => {
  setLoadingAndError(commit, true);
  try {
    const response = await deleteVideo(payload.uuid);
    setLoadingAndError(commit, false);

    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const deleteFolderAction = async ({ commit }, uuid) => {
  setLoadingAndError(commit, true);
  try {
    const response = await deleteFolder(uuid);

    commit(types.project.mutations.REMOVE_FOLDER, uuid);
    setLoadingAndError(commit, false);

    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const deleteTextLayerAction = async ({ commit }, uuid) => {
  try {
    await deleteTextLayer(uuid);

    commit(types.project.mutations.REMOVE_TEXT_LAYER, uuid);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const fetchImageLayersAction = async ({ commit }, uuid) => {
  try {
    const response = await fetchImageLayers(uuid);

    commit(types.project.mutations.SET_IMAGE_LAYERS, response.results);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const createImageLayerAction = async ({ commit }, payload) => {
  try {
    const formData = new FormData();
    formData.append("clip ", payload.clipUuid);
    formData.append("image ", payload.image);
    formData.append("x_pos_decimal", 0.1);
    formData.append("y_pos_decimal", 0.1);
    formData.append("width", payload.width);
    formData.append("height", payload.height);

    const response = await createImageLayer({ formData, image: payload.image });

    commit(types.project.mutations.ADD_IMAGE_LAYER, response);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const updateImageLayerAction = async ({ commit }, payload) => {
  try {
    await updateImageLayer(payload);

    commit(types.project.mutations.UPDATE_IMAGE_LAYER, payload);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const deleteImageLayerAction = async ({ commit }, uuid) => {
  try {
    await deleteImageLayer(uuid);

    commit(types.project.mutations.REMOVE_IMAGE_LAYER, uuid);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const fetchVideoSpeakersAction = async ({ commit }, uuid) => {
  try {
    const response = await fetchVideoSpeakers(uuid);

    commit(types.project.mutations.SET_VIDEO_SPEAKERS, response.speakers);
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

/*======== resize actions start ==========*/
const clipResizeCropSquareAction = async ({ commit }, payload) => {
  try {
    const response = await clipResizeCropSquare(payload);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const cloneClipAction = async ({ commit }, uuid) => {
  try {
    setLoadingAndError(commit, true);
    const response = await cloneClip(uuid);
    setLoadingAndError(commit, false);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const clipResizeCropVerticalAction = async ({ commit }, payload) => {
  try {
    const response = await clipResizeCropVertical(payload);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};
const clipResizeFitSquareAction = async ({ commit }, payload) => {
  try {
    const response = await clipResizeFitSquare(payload.uuid);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};
const clipResizeFitVerticalAction = async ({ commit }, payload) => {
  try {
    const response = await clipResizeFitVertical(payload.uuid, payload.format);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const clipResizeFitStandard4by5Action = async ({ commit }, payload) => {
  try {
    const response = await clipResizeFitStandard4by5(payload.uuid);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
}

const clipResizeCropStandard4by5Action = async ({ commit }, payload) => {
  try {
    const response = await clipResizeCropStandard4by5(payload);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
}
const clipResizeResetAction = async ({ commit }, payload) => {
  try {
    const response = await clipResizeReset(payload.uuid);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const reportErrorAction = async ({ commit }, payload) => {
  try {
    const response = await reportError(payload);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
};

const cleanClipAudioAction = async ({ commit }, uuid) => {
  try {
    const response = await cleanClipAudio(uuid);
    return response;
  } catch (error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
}

const undoCleanClipAudioAction = async({commit}, clipUuid) => {
  try {
    await patchClip({clipUuid: clipUuid, audio_cleaned: false})
    await fetchVideoClipAction({ commit:commit }, {clipUuid:clipUuid})
  }
  catch(error){
    setLoadingAndError(commit, false, error);
    throw error;
  }
  
}

const patchClipAction = async({commit}, payload) => {
  try{
    await patchClip({clipUuid: payload.clipUuid, ...payload})
    await fetchVideoClipAction({ commit:commit }, payload.clipUuid)
  }
  catch(error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
  
}

const onlyPatchClipAction = async({commit}, payload) => {
  try{
    await patchClip({clipUuid: payload.clipUuid, ...payload})
  }
  catch(error) {
    setLoadingAndError(commit, false, error);
    throw error;
  }
  
}

const uploadUserFontsAction = async({commit}, payload) => {
  try{
    await uploadFonts({ formData: payload.formData })
    setLoadingAndError(commit, false);
  }
  catch(error){
    setLoadingAndError(commit, false, error);
    throw error;
  }
  
}
/*======== resize actions end ==========*/
/*======== fetch videos list ==========*/
const fetchVideosListActions = async()=> {
  return await fetchVideos()
}
/*======== fetch videos list ==========*/

/*======== keys actions ==========*/
const fetchApiKeys = async ({commit}) => {
  const response =  await fetchkeys();
  commit(types.project.mutations.SET_KEYS, response.results)
};
const removeApiKey = async ({commit},payload) => {
  await removeKey(payload);
  commit(types.project.mutations.REMOVE_KEY, payload)

};
const createApiKey = async ({commit},payload) => {
  try{
    setLoadingAndError(commit, false);
    return await createKey(payload);
  }
  catch(error){
    setLoadingAndError(commit, false, error);
    throw error;
  }
};
const fetchExportsByStatusAction = async({commit}, status) =>{
  try{
   const res = await fetchExportesByStatus(status)
    setLoadingAndError(commit, false);
    return res
  }
  catch(error){
    setLoadingAndError(commit, false, error);
    throw error;
  }
}

const cancelSubscriptionAction = async({commit}, payload)=>{
  try{
    setLoadingAndError(commit, false);
    return await cancelSubsctiption(payload);
  }
  catch(error){
    setLoadingAndError(commit, false, error);
    throw error;
  }
}

/*======== end keys actions ==========*/
const actions = {
  [types.project.actions.CREATE_FOLDER]: createFolderAction,
  [types.project.actions.UPDATE_FOLDER]: updateFolderAction,
  [types.project.actions.UPDATE_VIDEO]: updateVideoAction,
  [types.project.actions.UPLOAD_VIDEO]: uploadVideoAction,
  [types.project.actions.UPLOAD_YOUTUBE_VIDEO]: uploadYoutubeVideoAction,
  [types.project.actions.FETCH_HOME_PAGE_APIS]: fetchHomePageAPIsActions,
  [types.project.actions.FETCH_VIDEOS]: fetchHomePageVideosActions,
  [types.project.actions.FETCH_VIDEOS_LIST]: fetchVideosListActions,
  [types.project.actions.FETCH_FOLDER]: fetchFolderAction,
  [types.project.actions.FETCH_VIDEO]: fetchVideoAction,
  [types.project.actions.FETCH_FOLDERS]: fetchHomePageFoldersActions,
  [types.project.actions.FETCH_VIDEO_SUBTITLE]: fetchSubtitlesForVideoActions,
  [types.project.actions.UPDATE_VIDEO_SUBTITLE]: updateVideoSubtitleActions,
  [types.project.actions.UPDATE_TRIMED_DURATIONS]: saveTrimedDurationsActions,
  [types.project.actions.FETCH_VIDEO_WAVEFORM]: fetchWaveformForVideoActions,
  [types.project.actions.CANCEL_UPLOAD_VIDEO]: cancelUploadVideos,
  [types.project.actions.EXPORT_CLIP]: exportClipAction,
  [types.project.actions.CREATE_NEW_CLIP]: createNewClipAction,
  [types.project.actions.FETCH_VIDEO_CLIPS]: fetchVideoClipsAction,
  [types.project.actions.FETCH_CLIP]: fetchVideoClipAction,
  [types.project.actions.UPDATE_CLIP]: updateClipAction,
  [types.project.actions.FETCH_FONTS]: fetchFontsAction,
  [types.project.actions.FETCH_CLIP_STYLE]: fetchClipStyleAction,
  [types.project.actions.UPDATE_CLIP_STYLE]: updateClipStyleAction,
  [types.project.actions.FETCH_EXPORTED_CLIP]: fetchExportedClipAction,
  [types.project.actions.PRE_UPLOAD_VIDEO]: preUploadVideoAction,
  [types.project.actions.DOWNLOAD_VIDEO_SRT]: downloadVideoSRTAction,
  [types.project.actions.DOWNLOAD_VIDEO_TEXT]: downloadVideoTextAction,
  [types.project.actions.DELETE_CLIP]: deleteClipAction,
  [types.project.actions.DELETE_VIDEO]: deleteVideoAction,
  [types.project.actions.DELETE_FOLDER]: deleteFolderAction,
  [types.project.actions.GET_TEXT_LAYER]: fetchTextLayerAction,
  [types.project.actions.CREATE_TEXT_LAYER]: createTextLayerAction,
  [types.project.actions.CREATE_TEMPRORY_TEXT_LAYER]:
    createTemproryTextLayerAction,
  [types.project.actions.UPDATE_TEXT_LAYER_FONTSIZE]: updateTextLayerFontsize,
  [types.project.actions.UPDATE_TEXT_LAYER]: updateTextLayerAction,
  [types.project.actions.DELETE_TEXT_LAYER]: deleteTextLayerAction,
  [types.project.actions.DELETE_CLIP]: deleteClipAction,
  [types.project.actions.DELETE_FOLDER]: deleteFolderAction,
  [types.project.actions.DOWNLOAD_CLIP_SRT]: downloadClipSRTAction,
  [types.project.actions.DOWNLOAD_CLIP_TEXT]: downloadClipTextAction,
  [types.project.actions.FETCH_IMAGE_LAYERS]: fetchImageLayersAction,
  [types.project.actions.CREATE_IMAGE_LAYER]: createImageLayerAction,
  [types.project.actions.UPDATE_IMAGE_LAYER]: updateImageLayerAction,
  [types.project.actions.DELETE_IMAGE_LAYER]: deleteImageLayerAction,
  [types.project.actions.FETCH_VIDEO_SPEAKERS]: fetchVideoSpeakersAction,

  [types.project.actions.CLIP_RESIZE_CROP_SQUARE]: clipResizeCropSquareAction,
  [types.project.actions.CLIP_RESIZE_CROP_VERTICAL]:
    clipResizeCropVerticalAction,
  [types.project.actions.CLIP_RESIZE_FIT_SQUARE]: clipResizeFitSquareAction,
  [types.project.actions.CLIP_RESIZE_FIT_VERTICAL]: clipResizeFitVerticalAction,
  [types.project.actions.CLIP_RESIZE_FIT_STANDARD_4_BY_5]: clipResizeFitStandard4by5Action,
  [types.project.actions.CLIP_RESIZE_CROP_STANDARD_4_BY_5]: clipResizeCropStandard4by5Action,
  [types.project.actions.CLIP_RESIZE_RESET]: clipResizeResetAction,
  [types.project.actions.CLONE_CLIP]: cloneClipAction,
  [types.project.actions.REPORT_ERROR]: reportErrorAction,
  [types.project.actions.CLEAN_CLIP_AUDIO]: cleanClipAudioAction,
  [types.project.actions.UNDO_CLEAN_CLIP_AUDIO]: undoCleanClipAudioAction,
  [types.project.actions.PATCH_CLIP]:patchClipAction,
  [types.project.actions.PATCH_CLIP_ONLY]:onlyPatchClipAction,
  [types.project.actions.UPLOAD_USER_FONTS]:uploadUserFontsAction,
  [types.project.actions.FETCH_KEYS]:fetchApiKeys,
  [types.project.actions.REMOVE_KEY]:removeApiKey,
  [types.project.actions.CREATE_KEY]:createApiKey,
  [types.project.actions.FETCH_EXPORTS_BY_STATUS]:fetchExportsByStatusAction,
  [types.project.actions.CANCEL_SUBSCRIPTION]:cancelSubscriptionAction,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
