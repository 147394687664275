<template>
  <div class="pa-10">
    <div class="mb-4">
      <a href="https://chopcast.mintlify.app/api-reference/introduction" target="_blank"><b>API Documentation</b></a>
    </div>
    <div class="d-flex align-baseline">
      <h2>Generate Key</h2>
    </div>
    <div class="d-flex align-baseline mt-4">
      <input v-model="keyTitle" placeholder="Key Title" class="key-input" />
      <v-btn class="ml-4" small flat :color="'#fbd458'" @click="generateKey">Generate</v-btn>
    </div>
    <div class="ma-2" :style="{visibility: generatedKey?'visible':'hidden'}">
        <div><span>Copy your 1 time key</span></div>
        <div class="mt-4"><span><b>{{generatedKey}}</b></span></div>
    </div>
    <v-card
    v-if="apiKeys.length"
    class="mx-auto mt-16"
  >
  <h3 class="pa-4">Keys</h3>
    <v-list two-line class="v-list-scrollable">
      <v-list-item-group
      >
        <template v-for="(item, index) in apiKeys">
          <v-list-item :key="item.name">
            <template>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>

                <v-list-item-subtitle
                  class="text--primary"
                  v-text="item.hashed_key"
                ></v-list-item-subtitle>

              </v-list-item-content>

              <v-list-item-action>
                <v-icon
                  color="grey lighten-1"
                  @click="removeKey(item.prefix)"
                >
                  mdi-delete
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider
            v-if="index < apiKeys.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import types from "../../store/types";
export default {
  data: () => ({
    keyTitle: "",
    generatedKey: ""
  }),
  mounted() {
    this.fetchApiKeys();
  },
  methods: {
    ...mapActions({
      fetchApiKeys: types.project.actions.FETCH_KEYS,
      removeApiKey: types.project.actions.REMOVE_KEY,
      createApikey: types.project.actions.CREATE_KEY,
    }),
   async generateKey(){
        const res = await this.createApikey({name:this.keyTitle})
        this.generatedKey = res.api_key
    },
    removeKey(prefix){
        this.removeApiKey(prefix)
   } 
  },
  computed: {
    ...mapState({
      apiKeys: (state) => state.project.apiKeys,
    }),
  },
};
</script>
<style>
.key-input {
  width: 400px;
  border: 1px solid lightgray;
  outline: none;
  height: 30px;
  padding: 5px;
}
.v-list-scrollable {
  max-height: 50vh; /* Adjust the height as needed */
  overflow-y: auto;
}
</style>
