<template>
  <div class="plans-wrapper">
    <v-slider
      v-if="user.uuid && !notSubscribed()"
      :max="getTotalMinutes()"
      min="0"
      :value="getUsedMinutes()"
      :thumb-size="30"
      thumb-label="always"
      readonly
      ><template v-slot:thumb-label="value">
        <div>
          <div class="plans-wrapper__thumb">{{ value.value }} upload mins</div>
          <div class="plans-wrapper__arrow"></div></div></template
    ></v-slider>

    <v-row>
      <v-col cols="12" class="d-flex align-start mb-5 justify-center">
        <div class="font-weight-bold mr-5">Bill monthly</div>
        <v-switch v-model="isYearly" color="primary" hide-details inset>
        </v-switch>
        <div class="font-weight-bold ml-5">Bill yearly</div>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="!isYearly">
      <v-col cols="12" md="3" xl="3" class="d-flex justify-center mb-3">
        <Mini
          :plan="miniPlan"
          :goToCheckout="goToCheckout"
          :userPlan="user.plan"
          type="monthly"
        />
      </v-col>
      <v-col cols="12" md="3" xl="3" class="d-flex justify-center mb-3">
        <Basic
          :plan="basicPlan"
          :goToCheckout="goToCheckout"
          :userPlan="user.plan"
          type="monthly"
        />
      </v-col>
      <v-col cols="12" md="3" xl="3" class="d-flex justify-center mb-3">
        <Pro
          :plan="proPlan"
          :goToCheckout="goToCheckout"
          :userPlan="user.plan"
          type="monthly"
        />
      </v-col>
      <v-col cols="12" md="3" xl="3" class="d-flex justify-center mb-3">
        <Custom type="monthly" />
      </v-col>
    </v-row>
    <v-row no-gutters v-if="isYearly">
      <v-col cols="12" md="3" xl="3" class="d-flex justify-center mb-3">
        <Mini
          v-if="miniPlan && miniPlan.prices && miniPlan.prices.length > 1"
          :plan="miniPlan"
          :goToCheckout="goToCheckout"
          :userPlan="user.plan"
          type="yearly"
        />
      </v-col>
      <v-col cols="12" md="3" xl="3" class="d-flex justify-center mb-3">
        <Basic
          v-if="basicPlan && basicPlan.prices && basicPlan.prices.length > 1"
          :plan="basicPlan"
          :goToCheckout="goToCheckout"
          :userPlan="user.plan"
          type="yearly"
        />
      </v-col>
      <v-col cols="12" md="3" xl="3" class="d-flex justify-center mb-3">
        <Pro
          v-if="proPlan && proPlan.prices && proPlan.prices.length > 1"
          :plan="proPlan"
          :goToCheckout="goToCheckout"
          :userPlan="user.plan"
          type="yearly"
        />
      </v-col>
      <v-col cols="12" md="3" xl="3" class="d-flex justify-center mb-3">
        <Custom type="yearly" />
      </v-col>
    </v-row>
    <v-divider v-if="user.uuid && !notSubscribed()" class="mt-5"></v-divider>
    <h2 v-if="user.uuid && !notSubscribed()" class="plans-wrapper__title">
      Usage
    </h2>
    <div
      v-if="user.uuid && !notSubscribed()"
      class="plans-wrapper__usage-table"
    >
      <div class="plans-wrapper__usage-table--cell">
        <div class="plans-wrapper__subtitle-regular">Minutes</div>
        <div class="plans-wrapper__usage-table--content">
          <div class="text-subtitle-2 font-weight-light">
            ( {{ getUsedMinutes() }} of {{ getTotalMinutes() }} mins used )
          </div>
          <v-progress-linear :value="percentageRange"></v-progress-linear>
        </div>
      </div>
      <!-- <div class="plans-wrapper__usage-table--cell">
        <div class="plans-wrapper__subtitle-regular">Space</div>
        <div class="plans-wrapper__usage-table--content">
          <div class="text-subtitle-2 font-weight-light">
            ( {{getUsedCapacityByGB()}} of {{getTotalCapacityByGB()}} GB used )
          </div>
          <v-progress-linear :value="percentageRange"></v-progress-linear>
        </div>
      </div> -->
    </div>
    <!-- <v-divider class="mt-5"></v-divider>
    <h2 class="plans-wrapper__title">Billing and invoices</h2>
    <v-divider class="plans-wrapper__divider"></v-divider>
    <div class="plans-wrapper__billing-table">
      <div class="plans-wrapper__billing-table--row">
        <div class="plans-wrapper__billing-table--cell">Card number</div>
        <div class="plans-wrapper__billing-table--cell">
          **** **** **** 2344
        </div>
        <div class="plans-wrapper__billing-table--cell">
          <v-btn
            class="plans-wrapper__billing-table__cta"
            text
            :ripple="false"
            width="100"
            height="36"
            outlined
          >
            <span class="font-weight-normal text-capitalize">Change</span>
          </v-btn>
        </div>
      </div>
    </div>
    <v-divider class="plans-wrapper__divider"></v-divider>
    <div class="plans-wrapper__billing-table">
      <div class="plans-wrapper__billing-table--row">
        <div class="plans-wrapper__billing-table--cell">Last payment</div>
        <div class="plans-wrapper__billing-table--cell">
          **** **** **** 2344
        </div>
        <div class="plans-wrapper__billing-table--cell">23 Oct 2021</div>
        <div class="plans-wrapper__billing-table--cell">49 USD</div>
        <div class="plans-wrapper__billing-table--cell">
          View payment details
        </div>
      </div>
    </div>
    <v-divider class="plans-wrapper__divider"></v-divider>
    <div class="plans-wrapper__billing-table" id="next-payment">
      <div class="plans-wrapper__billing-table--row">
        <div class="plans-wrapper__billing-table--cell">Next payment</div>
        <div class="plans-wrapper__billing-table--cell"></div>
        <div class="plans-wrapper__billing-table--cell">23 Oct 2021</div>
        <div class="plans-wrapper__billing-table--cell">49 USD</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import types from "../../store/types";
import Mini from "./PlanCards/Mini.vue";
import Basic from "./PlanCards/Basic.vue";
import Pro from "./PlanCards/Pro.vue";
import Custom from "./PlanCards/Custom.vue";

export default {
  components: { Mini, Basic, Pro, Custom },
  data() {
    return {
      value: 100,
      isYearly: true,
      miniPlan: {},
      basicPlan: {},
      proPlan: {},
    };
  },
  computed: {
    ...mapState({
      plans: (state) => state.user.plans,
      user: (state) => state.user.user,
    }),
    percentageRange() {
      const { plan_used_seconds, seconds_limit } = this.user;
      return (plan_used_seconds / seconds_limit) * 100;
    },
  },
  methods: {
    ...mapActions({
      fetchPlans: types.user.actions.FETCH_PLANS,
      buyPlan: types.user.actions.BUY_PLAN,
    }),
    goToCheckout(planUuid) {
      const payload = {
        plan_uuid: planUuid,
      };
      this.buyPlan(payload);
    },
    notSubscribed() {
      return !this.user.in_trial && !this.user.trial_ends && !this.user.plan;
    },
    getTotalMinutes() {
      let totalMinutes = 0;
      if (this.user && this.user?.seconds_limit) {
        totalMinutes = Math.ceil(this.user.seconds_limit / 60);

        if (this.user?.plan) {
          if (this.user?.plan?.s_recurring_interval === "year")
            totalMinutes = totalMinutes / 12;
        }
      }
      return totalMinutes;
    },
    getUsedMinutes() {
      if (this.user?.plan_used_seconds) {
        return Math.ceil(this.user.plan_used_seconds / 60);
      }
      return 0;
    },
  },
  async created () {
    await this.fetchPlans()
    this.miniPlan = this.plans.find((plan) => plan.s_name === "90 mins");
    this.basicPlan = this.plans.find((plan) => plan.s_name === "180 mins");
    this.proPlan = this.plans.find((plan) => plan.s_name === "360 mins");
  },
  mounted() {
    let classNumber = 0;

    document
      .querySelectorAll(".v-progress-linear__determinate")
      .forEach((item) => {
        const { classList } = item;
        if (classList.contains("primary")) {
          classList.remove("primary");
          if (this.percentageRange >= 0 && this.percentageRange < 33)
            classNumber = 0;
          else if (this.percentageRange >= 33 && this.percentageRange < 66)
            classNumber = 1;
          else if (this.percentageRange >= 66 && this.percentageRange <= 100)
            classNumber = 2;

          classList.add(`v-progress-linear__gradient-${classNumber}`);
        }
      });
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";
.plans-wrapper {
  height: 100%;
  &__thumb {
    background-color: black !important;
    width: 150px !important;
    height: 32px !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 16px;
    z-index: 10;
  }
  &__arrow {
    background-color: black !important;
    position: absolute !important;
    width: 18px !important;
    height: 18px !important;
    bottom: -4px !important;
    left: calc(50% - 9px);
    transform: rotate(45deg) !important;
    border-radius: 3px;
    z-index: 9;
  }
  &__title {
    font-family: "Satoshi Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    padding-top: 24px;
    color: $black;
    margin-bottom: 16px;
  }
  &__subtitle-regular {
    font-size: 16px;
    color: $black;
    margin-bottom: 8px;
  }
  &__usage-table {
    display: flex;
    &--cell {
      display: block;
      width: 50%;
      margin-right: 50px;
      font-weight: 600;
    }
    &--content {
      display: flex;
      align-items: baseline;
      div:first-child {
        width: 50% !important;
      }
    }
  }
  &__billing-table {
    &--row {
      display: flex;
      align-items: center;
      padding: 18px 0;
    }
    &--cell {
      font-size: 16px;
      font-family: "Satoshi Bold";
      font-weight: 700;
      width: 20%;
      &:nth-child(5) {
        color: $violet;
        cursor: pointer;
      }
    }
    &__cta {
      font-size: 16px;
      border-color: #c1d5e2 !important;
      color: black !important;
      border-radius: 5px;
    }
  }
  &__divider {
    opacity: 0.65;
  }
  #next-payment {
    :nth-child(3),
    :nth-child(4) {
      color: #c1d5e2 !important;
    }
  }
}
</style>
