const app = {
  mutations: {
    SET_IS_LOADER_VISIBLE: "app/mutations/SET_IS_LOADER_VISIBLE",
    SET_ERROR_MESSAGE: "app/mutations/SET_ERROR_MESSAGE",
    SET_REDIRECT_PAGE: "app/mutations/SET_REDIRECT_PAGE",
    SET_SUCCESS_MESSAGE: "app/mutations/SET_SUCCESS_MESSAGE",
    SET_SUCCESS_MESSAGE_TIME_OUT: "app/mutations/SET_SUCCESS_MESSAGE_TIME_OUT",
    SET_BUTTON_LOADER: "app/mutations/SET_BUTTON_LOADER",
    SET_DOWNLOAD_DIALOG_VISIBILITY: "app/mutations/SET_DOWNLOAD_DIALOG_VISIBILITY",
    RELOAD_EDITOR_PAGE: "app/mutations/RELOAD_EDITOR_PAGE"
  },
};
const user = {
  mutations: {
    SET_USER: "user/mutations/SET_USER",
    SET_REFRESH_TOKEN: "user/mutations/SET_REFRESH_TOKEN",
    RESET_STATE: "user/mutations/RESET_STATE",
    SET_PLANS: "user/mutations/SET_PLANS",
  },
  actions: {
    USER_LOGIN: "user/actions/USER_LOGIN",
    USER_LOGOUT: "user/actions/USER_LOGOUT",
    USER_PROFILE: "user/actions/USER_PROFILE",
    USER_SIGN_UP: "user/actions/USER_SIGN_UP",
    UPDATE_USER: "user/actions/UPDATE_USER",
    ACTIVATE_USER: "user/actions/ACTIVATE_USER",
    RESEND_VERIFICATION_TO_USER: "user/actions/RESEND_VERIFICATION_TO_USER",
    GOOGLE_AUTH_USER: "user/actions/GOOGLE_AUTH_USER",
    FETCH_PLANS: "user/actions/FETCH_PLANS",
    BUY_PLAN: "user/actions/BUY_PLAN",
    RESET_PASSWORD: "user/actions/RESET_PASSWORD",
    RESET_PASSWORD_CONFIRM: "user/actions/RESET_PASSWORD_CONFIRM",
    SET_PASSWORD: "user/actions/SET_PASSWORD",
  },
};

const project = {
  getters: {
    GET_VIDEOS: "project/getters/GET_VIDEOS",
    IS_VIDEO_UPLOADING_EXIST: "project/getters/IS_VIDEO_UPLOADING_EXIST",
  },
  mutations: {
    SET_FOLDERS: "project/mutations/SET_FOLDERS",
    SET_FOLDER: "project/mutations/SET_FOLDER",
    SET_VIDEOS: "project/mutations/SET_VIDEOS",
    UPDATE_VIDEOS_LIST: "project/mutations/UPDATE_VIDEOS_LIST",
    RESET_VIDEOS: "project/mutations/RESET_VIDEOS",
    RESET_FOLDERS: "project/mutations/RESET_FOLDERS",
    SET_VIDEO_SUBTITLE: "project/mutations/SET_VIDEO_SUBTITLE",
    SET_VIDEO_WAVEFORM: "project/mutations/SET_VIDEO_WAVEFORM",
    SET_TRIMED: "project/mutations/SET_TRIMED",
    ADD_FOLDER: "project/mutations/ADD_FOLDER",
    ADD_UPLOADING_VIDEOS: "project/mutations/ADD_UPLOADING_VIDEOS",
    UPDATE_UPLOADING_VIDEO_PERCENTAGE:
      "project/mutations/UPDATE_UPLOADING_VIDEO_PERCENTAGE",
    REMOVE_UPLOADING_VIDEOS: "project/mutations/REMOVE_UPLOADING_VIDEOS",
    SET_IS_FLOATING_UPLOAD_WINDOW_VISIBLE:
      "project/mutations/SET_IS_FLOATING_UPLOAD_WINDOW_VISIBLE",
    RESET_STATE: "project/mutations/RESET_STATE",
    SET_FONTS: "project/mutations/SET_FONTS",
    SET_USER_FONTS: "project/mutations/SET_USER_FONTS",
    SET_CURRENT_SUBTITLE: "project/mutations/SET_CURRENT_SUBTITLE",
    SET_CLIP_STYLE: "project/mutations/SET_CLIP_STYLE",
    SET_CLIP: "project/mutations/SET_CLIP",
    SET_WORDS_PER_LINE: "project/mutations/SET_WORDS_PER_LINE",
    SET_SUBTITLE_WORDS: "project/mutations/SET_SUBTITLE_WORDS",
    REMOVE_FOLDER: "project/mutations/REMOVE_FOLDER",
    SET_VIDEO_IN_FOLDER: "project/mutations/SET_VIDEO_IN_FOLDER",
    REMOVE_VIDEO: "project/mutations/REMOVE_VIDEO",
    SET_TEXT_LAYER: "project/mutations/SET_TEXT_LAYER",
    ADD_TEXT_LAYER: "project/mutations/ADD_TEXT_LAYER",
    UPDATE_TEXT_LAYER: "project/mutations/UPDATE_TEXT_LAYER",
    UPDATE_TEXT_LAYER_FONTSIZE: "project/mutations/UPDATE_TEXT_LAYER_FONTSIZE",
    REMOVE_TEMPRORY_TEXT_LAYER: "project/mutations/REMOVE_TEMPRORY_TEXT_LAYER",
    SET_SELECTED_TITLE_UUID: "project/mutations/SET_SELECTED_TITLE_UUID",
    REMOVE_TEXT_LAYER: "project/mutations/REMOVE_TEXT_LAYER",
    SET_IMAGE_LAYERS: "project/mutations/SET_IMAGE_LAYERS",
    ADD_IMAGE_LAYER: "project/mutations/ADD_IMAGE_LAYER",
    UPDATE_IMAGE_LAYER: "project/mutations/UPDATE_IMAGE_LAYER",
    REMOVE_IMAGE_LAYER: "project/mutations/REMOVE_IMAGE_LAYER",
    SET_SELECTED_IMAGE_LAYER_UUID:
      "project/mutations/SET_SELECTED_IMAGE_LAYER_UUID",
    SET_SEARCH_KEYWORD: "project/mutations/SET_SEARCH_KEYWORD",
    SET_VIDEO_SPEAKERS: "project/mutations/SET_VIDEO_SPEAKERS",
    SET_HOME_LOADER: "project/mutations/SET_HOME_LOADER",
    SET_DRAGGED_FOLDER_UUID: "project/mutations/SET_DRAGGED_FOLDER_UUID",
    SET_SUBTITLES_HEIGHT: "project/mutations/SET_SUBTITLES_HEIGHT",
    OPEN_AUDIO_ENHANCE_DIALOG: "project/mutations/OPEN_AUDIO_ENHANCE_DIALOG",
    SET_KEYS:"project/mutations/SET_KEYS",
    REMOVE_KEY:"project/mutations/REMOVE_KEY",
    RELOAD_PLAYER:"project/mutations/RELOAD_PLAYER",
    
  },
  actions: {
    FETCH_HOME_PAGE_APIS: "project/actions/FETCH_HOME_PAGE_APIS",
    CREATE_FOLDER: "project/actions/CREATE_FOLDER",
    UPDATE_FOLDER: "project/actions/UPDATE_FOLDER",
    UPDATE_VIDEO: "project/actions/UPDATE_VIDEO",
    UPLOAD_VIDEO: "project/actions/UPLOAD_VIDEO",
    UPLOAD_YOUTUBE_VIDEO: "project/actions/UPLOAD_YOUTUBE_VIDEO",
    FETCH_VIDEO_SUBTITLE: "project/actions/FETCH_VIDEO_SUBTITLE",
    UPDATE_VIDEO_SUBTITLE: "project/actions/UPDATE_VIDEO_SUBTITLE",
    FETCH_VIDEO_WAVEFORM: "project/actions/FETCH_VIDEO_WAVEFORM",
    CANCEL_UPLOAD_VIDEO: "project/actions/CANCEL_UPLOAD_VIDEO",
    EXPORT_CLIP: "project/actions/EXPORT_CLIP",
    CREATE_NEW_CLIP: "project/actions/CREATE_NEW_CLIP",
    FETCH_VIDEO_CLIPS: "project/actions/FETCH_VIDEO_CLIPS",
    FETCH_CLIP: "project/actions/FETCH_CLIP",
    UPDATE_CLIP: "project/actions/UPDATE_CLIP",
    FETCH_FONTS: "project/actions/FETCH_FONTS",
    UPLOAD_USER_FONTS: "project/actions/UPLOAD_USER_FONTS",
    FETCH_USER_ONTS: "project/actions/FETCH_USER_ONTS",
    FETCH_CLIP_STYLE: "project/actions/FETCH_CLIP_STYLE",
    UPDATE_CLIP_STYLE: "project/actions/UPDATE_CLIP_STYLE",
    FETCH_EXPORTED_CLIP: "project/actions/FETCH_EXPORTED_CLIP",
    FETCH_VIDEOS: "project/actions/FETCH_VIDEOS",
    FETCH_VIDEOS_LIST: "project/actions/FETCH_VIDEOS_LIST",
    FETCH_VIDEO: "project/actions/FETCH_VIDEO",
    FETCH_FOLDER: "project/actions/FETCH_FOLDER",
    FETCH_FOLDERS: "project/actions/FETCH_FOLDERS",
    PRE_UPLOAD_VIDEO: "project/actions/PRE_UPLOAD_VIDEO",
    DOWNLOAD_VIDEO_SRT: "project/actions/DOWNLOAD_VIDEO_SRT",
    DOWNLOAD_VIDEO_TEXT: "project/actions/DOWNLOAD_VIDEO_TEXT",
    DELETE_CLIP: "project/actions/DELETE_CLIP",
    DELETE_VIDEO: "project/actions/DELETE_VIDEO",
    DELETE_FOLDER: "project/actions/DELETE_FOLDER",
    GET_TEXT_LAYER: "project/actions/GET_TEXT_LAYER",
    CREATE_TEXT_LAYER: "project/actions/CREATE_TEXT_LAYER",
    CREATE_TEMPRORY_TEXT_LAYER: "project/actions/CREATE_TEMPRORY_TEXT_LAYER",
    UPDATE_TEXT_LAYER_FONTSIZE: "project/actions/UPDATE_TEXT_LAYER_FONTSIZE",
    UPDATE_TEXT_LAYER: "project/actions/UPDATE_TEXT_LAYER",
    DELETE_TEXT_LAYER: "project/actions/DELETE_TEXT_LAYER",
    DOWNLOAD_CLIP_SRT: "project/actions/DOWNLOAD_CLIP_SRT",
    DOWNLOAD_CLIP_TEXT: "project/actions/DOWNLOAD_CLIP_TEXT",
    FETCH_IMAGE_LAYERS: "project/actions/FETCH_IMAGE_LAYERS",
    CREATE_IMAGE_LAYER: "project/actions/CREATE_IMAGE_LAYER",
    UPDATE_IMAGE_LAYER: "project/actions/UPDATE_IMAGE_LAYER",
    DELETE_IMAGE_LAYER: "project/actions/DELETE_IMAGE_LAYER",
    FETCH_VIDEO_SPEAKERS: "project/actions/FETCH_VIDEO_SPEAKERS",
    CLIP_RESIZE_CROP_SQUARE: "project/actions/CLIP_RESIZE_CROP_SQUARE",
    CLIP_RESIZE_CROP_VERTICAL: "project/actions/CLIP_RESIZE_CROP_VERTICAL",
    CLIP_RESIZE_FIT_SQUARE: "project/actions/CLIP_RESIZE_FIT_SQUARE",
    CLIP_RESIZE_FIT_VERTICAL: "project/actions/CLIP_RESIZE_FIT_VERTICAL",
    CLIP_RESIZE_FIT_STANDARD_4_BY_5: "project/actions/CLIP_RESIZE_FIT_STANDARD_4_BY_5",
    CLIP_RESIZE_CROP_STANDARD_4_BY_5: "project/actions/CLIP_RESIZE_CROP_STANDARD_4_BY_5",
    CLIP_RESIZE_RESET: "project/actions/CLIP_RESIZE_RESET",
    CLONE_CLIP: "project/actions/CLONE_CLIP",
    UPDATE_TRIMED_DURATIONS: "project/actions/UPDATE_TRIMED_DURATIONS",
    REPORT_ERROR: "project/actions/REPORT_ERROR",
    CLEAN_CLIP_AUDIO: "project/actions/CLEAN_CLIP_AUDIO",
    UNDO_CLEAN_CLIP_AUDIO:"project/actions/UNDO_CLEAN_CLIP_AUDIO",
    PATCH_CLIP:"project/actions/PATCH_CLIP",
    PATCH_CLIP_ONLY:"project/actions/PATCH_CLIP_ONLY",
    FETCH_KEYS:"project/actions/FETCH_KEYS",
    REMOVE_KEY:"project/actions/REMOVE_KEY",
    CREATE_KEY:"project/actions/CREATE_KEY",
    FETCH_EXPORTS_BY_STATUS:"project/actions/FETCH_EXPORTS_BY_STATUS",
    CANCEL_SUBSCRIPTION:"project/actions/CANCEL_SUBSCRIPTION",
    FETCH_AUTO_CROP_CLIP:"project/actions/FETCH_AUTO_CROP_CLIP"
  },
};
const download = {
  mutations:{
    SET_EXPORT_CLIP_STATE: "download/mutations/SET_EXPORT_CLIP_STATE",
    ADD_TO_EXPORT_LIST:"download/mutations/ADD_TO_EXPORT_LIST",
    REMOVE_FROM_EXPORT_LIST:"download/mutationsREMOVE_FROM_EXPORT_LIST"
  },
  actions: {
    SET_EXPORT_CLIP: "download/actions/SET_EXPORT_CLIP",
  },
}
const chatWithVideo = {
  mutations:{
    ADD_MESSAGE: "chatWithVideo/mutations/ADD_MESSAGE",
    SHOW_CHAT_WINDOW: "chatWithVideo/mutations/SHOW_CHAT_WINDOW"
  },
}

const autoCrop = {
  mutations:{
  SET_AUTO_CROP_CLIP:"autoCrop/mutations/SET_AUTO_CROP_CLIP",
  AUTO_CROPPING:"autoCrop/mutations/AUTO_CROPPING",
  AUTO_CROPPING_PROGRESS:"autoCrop/mutations/AUTO_CROPPING_PROGRESS",
  SHOW_AUTO_CROP:"autoCrop/mutations/SHOW_AUTO_CROP",
  SET_IS_AUTO_CROP_STARTED:"autoCrop/mutations/SET_IS_AUTO_CROP_STARTED",
  UPDATE_AUTO_CROP_TIME_RANGE:"autoCrop/mutations/UPDATE_AUTO_CROP_TIME_RANGE"
  },
  actions:{
    AUTO_CROP:"autoCrop/actions/AUTO_CROP",
    FETCH_AUTO_CROP_CLIP:"autoCrop/actions/FETCH_AUTO_CROP_CLIP",
    UPDATE_AUTO_CROP_CLIP:"autoCrop/actions/UPDATE_AUTO_CROP_CLIP"
  }
}

export default {
  app,
  user,
  project,
  download,
  autoCrop,
  chatWithVideo
};

