import { state } from "./progress.state";
import { actions } from "./progress.action";
import { mutations } from "./progress.mutation";
import { getters } from "./progress.getters";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
