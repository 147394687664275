<template>
  <div class="subscription">
    <div class="subscription__wrapper">
      <div class="subscription__playground">
        <div class="subscription__playground--lhs">
          <v-btn
            text
            color="black"
            class="subscription__back-button mr-3"
            @click="$router.go(-1)"
          >
            <span class="font-weight-bold text-capitalize text-subtitle-1"
              ><v-icon color="black" class="mr-2">mdi-arrow-left</v-icon
              >back</span
            >
          </v-btn>
          <SideMenu :selectedItem="selectedItem" />
        </div>
        <div class="subscription__playground--rhs">
          <Account v-if="selectedItem === 0" />
          <!-- <InviteFriend v-else-if="selectedItem === 1" /> -->
          <Plans v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState, mapGetters, mapActions } from "vuex";
// import types from "../store/types";
import SideMenu from "../../components/Subscription/SideMenu.vue";
import Account from "../../components/Subscription/Account.vue";
// import InviteFriend from "../../components/Subscription/InviteFriend.vue";
import Plans from "../../components/Subscription/Plans.vue";

export default {
  data() {
    return {
      selectedItem: 0,
    };
  },
  components: {
    SideMenu,
    Account,
    // InviteFriend,
    Plans,
  },
  mounted() {
    if (this.$route.name === "Profile") this.selectedItem = 0;
    // else if (this.$route.name === "InviteFriend") this.selectedItem = 1;
    // TODO this.selectedItem = 2 when InviteFriend is invisible
    else if (this.$route.name === "Subscription") this.selectedItem = 1;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/views/subscription.scss";
</style>
