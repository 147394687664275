<template>
  <v-dialog v-model="isDialogVisible" max-width="600">
    <v-card>
      <v-card-title ref="CreateOrUpdateClipDialogTitle" class="text-h5">
        Clip Action
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container>
          <v-row>
            <div class="text-h6">
              Do you want to create new clip or update the current one ?
            </div>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-space-between pb-3">
        <v-btn
          class="new-folder-modal-wrapper--btn mt-3 font-weight-bold text-capitalize"
          @click="handleClickCreate"
          color="#fbd458"
          text
          :style="{ width: '49%' }"
          >Create new clip</v-btn
        >
        <v-btn
          color="black"
          text
          @click="handleClickUpdate"
          :style="{ width: '49%' }"
          class="ml-0 mt-3 font-weight-bold text-capitalize"
          >Update current clip</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CLIP_OPERATIONS from "../../constants/clipOperation";

export default {
  name: "CreateOrUpdateClipDialog",
  props: [
    "isCreateOrUpdateClipDialogDialogVisible",
    "showCreateOrUpdateClipDialogDialog",
    "handleChoice",
  ],
  computed: {
    isDialogVisible: {
      get() {
        return this.isCreateOrUpdateClipDialogDialogVisible;
      },
      set() {
        this.showCreateOrUpdateClipDialogDialog(false);
      },
    },
  },
  methods: {
    handleClickCreate() {
      this.handleChoice(CLIP_OPERATIONS.CREATE_CLIP);
    },
    handleClickUpdate() {
      this.handleChoice(CLIP_OPERATIONS.UPDATE_CLIP);
    },
  },

  updated() {
    // When dialog open, non of all click handlers work for the first click for any button so it is realized that the dialog itself needs to be focused or clicking on any component inside dialog will focus in the dialog and the click handler will work directly with first click so this fake click on dialog title will solve this issue
    this.$nextTick(() => this.$refs.CreateOrUpdateClipDialogTitle.click());
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/videos/new-folder.scss";
</style>
