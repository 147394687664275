var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"width":"409"},model:{value:(_vm.isDownloadDialogShown),callback:function ($$v) {_vm.isDownloadDialogShown=$$v},expression:"isDownloadDialogShown"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-icon',{on:{"click":function($event){return _vm.showDownloadDialogVisible(false)}}},[_vm._v("mdi-close")])],1),_c('v-card-text',{staticClass:"download-dialog-wrapper px-0 pt-0"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"download-dialog-wrapper__title font-weight-bold"},[_vm._v(" "+_vm._s(_vm.videoName)+" ")])])],1),(this.clip)?_c('v-row',[(!_vm.isClipNotExportSucceeded())?_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"download-dialog-wrapper__status font-weight-bold"},[_vm._v(" Last export ran "),_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.formateDate(_vm.exportingDate)))])]}}],null,false,1534023089)},[_c('span',[_vm._v(_vm._s(new Date(_vm.exportingDate).toLocaleString()))])])],1)])]):_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"download-dialog-wrapper__status font-weight-bold"},[_vm._v(" "+_vm._s(_vm.handleStatus(_vm.exportingStatus))+" ")])])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-flex download-dialog-wrapper__section text-subtitle-1 font-weight-bold justify-space-between"},[_c('div',{staticClass:"download-dialog-wrapper__section__download-container"},[_vm._v(" .mp4 "),(_vm.videoLoading)?_c('v-progress-linear',{staticClass:"download-dialog-wrapper__section__progress",attrs:{"color":"#fbd458","value":_vm.videoPercentage}}):_vm._e()],1),(_vm.isOptimized)?_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{attrs:{"color":"success","hide-details":""},model:{value:(_vm.burnSubtitles),callback:function ($$v) {_vm.burnSubtitles=$$v},expression:"burnSubtitles"}})],1)]}}],null,false,1371618789)},[_c('span',[_vm._v("Burn subtitle on/off")])]),(_vm.videoLoading)?_c('v-icon',{staticClass:"download-dialog-wrapper__section__loading"},[_vm._v("mdi-loading ")]):(_vm.isProcessing())?_c('v-icon',{staticClass:"mdi mdi-reload mdi-spin"}):_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.mustExport(_vm.videoLink)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":!_vm.mustExport(_vm.videoLink) ? 'secondary' : ''},on:{"click":function($event){!_vm.mustExport(_vm.videoLink)
                          ? _vm.downloadFile(_vm.videoLink, 'mp4')
                          : _vm.exportClip('mp4')}}},'v-icon',attrs,false),on),[_vm._v("mdi-cloud-download-outline")])]}}],null,false,4032908291)},[_c('span',[_vm._v("Start export to download")])])],1):_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v("Clips need to be under 10 minutes in length")])])],1)])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex download-dialog-wrapper__section text-subtitle-1 font-weight-bold justify-space-between"},[_c('div',{staticClass:"download-dialog-wrapper__section__download-container"},[_vm._v(" .mp3 "),(_vm.audioLoading)?_c('v-progress-linear',{staticClass:"download-dialog-wrapper__section__progress",attrs:{"color":"#fbd458","value":_vm.audioPercentage}}):_vm._e()],1),(_vm.isOptimized)?_c('div',[(_vm.audioLoading)?_c('v-icon',{staticClass:"download-dialog-wrapper__section__loading"},[_vm._v("mdi-loading ")]):(_vm.isProcessing())?_c('v-icon',{staticClass:"mdi mdi-reload mdi-spin"}):_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.mustExport(_vm.audioLink)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":!_vm.mustExport(_vm.audioLink) ? 'secondary' : ''},on:{"click":function($event){!_vm.mustExport(_vm.audioLink)
                        ? _vm.downloadFile(_vm.audioLink, 'mp3')
                        : _vm.exportClip('mp3')}}},'v-icon',attrs,false),on),[_vm._v("mdi-cloud-download-outline")])]}}],null,false,365744132)},[_c('span',[_vm._v("Start export to download")])])],1):_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v("Clips need to be under 10 minutes in length")])])],1)])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex download-dialog-wrapper__section text-subtitle-1 font-weight-bold justify-space-between"},[_c('div',{staticClass:"download-dialog-wrapper__section__download-container"},[_vm._v(" .srt "),(_vm.srtLoading)?_c('v-progress-linear',{staticClass:"download-dialog-wrapper__section__progress",attrs:{"color":"#fbd458","value":_vm.srtPercentage}}):_vm._e()],1),(!_vm.srtLoading)?_c('v-icon',{attrs:{"color":"secondary"},on:{"click":_vm.handleDownloadSRT}},[_vm._v("mdi-cloud-download-outline")]):_c('v-icon',{staticClass:"download-dialog-wrapper__section__loading"},[_vm._v("mdi-loading")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex download-dialog-wrapper__section text-subtitle-1 font-weight-bold justify-space-between"},[_c('div',{staticClass:"download-dialog-wrapper__section__download-container"},[_vm._v(" .txt "),(_vm.textLoading)?_c('v-progress-linear',{staticClass:"download-dialog-wrapper__section__progress",attrs:{"color":"#fbd458","value":_vm.textPercentage}}):_vm._e()],1),(!_vm.textLoading)?_c('v-icon',{attrs:{"color":"secondary"},on:{"click":_vm.handleDownloadText}},[_vm._v("mdi-cloud-download-outline")]):_c('v-icon',{staticClass:"download-dialog-wrapper__section__loading"},[_vm._v("mdi-loading")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }