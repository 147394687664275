<template>
  <div class="videos">
    <div class="videos__wrapper">
      <div class="videos__wrapper__header">
        <div class="videos__wrapper__heading">
          <p class="videos__wrapper__heading_title mb-2">My Folders</p>
          <p class="videos__wrapper__heading_subtitle">
            Access your videos and clips all in one place
          </p>
        </div>
        <div
          class="videos__wrapper__create--btn ml-auto"
          v-if="!isEmptyAccount"
        >
          <v-menu offset-y :nudge-top="-12" rounded :nudge-left="60">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                :ripple="false"
                width="148"
                height="48"
                id="no-background-hover"
              >
                <span class="font-weight-bold text-capitalize">New</span>
              </v-btn>
            </template>
            <v-list class="videos__wrapper__create--menu">
              <v-list-item
                class="videos__wrapper__create--menu-item"
                @click="showNewFolderDialog(true)"
              >
                <v-list-item-title>Folder</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                class="videos__wrapper__create--menu-item"
                @click="showUploadDialog(true)"
              >
                <v-list-item-title>Upload video</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div class="videos__playground">
        <NewFolder
          :isNewFolderDialogVisible="isNewFolderDialogVisible"
          :showNewFolderDialog="showNewFolderDialog"
        />
      </div>
      <UploadVideoDialog
        v-if="isUploadVideoDialogVisible"
        :isUploadVideoDialogVisible="isUploadVideoDialogVisible"
        :showUploadDialog="showUploadDialog"
        :showFloatingUploadWindow="showFloatingUploadWindow"
      />
      <div v-if="!isEmptyAccount">
        <FoldersGrid :folders="folders.results" />
        <div
          v-observe-visibility="{
            callback: fetchMoreFolders,
            intersection: {
              threshold: 0.3,
            },
          }"
        />
        <VideosGrid
          :videos="videos"
          :clipSpaceLg="3"
          :clipSpaceMd="6"
          @deleted="removeVideo"
        />
      </div>
      <div class="videos__empty-playground" v-if="isEmptyAccount">
        <UploadVideo
          :showUploadDialog="showUploadDialog"
          :text="emptyFolderText"
        />
      </div>
      <v-row justify="center">
        <v-progress-circular
          v-if="isHomePageLoaderVisible"
          :width="4"
          :size="40"
          color="#fbd458"
          indeterminate
        ></v-progress-circular>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import types from "../store/types";
import NewFolder from "../components/Videos/NewFolder.vue";
import UploadVideo from "../components/Videos/UploadVideo.vue";
import UploadVideoDialog from "../components/Videos/UploadVideoDialog.vue";
import VideosGrid from "../components/Videos/VideosGrid.vue";
import FoldersGrid from "../components/Videos/FoldersGrid.vue";

export default {
  components: {
    NewFolder,
    UploadVideo,
    UploadVideoDialog,
    FoldersGrid,
    VideosGrid,
  },
  name: "Videos",
  data() {
    return {
      isNewFolderDialogVisible: false,
      isUploadVideoDialogVisible: false,
      emptyFolderText:
        "Your workspace is empty, upload a video to start chopping",
      intervalId:null,
      videosList:[]
    };
  },
  methods: {
    ...mapActions({
      fetchHomePageAPIs: types.project.actions.FETCH_HOME_PAGE_APIS,
      fetchVideos: types.project.actions.FETCH_VIDEOS,
      fetchFolders: types.project.actions.FETCH_FOLDERS,
      fetchVideosList : types.project.actions.FETCH_VIDEOS_LIST
    }),
    ...mapMutations({
      showFloatingUploadWindow:
        types.project.mutations.SET_IS_FLOATING_UPLOAD_WINDOW_VISIBLE,
      resetVideos: types.project.mutations.RESET_VIDEOS,
      resetFolders: types.project.mutations.RESET_FOLDERS,
      removeVideo: types.project.mutations.REMOVE_VIDEO,
      udpateVideosList: types.project.mutations.UPDATE_VIDEOS_LIST
    }),
    showNewFolderDialog(flag) {
      this.isNewFolderDialogVisible = flag;
    },
    showUploadDialog(flag) {
      this.isUploadVideoDialogVisible = flag;
    },
    async getHomePageData() {
      try {
        await this.fetchHomePageAPIs();
      } catch (errorMessage) {
        // handled in store
      }
    },
    async fetchMoreFolders(isVisible) {
      if (!isVisible) return;
      if (this.folders.next) {
        const page = this.folders.next.split("page=")[1];
        try {
          await this.fetchFolders(page);
        } catch (errorMessage) {
          // handled in store
        }
      }
    },
  },
  computed: {
    ...mapState({
      folders: (state) => state.project.folders,
      videosData: (state) => state.project.videos,
      isHomePageLoaderVisible: (state) => state.project.isHomePageLoaderVisible,
      user: (state) => state.user.user,
    }),
    ...mapGetters({
      videos: types.project.getters.GET_VIDEOS,
    }),
    isEmptyAccount() {
      return (
        this.folders?.results?.length === 0 &&
        this.videos?.length === 0 &&
        !this.isHomePageLoaderVisible
      );
    },
  },
  mounted() {
    this.resetFolders();
    this.resetVideos();
    this.getHomePageData();
    window.addEventListener('beforeunload', ()=> {
      this.showFloatingUploadWindow(false)
    })
    // set interval to update the status of newly uploaded video
    this.intervalId = setInterval(async()=>{
      const videos = this.videos.filter(v=>!v.keymoments)
      if(videos.length){
        const ids = videos.map(v=>v.uuid)
        const response = await this.fetchVideosList()
        const updatedVideos = response.results.filter(v=>ids.includes(v.uuid))
        this.udpateVideosList(updatedVideos)
      }
    }, 20000)
    if(window.location.href.includes("subscriptions/success?session_id")){
      setTimeout(()=>{
        window.rewardful('convert', { email: this.user.email });
      }, 1000)
    }
  },
  beforeMount() {
    this.$gtm.trackEvent({
      event: "login",
      category: "User data",
      action: "login",
      label: "Home Page",
      value: this.user,
    });
  },
  beforeDestroy() {
    clearInterval(this.intervalId) // Clear the interval before the component is destroyed
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/views/videos.scss";
</style>
