<template>
  <div class="container container--fluid">
  <div class="row ma-0">
    <div class="col-xl-8 col-lg-8 col-md-7">
      <v-row class="justify-space-between align-baseline flex-nowrap mt-0 ma-0 pt-4 pb-2">
        <div class="d-flex">
          <v-btn
            text
            color="black"
            class="clips__back-button mr-3 mt-n1"
            @click="$router.push(`/demo-edit/${$route.params.uuid}/subtitles`)"
          >
            <span class="font-weight-bold text-capitalize text-subtitle-1">
              <v-icon color="black" class="mr-2">mdi-arrow-left</v-icon>
              back
            </span>
          </v-btn>
          <span class="video-title pa-0">
            <v-breadcrumbs :items="items" class="pa-0" style="align-items: flex-start">
              <template v-slot:divider>
                <v-icon class="pb-3">mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
          </span>
        </div>
        <div class="clips__video d-flex flex-nowrap">
          <v-btn
            class="action-button ml-2"
            text
            :ripple="false"
            @click="signupRedirect"
          >
            <v-icon left size="20" class="mt-1"> mdi-download </v-icon>
            <span class="font-weight-normal text-capitalize">Export</span>
          </v-btn>
          <v-btn
            class="edit-action ml-2"
            text
            :ripple="false"
            @click="editVideo"
          >
            <v-icon left> mdi-movie-filter </v-icon>
            <span class="font-weight-normal text-capitalize">Edit</span>
          </v-btn>
        </div>
      </v-row>
      <div class="centered-video">
        <VideoPlayer
          v-if="video.video_file"
          :src="video.video_file"
          :timeRange="timeRange"
          :videoSubtitle="videoSubtitle"
          :videoDuration="video.duration"
          :videoWidth="videoWidth"
          :videoHeight="videoHeight"
          :video="video"
          :clips="viewedClips"
          :setResizeDimension="setResizeDimension"
          :resizeDimension="resizeDimension"
          :clipsList="clips.results"
          :isCrop="isCrop"
          :setIsCrop="setIsCrop"
          :selectedClip="clip"
          @updateTimeRange="updateTimeRange"
        />
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-5">
      <v-row class="mt-0">
        <div class="col-12">
          <v-list class="pb-0">
            <v-list-item class="new-clip-card" @click="signupRedirect">
              <div class="new-clip">
                <v-icon class="mr-2" size="30" color="#fbd458">mdi-movie-filter</v-icon>
                <span>Create new clip</span>
              </div>
            </v-list-item>
          </v-list>
        </div>
      </v-row>
      <ClipsList
        :clips="viewedClips"
        :openClipPage="openClipPage"
        :playedClip="playedClip"
        :isLoading="isLoading"
        :fetchMoreClips="fetchMoreClips"
        @showDownloadDialogVisible="showDownloadDialogVisible"
      />
    </div>
    <DownloadDialog
      ref="downloadDialogRef"
      :isDownloadDialogVisible="isDownloadDialogVisible"
      :showDownloadDialogVisible="showDownloadDialogVisible"
      :videoLink="videoLink"
      :audioLink="audioLink"
      :videoName="videoName"
      :videoUuid="$route.params.uuid"
      :fetchExportedClipAction="fetchExportedClipAction"
      :exportClipAction="exportClipAction"
      :exportingStatus="exportedClip ? exportedClip.status : ''"
      :exportingDate="exportedClip ? exportedClip.started_at : ''"
      :lastExportedBurnSubtitle="
        exportedClip ? exportedClip.burn_subtitles : false
      "
      :clip="currentClip"
    />
    <AfterExportDialog
      :isAfterExportDialogVisible="isAfterExportDialogVisible"
      :showAfterExportDialogVisible="showAfterExportDialogVisible"
    />
  </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import VideoPlayer from "../../components/VideoPlayer.vue";
import types from "../../store/types";
import trimString from "../../utils/TrimString";
import ClipsList from "./DemoClipsList.vue";
import DownloadDialog from "../../components/Videos/DownloadDialog.vue";
import AfterExportDialog from "../../components/Videos/AfterExportDialog.vue";
export default {
  components: {
    VideoPlayer,
    ClipsList,
    DownloadDialog,
    AfterExportDialog,
  },
  data() {
    return {
      items: [],
      isDownloadDialogVisible: false,
      isAfterExportDialogVisible: false,
      video: {},
      clips: { results: [] },
      folder: {},
      timeRange: {},
      playedClip: null,
      subtitles: [],
      videoLink: "",
      audioLink: "",
      videoName: "",
      videoWidth: null,
      videoHeight: null,
      exportedClip: null,
      isCrop: false,
      resizeDimension: "vjs-16-9",
      clipName:"",
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      videoSubtitle: (state) => state.project.videoSubtitle,
    }),
    viewedClips() {
      return [...this.clips.results].sort(function (a, b) {
        return a.start_time - b.start_time;
      });
    },
    currentClip() {
      return this.playedClip ? this.viewedClips[this.playedClip] : null;
    },
    clip() {
      return this.clips && this.clips.results? this.clips.results.find(c=>c.uuid === this.$route.params.clipUuid):null
    }
  },
  watch: {
    $route() {
      this.buildBreadcrumbTree();

      const { clipUuid } = this.$route.params;
      if (clipUuid) {
        this.setPlayedClip(clipUuid);
      } else {
        this.timeRange = {
          startTime: 0,
          endTime: this.video.duration,
        };
        this.playedClip = null;
        this.setVideoDownloadLinks();
      }
    },
    exportedClip(newValue, oldValue) {
      if (
        newValue?.clip == oldValue?.clip &&
        newValue?.status != oldValue?.status &&
        newValue?.status == "succeeded"
      ) {
        this.downloadFileAfterExporting();
      }
    },
  },
  methods: {
    goToPrevious() {
      this.$router.push(this.prevRoute);
    },

    ...mapActions({
      fetchSubtitlesForVideo: types.project.actions.FETCH_VIDEO_SUBTITLE,
      exportClip: types.project.actions.EXPORT_CLIP,
      fetchExportedClip: types.project.actions.FETCH_EXPORTED_CLIP,
      fetchFolder: types.project.actions.FETCH_FOLDER,
      fetchVideo: types.project.actions.FETCH_VIDEO,
      fetchClips: types.project.actions.FETCH_VIDEO_CLIPS,
    }),
    showDownloadDialogVisible(flag) {
      this.isDownloadDialogVisible = flag;
    },
    showAfterExportDialogVisible(flag) {
      this.isAfterExportDialogVisible = flag;
    },
    setIsCrop(isCrop) {
      this.isCrop = isCrop;
    },
    setResizeDimension(dimension) {
      this.resizeDimension = dimension;
    },
    async exportClipAction(burnSubtitles) {
      const { clipUuid } = this.$route.params;

      if (clipUuid || this.playedClip)
        try {
          const uuid = clipUuid || this.viewedClips[this.playedClip].uuid;

          const payload = { clipUuid: uuid, burn_subtitles: burnSubtitles };
          const response = await this.exportClip(payload);

          this.exportedClip = response;
        } catch (err) {
          // handled in store
        }
    },
    setVideoTimeRange(index) {
      const { uuid, start_time, end_time } = this.viewedClips[index];
      this.playedClip = index;
      this.timeRange = {
        startTime: start_time,
        endTime: end_time,
      };
      this.fetchExportedClipAction(uuid);
    },
    signupRedirect() {
      const signupPageURL = "https://app.chopcast.io/signup"; // Replace with your signup page URL
      window.open(signupPageURL, "_blank");
    },
    openClipPage(index) {
      const clip = this.viewedClips[index];
      const { uuid, clipUuid, folderUuid } = this.$route.params;

      if (clipUuid !== clip.uuid) {
        let clipURL = `/demo-video/${uuid}/clip/${clip.uuid}`;

        if (folderUuid) {
          clipURL = `/folder/${folderUuid}` + clipURL;
        }

        this.setVideoTimeRange(index);

        this.$router.push(clipURL);
      }
    },
    isCurrentFolderEmpty() {
      return !!Object.keys(this.folder || {}).length;
    },
    async buildBreadcrumbTree() {
      const { clipUuid, folderUuid } = this.$route.params;
      let newItems = [];
      if(clipUuid){
        this.clipName = this.clips.results.find(c=>c.uuid === this.$route.params.clipUuid).name
      }
      if (folderUuid) {
        if (!this.folder.uuid) {
          this.folder = await this.fetchFolder({ uuid: folderUuid });
        }

        newItems = [
          {
            text: "Folders",
            disabled: false,
            href: "/",
          },
          {
            text: trimString(this.folder.name, 20),
            disabled: false,
            href: `/folder/${this.folder.uuid}`,
          },
          {
            text: trimString(this.video.name, 20),
            disabled: false,
            href: `/folder/${this.folder.uuid}/video/${this.video.uuid}`,
          },
        ];
      } else {
        newItems = [
          {
            text: !this.clipName?this.video.name:trimString(this.video.name, 20),
            disabled: false,
            href: `/demo-video/${this.video.uuid}`,
          },
        ];
      }

      if (clipUuid) {
        newItems = [
          ...newItems,
          {
            text: trimString(this.clipName, 40),
            disabled: true,
          },
        ];
      }

      this.items = newItems;
    },
    getTargetVideo() {
      const { uuid: videoUuid } = this.$route.params;

      return new Promise((resolve) => {
        Promise.all([
          this.fetchClips({ uuid: videoUuid }),
          this.fetchVideo({ uuid: videoUuid }),
        ]).then((values) => {
          const [clips, video] = values;
          this.video = video;
          this.clips = clips;
          resolve();
        });
      });
    },
    updateTimeRange(){
      this.timeRange = {
        startTime:0,
        endTime:this.video.duration
      }
    },
    setVideoDownloadLinks() {
      this.videoLink = this.video.video_file;
      this.audioLink = this.video.audio_file;
      this.videoName = this.video.name;
    },
    setVideoDimensions(width, height) {
      this.videoWidth = width;
      this.videoHeight = height;
    },
    editVideo() {
      const { clipUuid } = this.$route.params;

      let url = `/demo-edit/${this.video.uuid}`;
      if (clipUuid) {
        url += `/clip/${clipUuid}`;
      }
      url += "/subtitles";
      this.$router.push(url).catch(() => {});
    },
    goToPreviousPage() {
      const { uuid, clipUuid, folderUuid } = this.$route.params;

      let URL = "/";

      if (folderUuid) {
        URL = `/folder/${folderUuid}` + URL;
      }

      if (clipUuid) {
        URL += `/demo-video/${uuid}/clips/`;
      }

      this.$router.push(URL);
    },
    async fetchExportedClipAction(uuid) {
      const { clipUuid } = this.$route.params;

      if (clipUuid || uuid) {
        try {
          const payload = { clipUuid: uuid || clipUuid };
          const response = await this.fetchExportedClip(payload);

          this.setClipDownloadLinks(
            response?.results[0] || {},
            this.viewedClips[this.playedClip].name
          );
        } catch (err) {
          // handled in store
        }
      }
    },
    setClipDownloadLinks(clip, clipName) {
      this.videoName = clipName;
      this.videoLink = clip.clip_file;
      this.audioLink = clip.clip_audio_file;
      this.exportedClip = clip;
    },
    setPlayedClip(clipUuid) {
      const clipIndex = this.viewedClips.findIndex(
        (clip) => clip.uuid == clipUuid
      );
      this.setVideoTimeRange(clipIndex);
    },
    downloadFileAfterExporting() {
      const downloadDialogRef = this.$refs["downloadDialogRef"];
      if (downloadDialogRef) {
        const extension = downloadDialogRef.exportingExtension;
        if (extension) {
          this.getchCurrentVideo();
          const link = extension == "mp4" ? this.videoLink : this.audioLink;
          downloadDialogRef.downloadFile(link, extension);
        }
      }
    },
    getchCurrentVideo() {
      const { uuid: videoUuid } = this.$route.params;

      this.fetchClips({
        uuid: videoUuid,
        noLoading: true,
      }).then((clips) => {
        this.clips.results = [...clips];
      });
    },
    async fetchMoreClips(isVisible) {
      if (!isVisible) return;
      if (this.clips.next) {
        const { uuid: videoUuid } = this.$route.params;
        const page = this.clips.next.split("page=")[1].split("&video")[0];
        this.isLoading = true;

        return new Promise((resolve) =>
          this.fetchClips({ uuid: videoUuid, page }).then((values) => {
            const clips = values;

            if (!Object.keys(this.clips).length) this.clips = clips;
            else if (clips.next !== this.clips.next) {
              const { results, ...rest } = clips;
              this.clips = {
                ...rest,
                results: [...this.clips.results, ...results],
              };
            }
            this.isLoading = false;
            resolve();
          })
        );
      }
    },
    goToNewClip() {
      this.goTo(
        this.isCurrentFolderEmpty()
          ? `/new-clip/${this.folder.uuid}/${this.$route.params.uuid}/subtitles`
          : `/new-clip/${this.$route.params.uuid}/subtitles`
      );
    },
    goTo(url) {
      this.$router.push(url).catch(() => {});
    },
    setClipToExport(clip) {
      this.clipToExport = clip;
    },
  },
  mounted() {
    this.getTargetVideo().then(() => {
      const videoUuid = this.$route.params.uuid;
      const { clipUuid } = this.$route.params;
      this.buildBreadcrumbTree();
      this.fetchSubtitlesForVideo(videoUuid);

      if (clipUuid) {
        this.setPlayedClip(clipUuid);
      } else {
        this.setVideoDownloadLinks();
        this.timeRange = {
          startTime: 0,
          endTime: this.video.duration,
        };
      }
      this.setVideoDimensions(this.video.width, this.video.height);
    });
  },
};
</script>

<style>
.centered-video {
  padding-left: 20px;
	/* Adjust the margin as needed */
	text-align: center;
}
 .centered-video .vjs-control-bar {
  display: flex !important;
  visibility: visible !important;
}
 .edit-action {
  background: white;
  border: 1px solid;
  font-weight: 800;
}
 .action-button {
  font-weight: 800;
  background: #fbd458;
}
 .action-button .close {
  padding-right: 0px;
  padding-left: 12px;
}
 .new-clip-card {
  border: 2px dotted orange;
  border-radius: 5px;
  margin: 0 10px;
}
 .new-clip-card .new-clip {
  margin: 0 10px;
  padding: 10px;
  height: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
 .video-title {
  padding: 25px;
  font-size: 20px;
  font-weight: 600;
}
 .steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
 .steps .step-description {
  text-align: left;
  margin: 10px;
  font-weight: 800;
  font-size: 20px;
}
</style>
