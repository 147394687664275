<template>
  <div :style="{ position: 'relative' }">
    <div class="basic-wrapper__badge">Most popular</div>
    <img
      class="basic-wrapper__icon"
      src="@/assets/images/icons/crown.svg"
      alt="crown"
    />
    <div class="basic-wrapper">
      <p class="basic-wrapper__header">{{ plan.s_name }}</p>
      <p class="basic-wrapper__title">${{ price }}</p>
      <p
        class="basic-wrapper__subtitle"
        :style="{ marginBottom: '10px', marginTop: '-5px' }"
        v-if="type === 'yearly'"
      >
        Paid annually
      </p>
      <p class="basic-wrapper__subtitle">
        Perfect for startups starting out with content.
      </p>
      <div class="basic-wrapper__features">
        <div class="basic-wrapper__features--point">
          <v-icon color="#fff">mdi-checkbox-marked-circle</v-icon>
          <span>{{ minutes }} mins / m</span>
        </div>
        <div class="basic-wrapper__features--point">
          <v-icon color="#fff">mdi-checkbox-marked-circle</v-icon>
          <span>{{ storage }} GB / m</span>
        </div>
        <div class="basic-wrapper__features--point">
          <v-icon color="#fff">mdi-checkbox-marked-circle</v-icon>
          <span>Unlimited exports</span>
        </div>
      </div>

      <v-btn
        class="basic-wrapper__cta"
        text
        :ripple="false"
        width="194"
        height="42"
        @click="goToCheckout(planUuid)"
      >
        <span> Subscribe </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: ["plan", "goToCheckout", "userPlan", "type"],
  computed: {
    planUuid() {
      if (this.type === "yearly") {
        return this.plan.prices[1].uuid;
      }
      return this.plan.prices[0].uuid;
    },
    price() {
      if (this.type === "yearly") {
        return `${Number(
          (Math.ceil(this.plan.prices[1].s_unit_amount / 100) / 12).toFixed(1)
        )}/m`;
      }
      return `${Math.ceil(this.plan.prices[0].s_unit_amount / 100)}/m`;
    },
    storage() {
      if (this.type === "yearly") {
        return Math.ceil(this.plan.prices[1].storage_limit / (1024 * 12));
      }
      return Math.ceil(this.plan.prices[0].storage_limit / 1024);
    },
    minutes() {
      if (this.type === "yearly") {
        return Math.ceil(this.plan.prices[1].seconds_limit / (60 * 12));
      }
      return Math.ceil(this.plan.prices[0].seconds_limit / 60);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/colors.scss";

.basic-wrapper {
  position: relative;
  width: 226px;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  background-color: $violet;
  padding: 18px 18px 30px;
  color: white;
  &__badge {
    width: 102px;
    height: 32px;
    position: absolute;
    color: white;
    font-size: 13px;
    background-color: $black;
    border-radius: 3px;
    padding: 7px 9px 7px 15px;
    right: -3px;
    top: 9px;
    z-index: 10;
  }
  &__icon {
    position: absolute;
    top: -5px;
    z-index: 15;
    right: 79px;
  }
  &__header {
    font-size: 16px;
    position: relative;
    margin-bottom: 0;
    font-weight: 700;
    font-family: "Satoshi Bold";
  }
  &__title {
    position: relative;
    font-size: 36px;
    margin-bottom: 0;
    font-weight: bold;
  }
  &__subtitle {
    position: relative;
    font-size: 13px;
    font-weight: 700;
    font-family: "Satoshi Bold";
  }
  &__features {
    margin-top: 35px;
    &--point {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      span {
        margin-left: 12px;
        font-weight: 600;
      }
    }
  }
  &__cta {
    position: absolute;
    font-size: 16px;
    bottom: 30px;
    left: 14px;
    font-weight: 600;
    background: $yellow;
    color: #2c2c2d;
    text-transform: capitalize;
    &--subscribed {
      background: transparent;
      border-color: black !important;
      color: black !important;
    }
  }
}
</style>
