<template>
  <div class="clip-slider__clip-card">
    <v-icon
      class="clip-slider__clip-icon"
      left
      size="30"
      color="white"
      v-if="isPlayed"
    >
      mdi-equalizer
    </v-icon>
    <img
      :style="{ opacity: isPlayed ? 0.8 : 1 }"
      class="clip-slider__clip-image"
      :src="clip.thumbnail"
      @click="onClickAction(clipIndex)"
    />
    <div
      class="clip-slider__clip-title"
      @click="isEditMode = !isEditMode"
      v-if="!isEditMode"
    >
      {{ trimText(clip.name) }}<v-icon size="20">mdi-pencil-outline</v-icon>
    </div>
    <v-text-field
      v-else
      autofocus
      label="Type Clip Name"
      single-line
      outlined
      class="new-content-wrapper__edit-field--clip"
      :error-messages="contentNameErrors"
      v-model.trim="contentName"
      @input="onFocus"
      @blur="onBlur"
      @keydown.enter="onBlur"
      @keydown.esc="isEditMode = !isEditMode"
      full-width
    ></v-text-field>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import types from "../../store/types";
import trimString from "../../utils/TrimString";

const clipNameMinLength = 3;

export default {
  props: ["isPlayed", "clip", "onClickAction", "clipIndex"],
  data() {
    return {
      isEditMode: false,
      contentName: null,
    };
  },
  computed: {
    contentNameErrors() {
      const errors = [];
      if (!this.$v.contentName.$dirty) return errors;
      !this.$v.contentName.required &&
        errors.push("Type Clip Name is required");
      !this.$v.contentName.minLength &&
        errors.push(
          `Clip Name must contains at least ${clipNameMinLength} characters`
        );
      return errors;
    },
  },
  methods: {
    ...mapActions({
      updateClip: types.project.actions.UPDATE_CLIP,
    }),
    trimText(text) {
      return trimString(text, 20);
    },
    onFocus() {
      this.$v.contentName.$touch();
    },
    onBlur: async function () {
      if (this.clip.name === this.contentName) {
        this.isEditMode = !this.isEditMode;
        return;
      }

      const videoUuid = this.$route.params.uuid;
      const clipUuid = this.clip.uuid;

      this.$v.contentName.$touch();
      if (!this.$v.contentName.$invalid) {
        this.isEditMode = !this.isEditMode;
        this.clip.name = this.contentName;
        try {
          let payload;

          // Update Clip Name
          payload = {
            name: this.contentName,
            video: videoUuid,
            start_time: this.clip.start_time,
            end_time: this.clip.end_time,
          };
          await this.updateClip({ ...payload, clipUuid });
        } catch (error) {
          // handled in store
        }
      }
    },
  },
  validations: {
    contentName: {
      required,
      minLength: minLength(clipNameMinLength),
    },
  },
  mounted() {
    this.contentName = this.clip.name;
  },
};
</script>

<style lang="scss" scoped>
.clip-slider {
  &__clip-card {
    height: 120px;
    width: 145px;
    margin-right: 16px;
    cursor: pointer;
    text-align: center;
    position: relative;
  }
  &__clip-image {
    border-radius: 4px;
    height: 82px;
  }
  &__clip-title {
    font-family: "Satoshi Bold" !important;
    font-size: 14px;
    & i {
      opacity: 0;
    }
    &:hover {
      & i {
        opacity: 1;
      }
    }
  }
  &__clip-icon {
    position: absolute;
    top: 25px;
    left: 55px;
    z-index: 1;
  }
}
</style>
