<template>
  <div
    class="context-menu"
    v-show="show"
    :style="style"
    ref="context"
    tabindex="0"
  >
    <slot></slot>
  </div>
</template>
<script>

export default {
  name: "ContextMenu",
  data() {
    return {
      left: 0, // left position
      top: 0, // top position
      show: false, // affect display of context menu
    };
  },
  computed: {
    // get position of context menu
    style() {
      return {
        top: this.top + "px",
        left: this.left + "px",
      };
    },
  },
  methods: {
    // closes context menu
    close() {
      this.show = false;
      this.left = 0;
      this.top = 0;
    },
    open() {
      // updates position of context menu
      const container = document
      .querySelector(".subtitles-wrapper__content")
      const containerRect = container.getBoundingClientRect();
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const endNode = range.endContainer;
      const endOffset = range.endOffset;

      const textRange = document.createRange();
      textRange.setStart(endNode, endOffset);
      textRange.setEnd(endNode, endOffset);

      const endRect = textRange.getBoundingClientRect();
      const top = endRect.top - containerRect.top + endRect.height;
      const left = endRect.left - containerRect.left + (endOffset * 8);
      // updates position of context menu
      this.left = left;
      this.top = top;
      // make element focused
      // @ts-ignore
      // Vue.nextTick(() => this.$el.focus());
      this.show = true;
    },
  },
};
</script>
<style>
.context-menu {
  position: absolute;
  background: white;
  z-index: 999;
  outline: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
}
</style>
