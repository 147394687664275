const formatDate = (dateString)=> {
    // Parse the date string
    const date = new Date(dateString);

    // Get day, month, and year from the date
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();

    // Return the formatted date
    return `${day}-${month}-${year}`;
}

export default formatDate;