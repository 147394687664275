<template>
  <v-slider
    class="zoom_slider"
    v-model="zoom"
    color="blue"
    thumb-label
    :max="max"
    :min="min"
    @change="updateValue"
  >
    <template v-slot:prepend>
      <v-btn
        size="default"
        variant="text"
        icon
        :color="'#7367f0'"
        @click="zoomOut"
      >
        <v-icon>mdi-magnify-minus-outline</v-icon>
      </v-btn>
    </template>
    <template v-slot:append>
      <v-btn
        size="default"
        variant="text"
        icon
        :color="'#7367f0'"
        @click="zoomIn"
      >
        <v-icon>mdi-magnify-plus-outline</v-icon>
      </v-btn>
    </template>
  </v-slider>
</template>

<script lang="js">
import {mapState} from "vuex";
  export default {
    data () {
      return {
        min: 0,
        max: 20,
        zoom:2
      }
    },
    computed: {
      ...mapState({
      zoomVal:(state) => state.video.zoom
      })
    },
    methods: {
      updateValue (e) {
        this.$store.commit("video/setZoom", e)
      },
      zoomOut () {
        this.zoom = (this.zoom - 1)
        this.$store.commit("video/setZoom", this.zoom - 1)
      },
      zoomIn () {
        this.zoom = (this.zoom + 1)
        this.$store.commit("video/setZoom", this.zoom + 1)
      },
    },
    watch:{
      zoomVal(val){
        this.zoom = val;
      }
    },
    destroyed(){
      this.$store.commit("video/setZoom", 0)
    }
  }
</script>
<style lang="scss" scoped>
.zoom_slider {
  .v-input__prepend-outer {
    margin-top: 0 !important;
  }
  .v-slider__track-container {
    height: 3px !important;
  }
}
</style>
