<template>
  <div class="sign-in-form">
    <div class="sign-in-form__login">
      <div class="sign-in-form__login__title text-center">
        <h3>Sign in</h3>
        <p>Let’s get <b>chopping.</b></p>
      </div>
      <div class="sign-in-form__login__form">
        <div class="sign-in-form__login__form__field">
          <v-text-field
            label="Email"
            single-line
            outlined
            id="email"
            type="email"
            :error-messages="emailErrors"
            v-model.trim="email"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
            ><v-icon v-if="!$v.email.$invalid" color="#28B446" slot="append"
              >mdi-checkbox-marked-circle</v-icon
            ></v-text-field
          >
        </div>
        <div class="sign-in-form__login__form__field">
          <v-text-field
            label="Password"
            single-line
            outlined
            :error-messages="passwordErrors"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            v-model="password"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            v-on:keyup.enter="signInUser"
          ></v-text-field>
        </div>
        <div class="sign-in-form__login__form__forgot">
          <v-checkbox
            :value="remember"
            hide-details
            @click="remember = !remember"
            color="#fbd458"
          ></v-checkbox>
          Remember me
        </div>
        <div
          @click="signInUser"
          class="sign-in-form__login__form__submit"
          role="button"
        >
          Login
        </div>
        <SignInWithGoogle />
        <router-link to="/password/forgot">
          <div class="forgot-password">Forgot password?</div>
        </router-link>

        <!-- <div class="g-signin2" data-onsuccess="onSignIn"></div> -->
        <div class="sign-in-form__login__form__join-free">
          <p>
            Don’t have an account?
            <span @click="toSignup">Join free today</span>
          </p>
        </div>
        <div class="sign-in-form__login__form__join-free">
          <p style="font-size: 14px;" class="text-center">
            By using chopcast you agree to the <a style="font-size:14px" href="https://www.chopcast.io/hubfs/Tribetactics%20Limited%20T_A%20Chopcast%20Terms%20of%20Service%20-%20Jan%204%202023.pdf" target="_blank">Terms of Service</a>, <a style="font-size:14px" href="https://www.chopcast.io/privacy-policy#:~:text=It%20is%20Tribetactics%20limited's%20policy,sites%20we%20own%20and%20operate.&text=We%20don't%20share%20any,when%20required%20to%20by%20law." target="_blank"> Privacy Policy</a> and <a style="font-size:14px" href="https://www.chopcast.io/hubfs/Tribetactics%20Limited%20T_A%20Chopcast%20Fair%20Use%20Policy%20-%20Jun%205%202023.pdf" target="_blank">Fair Use Policy</a>
          </p>
        </div>
      </div>
    </div>
    <transition name="slide">
      <ErrorMessage :errorMessage="errorMessage" />
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required, email, minLength } from "vuelidate/lib/validators";
import ErrorMessage from "../components/ErrorMessage";
import SignInWithGoogle from "../shared/SignInWithGoogle.vue";
import types from "../store/types";
import {
  setUserTokenCookie,
  setUserRefreshTokenCookie,
} from "../utils/Cookies";

const passwordMinLength = 8;

export default {
  name: "Signin",
  components: {
    ErrorMessage,
    SignInWithGoogle,
  },
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      remember: false,
    };
  },
  methods: {
    ...mapActions({
      loginUser: types.user.actions.USER_LOGIN,
      fetchUserProfile: types.user.actions.USER_PROFILE,
    }),
    ...mapMutations({
      setErrorMessage: types.app.mutations.SET_ERROR_MESSAGE,
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE,
      setSuccessMessageTimeout:
        types.app.mutations.SET_SUCCESS_MESSAGE_TIME_OUT,
      setRefreshToken: types.user.mutations.SET_REFRESH_TOKEN,
      setRedirectPage: types.app.mutations.SET_REDIRECT_PAGE,
    }),
    async signInUser() {
      if (this.$v.email.$invalid || this.$v.password.$invalid) {
        this.setErrorMessage(["Please enter your email and password"]);
        return;
      }
      const signInData = {
        email: this.email,
        password: this.password,
      };
      try {
        const { access: token, refresh } = await this.loginUser(signInData);
        this.setRefreshToken(refresh);
        if (this.remember) {
          setUserTokenCookie(token);
          setUserRefreshTokenCookie(refresh);
        }
        await this.fetchUserProfile(token);
        if (this.redirectPage) {
          this.$router.push(this.redirectPage);
          this.setRedirectPage("");
        } else {
          console.log("in herererererer 142")
          this.$router.push("/").catch(() => {});
          setTimeout(()=>{
          window.location.reload()
        }, 50)
        }
      } catch (err) {
        // handled in store
      }
    },
    toSignup() {
      this.$router.push("/sign-up").catch(() => {});
    },
  },
  computed: {
    ...mapState({
      errorMessage: (state) => state.app.errorMessage,
      redirectPage: (state) => state.app.redirectPage,
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("Email is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      !this.$v.password.minLength &&
        errors.push(
          `Password must contains at least ${passwordMinLength} characters`
        );
      return errors;
    },
  },
  validations: {
    email: {
      email,
      required,
    },
    password: {
      required,
      minLength: minLength(passwordMinLength),
    },
  },
  mounted() {
    setTimeout(() => {
      this.setSuccessMessageTimeout(5000);
      this.setSuccessMessage("");
    }, 7000);
  },
};
</script>


<style lang="scss" scoped>
@import "@/assets/styles/components/signin.scss";
</style>
