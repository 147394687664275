<template>
  <vue-drag-resize
    v-if="textExists"
    id="subtitletext"
    ref="subtitletext"
    axis="y"
    :isActive="true"
    :isResizable="false"
    :parentLimitation="true"
    :isDraggable="isEditPage"
    w="auto"
    h="auto"
    :class="['subtitle-wrapper', 'd-flex', 'justify-content-center',{'postop': top === -1}]"
    :y="top"
    @dragstop="onDragStop"
    :key="leftPos+'-'+top"
    :z="2">
    <div class="['subtitle-wrapper__text', { enabled: isEditPage }]">
      <div
        v-html="currentText"
        :class="['subtitle-wrapper__text-content', `${strokeClass}`]"
        :style="{
          fontFamily: clipStyle.font && clipStyle.font? clipStyle.font+ ' !important':'Arvo-Bold !important',
          fontSize: !isEditPage?'30px':fontSize + 'px',
          color: clipStyle.color || 'white',
          fontWeight: clipStyle.text_style_bold ? 'bolder' : fontWeigh,
          fontStyle: clipStyle.text_style_italic ? 'italic' : 'normal',
          textDecoration: clipStyle.text_style_underline ? 'underline !important':'none',
          wordBreak: 'break-word',
          backgroundColor: clipStyle.background_color || '',
          borderRadius: '5px', lineHeight:fontSize+'px',
          paddingTop: '12px'}">
        </div>
      </div>
  </vue-drag-resize>
</template>

<script>
import VueDragResize from 'vue-drag-resize';
import { mapActions, mapState, mapMutations } from 'vuex';
import types from '../../store/types';
import { fonstSizes } from '../../utils/fontSizes';

export default {
  name: 'SubTitleText',
  components: {
    VueDragResize,
  },
  props: {
    text: String,
    clipStyle: Object,
    videoDim: Object,
    createClip: Function,
    video: Object,
    isEditPage: Boolean,
    resizeDimension: String,
    isCrop: Boolean,
    fetchCurrentClip: Function,
  },
  data() {
    return {
      firstLoadComplete: false,
      currentText: '',
      previousText: '',
      top: -1,
      left: 0,
      isDraggable: false,
      parentWidth: 0,
      fontSize: 30,
      fontWeigh:400,
      textShadow:'-1px -2px 3px black, -2px -1px 6px black, 2px -1px 9px black, 0px 4px 4px #060606, 2px 2px 6px #060606, 2px -1px 9px black, 2px -1px 9px black, 2px -1px 9px black, 2px -1px 9px black, 2px -1px 9px black, 0px 6px 9px black, 2px -1px 9px black, -1px -1px 0px black, -6px 8px 9px black',
      temp:0
    };
  },
  computed: {
    ...mapState({
      clip: (state) => state.project.clip,
      textHeight: (state) => state.project.subtitleHeight,
    }),
    textExists () {
      return this.currentText.trim().length > 0;
    },
    subtitleTextBox () {
      let subtitleTextBox = null;
      this.$nextTick(() => {
        subtitleTextBox = this.$refs && this.$refs['subtitletext'];
      });
      return subtitleTextBox;
    },
    subtitleTextWidth () {
      let w = 60;
      if(this.subtitleTextBox) {
        w = this.subtitleTextBox.clientWidth;
      }
      return w;
    },
    leftPos () {
      const { width: VideoPlayerWidth } = this.videoDim;
      let left = (VideoPlayerWidth / 2) - this.subtitleTextWidth;
      return left;
    },
    strokeClass() {
      if(this.clipStyle.outline === 1){
        return 'stroke-text1 smooth-16'
      }
      if(this.clipStyle.outline === 2){
        return 'stroke-text1 smooth-16'
      }
      if(this.clipStyle.outline === 3){
        return 'stroke-text2 smooth-16'
      }
      if(this.clipStyle.outline === 4){
        return 'stroke-text3 smooth-16'
      }
      return ''
  }
},
  mounted () {
    if(this.subtitleTextBox) {
      this.setSubtitleHeight({height: this.subtitleTextBox.clientHeight})
    }
  },
  methods: {
    ...mapActions({
      updateClipStyle: types.project.actions.UPDATE_CLIP_STYLE,
    }),
    ...mapMutations({
      setSubtitleHeight: types.project.mutations.SET_SUBTITLES_HEIGHT,
    }),
    handleOnClick() {
      this.isDraggable = true;
    },
    async setPosition (y) {
      try {
        const { clipUuid } = this.$route.params;
        const payload = {
          clipUuid,
          x_pos_decimal: null,
          y_pos_decimal: y,
          x_pos_string: 'center',
          y_pos_string: null,
        };
        if (!clipUuid) {
          const clip = await this.createClip({
            start_time: 0,
            end_time: this.video.duration,
          });
          payload.clipUuid = clip.uuid;
        }
        await this.updateClipStyle(payload);
        await this.fetchCurrentClip();
      } catch (error) {
        // handled in store
      }
    },
    onDragStop (newRect) {
      this.isDraggable = false;
      this.top = newRect.top;
      this.setFontSize();
      this.previousText = this.currentText;
      this.setPosition(newRect.top / this.videoDim.height);
    },
    setFontSize () {
      if(this.clipStyle){
        this.fontSize = fonstSizes.find(f=>f.label === this.clipStyle.size).value
      }
      else{
        this.fontSize = 30
      }
      
    },
    setInitialTopPos () {
      const { height: VideoPlayerHeight } = this.videoDim;
      const subtitlePadding = 5;
      const videoControlBarHeight = 40;
      // Use the position set in the API or set a default position
      if(this.clipStyle && this.clipStyle.y_pos_decimal) {
        this.top = (this.clipStyle.y_pos_decimal * this.videoDim.height);
      } else {
        this.top = this.textHeight ? VideoPlayerHeight
          - this.textHeight
          - subtitlePadding
          - videoControlBarHeight:VideoPlayerHeight/1.2;
      }
    },
    setFontWeight() {
      if(this.clipStyle && this.clipStyle.font){
        if(this.clipStyle.font.includes('Medium')){
          this.fontWeigh = 500
        }
        if(this.clipStyle.font.includes('-Bold')){
          this.fontWeigh = 700
        }
        if(this.clipStyle.font.includes('-SemiBold')){
          this.fontWeigh = 600
        }
        if(this.clipStyle.font.includes('-ExtraBold')){
          this.fontWeigh = 800
        }
        if(this.clipStyle.font.includes('-Light')){
          this.fontWeigh = 300
        }
        if(this.clipStyle.font.includes('-Thin')){
          this.fontWeigh = 100
        }
        if(this.clipStyle.font.includes('-ExtraLight')){
          this.fontWeigh = 200
        }
      }
      else{
        this.fontWeigh = 500
      }
    }
  },
  watch: {
    text (val) {
      this.setFontWeight();  
      this.currentText = val;
      this.setInitialTopPos();
      this.setFontSize();
    },
    clipStyle () {
      // Preserve the text after drop action
      // this.currentText = this.previousText;
      if(!this.firstLoadComplete) {
        this.setInitialTopPos();
        this.firstLoadComplete = true;
      }
      this.setFontSize();
      // adjusting the fontweigt according to the selecte font-family
      this.setFontWeight();  
    },
    resizeDimension() {
      this.setFontSize()
    },
    isCrop() {
      this.setFontSize();
    }
  }
};
</script>

<style lang="scss" scoped>
.subtitle-wrapper {
  height: fit-content;
  width: fit-content;
  left: 0;
  right: 0;
  justify-content: center;
  width: 100%;
  &__text {
    cursor: context-menu;
    &.enabled {
      cursor: pointer;
    }
    margin: auto;
    &-content {
      padding: 5px 25px;
      line-height: 0;
    }
    .content-container{
      z-index: 3;
    }
  }
$stroke-count: 3; 

@for $i from 1 through $stroke-count {
  .stroke-text#{$i} {
    --stroke-color: black;
    letter-spacing: var(--stroke-width);
    color: white;
    text-shadow: var(--stroke-width) 0 0 var(--stroke-color),
    calc(var(--stroke-width) * -1) 0 0 var(--stroke-color),
    0 var(--stroke-width) 0 var(--stroke-color),
    0 calc(var(--stroke-width) * -1) 0 var(--stroke-color);
    // Specific style for each slider
    --stroke-width: #{$i}px;
  }
$stroke-count: 3; 

@for $i from 1 through $stroke-count {
  .stroke-text#{$i} {
    --stroke-color: black;
    letter-spacing: var(--stroke-width);
    color: white;
    text-shadow: var(--stroke-width) 0 0 var(--stroke-color),
    calc(var(--stroke-width) * -1) 0 0 var(--stroke-color),
    0 var(--stroke-width) 0 var(--stroke-color),
    0 calc(var(--stroke-width) * -1) 0 var(--stroke-color);
    // Specific style for each slider
    --stroke-width: #{$i}px;
  }
}

.smooth-16 {
  text-shadow: calc(var(--stroke-width) * 1) calc(var(--stroke-width) * 0) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0) calc(var(--stroke-width) * 1) 0
      var(--stroke-color),
    calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 0
      var(--stroke-color),
    calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 0
      var(--stroke-color),
    calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 0
      var(--stroke-color),
    calc(var(--stroke-width) * -1) calc(var(--stroke-width) * 0) 0
      var(--stroke-color),
    calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827) 0
      var(--stroke-color),
    calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071) 0
      var(--stroke-color),
    calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0) calc(var(--stroke-width) * -1) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 0
      var(--stroke-color);
}

}
.postop{
  top:80% !important;
}

.smooth-16 {
  text-shadow: calc(var(--stroke-width) * 1) calc(var(--stroke-width) * 0) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0) calc(var(--stroke-width) * 1) 0
      var(--stroke-color),
    calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 0
      var(--stroke-color),
    calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 0
      var(--stroke-color),
    calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 0
      var(--stroke-color),
    calc(var(--stroke-width) * -1) calc(var(--stroke-width) * 0) 0
      var(--stroke-color),
    calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827) 0
      var(--stroke-color),
    calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071) 0
      var(--stroke-color),
    calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0) calc(var(--stroke-width) * -1) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 0
      var(--stroke-color),
    calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 0
      var(--stroke-color);
}

}
.postop{
  top:80% !important;
}
.postop{
  top:80% !important;
}
</style>
