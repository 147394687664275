var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.isVisibleInVideoEdit
      ? 'clip-slider__video-edit'
      : 'clip-slider__video-view',
    'd-flex clip-slider' ]},[_c('v-slide-group',{attrs:{"show-arrows":""}},[_vm._l((_vm.clips),function(clip,i){return _c('v-slide-item',{key:clip.uuid + Math.random()},[_c('Clip',{attrs:{"isPlayed":_vm.playedClip === i,"clip":clip,"clipIndex":i,"onClickAction":_vm.isVisibleInVideoEdit ? _vm.setVideoTimeRange : _vm.openClipPage}})],1)}),_c('v-slide-item',[(!_vm.isLoading)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
          callback: _vm.fetchMoreClips,
          intersection: {
            threshold: 0.05,
          },
        }),expression:"{\n          callback: fetchMoreClips,\n          intersection: {\n            threshold: 0.05,\n          },\n        }"}],style:({ width: '1px' })}):_vm._e()])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }