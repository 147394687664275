<template>
  <div class="verify">
    <div class="verify__wrapper">
      <div class="verify__wrapper__title">
        <h3>Verify Your Email</h3>
        <p>We sent you an email with a code to verify it!</p>
      </div>
      <div class="verify__wrapper__form">
        <div class="verify__wrapper__form__submit">
          <button @click="activate">Verify</button>
          <p>
            Already verified?
            <span @click="$router.push('/sign-in').catch(() => {})">Login</span>
          </p>
          <p
            class="verify__wrapper__form__submit--resend"
            @click="resendVerification"
          >
            Resend verification code
          </p>
        </div>
      </div>
    </div>
    <transition name="slide">
      <ErrorMessage :errorMessage="errorMessage" />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import types from "../store/types";
import ErrorMessage from "../components/ErrorMessage";

export default {
  name: "Verify",
  components: {
    ErrorMessage,
  },
  computed: {
    ...mapState({
      errorMessage: (state) => state.user.errorMessage,
      user: (state) => state.user.user,
    }),
  },
  methods: {
    ...mapActions({
      activateUser: types.user.actions.ACTIVATE_USER,
      resendVerificationToUser: types.user.actions.RESEND_VERIFICATION_TO_USER,
    }),
    async activate() {
      try {
        const { uuid, token } = this.$route.params;
        const payload = {
          uid: uuid,
          token,
        };
        await this.activateUser(payload);
        this.$router.push("/sign-in").catch(() => {});
      } catch (err) {
        // handled in store
      }
    },
    async resendVerification() {
      try {
        const payload = { email: this.user.email };
        await this.resendVerificationToUser(payload);
      } catch (err) {
        // handled in store
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/components/verify.scss";
</style>
