<template>
  <div class="reset">
    <div class="reset__wrapper">
      <div class="reset__wrapper__title">
        <h3>Type Your New Password</h3>
      </div>
      <div class="reset__wrapper__form">
        <div class="reset__wrapper__form__submit">
          <div class="sign-up__wrapper__form__field">
            <label for="password">Password</label>
            <v-text-field
              label="Type your password"
              single-line
              outlined
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
              @keydown.enter="resetPassword"
              :disabled="disableAllFields"
            >
              <v-icon
                @click="showPassword = !showPassword"
                v-if="showPassword"
                slot="append"
                >mdi-eye</v-icon
              >
              <v-icon @click="showPassword = !showPassword" v-else slot="append"
                >mdi-eye-off</v-icon
              >
              <v-icon
                v-if="password && !$v.password.$invalid"
                color="#28B446"
                slot="append"
                >mdi-checkbox-marked-circle</v-icon
              >
            </v-text-field>
          </div>
          <div class="sign-up__wrapper__form__field">
            <label for="password">Confirm Password</label>
            <v-text-field
              label="Type your password again"
              single-line
              outlined
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirmPassword ? 'text' : 'password'"
              v-model="confirmPassword"
              @keydown.enter="resetPassword"
              :disabled="disableAllFields"
            >
              <v-icon
                @click="showConfirmPassword = !showConfirmPassword"
                v-if="showConfirmPassword"
                slot="append"
                >mdi-eye</v-icon
              >
              <v-icon
                @click="showConfirmPassword = !showConfirmPassword"
                v-else
                slot="append"
                >mdi-eye-off</v-icon
              >
              <v-icon
                v-if="confirmPassword && confirmPassword === password"
                color="#28B446"
                slot="append"
                >mdi-checkbox-marked-circle</v-icon
              >
            </v-text-field>
            <div
              v-if="validate"
              class="sign-up__wrapper__form__field__validation-popup"
            >
              <div v-for="rule in passwordRules" :key="rule.key">
                <v-icon
                  v-if="$v.password[rule.key]"
                  color="#28B446"
                  slot="append"
                  >mdi-checkbox-marked-circle</v-icon
                >
                <v-icon v-else color="#C1D5E2" slot="append"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                <p>{{ rule.statement }}</p>
              </div>
            </div>
          </div>
          <div class="reset__wrapper__form__submit">
            <button
              id="submit-btn"
              :disabled="disableAllFields"
              @click="resetPassword"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
    <transition name="slide">
      <ErrorMessage :errorMessage="errorMessage" />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { helpers, minLength } from "vuelidate/lib/validators";
import types from "../store/types";
import ErrorMessage from "../components/ErrorMessage";

const regexAZ = helpers.regex("regex", /(?=.*[A-Z])/gm);
const regexaz = helpers.regex("regex", /(?=.*[a-z])/gm);
const regexNumber = helpers.regex("regex", /(?=.*[0-9])/gm);
const passwordMinLength = 8;

export default {
  name: "Reset",
  components: {
    ErrorMessage,
  },
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      password: "",
      confirmPassword: "",
      disableAllFields: false,
      passwordRules: [
        {
          key: "minLength",
          statement: "contains at least 8 characters",
        },
        {
          key: "regexaz",
          statement: "contains lower (a-z) case letter",
        },
        {
          key: "regexAZ",
          statement: "contains upper (A-Z) case letter",
        },
        {
          key: "regexNumber",
          statement: "contains at least one number (0-9)",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      errorMessage: (state) => state.user.errorMessage,
    }),
    validate() {
      const isValid =
        this.$v.password.regexaz &&
        this.$v.password.minLength &&
        this.$v.password.regexAZ &&
        this.$v.password.regexNumber;
      if (this.password.length && !isValid) return true;

      if (isValid) return false;

      return false;
    },
  },
  methods: {
    ...mapActions({
      resetPasswordConfirmAction: types.user.actions.RESET_PASSWORD_CONFIRM,
    }),
    ...mapMutations({
      setErrorMessage: types.app.mutations.SET_ERROR_MESSAGE,
    }),
    async resetPassword() {
      if (!this.password || this.$v.password.$invalid) {
        this.setErrorMessage(["Please set new password and confirm it"]);
        return;
      }
      if (this.password !== this.confirmPassword) {
        this.setErrorMessage(["Please confirm password correctly"]);
        return;
      }
      try {
        const { uuid, token } = this.$route.params;
        const payload = {
          uid: uuid,
          token,
          new_password: this.password,
        };
        await this.resetPasswordConfirmAction(payload);
        this.setErrorMessage(["Your password set successfully"]);
        this.disableAllFields = true;
        setTimeout(() => {
          this.$router.push("/sign-in").catch(() => {});
        }, 1500);
      } catch (err) {
        this.disableAllFields = true;
        if (Object.keys(err)[0] === "token" || Object.keys(err)[0] === "uid")
          setTimeout(() => {
            this.$router.push("/password/forgot").catch(() => {});
          }, 1500);
      }
    },
  },
  validations: {
    password: {
      regexAZ,
      regexaz,
      regexNumber,
      minLength: minLength(passwordMinLength),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/components/reset.scss";
@import "../assets/styles/components/signup.scss";
</style>
