<template>
  <div class="account-wrapper">
    <v-row>
      <v-col cols="12">
        <div class="account-wrapper__user-info">
          <v-avatar
            tile
            class="rounded mr-3"
            width="72"
            height="72"
            max-width="72"
            min-width="72"
          >
            <img src="@/assets/images/icons/user.svg" alt="user" />
          </v-avatar>
          <!-- <v-btn
            class="account-wrapper__billing-table__cta ml-2 mr-4"
            text
            :ripple="false"
            width="89"
            height="36"
            outlined
          >
            <span class="font-weight-normal text-capitalize">Upload</span>
          </v-btn>
          <v-btn
            class="account-wrapper__billing-table__cta--negative"
            text
            :ripple="false"
            width="89"
            height="36"
          >
            <span class="font-weight-normal text-capitalize">Remove</span>
          </v-btn> -->
        </div>
      </v-col>
      <v-col cols="12">
        <div v-for="(item, i) in userInfo" :key="i">
          <div class="account-wrapper__billing-table">
            <div class="account-wrapper__billing-table--row">
              <div
                class="account-wrapper__billing-table--cell account-wrapper__billing-table--cell-narrow text-capitalize"
              >
                {{ item.label }}
              </div>
              <div
                class="account-wrapper__billing-table--cell account-wrapper__billing-table--cell-wide"
              >
                <span v-if="!isEditMode[item.label]">{{ item.data }}</span>
                <v-text-field
                  v-else
                  class="pt-0 text-capitalize"
                  autofocus
                  :label="`Type User ${item.label}`"
                  single-line
                  :error-messages="
                    item.label === 'name' ? nameErrors : emailErrors
                  "
                  v-model.trim="$data[item.label]"
                  @input="$v[item.label].$touch()"
                  @blur="$v[item.label].$touch()"
                  full-width
                ></v-text-field>
              </div>
              <div
                class="account-wrapper__billing-table--cell"
                v-show="item.label === 'name'"
              >
                <v-btn
                  :class="[
                    'account-wrapper__billing-table__cta',
                    isEditMode[item.label] &&
                      'account-wrapper__billing-table__cta--active',
                  ]"
                  text
                  :ripple="false"
                  width="100"
                  height="36"
                  outlined
                  @click="
                    isEditMode[item.label]
                      ? updateUserData(item.label)
                      : enterEditMode(item.label)
                  "
                >
                  <span class="font-weight-normal text-capitalize">{{
                    isEditMode[item.label] ? "Save" : "Edit"
                  }}</span>
                </v-btn>
              </div>
            </div>
          </div>
          <v-divider class="account-wrapper__divider"></v-divider>
        </div>
        <div v-for="(item) in minutesInfo" :key="item.label">
          <div class="account-wrapper__billing-table">
            <div class="account-wrapper__billing-table--row">
              <div
                class="account-wrapper__billing-table--cell account-wrapper__billing-table--cell-narrow"
                style="font-style: italic; font-weight: 300;"
              >
                {{ item.label }}
              </div>
              <div
                class="account-wrapper__billing-table--cell account-wrapper__billing-table--cell-wide"
              >
                <span>{{ item.data }}</span>
              </div>
            </div>
          </div>
          <v-divider class="account-wrapper__divider"></v-divider>
        </div>
      </v-col>

      <v-col cols="12">
        <p
          v-if="!isChangePasswordViewed"
          class="mb-0 text-h5 account-wrapper__change-password-button"
          @click="isChangePasswordViewed = true"
        >
          Change password
        </p>
      </v-col>
      <v-col cols="12" lg="8" class="pb-0" v-if="isChangePasswordViewed">
        <div class="sign-up__wrapper__form__field">
          <div class="d-flex">
            <label for="password" class="mt-3">Old Password</label>
            <v-text-field
              label="Type old password"
              single-line
              outlined
              :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showOldPassword ? 'text' : 'password'"
              v-model="oldPassword"
              @input="$v.oldPassword.$touch()"
              @blur="$v.oldPassword.$touch()"
              :error-messages="oldPasswordErrors"
            >
              <v-icon
                @click="showOldPassword = !showOldPassword"
                v-if="showOldPassword"
                slot="append"
                >mdi-eye</v-icon
              >
              <v-icon
                @click="showOldPassword = !showOldPassword"
                v-else
                slot="append"
                >mdi-eye-off</v-icon
              >
              <v-icon
                v-if="oldPassword && !$v.oldPassword.$invalid"
                color="#28B446"
                slot="append"
                >mdi-checkbox-marked-circle</v-icon
              >
            </v-text-field>
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="8" class="pt-0" v-if="isChangePasswordViewed">
        <div class="sign-up__wrapper__form__field">
          <div class="d-flex">
            <label for="password" class="mt-3">New Password</label>
            <v-text-field
              label="Type new password"
              single-line
              outlined
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
            >
              <v-icon
                @click="showPassword = !showPassword"
                v-if="showPassword"
                slot="append"
                >mdi-eye</v-icon
              >
              <v-icon @click="showPassword = !showPassword" v-else slot="append"
                >mdi-eye-off</v-icon
              >
              <v-icon
                v-if="password && !$v.password.$invalid"
                color="#28B446"
                slot="append"
                >mdi-checkbox-marked-circle</v-icon
              >
            </v-text-field>
          </div>
          <div
            v-if="validatePassword"
            class="sign-up__wrapper__form__field__validation-popup"
          >
            <div v-for="rule in passwordRules" :key="rule.key">
              <v-icon v-if="$v.password[rule.key]" color="#28B446" slot="append"
                >mdi-checkbox-marked-circle</v-icon
              >
              <v-icon v-else color="#C1D5E2" slot="append"
                >mdi-checkbox-marked-circle-outline</v-icon
              >
              <p>{{ rule.statement }}</p>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12 d-flex justify-center" v-if="isChangePasswordViewed">
        <v-btn
          class="account-wrapper__set-password-button"
          @click="updatePassword"
          :disabled="
            !password ||
            !oldPassword ||
            validatePassword ||
            !!oldPasswordErrors.length
          "
        >
          Save Password
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required, email, minLength, helpers } from "vuelidate/lib/validators";
import { mapState, mapActions, mapMutations } from "vuex";
import types from "../../store/types";

const regexAZ = helpers.regex("regex", /(?=.*[A-Z])/gm);
const regexaz = helpers.regex("regex", /(?=.*[a-z])/gm);
const regexNumber = helpers.regex("regex", /(?=.*[0-9])/gm);
const nameMinLength = 2;
const passwordMinLength = 8;

export default {
  data: () => ({
    isEditMode: {
      name: false,
      email: false,
      oldPassword: false,
      password: false,
    },
    name: "",
    email: "",
    oldPassword: "",
    password: "",
    showPassword: false,
    showOldPassword: false,
    passwordRules: [
      {
        key: "minLength",
        statement: "contains at least 8 characters",
      },
      {
        key: "regexaz",
        statement: "contains lower (a-z) case letter",
      },
      {
        key: "regexAZ",
        statement: "contains upper (A-Z) case letter",
      },
      {
        key: "regexNumber",
        statement: "contains at least one number (0-9)",
      },
    ],
    isChangePasswordViewed: false,
  }),
  methods: {
    ...mapActions({
      updateUser: types.user.actions.UPDATE_USER,
      setPasswordAction: types.user.actions.SET_PASSWORD,
    }),
    ...mapMutations({
      setErrorMessage: types.app.mutations.SET_ERROR_MESSAGE,
    }),
    enterEditMode(key) {
      this.isEditMode[key] = true;
    },
    updateUserData: async function (key) {
      try {
        this.isEditMode[key] = false;

        const payload = {
          full_name: this.name,
          email: this.email,
        };
        await this.updateUser(payload);
      } catch (error) {
        // handled in store
      }
    },
    updatePassword() {
      this.setPasswordAction({
        current_password: this.oldPassword,
        new_password: this.password,
      }).then(() => {
        this.isChangePasswordViewed = false;
      });
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    userInfo() {
      return [
        { label: "name", data: this.name },
        { label: "email", data: this.email },
        { label: "Plan", data: this.user.plan?this.getPlanName:'Trial' },
      ];
    },
    minutesInfo() {
      return [
        { label: "Allocated minutes", data: this.user.plan?this.user.plan.seconds_limit/60:this.user.seconds_limit/60 },
        { label: "Minutes used", data: (this.user.plan_used_seconds/60).toFixed(0) },
      ]
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      !this.$v.name.minLength &&
        errors.push(`Name must contains at least ${nameMinLength} characters`);
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("Email is required");
      return errors;
    },
    validatePassword() {
      const isValid =
        this.$v.password.regexaz &&
        this.$v.password.minLength &&
        this.$v.password.regexAZ &&
        this.$v.password.regexNumber;
      if (this.password.length && !isValid) return true;

      if (isValid) return false;

      return false;
    },
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.oldPassword.$dirty) return errors;
      !this.$v.oldPassword.minLength &&
        errors.push(
          `Password must contains at least ${passwordMinLength} characters`
        );
      !this.$v.oldPassword.regexAZ &&
        errors.push("Password must contains upper (A-Z) case letter");
      !this.$v.oldPassword.regexaz &&
        errors.push("Password must contains lower (a-z) case letter");
      !this.$v.oldPassword.regexNumber &&
        errors.push("Password must contains at least one number (0-9)");
      return errors;
    },
    getPlanName() {
     return this.user.plan.s_recurring_interval === 'month'?this.user.plan.s_unit_amount/100+'$ per month':this.user.plan.s_unit_amount/100+'$ per year'
    }
  },
  mounted() {
    this.name = this.user.full_name;
    this.email = this.user.email;
  },
  validations: {
    name: {
      required,
      minLength: minLength(nameMinLength),
    },
    email: {
      email,
      required,
    },
    oldPassword: {
      regexAZ,
      regexaz,
      regexNumber,
      minLength: minLength(passwordMinLength),
    },
    password: {
      regexAZ,
      regexaz,
      regexNumber,
      minLength: minLength(passwordMinLength),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";
@import "@/assets/styles/components/signup.scss";

.account-wrapper {
  height: 100%;
  padding-top: 60px;
  &__user-info {
    width: 100%;
    margin-bottom: 18px;
  }
  &__billing-table {
    &--row {
      display: flex;
      align-items: center;
      padding: 18px 0;
    }
    &--cell {
      font-size: 16px;
      font-family: "Satoshi Bold";
      font-weight: 700;
      height: 30px;
      display: flex;
      align-items: center;
    }
    &--cell-narrow {
      width: 20%;
    }
    &--cell-wide {
      width: 35%;
      color: $light-gray;
    }
    &__cta {
      font-size: 16px;
      border-color: #c1d5e2 !important;
      color: black !important;
      border-radius: 5px;
      &--active {
        color: $dark-black !important;
        background-color: $yellow !important;
        border-color: $yellow !important;
      }
      &--negative {
        color: $error !important;
        background-color: $google-button-background !important;
        border-color: $off-white !important;
      }
    }
  }
  &__divider {
    opacity: 0.65;
  }
  &__set-password-button {
    background-color: $yellow !important;
    color: white;
    height: 46px !important;
  }
  &__change-password-button {
    color: $violet;
    cursor: pointer;
  }
  label {
    width: 170px !important;
  }
}
</style>
