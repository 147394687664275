
export const mutations = {
  setUploadProgresses: (state, payload) => {
    const uploadProgress = state.uploadProgresses.find(p=>p.uuid === payload.uuid)
    if(uploadProgress){
    const index = state.uploadProgresses.indexOf(uploadProgress)
      if(uploadProgress && index!==-1){
        state.uploadProgresses[index] = payload
      }
    }
    else {
      state.uploadProgresses.push(payload)
    }
  },
  removeUploadProgress: (state, payload) =>{
    state.uploadProgresses = state.uploadProgresses.filter(p=>p.uuid !== payload.uuid)
  }
};
