<template>
  <v-container fluid :class="isClips ? 'mt-0' : 'mt-10'">
    <v-row>
      <v-col
        v-if="!isHomePage && isClips"
        :lg="clipSpaceLg"
        :md="clipSpaceMd"
        sm="6"
        cols="12"
        class="videos-grid-wrapper__col"
        @click="goToNewClip"
      >
        <CreateVideoClip />
      </v-col>
      <v-col
        v-for="video in videos"
        :lg="clipSpaceLg"
        :md="clipSpaceMd"
        sm="6"
        cols="12"
        class="videos-grid-wrapper__col"
        :key="video.uuid"
      >
        <Video
          :goToVideoClips="goToVideoClips"
          :goToViewVideo="goToViewVideo"
          :goToEditVideo="goToEditVideo"
          :isClips="isClips"
          :content="video"
          :thumbnail="isClips ? video.thumbnail : thumbnail"
          :videoName="videoName"
          :clipsVideoUuid="clipsVideoUuid"
          :addClonnedClip="addClonnedClip"
          @deleted="$emit('deleted', video.uuid)"
        />
      </v-col>
      <v-col v-if="!isLoading">
        <div
          v-observe-visibility="{
            callback: fetchMoreVideos,
            throttle: 2000,
            intersection: {
              threshold: 0.05,
            },
          }"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import types from "../../store/types";
import Video from "./Video.vue";
import CreateVideoClip from "./CreateVideoClip.vue";

export default {
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
    isClips: {
      type: Boolean,
      default: false,
    },
    clipSpaceLg: {
      type: Number,
      default: 3,
    },
    clipSpaceMd: {
      type: Number,
      default: 6,
    },
    thumbnail: {
      type: String,
    },
    clipsVideoUuid: {
      type: String,
    },
    videoName: {
      type: String,
    },
    folder: {
      type: Object,
    },
    addClonnedClip: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  components: { Video, CreateVideoClip },
  computed: {
    ...mapState({
      videosData: (state) => state.project.videos,
    }),
    isHomePage() {
      return this.$route.name === "Home";
    },
    currentFolderUuid() {
      return this.folder.uuid;
    },
  },
  methods: {
    ...mapActions({
      fetchVideos: types.project.actions.FETCH_VIDEOS,
    }),
    isCurrentFolderEmpty() {
      return !!Object.keys(this.folder || {}).length;
    },
    goTo(url) {
      this.$router.push(url).catch(() => {});
    },
    goToVideoClips(id) {
      this.goTo(
        this.isCurrentFolderEmpty()
          ? `/folder/${this.currentFolderUuid}/video/${id}/clips`
          : `/video/${id}/clips`
      );
    },
    goToViewVideo(id, clipUuid) {
      if (this.isCurrentFolderEmpty()) {
        const folderUuid = this.currentFolderUuid;
        this.goTo(
          clipUuid
            ? `/folder/${folderUuid}/video/${id}/clip/${clipUuid}`
            : `/folder/${folderUuid}/video/${id}`
        );
      } else {
        this.goTo(clipUuid ? `/video/${id}/clip/${clipUuid}` : `/video/${id}`);
      }
    },
    goToEditVideo(id, clipUuid) {
      if (this.isCurrentFolderEmpty()) {
        const folderUuid = this.currentFolderUuid;
        this.goTo(
          clipUuid
            ? `/video-edit/${folderUuid}/${id}/clip/${clipUuid}/subtitles`
            : `/video-edit/${folderUuid}/${id}/subtitles`
        );
      } else {
        this.goTo(
          clipUuid
            ? `/video-edit/${id}/clip/${clipUuid}/subtitles`
            : `/video-edit/${id}/subtitles`
        );
      }
    },
    goToNewClip() {
      this.goTo(
        this.isCurrentFolderEmpty()
          ? `/new-clip/${this.currentFolderUuid}/${this.clipsVideoUuid}/subtitles`
          : `/new-clip/${this.clipsVideoUuid}/subtitles`
      );
    },
    async fetchMoreVideos(isVisible) {
      if (!isVisible) return;
      if (this.videosData.next) {
        this.isLoading = true;
        const page = this.videosData.next.split("page=")[1];
        try {
          await this.fetchVideos(page);
          this.isLoading = false;
        } catch (errorMessage) {
          this.isLoading = false;
          // handled in store
        }
      }
    },
  },
};
</script>
