<template>
  <div class="resize-dialog-wrapper">
    <v-menu
      bottom
      offset-y
      content-class="resize-dialog-wrapper__list"
      min-width="314"
      :close-on-content-click="false"
      v-model="isClosed"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="resize-dialog-wrapper__fit-color"
          v-bind="attrs"
          v-on="on"
          :ripple="false"
          text
        >
          <span class="font-weight-normal text-capitalize"> Fit color </span>
          <v-icon size="20">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list class="py-0">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" class="d-flex justify-center mt-7">
            <v-color-picker
              v-model="color"
              mode="hexa"
              dot-size="25"
              hide-mode-switch
              swatches-max-height="200"
            ></v-color-picker>
          </v-col>
          <v-col cols="12" class="px-4 pt-0 pb-2">
            <v-btn
              class="resize-dialog-wrapper__apply-button"
              text
              :ripple="false"
              block
              height="36"
              @click="selectColor"
            >
              Apply
            </v-btn>
          </v-col>
        </v-row>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ["fitColor", "updateClip"],
  data() {
    return {
      color: "ffffff",
      isClosed: false,
    };
  },
  mounted() {
    this.color = this.fitColor;
  },
  watch: {
    fitColor(newValue) {
      if (newValue != this.color) {
        this.color = newValue;
      }
    },
    isClosed(newValue) {
      if (!newValue) {
        if (this.color != this.fitColor) {
          this.color = this.fitColor;
        }
        if (this.isCrop != this.isCropped) {
          this.isCrop = this.isCropped;
        }
      }
    },
  },
  methods: {
    selectColor() {
      this.updateClip({ fit_color: this.color });
      this.isClosed = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/components/videos/resize-dialog.scss";
</style>
