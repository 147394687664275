import types from "../types";
import {
  autoCrop,
  getAutoCropStatus,
  updateAutoCrop
} from "../../utils/APIs";
import setLoadingAndError from "../../utils/LoaderAndError";

const state = {
    autoCropping:null,
    autoCropProgress:15,
    showAutoCrop:false,
    isAutoCropStarted:false,
    autoCropClip:null
};

const autoCropAction = async ({commit}, payload) => {
    const response =  await autoCrop(payload);
    setLoadingAndError(commit, false);
    return response
  };
  
  const fetchAutocropClip = async ({commit}, payload) => {
    const response =  await getAutoCropStatus(payload);
    commit(types.autoCrop.mutations.SET_AUTO_CROP_CLIP, response)
    return response
  };

  const updateAutoCropClip = async ({commit}, payload) => {
    const response =  await updateAutoCrop(payload);
    commit(types.autoCrop.mutations.SET_AUTO_CROP_CLIP, response)
    return response
  };

const mutations = {
  [types.autoCrop.mutations.AUTO_CROPPING]: (state, payload) => {
    state.autoCropping= payload;
  },
  [types.autoCrop.mutations.AUTO_CROPPING_PROGRESS]: (state, payload) => {
    state.autoCropProgress= payload;
  },
  [types.autoCrop.mutations.SHOW_AUTO_CROP]: (state, payload) => {
    state.showAutoCrop= payload;
  },
  [types.autoCrop.mutations.SET_IS_AUTO_CROP_STARTED]: (state, payload) => {
    state.isAutoCropStarted= payload;
  },
  [types.autoCrop.mutations.SET_AUTO_CROP_CLIP]: (state, payload) => {
    state.autoCropClip= payload;
  },
  [types.autoCrop.mutations.UPDATE_AUTO_CROP_TIME_RANGE]: (state, payload) => {
    state.autoCropClip= {...state.autoCropClip, clip_start_time_relatedto_new_buffered_clip:payload.startTime, clip_end_time_relatedto_new_buffered_clip:payload.endTime};
  },
};
const actions = {
    [types.autoCrop.actions.AUTO_CROP]:autoCropAction,
    [types.autoCrop.actions.FETCH_AUTO_CROP_CLIP]:fetchAutocropClip,
    [types.autoCrop.actions.UPDATE_AUTO_CROP_CLIP]:updateAutoCropClip
  };

export default {
  state,
  mutations,
  actions
};
