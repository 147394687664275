import { state } from "./video.state";
import { actions } from "./video.action";
import { mutations } from "./video.mutation";
import { getters } from "./video.getters";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
