<template>
  <div>
    <v-tooltip top>
    <template v-slot:activator="{ on }">
     <v-btn v-on="on" fab bottom right class="fab-button" @click="showPopup = true">
      <v-icon>mdi-alert-circle</v-icon>
    </v-btn>
    </template>
    <span><b style="font-size: 15px;">Is something not right?</b><br> Please help us make chopcast better! We’ll get back to you within 1 working day.</span>
  </v-tooltip>
    

    <v-dialog v-model="showPopup" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Report an error</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="bugReport"
            label="Error description"
            outlined
            rows="5"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error-action" @click="sendBugReport">Send</v-btn>
          <v-btn @click="showPopup = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import types from "../.././store/types";
export default {
  
  data() {
    return {
      showPopup: false,
      bugReport: '',
    };
  },
  methods: {
    ...mapActions({
      reportErrorAction: types.project.actions.REPORT_ERROR,
    }),
    ...mapMutations({
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE,
    }),
    async sendBugReport() {
      this.showPopup = false;
      const payload = {
        is_active: true,
        bug: this.bugReport,
        video: this.$route.params && this.$route.params.uuid?this.$route.params.uuid:"",
        clip: this.$route.params && this.$route.params.uuid?this.$route.params.clip:"",
        on_page: window.location.href
      }

     await this.reportErrorAction(payload)
     this.setSuccessMessage("Thanks for helping make chopcast better. We'll investigate this and get back to you within 1 working day.")
    },
  },
};
</script>

<style scoped>
  .error-action{
    background-color: #fbd458 !important;
  }
</style>
