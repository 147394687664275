var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VueDragResize',{staticClass:"title-wrapper d-flex justify-content-center",style:({
    fontSize: _vm.title.font_size ? _vm.title.font_size + 'px' : '20px',
    color: _vm.title.color ? _vm.title.color : 'white',
    fontWeight: 'bolder',
    backgroundColor: _vm.title.background_color
      ? _vm.title.background_color
      : 'transparent',
    visibility: _vm.title.uuid && _vm.isFakeVideoLayerFilled?'visible':'hidden' 
  }),attrs:{"isActive":true,"isResizable":false,"parentLimitation":true,"isDraggable":_vm.isEditPage,"w":"auto","h":"auto","x":_vm.left,"y":_vm.top,"parentW":_vm.parentWidth,"parentH":_vm.parentHeight,"id":"title-text","z":1},on:{"dragstop":_vm.changeStatus,"clicked":_vm.onTitleClicked,"dragging":function (e) { return _vm.setVerticalLineVisibility(true, e); }}},[_c('p',{staticClass:"title-wrapper__text",class:['title-wrapper__text', { enabled: _vm.isEditPage }]},[_c('span',{staticClass:"title-wrapper__text__content",style:({
        fontFamily: _vm.title.font + ' !important',
      })},[_vm._v(_vm._s(_vm.title.text)+" ")])]),(_vm.isEditPage)?_c('v-icon',{staticClass:"title-wrapper__delete",attrs:{"size":"18"},on:{"click":_vm.deleteTitle}},[_vm._v("mdi-close ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }