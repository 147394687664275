import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuelidate from "vuelidate";
import VueEllipseProgress from "vue-ellipse-progress";
import VueWaveSurfer from "vue-wave-surfer";
import VueObserveVisibility from "vue-observe-visibility";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vuetify from "./plugins/vuetify";
import VueDragResize from "vue-drag-resize";
import Icon from "../src/shared/Icon";
import VueGtag from "vue-gtag";
import VueGtm from "@gtm-support/vue2-gtm";
import * as Sentry from "@sentry/vue";

Vue.component("vue-drag-resize", VueDragResize);
Vue.component("Icon", Icon);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VueEllipseProgress);
Vue.use(VueWaveSurfer);
Vue.use(VueObserveVisibility);
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GTAG_KEY },
    pageTrackerScreenviewEnabled: true,
  },
  router
);

Vue.use(VueGtm, {
  id: "GTM-W27LXM9",
  debug: true,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});

Vue.config.productionTip = false;
Vue.directive("outside-click", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // Check that click is outside of the el and its children
      if (!(el === event.target || el.contains(event.target))) {
        // Call the provided method on the Vue component
        vnode.context[binding.expression](event);
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
});
Sentry.init({
  Vue,
  dsn: "https://79138e08aab94ae4bfae37010d49e5d9@o1127651.ingest.sentry.io/4505509212782592",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "app.chopcast.io", "app.staging.chopcast.io"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
