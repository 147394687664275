<template>
  <v-row justify="center">
    <v-dialog v-model="isDialogVisible" max-width="300">
      <v-card>
        <v-card-title class="text-h5"> New Folder </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-text-field
                ref="newFolderName"
                label="Type Folder Name"
                single-line
                outlined
                class="text-h5 font-weight-light"
                :error-messages="folderNameErrors"
                v-model.trim="folderName"
                v-on:keyup.enter="createNewFolder"
              ></v-text-field>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="showNewFolderDialog(false)"
            ><span class="text-capitalize">Cancel</span></v-btn
          >
          <v-btn
            class="new-folder-modal-wrapper--btn"
            @click="createNewFolder"
            color="#fbd458"
            text
            ><span class="font-weight-bold text-capitalize">Create</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import types from "../../store/types";
import { required, minLength } from "vuelidate/lib/validators";
// import types from "../../store/types";

const folderNameMinLength = 2;

export default {
  props: ["isNewFolderDialogVisible", "showNewFolderDialog"],
  data() {
    return {
      folderName: "",
    };
  },
  methods: {
    ...mapActions({
      createFolder: types.project.actions.CREATE_FOLDER,
    }),
    createNewFolder: async function () {
      this.$v.folderName.$touch();
      if (!this.$v.folderName.$invalid) {
        try {
          const payload = { name: this.folderName };
          await this.createFolder(payload);
          this.showNewFolderDialog(false);
        } catch (error) {
          // handled in store
        }
      }
    },
    focusFolderName() {
      this.$refs["newFolderName"].focus();
    },
  },
  watch: {
    isNewFolderDialogVisible(value) {
      if (value) {
        setTimeout(() => {
          this.focusFolderName();
        }, 0);
      }
    },
  },
  computed: {
    isDialogVisible: {
      get() {
        return this.isNewFolderDialogVisible;
      },
      set(val) {
        this.showNewFolderDialog(val);
      },
    },
    folderNameErrors() {
      const errors = [];
      if (!this.$v.folderName.$dirty) return errors;
      !this.$v.folderName.required && errors.push("Folder Name is required");
      !this.$v.folderName.minLength &&
        errors.push(
          `Folder Name must contains at least ${folderNameMinLength} characters`
        );
      return errors;
    },
  },
  validations: {
    folderName: {
      required,
      minLength: minLength(folderNameMinLength),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/videos/new-folder.scss";
</style>
