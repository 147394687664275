<template>
  <div class="sign-in-page">
    <div class="sign-in-page__center form-center">
      <Forgot />
    </div>
  </div>
</template>

<script>
import Forgot from "../components/Forgot.vue";
export default {
  components: { Forgot },
  name: "ForgotPage",
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/views/signin.scss";
.form-center{
  width: 100% !important;
  padding-left: 25%;
  padding-right: 25%;
}
</style>
