var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.textExists)?_c('vue-drag-resize',{key:_vm.leftPos+'-'+_vm.top,ref:"subtitletext",class:['subtitle-wrapper', 'd-flex', 'justify-content-center',{'postop': _vm.top === -1}],attrs:{"id":"subtitletext","axis":"y","isActive":true,"isResizable":false,"parentLimitation":true,"isDraggable":_vm.isEditPage,"w":"auto","h":"auto","y":_vm.top,"z":2},on:{"dragstop":_vm.onDragStop}},[_c('div',{staticClass:"['subtitle-wrapper__text', { enabled: isEditPage }]"},[_c('div',{class:['subtitle-wrapper__text-content', ("" + _vm.strokeClass)],style:({
        fontFamily: _vm.clipStyle.font && _vm.clipStyle.font? _vm.clipStyle.font+ ' !important':'Arvo-Bold !important',
        fontSize: !_vm.isEditPage?'30px':_vm.fontSize + 'px',
        color: _vm.clipStyle.color || 'white',
        fontWeight: _vm.clipStyle.text_style_bold ? 'bolder' : _vm.fontWeigh,
        fontStyle: _vm.clipStyle.text_style_italic ? 'italic' : 'normal',
        textDecoration: _vm.clipStyle.text_style_underline ? 'underline !important':'none',
        wordBreak: 'break-word',
        backgroundColor: _vm.clipStyle.background_color || '',
        borderRadius: '5px', lineHeight:_vm.fontSize+'px',
        paddingTop: '12px'}),domProps:{"innerHTML":_vm._s(_vm.currentText)}})])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }