var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resize-dialog-wrapper"},[_c('v-menu',{attrs:{"bottom":"","offset-y":"","content-class":"resize-dialog-wrapper__list","min-width":"314","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"resize-dialog-wrapper__resize-button",attrs:{"ripple":false,"text":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"font-weight-normal text-capitalize"},[_vm._v("Resize")]),_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-chevron-down")])],1)]}}]),model:{value:(_vm.isClosed),callback:function ($$v) {_vm.isClosed=$$v},expression:"isClosed"}},[_c('v-list',{staticClass:"py-0"},[_c('v-radio-group',{model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}},_vm._l((_vm.options),function(option){return _c('v-radio',{key:option.text,attrs:{"color":"secondary","label":option.text,"value":option.value}})}),1),_c('v-row',{staticClass:"pa-0 ma-0"},[(!_vm.isOriginalRatio)?_c('v-col',{staticClass:"resize-dialog-wrapper__is-fit d-flex align-start mb-5 justify-center",attrs:{"cols":"12"}},[_c('div',{class:[
              'mr-5',
              { 'resize-dialog-wrapper__is-fit__inactive-tab': _vm.isCrop } ]},[_vm._v(" Fit ")]),_c('v-switch',{attrs:{"color":"primary","hide-details":"","inset":""},model:{value:(_vm.isCrop),callback:function ($$v) {_vm.isCrop=$$v},expression:"isCrop"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"crop-icon",attrs:{"size":"small"}},'v-icon',attrs,false),on),[_vm._v("mdi-help")]),_c('div',{class:[
                  'ml-5',
                  { 'resize-dialog-wrapper__is-fit__inactive-tab': !_vm.isCrop } ]},[_vm._v("Crop ")])]}}],null,false,781773607)},[_c('span',[_vm._v("Click and drag the video to adjust the frame to your liking")])])],1):_vm._e(),(!_vm.isOriginalRatio)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{class:['resize-dialog-wrapper__apply-button', 'font-weight-bold', 'text-capitalize',{'auto-crop':_vm.autoCropSelect}],attrs:{"text":"","ripple":false,"small":""},on:{"click":_vm.autoCropClip}},[_vm._v(" Auto Crop "),_c('v-icon',_vm._g(_vm._b({staticClass:"crop-icon",staticStyle:{"margin-top":"0"},attrs:{"size":"small"}},'v-icon',attrs,false),on),[_vm._v("mdi-help")])],1)]}}],null,false,2215350202)},[_c('span',[_vm._v("Click to auto crop this video")])])],1):_vm._e(),_c('v-col',{staticClass:"px-4 pt-0 pb-2",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"resize-dialog-wrapper__apply-button font-weight-bold text-capitalize",attrs:{"text":"","ripple":false,"block":"","height":"36"},on:{"click":_vm.selectRatio}},[_vm._v(" Apply ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }