<template>
  <div class="main-wrapper">
    <HeaderLogo />
  <div class="sign-in-page">
    <div class="sign-in-page__right">
      <SignIn />
    </div>
    <div class="sign-in-page__right sign-in-page__testimonials" style="background-color: #fbfbfe;"> 
     <Testimonials/>
    </div>
  </div>
</div>
</template>

<script>
import SignIn from "../components/Signin.vue";
import HeaderLogo from "../components/HeaderLogo.vue";
import Testimonials from "../views/Testimonials.vue"
import { testimonials } from "../utils/testimonials";
export default {
  components: { SignIn, Testimonials, HeaderLogo },
  name: "SignInPage",
  data(){
    return{
      testimonials:testimonials,
        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],
    }
}
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/views/signin.scss";
</style>
