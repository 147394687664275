
export const mutations = {
  setZoom: (state, payload) => {
    state.zoom = payload;
  },
  setHasSelectedText: (state, payload) => {
    state.hasSelectedText = payload;
  },
  setYoutubeVideoUploadStatus: (state, payload) => {
    state.isUploadingYoutubeVideo = payload
  }
};
