<template>
  <div class="sign-in-page">
    <div class="sign-in-page__left">
      <div class="sign-in-page__left__description">
        <img src="@/assets/images/icons/login-logo.svg" alt="logo" />
        <p>
          “Chopcast is just awesome. I’ve saved so much time uploading our
          webinars and video podcasts and automatically chopping the best bits
          for social. Highly recommended!”
        </p>
        <p>- Jon B.</p>
      </div>
    </div>
    <div class="sign-in-page__right">
      <Verify />
    </div>
  </div>
</template>

<script>
import Verify from "../components/Verify.vue";
export default {
  components: { Verify },
  name: "VerifyPage",
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/views/signin.scss";
</style>
