<template>
  <router-view :key="computedKey"></router-view>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import types from "../store/types";
export default {
  computed: {
    computedKey() {
      // Scenario 1: Reload on any route change
      if (this.reloadEditorPage) {
        return this.$route.fullPath;
      }
      // Scenario 2: Reload only if specific parameter changes
      return this.$route.params.clipUuid;
    },
    ...mapState({
      reloadEditorPage:(state)=> state.app.reloadEditorPage
    })
  },
  methods: {
    ...mapMutations({
      setIsAutoCropping: types.autoCrop.mutations.AUTO_CROPPING,
      setAutoCropProgress: types.autoCrop.mutations.AUTO_CROPPING_PROGRESS,
      setIsAutocropStarted: types.autoCrop.mutations.SET_IS_AUTO_CROP_STARTED,
      setShowAutoCropPlayer: types.autoCrop.mutations.SHOW_AUTO_CROP,
      setAutoCropClip: types.autoCrop.mutations.SET_AUTO_CROP_CLIP,
    }),
    resetAutoCrop() {
      this.setIsAutoCropping(null);
      this.setIsAutocropStarted(false);
      this.setAutoCropProgress(15);
      this.setShowAutoCropPlayer(false);
      this.setAutoCropClip(null);
    },
  },
  watch: {
    $route(value, newValue) {
      if (value.params.clipUuid !== newValue.params.clipUuid) {
        this.resetAutoCrop();
      }
    },
  },
};
</script>
