<template>
  <v-navigation-drawer
    v-model="showChat"
    fixed
    right
    width="400"
    app
    class="custom-drawer"
    content-class="drawer-content"
  >
    <v-card flat class="chat-card">
      <div class="chat-header">
        <v-card-title class="headline d-flex justify-space-between">
          <span style="font-size: 18px;font-weight: 500;">Chat with video</span>
        </v-card-title>
        <v-icon @click="closeChat" class="cursor-pointer close-icon"
          >mdi-close</v-icon
        >
      </div>
      <v-card-text class="chat-messages" ref="chatMessages">
        <v-container>
          <v-row
            v-for="(message, index) in messages"
            :key="index"
            class="message-row my-2"
            :class="{
              'message-right': message.user === 'me',
              'message-left': message.user === 'them',
            }"
          >
            <v-col cols="12">
              <div class="message-wrapper">
                <span
                  :style="{
                    backgroundColor:
                      message.user === 'me' ? 'primary' : 'grey lighten-1',
                  }"
                  dark
                  class="message-chip"
                >
                  <span v-html="message.text"></span>
                  <v-chip
                    v-if="message.user === 'them' && message.startTime"
                    class="ma-2"
                    play
                    style="cursor: pointer"
                    @click="timestampClicked(message.startTime)"
                  >
                    Click to play: {{ secondsToMinutes(message.startTime) }}
                  </v-chip>
                </span>
                <v-icon
                  v-if="message.user === 'them' && index !== 0"
                  small
                  class="copy-icon"
                  @click="copyMessage(message.text)"
                >
                  mdi-content-copy
                </v-icon>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="isTyping" class="message-row my-2 message-left">
            <v-col cols="12">
              <div class="message-wrapper typing-indicator">
                <v-chip class="message-chip">
                  <span class="typing-dot"></span>
                  <span class="typing-dot"></span>
                  <span class="typing-dot"></span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="chat-input">
        <v-text-field
          v-model="newMessage"
          label="Type a message"
          outlined
          dense
          @keyup.enter="sendMessage"
          append-icon="mdi-send"
          @click:append="sendMessage"
        ></v-text-field>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { chatWithVideo } from "../utils/APIs";
import secondsToMinutes from "../utils/SecondsToTime"

import types from "../store/types";
export default {
  props: {
    onClickTimeStamp: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      newMessage: "",
      greetingMessages: [
        { user: "them", text: "How can I help?" },
      ],
      secondsToMinutes:secondsToMinutes,
      isTyping:false
    };
  },

  computed:{
    ...mapState({
      messages:(state)=>state.chatWithVideo.messages,
      showChat:(state)=>state.chatWithVideo.showChat
    })
  },

  mounted(){
    if(!this.messages.length){
      this.greetingMessages.forEach((msg)=>{
        this.addMessage(msg)
      })
    }
  },
  methods: {
    ...mapMutations({
      addMessage: types.chatWithVideo.mutations.ADD_MESSAGE
    }),
    sendMessage() {
      if (this.newMessage.trim() !== "") {
        this.addMessage({ user: "me", text: this.newMessage });
        this.isTyping = true
        this.scrollToBottom();
        chatWithVideo({
          video: this.$route.params.uuid,
          question: this.newMessage,
        }).then((response) => {
          if (Array.isArray(response)) {
            response.forEach((res) => {
              this.addMessage({
                user: "them",
                text: `${res.text}`,
                startTime: res.start_time,
                endTime: res.end_time,
              });
            });
          }
          
          else if(!response.start_time){
            for (const key in response) {
              if (Object.prototype.hasOwnProperty.call(response, key)) {
                    response[key].forEach(item => {
                      this.addMessage({
                            user: "them",
                            text: item.text,
                            startTime: item.start_time,
                            endTime: item.end_time
                        });
                    });
                }
            }
          }
          else {
            const keys = Object.keys(response);
            if (keys.includes('start_time') || keys.includes('start_time')) {
              this.addMessage({
                user: "them",
                text: `${response.text}`,
                startTime: response.start_time,
                endTime: response.end_time,
              });
            } else {
              this.addMessage({ user: "them", text: `${response.text}` });
            }
          }
          this.isTyping = false
        }).catch(()=>{
          this.addMessage({ user: "them", text: `"Oops! Something went wrong while processing your request. Please try again in a moment."` });
          this.isTyping = false
        });
        this.newMessage = "";
      }
    },
    closeChat() {
      this.drawer = false;
      this.showChatWindow(false)
    },
    copyMessage(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.setSuccessMessage("Message copied to clipboard.");
      });
    },
    ...mapMutations({
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE,
      showChatWindow: types.chatWithVideo.mutations.SHOW_CHAT_WINDOW
    }),
    timestampClicked(ts) {
      this.onClickTimeStamp(ts);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatMessages = this.$refs.chatMessages;
        if (chatMessages) {
          chatMessages.scrollTop = chatMessages.scrollHeight;
        }
      });
    },
  },
};
</script>

<style scoped>
.custom-drawer {
  margin-top: 120px;
  margin-bottom: 20px;
  height: 100vh;
  max-height: calc( 100vh - 120px)!important; /* Adjust height to leave some space at the top and bottom */
  z-index: 10000000000;
  top: auto !important;
    bottom: 0;
    margin: 0;
}
.drawer-content {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
}
.chat-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.chat-messages {
  flex: 1;
  overflow-y: auto;
}
.chat-input {
  display: flex;
  align-items: center;
  .v-text-field__details{
      display: none !important;

  }
}
.cursor-pointer {
  cursor: pointer;
  font-size: 15px;
}
.close-icon {
  position: absolute;
  top: 6px;
  right: 10px;
  background-color: white;
  color: black;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, color 0.3s;
}
.close-icon:hover {
  background-color: black;
  color: white;
}
.chat-header {
  background-color: #fbd458;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  position: relative;
  height: 40px;
}
.chat-header .v-card-title {
  margin: 0;
}
.message-row {
  display: flex;
}
.message-right .message-wrapper {
  margin-left: auto;
  text-align: left;
  background-color: gray;
  border-radius: 5px;
  padding: 5px;
  color: white;
  width: fit-content
}
.message-left .message-wrapper {
  margin-right: auto;
  text-align: start;
  background-color: #7367f0;
  color: white;
  border-radius: 5px;
  padding: 5px;
  width: fit-content
}
.message-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 75%;
  white-space: normal; /* Allow text to wrap */
}
.message-chip {
  flex: 1;
  white-space: normal; /* Ensure text wraps within the chip */
  word-break: break-word; /* Ensure long words break appropriately */
  padding: 5px;
}
.copy-icon {
  margin-left: 8px;
  color: grey;
  background-color: #f5f5f5;
  border-radius: 50%;
  padding: 4px;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}
.copy-icon:hover {
  background-color: black;
  color: white;
}
.typing-indicator .message-chip {
  display: flex;
  align-items: center;
  background-color: #7367f0 !important;
}
.typing-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: white;
  border-radius: 50%;
  animation: typing 1s infinite;
}
.typing-dot:nth-child(2) {
  animation-delay: 0.2s;
}
.typing-dot:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes typing {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
</style>
