<template>
    <v-card>
        <v-card-title class="headline red--text">
          <v-icon left>mdi-alert</v-icon>
          Warning
        </v-card-title>
        <v-card-text>
            <p>{{message}}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancelExport" color="red" text>
            YES
          </v-btn>
          <v-btn @click="continueExport" color="green" text>
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
  </template>
  
  <script>
  export default {
    props: {
      yes: {
        type: Function,
        required: true,
      },
      no: {
        type: Function,
        required: true,
      },
      message: {
        type:String,
        required: true
      }
    },
    data() {
      return {
        showWarning: false,
      };
    },
    methods: {
      // Handle cancel button click
      cancelExport() {
        // Add logic to cancel the export if needed
        this.yes();
      },
      // Handle OK button click
      continueExport() {
        // Add logic to continue with the export
        this.no();
      },
    },
  };
  </script>
  
  <style>
  .warning-box {
    background-color: #ffeeee;
    border: 1px solid #ff9999;
    padding: 10px;
    margin: 10px 0;
  }
  </style>
  