<template>
  <div>
    <div v-if="isLoadingDemo" class="centered-content">Loading demo .......</div>
  
  <div v-else class="d-flex video-edit-wrapper">
    <EditFlowNavbar
      :baseUrl="baseUrl"
      :onClickLogo="onClickLogo"
      :previousRoute="prevRoute"
    />
    <div class="video-edit-wrapper__playground pt-0">
      <v-container fluid>
        <v-row class="justify-space-between pb-4">
          <v-col lg="8" cols="12" class="pt-5">
            <p class="video-edit-wrapper__playground__name mb-0">
              <span
                class="video-edit-wrapper__playground__name__video"
                @click="$router.go(-1)"
              >
                {{ video.name }}
              </span>
              <v-icon v-if="isClip" :style="{ margin: 'auto -5px' }"
                >mdi-chevron-right</v-icon
              >
              <span v-if="isClip">
                <span
                  v-if="currentClip.uuid && !isEditMode"
                  class="video-edit-wrapper__playground__name__clip"
                  @click="isEditMode = !isEditMode"
                >
                  {{ currentClip.name
                  }}<v-icon size="20">mdi-pencil-outline</v-icon>
                </span>
                <v-text-field
                  v-else
                  autofocus
                  label="Type Clip Name"
                  single-line
                  outlined
                  class="new-content-wrapper__edit-field--breadcrumb"
                  :style="{ display: 'inline-flex' }"
                  :error-messages="contentNameErrors"
                  v-model.trim="contentName"
                  @input="onFocus"
                  @blur="onBlur"
                  @keydown.enter="onBlur"
                  @keydown.esc="isEditMode = !isEditMode"
                ></v-text-field>
              </span>
            </p>
          </v-col>
          <v-col
            lg="4"
            cols="12"
            class="d-inline-flex justify-start d-lg-flex justify-lg-end"
          >
            <FitColorPikerDialog
              v-if="isResizeFit"
              :fitColor="currentClip.fit_color"
              :updateClip="updateClipFitColor"
            />
            <ResizeDialog
              :setResizeDimension="setResizeDimension"
              :resizeDimension="resizeDimension"
              :setIsCrop="setIsCrop"
              :isCropped="isCrop"
              :videoAspectRatio="videoAspectRatio"
            />
          </v-col>
        </v-row>
        <v-row
          class="video-edit-wrapper__subtitle-video-wrapper"
          style="position: relative"
        >
          <v-col class="video-edit-wrapper__subtitle pl-0">
            <router-view
              :createNewClip="updateCurrentClipTimes"
              :fetchCurrentClip="fetchCurrentClip"
              :video="video"
              :realClipDim="realClipDim()"
            />
          </v-col>
          <v-col
            class="video-edit-wrapper__video text-center"
            style="position: relative"
            ><div
              id="player-wrapper"
              v-if="videoSubtitle.length && videoWaveform.length"
              style="visibility: hidden"
            >
              <VideoPlayer
                v-if="video.video_file"
                ref="videoPlayerRef"
                :src="video.video_file"
                :video="video"
                :clips="viewedClips"
                :videoSubtitle="videoSubtitle"
                :timeRange="timeRange"
                :audioWaveformId="audioWaveformId"
                :getVideoCurrentTime="getVideoCurrentTime"
                :updateCurrentClip="updateCurrentClipTimes"
                :videoWaveform="videoWaveform"
                :playedClipIndex="playedClip"
                :setResizeDimension="setResizeDimension"
                :resizeDimension="resizeDimension"
                :highlightedRegion="highlightedRegion"
                :isCrop="isCrop"
                :videoWidth="videoWidth"
                :videoHeight="videoHeight"
                :setViewedTimeRange="setViewedTimeRange"
                :setIsCrop="setIsCrop"
                :shouldCallResizeApi="shouldCallResizeApi"
                :setVideoAspectRatio="setVideoAspectRatio"
                :fetchCurrentClip="fetchCurrentClip"
                :sameCurrentTime="sameCurrentTime"
                :setSameTimeRange="setSameTimeRange"
                :showPlayer="showPlayer"
              />
            </div>
            <div
              :style="{ visibility: !showPlayer ? 'visible' : 'hidden' }"
              class="d-flex align-center justify-center loader-wrapper"
            >
              <v-progress-circular
                :size="50"
                color="blue-grey"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <div class="video-edit-wrapper__waveform">
        <div class="video-edit-wrapper__Speakers-container">
          <div class="d-flex justify-space-between">
            <div
              class="video-edit-wrapper__Speakers-container__speakers d-flex"
            >
              <!-- <p
                  v-for="(speaker, index) in videoSpeakers"
                  :key="speaker.speakerTag"
                  class="video-edit-wrapper__Speakers-container__speakers__speaker mb-0"
                  :style="{
                    backgroundColor: speakersBackgroundColors[index % 7],
                    cursor: 'pointer',
                  }"
                  @click="resetZoom"
                >
                  Speaker {{ speaker.speakerTag }}
                </p> -->
            </div>
            <div style="width: 25%">
              <ZoomSlider />
            </div>
          </div>
        </div>
        <div>
          <div id="speaker_container_wrapper">
            <!-- <div
                id="speaker_container"
                class="video-edit-wrapper__Speakers-container__speakers-slots d-flex"
              >
                <span
                  v-for="(speaker, index) in videoSpeakers"
                  :key="speaker.speakerTag"
                >
                  <div
                    v-for="slot in speaker.slots"
                    :key="slot.startTime"
                    class="video-edit-wrapper__Speakers-container__speakers-slots__slot"
                    :style="{
                      left: computeLeft(slot.startTime) + '%',
                      width: computeWidth(slot.startTime, slot.endTime) + '%',
                      backgroundColor: speakersBackgroundColors[index % 7],
                    }"
                    @click="
                      highlightSpeakerRegion(
                        slot.startTime,
                        slot.endTime,
                        speakersBackgroundColors[index % 7]
                      )
                    "
                  ></div>
                </span>
              </div> -->
          </div>
          <div
            :id="audioWaveformId"
            class="video-edit-wrapper__waveform__audio"
          ></div>
          <div id="region-start"></div>
          <div id="region-end"></div>
        </div>
        <div
          class="video-edit-wrapper__waveform__time__container d-flex justify-center align-center"
        >
          <div class="video-edit-wrapper__waveform__time">
            {{ secondsToMinutes(viewedTimeRange.startTime, true) }}/{{
              secondsToMinutes(viewedTimeRange.endTime, true)
            }}
          </div>
        </div>
        <ClipsSlider
          :clips="viewedClips"
          :setVideoTimeRange="changeSelectedClip"
          :isVisibleInVideoEdit="true"
          :playedClip="playedClip"
          :isLoading="isLoading"
          :fetchMoreClips="fetchMoreClips"
        />
        <DownloadDialog
          ref="downloadDialogRef"
          :isDownloadDialogVisible="isDownloadDialogVisible"
          :showDownloadDialogVisible="showDownloadDialogVisible"
          :videoLink="videoLink"
          :audioLink="audioLink"
          :videoName="videoName"
          :videoUuid="$route.params.uuid"
          :fetchExportedClipAction="fetchExportedClipAction"
          :exportClipAction="exportClipAction"
          :exportingStatus="exportedClip ? exportedClip.status : ''"
          :exportingDate="exportedClip ? exportedClip.started_at : ''"
          :lastExportedBurnSubtitle="
            exportedClip ? exportedClip.burn_subtitles : false
          "
          :clip="currentClip"
        />
        <AfterExportDialog
          :isAfterExportDialogVisible="isAfterExportDialogVisible"
          :showAfterExportDialogVisible="showAfterExportDialogVisible"
        />
        <CreateOrUpdateClipDialog
          :isCreateOrUpdateClipDialogDialogVisible="
            isCreateOrUpdateClipDialogDialogVisible
          "
          :showCreateOrUpdateClipDialogDialog="
            showCreateOrUpdateClipDialogDialog
          "
          :handleChoice="handleChoice"
        />
      </div>
    </div>
    <v-dialog v-model="open" max-width="500px">
      <EnhanceAudio />
    </v-dialog>
  </div>
</div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import EditFlowNavbar from "../../components/Videos/EditFlowNavbar.vue";
import VideoPlayer from "../../components/VideoPlayer.vue";
import ClipsSlider from "../../components/Videos/ClipsSlider.vue";
import ZoomSlider from "../../shared/ZoomSlider.vue";
import types from "../../store/types";
import AfterExportDialog from "../../components/Videos/AfterExportDialog.vue";
import CreateOrUpdateClipDialog from "../../components/Videos/CreateOrUpdateClipDialog.vue";
import secondsToMinutes from "../../utils/SecondsToTime";
import ResizeDialog from "../../components/Videos/ResizeDialog.vue";
import DownloadDialog from "../../components/Videos/DownloadDialog.vue";
import FitColorPikerDialog from "../../components/Videos/FitColorPikerDialog.vue";
import EnhanceAudio from "../../components/Videos/EnhanceAudio.vue";
import CLIP_OPERATIONS from "../../constants/clipOperation";
//   import {
//   setUserTokenCookie,
//   setUserRefreshTokenCookie,
// } from "../../utils/Cookies";

const clipNameMinLength = 3;

export default {
  components: {
    EditFlowNavbar,
    VideoPlayer,
    ClipsSlider,
    AfterExportDialog,
    CreateOrUpdateClipDialog,
    ResizeDialog,
    DownloadDialog,
    FitColorPikerDialog,
    ZoomSlider,
    EnhanceAudio,
  },
  name: "VideoEdit",
  data() {
    return {
      showPlayer: false,
      audioWaveformId: "myAudio",
      timeRange: {},
      viewedTimeRange: {
        startTime: 0,
        endTime: 0,
      },
      video: {},
      clips: { results: [] },
      currentClip: {},
      isStartEditMode: false,
      currentTime: -1,
      videoTimeString: "00:00:00",
      videoTime: -1,
      baseUrl: "",
      playedClip: null,
      isEditMode: false,
      contentName: null,
      isExportDialogVisible: false,
      isResizeDialogVisible: false,
      isAfterExportDialogVisible: false,
      isCreateOrUpdateClipDialogDialogVisible: false,
      isDownloadDialogVisible: false,
      videoLink: "",
      audioLink: "",
      videoName: "",
      exportedClip: null,
      videoFilterSelectors: ["Search"],
      selectedSelector: "Search",
      searchKeyword: "",
      selectedSearchWordIndex: -1,
      wordPositions: [],
      speakersBackgroundColors: [
        "#FAE08C",
        "#9DAAFA",
        "#F6C4CD",
        "#EBDED4",
        "#e5504c",
        "#5fa88d",
        "#407b9e",
        "#e3793f",
      ],
      resizeDimension: "vjs-16-9",
      videoAspectRatio: "vjs-16-9",
      isCrop: false,
      shouldCallResizeApi: false,
      highlightedRegion: null,
      highlightedRegionTime: null,
      videoWidth: null,
      videoHeight: null,
      sameCurrentTime: false,
      isLoading: false,
      prevRoute: null,
      isLoadingDemo:true
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    ...mapState({
      videoSubtitle: (state) => state.project.videoSubtitle,
      wordsPerLine: (state) => state.project.wordsPerLine,
      videoWaveform: (state) => state.project.videoWaveform,
      videosSearchKeywords: (state) => state.project.videosSearchKeywords,
      videoSpeakers: (state) => state.project.videoSpeakers,
      zoom: (state) => state.video.zoom,
      hasSelectedText: (state) => state.video.hasSelectedText,
      subtitleWords: (state) => state.project.subtitleWords,
      open: (state) => state.project.openAudioEnhanceDialog,
    }),
    isClip() {
      return this.$route.params?.clipUuid;
    },
    clipImageLayers() {
      return this.imageLayers;
    },
    searchKeywords() {
      return (
        this.videosSearchKeywords.find((video) => video.uuid == this.video.uuid)
          ?.words || []
      );
    },
    viewedClips() {
      return [...this.clips.results].sort(function (a, b) {
        return a.start_time - b.start_time;
      });
    },
    contentNameErrors() {
      const errors = [];
      if (!this.$v.contentName.$dirty) return errors;
      !this.$v.contentName.required &&
        errors.push("Type Clip Name is required");
      !this.$v.contentName.minLength &&
        errors.push(
          `Clip Name must contains at least ${clipNameMinLength} characters`
        );
      return errors;
    },
    isResizeFit() {
      return (
        this.video?.video_file &&
        !this.isCrop &&
        this.resizeDimension != this.videoAspectRatio
      );
    },
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal.params.clipUuid !== oldVal.params.clipUuid) {
        this.setSelectedTitleUuid("");
        this.setSelectedImageLayerUuid("");

        const { clipUuid } = newVal.params;
        if (clipUuid) {
          const clipIndex = this.viewedClips.findIndex(
            (clip) => clip.uuid == clipUuid
          );
          this.setVideoTimeRange(clipIndex);
        }
      }
    },
    exportedClip(newValue, oldValue) {
      if (
        newValue?.clip == oldValue?.clip &&
        newValue?.status != oldValue?.status &&
        newValue?.status == "succeeded"
      ) {
        this.downloadFileAfterExporting();
        this.setUpdatedClip({
          ...this.currentClip,
          is_dirty: false,
        });
      }
    },

    hasSelectedText() {
      if (this.hasSelectedText) {
        const selectedText = window.getSelection();
        const selectedRange = selectedText.getRangeAt(0);
        const firstIndex = parseInt(
          selectedRange.startContainer.parentElement.id
        );
        const lastIndex = parseInt(selectedRange.endContainer.parentElement.id);
        this.creatClipFromText(firstIndex, lastIndex);
      }
    },
  },
  methods: {
    ...mapMutations({
      setClipStyle: types.project.mutations.SET_CLIP_STYLE,
      setSelectedTitleUuid: types.project.mutations.SET_SELECTED_TITLE_UUID,
      setSelectedImageLayerUuid:
        types.project.mutations.SET_SELECTED_IMAGE_LAYER_UUID,
      setSearchKeywords: types.project.mutations.SET_SEARCH_KEYWORD,
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE,
      setRefreshToken: types.user.mutations.SET_REFRESH_TOKEN,
    }),
    ...mapActions({
      createClip: types.project.actions.CREATE_NEW_CLIP,
      updateClip: types.project.actions.UPDATE_CLIP,
      fetchSubtitlesForVideo: types.project.actions.FETCH_VIDEO_SUBTITLE,
      fetchVideoWaveform: types.project.actions.FETCH_VIDEO_WAVEFORM,
      exportClip: types.project.actions.EXPORT_CLIP,
      fetchFonts: types.project.actions.FETCH_FONTS,
      fetchVideoSpeakersAction: types.project.actions.FETCH_VIDEO_SPEAKERS,
      fetchExportedClip: types.project.actions.FETCH_EXPORTED_CLIP,
      fetchFolder: types.project.actions.FETCH_FOLDER,
      fetchVideo: types.project.actions.FETCH_VIDEO,
      fetchClips: types.project.actions.FETCH_VIDEO_CLIPS,
      fetchClip: types.project.actions.FETCH_CLIP,
    }),
    setVideoDimensions(width, height) {
      this.videoWidth = width;
      this.videoHeight = height;
    },
    updateWaveSurferZoom(zoom) {
      console.log("xoom", zoom, this.$refs);
    },
    setIsCrop(isCrop) {
      this.isCrop = isCrop;
    },
    secondsToMinutes: secondsToMinutes,
    showAfterExportDialogVisible(flag) {
      this.isAfterExportDialogVisible = flag;
    },
    showCreateOrUpdateClipDialogDialog(flag) {
      this.isCreateOrUpdateClipDialogDialogVisible = flag;
    },
    async handleChoice(choice) {
      const { uuid, video } = this.currentClip;
      const { start_time, end_time } = this.highlightedRegionTime;
      const payload = {
        uuid,
        start_time,
        end_time,
        video: video || this.video.uuid,
        folderUuid: this.$route.params.folderUuid,
      };
      if (choice === CLIP_OPERATIONS.UPDATE_CLIP)
        await this.handleUpdateClip(payload, uuid);
      else await this.handleCreateClip(payload);
      this.highlightedRegion = null;
      this.highlightedRegionTime = null;
      this.showCreateOrUpdateClipDialogDialog(false);
    },
    setResizeDimension(dimension, shouldCallResizeApi) {
      this.shouldCallResizeApi = shouldCallResizeApi;
      this.resizeDimension = dimension;
      let wrapper = document.getElementById("player-wrapper");
      let progress = document.querySelector(".vjs-control-bar");
      setTimeout(() => {
        wrapper.style.visibility = "visible";
        progress.style.visibility = "visible";
        this.showPlayer = true;
      });
      this.setUpdatedClip({
        ...this.currentClip,
        is_dirty: true,
      });
      this.showPlayer = true;
    },
    setVideoAspectRatio(aspectRatio) {
      this.videoAspectRatio = aspectRatio;
    },
    async exportClipAction(burnSubtitles) {
      const { clipUuid } = this.$route.params;
      const videoDim = document.getElementById("fake-video-layer");
      if (clipUuid || this.playedClip)
        try {
          const uuid = this.viewedClips[this.playedClip].uuid || clipUuid;

          const payload = {
            clipUuid: uuid,
            burn_subtitles: burnSubtitles,
            highlight_subtitle_word: false,
            preview_height: videoDim.clientHeight,
            preview_width: videoDim.clientWidth,
          };
          const response = await this.exportClip(payload);

          this.exportedClip = response;
        } catch (err) {
          // handled in store
        }
    },
    getVideoCurrentTime(time, vidTime) {
      if (time) this.currentTime = time;

      if (vidTime) {
        this.videoTimeString = secondsToMinutes(vidTime, true);
        this.videoTime = vidTime;
      }
    },
    realClipDim() {
      const { width, height } = this.currentClip;
      return { width, height };
    },
    async handleCreateClip(payload) {
      const response = await this.createClip(payload);
      this.clips.results = [...this.clips.results, response];
      const clipIndex = this.viewedClips.findIndex(
        (clip) => clip.uuid == response["uuid"]
      );
      this.openClipPage(clipIndex);
    },
    async handleUpdateClip(payload, uuid) {
      const response = await this.updateClip({ ...payload, clipUuid: uuid });
      this.setUpdatedClip(response);
      this.setSuccessMessage("Region updated successfully");
    },
    async updateCurrentClipTimes(times) {
      this.isStartEditMode = true;
      const { start_time, end_time } = times;

      const { uuid, video } = this.currentClip;
      const payload = {
        uuid,
        start_time,
        end_time,
        video: video || this.video.uuid,
        folderUuid: this.$route.params.folderUuid,
      };

      let response = null;
      if (uuid) {
        if (this.highlightedRegion) {
          this.highlightedRegionTime = times;
          this.showCreateOrUpdateClipDialogDialog(true);
        } else {
          response = await this.handleUpdateClip(payload, uuid);
          this.currentClip.start_time = start_time;
          this.currentClip.end_time = end_time;

          this.setTimeRange(start_time, end_time);

          this.highlightedRegion = null;
        }
      } else {
        response = await this.handleCreateClip(payload);
        this.currentClip.start_time = start_time;
        this.currentClip.end_time = end_time;

        this.setTimeRange(start_time, end_time);

        this.highlightedRegion = null;
      }

      return response;
    },
    updateClipFitColor(data) {
      this.updateCurrentClip(data).then(() => {
        const coloredLayer1 = document.getElementById("fake-colored-layer-1");
        const coloredLayer2 = document.getElementById("fake-colored-layer-2");
        coloredLayer1.style.backgroundColor = data.fit_color;
        coloredLayer2.style.backgroundColor = data.fit_color;
      });
    },
    async updateCurrentClip(data) {
      this.isStartEditMode = true;

      const { uuid, video, start_time, end_time } = this.currentClip;
      const payload = {
        uuid,
        video: video || this.video.uuid,
        start_time,
        end_time,
        ...data,
      };

      const response = await this.updateClip({ ...payload, clipUuid: uuid });
      this.setUpdatedClip(response);
      return response;
    },
    setUpdatedClip(updatedClip) {
      this.setTimeRange(updatedClip.start_time, updatedClip.end_time);
      const updatedClips = [...this.clips.results];
      const clipIndex = this.clips.results.findIndex(
        (clip) => clip.uuid == updatedClip.uuid
      );
      updatedClips[clipIndex] = { ...updatedClip };
      this.clips.results = [...updatedClips];
      this.currentClip = updatedClip;
      this.playedClip = this.viewedClips.findIndex(
        (clip) => clip.uuid == updatedClip.uuid
      );
    },
    goTo(url) {
      this.$router.replace(url).catch(() => {});
    },
    changeSelectedClip(index) {
      console.log("change selected clip");
      this.openClipPage(index);
      this.setVideoTimeRange(index);
    },
    openClipPage(index) {
      this.currentClip = this.viewedClips[index];
      this.contentName = this.currentClip.name;

      const { uuid: clipUuid, isNewClip } = this.currentClip;
      const { uuid: videoUuid, folderUuid } = this.$route.params;
      if (isNewClip)
        this.goTo(
          folderUuid
            ? `/new-clip/${folderUuid}/${videoUuid}`
            : `/new-clip/${videoUuid}`
        );
      else if (folderUuid)
        this.goTo(
          clipUuid
            ? `/video-edit/${folderUuid}/${videoUuid}/clip/${clipUuid}/${this.getTabName()}`
            : `/video-edit/${folderUuid}/${videoUuid}/${this.getTabName()}`
        );
      else if (this.$route.path.includes("demo"))
        this.goTo(
          clipUuid
            ? `/demo-edit/${videoUuid}/clip/${clipUuid}/${this.getTabName()}`
            : `/demo-edit/${videoUuid}/${this.getTabName()}`
        );
      else
        this.goTo(
          clipUuid
            ? `/video-edit/${videoUuid}/clip/${clipUuid}/${this.getTabName()}`
            : `/video-edit/${videoUuid}/${this.getTabName()}`
        );
    },
    getTabName() {
      const name = this.$route.name;
      if (name.includes("Subtitles")) {
        return "subtitles";
      } else if (name.includes("Text")) {
        return "text";
      } else if (name.includes("Images")) {
        return "images";
      } else if (name.includes("templates")) {
        return "subtitles";
      } else if (name.includes("Elements")) {
        return "elements";
      } else return "subtitles";
    },
    setVideoTimeRange(index) {
      this.playedClip = index;
      this.currentClip = this.viewedClips[index];
      this.contentName = this.currentClip.name;
      const { uuid, start_time, end_time } = this.currentClip;
      this.setTimeRange(start_time, end_time);

      this.fetchExportedClipAction(uuid);
    },
    setTimeRange(start_time, end_time) {
      this.timeRange = {
        startTime: start_time,
        endTime: end_time,
      };
      // this.currentTime = start_time;
      this.viewedTimeRange = this.timeRange;
    },
    setViewedTimeRange(start_time, end_time) {
      this.viewedTimeRange = {
        startTime: start_time,
        endTime: end_time,
      };
    },
    getTargetVideo() {
      const { uuid: videoUuid } = this.$route.params;

      return new Promise((resolve) => {
        Promise.all([
          this.fetchClips({ uuid: videoUuid }),
          this.fetchVideo({ uuid: videoUuid }),
        ]).then((values) => {
          const [clips, video] = values;
          this.video = video;
          this.clips = clips;
          resolve();
        });
      });
    },
    setVideoDownloadLinks() {
      this.videoLink = this.video.video_file;
      this.audioLink = this.video.audio_file;
      this.videoName = this.video.name;
    },
    async sendClipData() {
      let response;
      const { uuid, name, start_time, end_time, video } = this.currentClip;
      const payload = {
        name,
        start_time,
        end_time,
        video,
      };
      if (uuid) {
        response = await this.updateClip({
          ...payload,
          clipUuid: uuid,
        });
        this.setUpdatedClip(response);
      } else {
        response = await this.createClip(payload);
        this.clips.results = [response, ...this.clips.results];
      }

      return response;
    },
    getBaseUrl() {
      if (
        !this.$route.matched.filter(({ name }) => name === this.$route.name)[0]
          .parent
      )
        this.baseUrl = this.$route.path.replace(/\/+$/, "");
      else {
        const { path } = this.$route;
        this.baseUrl = path
          .substr(0, path.lastIndexOf("/"))
          .replace(/\/+$/, "");
      }
    },
    onClickLogo() {
      if (this.isStartEditMode) this.saveAllChanges();

      this.goTo("/");
    },
    async saveAllChanges() {
      if (this.isStartEditMode) {
        const res = await this.sendClipData();

        const { uuid } = this.currentClip;
        const { folderUuid } = this.$route.params;

        let payload = {
          content: res,
          folderUuid: folderUuid || null,
          videoUuid: this.video.uuid,
          clipUuid: uuid || null,
        };

        this.updateClipContent(payload);
        this.isStartEditMode = false;
      }
    },
    updateClipContent(payload) {
      const updatedClips = [...this.clips.results];
      const { content, clipUuid } = payload;

      if (clipUuid) {
        const oldClipIndex = this.viewedClips.findIndex(
          (clip) => clip.uuid == clipUuid
        );
        updatedClips[oldClipIndex] = { ...content };
      } else {
        updatedClips.unshift({ ...content });
      }

      this.clips.results = [...updatedClips];
    },
    addSearchKeyword() {
      this.setSearchKeywords({
        uuid: this.video.uuid,
        word: this.searchKeyword,
      });

      const searchKeywordIndex = this.searchKeywords.findIndex(
        (word) => word.toUpperCase() == this.searchKeyword.toUpperCase()
      );

      this.selectedSearchWordIndex = searchKeywordIndex;

      if (searchKeywordIndex === -1) {
        this.selectedSearchWordIndex = this.searchKeywords.length - 1;
      }

      this.searchKeyword = "";
      this.getTheWordPositions(
        this.searchKeywords[this.selectedSearchWordIndex]
      );
    },
    selectKeyword(index) {
      this.selectedSearchWordIndex = index;
      this.getTheWordPositions(this.searchKeywords[index]);
    },
    getTheWordPositions(word) {
      const words =
        this.videoSubtitle?.filter((subtitle) =>
          subtitle.subtitle.toUpperCase().includes(word.toUpperCase())
        ) || [];

      this.wordPositions = words;
    },
    computeLeft(startTime) {
      return (startTime / this.videoTime) * 100;
    },
    computeWidth(startTime, endTime) {
      const startPrcentage = (startTime / this.videoTime) * 100;
      const endPrcentage = (endTime / this.videoTime) * 100;
      return endPrcentage - startPrcentage;
    },
    seekToTheStart(startTime) {
      if (this.$refs["videoPlayerRef"])
        this.$refs["videoPlayerRef"].seekToTime(startTime);
    },
    highlightSpeakerRegion(start, end, color) {
      this.highlightedRegion = {
        id: "highlightSpeakerRegion" + start,
        start,
        end,
        color,
      };
      this.seekToTheStart(start);
      this.$store.commit("video/setZoom", 0);
    },
    highlightSearchWordRegion(startTime, endTime) {
      const diffSeconds = 2;
      const start =
        startTime - diffSeconds < 0 ? startTime : startTime - diffSeconds;
      const end =
        endTime + diffSeconds > this.video.duration
          ? this.video.duration
          : endTime + diffSeconds;

      this.highlightedRegion = {
        id: "highlightSearchWordRegion" + start,
        start,
        end,
        color: "#FFE1E1",
      };
      console.log("setting seek value");
      this.seekToTheStart(start);
    },
    showDownloadDialogVisible(flag) {
      this.isDownloadDialogVisible = flag;
    },
    async fetchExportedClipAction(uuid) {
      const { clipUuid } = this.$route.params;

      if (clipUuid || uuid) {
        try {
          const payload = { clipUuid: uuid || clipUuid };
          const response = await this.fetchExportedClip(payload);

          this.setClipDownloadLinks(
            response?.results[0] || {},
            this.viewedClips[this.playedClip].name
          );
        } catch (err) {
          // handled in store
        }
      }
    },
    onFocus() {
      this.$v.contentName.$touch();
    },
    onBlur: async function () {
      if (this.currentClip.name === this.contentName) {
        this.isEditMode = !this.isEditMode;
        return;
      }

      const videoUuid = this.$route.params.uuid;
      const clipUuid = this.currentClip.uuid;

      this.$v.contentName.$touch();
      if (!this.$v.contentName.$invalid) {
        this.isEditMode = !this.isEditMode;
        this.currentClip.name = this.contentName;
        try {
          let payload;

          // Update Clip Name
          payload = {
            name: this.contentName,
            video: videoUuid,
            start_time: this.currentClip.start_time,
            end_time: this.currentClip.end_time,
          };
          const response = await this.updateClip({ ...payload, clipUuid });
          this.setUpdatedClip(response);
        } catch (error) {
          // handled in store
        }
      }
    },
    setClipDownloadLinks(clip, clipName) {
      this.videoName = clipName;
      this.videoLink = clip.clip_file;
      this.audioLink = clip.clip_audio_file;
      this.exportedClip = clip;
    },
    downloadFileAfterExporting() {
      const downloadDialogRef = this.$refs["downloadDialogRef"];
      if (downloadDialogRef) {
        const extension = downloadDialogRef.exportingExtension;
        if (extension) {
          this.getchCurrentVideo();
          const link = extension == "mp4" ? this.videoLink : this.audioLink;
          downloadDialogRef.downloadFile(link, extension);
        }
      }
    },
    getchCurrentVideo() {
      const { uuid: videoUuid } = this.$route.params;

      this.fetchClips({
        uuid: videoUuid,
        noLoading: true,
      }).then((clips) => {
        this.clips.results = [...clips.results];
      });
    },
    fetchCurrentClip() {
      const { clipUuid } = this.$route.params;
      this.fetchClip(clipUuid).then((clip) => {
        this.setSameTimeRange(true);
        this.setUpdatedClip(clip);
      });
    },
    setSameTimeRange(value) {
      this.sameCurrentTime = value;
    },
    async fetchMoreClips(isVisible) {
      if (!isVisible) return;
      if (this.clips.next) {
        const { uuid: videoUuid } = this.$route.params;
        const page = this.clips.next.split("page=")[1].split("&video")[0];
        this.isLoading = true;

        return new Promise((resolve) =>
          this.fetchClips({ uuid: videoUuid, page }).then((values) => {
            const clips = values;

            if (!Object.keys(this.clips).length) this.clips = clips;
            else if (clips.next !== this.clips.next) {
              const { results, ...rest } = clips;
              this.clips = {
                ...rest,
                results: [...this.clips.results, ...results],
              };
            }
            this.isLoading = false;
            resolve();
          })
        );
      }
    },

    resetZoom() {
      this.$store.commit("video/setZoom", 0);
    },

    async creatClipFromText(firstIndex, lastIndex) {
      const { uuid, video } = this.currentClip;
      const payload = {
        uuid,
        start_time: this.subtitleWords[firstIndex].startTime,
        end_time: this.subtitleWords[lastIndex].endTime,
        video: video || this.video.uuid,
        folderUuid: this.$route.params.folderUuid,
      };
      try {
        await this.handleCreateClip(payload);
        this.$store.commit("video/setHasSelectedText", false);
      } catch (err) {
        this.$store.commit("video/setHasSelectedText", false);
      }
    },
  },
  validations: {
    contentName: {
      required,
      minLength: minLength(clipNameMinLength),
    },
  },
  async mounted() {
    const { clipUuid } = this.$route.params;
    if (clipUuid) {
      this.fetchClip(clipUuid);
    }
    this.getTargetVideo().then(async() => {
      this.getBaseUrl();
      const { name } = this.$route;
      const { uuid: videoUuid, clipUuid } = this.$route.params;
      this.fetchVideoWaveform(videoUuid);
      this.fetchVideoSpeakersAction(videoUuid);
      await this.fetchSubtitlesForVideo(videoUuid);
      this.isLoadingDemo = false
      this.fetchFonts();

      if (clipUuid) {
        const clipIndex = this.viewedClips.findIndex(
          (clip) => clip.uuid == clipUuid
        );
        this.setVideoTimeRange(clipIndex);
      } else {
        this.setTimeRange(0, this.video.duration);
      }
      if (
        name === "NewClipForVideo" ||
        name === "NewClipForVideoInsideFolder"
      ) {
        const nonACGClipsLength = this.viewedClips.filter(
          (clip) => !clip.is_acg
        ).length;

        const newClip = {
          name: `clip ${nonACGClipsLength + 1}`,
          end_time: this.video.duration,
          start_time: 0,
          uuid: Date.now(),
          video: this.video.uuid,
          isNewClip: true,
        };
        this.video = { ...this.video, clips: [...this.viewedClips, newClip] };
        this.currentClip = newClip;
        this.contentName = newClip.name;
        this.setTimeRange(0, this.video.duration);
      }
    });

    document.getElementById("myAudio").addEventListener("wheel", (e) => {
      if (e.deltaY > 0) {
        e.preventDefault();
        this.zoom <= 20
          ? this.$store.commit("video/setZoom", this.zoom + 1)
          : this.$store.commit("video/setZoom", this.zoom);
      }
      if (e.deltaY < 0) {
        e.preventDefault();
        this.zoom > 0
          ? this.$store.commit("video/setZoom", this.zoom - 1)
          : this.$store.commit("video/setZoom", this.zoom);
      }
    });
  },
  updated() {
    this.getBaseUrl();
  },
  // eslint-disable-next-line no-unused-vars
  async beforeRouteUpdate(to, from, next) {
    await this.saveAllChanges();
    next();
  },
  // eslint-disable-next-line no-unused-vars
  async beforeRouteLeave(to, from, next) {
    await this.saveAllChanges();
    next();
  },
  destroyed() {
    this.setClipStyle({});
    this.setSelectedTitleUuid("");
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/views/video-edit.scss";
.centered-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 800;
}
#region-start {
  position: absolute;
  font-size: 12px;
}
.loader-wrapper {
  position: absolute;
  inset: 0;
  pointer-events: none;
}
.video-edit-wrapper__waveform__audio {
  /* scrollbar */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #fbd458;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgb(255, 193, 69);
    -webkit-box-shadow: inset 0 0 6px #fbd458;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: #fbd458;
  }
}
</style>
