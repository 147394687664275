<template>
    <div style="margin-top: 15%;">
      <v-carousel
    cycle
    height="350"
    :show-arrows="false"
    hide-delimiters
  >
    <v-carousel-item
      v-for="(t, i) in testimonials"
      :key="i"
      
    >
        <div class="kl-testimonial" style="margin: 15% 20% auto 20%;">
            <div class="kl-testimonial__footer">
                <img :src="require(`@/assets/images/testimonials/${t.name}.png`)" alt="">
          
          <div class="kl-testimonial__author">
            <b>{{t.name}}</b>
            <span>{{t.title}}</span>
          </div>
        </div>
          <p class="kl-testimonial__content">"{{t.testimonial}}"</p>
        </div>
    </v-carousel-item>
      </v-carousel>
      <div class="d-flex justify-center align-center flex-column">
        <p style="font-size: 16px;
            line-height: 24px;
            letter-spacing: .015em;
            color: #05294b;
            font-weight: 800;">
        Trusted by:
        </p>
        <div>
            <img src="@/assets/images/logos.png" style="height: 136px; margin-left: 10px;" alt="logo">
        </div>
      </div>
    </div>
</template>

<script>
import { testimonials } from '../utils/testimonials';

export default {
    name:'Testimonials',
    data(){
        return{
            testimonials:testimonials
        }
    }
}
</script>

<style>
.kl-testimonials {
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    background: black;
}

.kl-testimonial {
    border: .5px solid hsla(46,92%,76%,.2);
    border-radius: 9px;
    padding: 18px 25px 25px;
    margin-bottom: 20px;
}

.kl-testimonial__quote {
    margin: 0 0 25px
}

.kl-testimonial__quote svg {
    fill: #282829;
    height: 32px;
    max-height: 32px
}

.kl-testimonial__content {
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: .005em;
    color: #5d7284;
}

.kl-testimonial__footer {
    align-items: center;
    display: flex;
    justify-content: center;
}

.kl-testimonial__footer img {
    border-radius: 50%;
    display: block;
    height: 52px;
    margin: 0 16px 0 0;
    object-fit: cover;
    width: 52px
}

.kl-testimonial__author {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #05294b;
}

.kl-testimonial__author span {
    margin-top: 2px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: .005em;
    color: #05294b;
    display: block;
    font-family: Inter,sans-serif;
    /* font-size: 14px;
    font-size: .75rem; */
    font-weight: 700;
    margin: 0 0 8px
}


.kl-testimonials::-webkit-scrollbar {
    width: 0;
}
.kl-testimonials::-webkit-scrollbar-thumb {
    background-color: transparent;
}

</style>
