import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import appModule from "./modules/app";
import userModule from "./modules/user";
import projectModule from "./modules/project";
import progressModule from "./modules/progress/index"
import videoModule from "./modules/video/index";
import downloadModule from "./modules/download";
import autoCropModule from "./modules/autocrop";
import chatWithVideo from "./modules/chatWithVideo";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  reducer:state=>({
    user:state.user,
    download:state.download,
    progress:state.progress,
  })
});

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: {
    app: appModule,
    user: userModule,
    project: projectModule,
    video: videoModule,
    download: downloadModule,
    progress:progressModule,
    autoCrop:autoCropModule,
    chatWithVideo:chatWithVideo
  },
});
