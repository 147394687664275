<template>
  <div class="image-wrapper">
    <div class="image-wrapper__add-image">
      <input
        id="image-wrapper__add-image__input"
        class="image-wrapper__add-image__input"
        type="file"
        accept=".png, .jpg, .gif, .tif"
        @change="selectFile"
      />
      <div class="image-wrapper__add-image__title d-flex justify-space-between">
        <div class="d-flex">
          <img
            src="@/assets/images/icons/image-vector.svg"
            alt="image-vector"
          />
          <p class="mb-0">Add image</p>
        </div>
        <div v-if="imageLayers.length">
          <p
            class="mb-0 image-wrapper__add-image__browse"
            @click="openFileInput"
          >
            Browse
          </p>
        </div>
      </div>
      <v-row>
          <v-col
            v-for="image in viewedImageLayers"
            :key="image.uuid"
            lg="4"
            md="6"
            cols="12"
            class="image-wrapper__add-image__container pb-0"
          >
            <img
              class="image-wrapper__add-image__container__image"
              :src="image.image"
              :alt="image.uuid"
            />
          </v-col>
        </v-row>
      <div 
        :class="{draggingUploadedFile: isDragging, 'dropzone':imageLayers.length}"
        @dragover.stop.prevent="handleDragOver"
        @drop.stop.prevent="handleDroppedFile"
        @dragleave.stop.prevent="handleDragLeave">
        <div
          class="image-wrapper__add-image d-flex align-center flex-column border"
        >
          <img
            src="@/assets/images/icons/image-vector.svg"
            alt="image-vector"
            width="29.33"
            height="24"
          />
          <p class="mb-0 image-wrapper__add-image__drag-text">
            Drag and drop images here
          </p>
          <p class="image-wrapper__add-image__supported-formats">
            We support JPG, PNG, TIF, and GIF image formats.
          </p>
          <p
            class="mb-0 image-wrapper__add-image__browse"
            @click="openFileInput"
          >
            Browse
          </p>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import types from "../../store/types";

export default {
  props: {
    createNewClip: Function,
    video: Object,
    fetchCurrentClip: Function,
  },
  data() {
    return {
      isDragging:false
    };
  },
  watch: {
    selectedImageLayerUuid() {
      // this.setValues();
    },
  },
  computed: {
    ...mapState({
      selectedImageLayerUuid: (state) => state.project.selectedImageLayerUuid,
      imageLayers: (state) => state.project.imageLayers,
      clip: (state) => state.project.clip,
      showAutoCrop: (state) => state.autoCrop.showAutoCrop
    }),
    selectedImage: function () {
      return this.imageLayers.find(
        (image) => image.uuid == this.selectedImageLayerUuid
      );
    },
    viewedImageLayers() {
      return this.imageLayers.filter(
        (image) => image.clip == this.$route.params.clipUuid
      );
    },
  },
  methods: {
    ...mapActions({
      createImageLayerAction: types.project.actions.CREATE_IMAGE_LAYER,
    }),
    ...mapMutations({
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE,
    }),
    openFileInput() {
      document.getElementById("image-wrapper__add-image__input").click();
    },
    selectFile(event) {
      const file = event.target?.files[0];
      if (file) {
        this.createImageLayer(file);
      }
    },
    handleDragOver() {
      this.isDragging = true;
    },
    handleDroppedFile(e) {
      const file = e.dataTransfer.files[0];
      const fileType = file.type.toLowerCase();

      if (
        fileType == "image/jpeg" ||
        fileType == "image/png" ||
        fileType == "image/gif" ||
        fileType == "image/tif"
      ) {
        this.createImageLayer(file);
      }
      this.isDragging = false;
    },
    async createImageLayer(file) {
      let clipUuid = this.$route.params.clipUuid;

      if (!clipUuid) {
        const clip = await this.createNewClip({
          start_time: 0,
          end_time: this.video.duration,
        });
        clipUuid = clip.uuid;
      }

      this.getImageDimensions(file).then(async ({ width, height }) => {
        const videoDim = document.getElementById(this.showAutoCrop?"fake-video-layer1":"fake-video-layer")
        await this.createImageLayerAction({
          clipUuid,
          image: file,
          width: Math.round((150/videoDim.clientWidth)*this.clip.width),
          height: Math.round(((height / width) * 150)/videoDim.clientHeight * this.clip.height),
        });
        this.fetchCurrentClip();
        this.setSuccessMessage("Image added successfully");
      });
    },
    getImageDimensions(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;

          image.onload = function () {
            let height = this.height;
            let width = this.width;
            resolve({ width, height });
          };
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/components/videos/images.scss";
.dropzone{
  margin-top: 5%;
}
.border {
  border:3px dashed gray;
  border-radius: 5px;
}
</style>
