<template>
  <div
    :class="[
      isVisibleInVideoEdit
        ? 'clip-slider__video-edit'
        : 'clip-slider__video-view',
      'd-flex clip-slider',
    ]"
  >
    <v-slide-group show-arrows>
      <v-slide-item v-for="(clip, i) in clips" :key="clip.uuid + Math.random()">
        <Clip
          :isPlayed="playedClip === i"
          :clip="clip"
          :clipIndex="i"
          :onClickAction="
            isVisibleInVideoEdit ? setVideoTimeRange : openClipPage
          "
        />
      </v-slide-item>
      <v-slide-item>
        <div
          :style="{ width: '1px' }"
          v-if="!isLoading"
          v-observe-visibility="{
            callback: fetchMoreClips,
            intersection: {
              threshold: 0.05,
            },
          }"
        />
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import Clip from "./Clip.vue";

export default {
  components: { Clip },
  props: [
    "clips",
    "openClipPage",
    "setVideoTimeRange",
    "isVisibleInVideoEdit",
    "playedClip",
    "isLoading",
    "fetchMoreClips",
  ],
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";
.clip-slider {
  &__video-edit {
    padding: 20px 10px 0px;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 24px;
  }
  &__video-view {
    margin-top: 60px;
  }
}
</style>
