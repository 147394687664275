<template>
  <v-row justify="center">
    <v-dialog
      v-model="isAfterExportDialogShown"
      min-width="300"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon @click="showAfterExportDialogVisible(false)">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="after-export-wrapper pl-0 pr-0 pb-0">
          <v-container fluid>
            <v-row>
              <v-col
                cols="12"
                class="d-flex flex-column justify-center align-center pa-0"
              >
                <img
                  src="@/assets/images/icons/editLogo.svg"
                  alt="logo"
                  width="117"
                  height="117"
                />

                <p class="after-export-wrapper__title mb-0">
                  Clip is being exported, and will be available to download
                  shortly
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["isAfterExportDialogVisible", "showAfterExportDialogVisible"],
  computed: {
    isAfterExportDialogShown: {
      get: function () {
        return this.isAfterExportDialogVisible;
      },
      set: function () {
        return this.showAfterExportDialogVisible(false);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/videos/after-export-dialog.scss";
</style>
