<template>
  <v-card>
    <v-card-title class="justify-center">
      <div class="d-flex justify-space-between align-baseline">
        <p class="text-center font-weight-bold">Advanced Settings</p>
      </div>
      <v-icon class="close-icon" @click="close">mdi-close</v-icon>
    </v-card-title>
    <v-card-text class="download-dialog-wrapper px-0 pt-0">
      <v-container fluid>
        <v-list lines="two">
          <!-- <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="font-weight-bold"
                v-text="'Enable/Disable Emojis'"
              ></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="emojiSettings" hide-details inset> </v-switch>
            </v-list-item-action>
          </v-list-item>
          <v-spacer></v-spacer>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="font-weight-bold"
                v-text="'Enable/Disable Caption Highlight'"
              ></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="subtitlesStyling" hide-details inset>
              </v-switch>
            </v-list-item-action>
          </v-list-item> -->
          <v-list-item v-if="subtitlesStyling" class="pr-0">
            <v-list-item-content>
              <v-list-item-title
                v-text="'Caption highlighting'"
              ></v-list-item-title>
            </v-list-item-content>
            <v-switch
                  v-model="subtitlesStyles.caption_highlight"
                  color="success"
                  hide-details
                ></v-switch>
          </v-list-item>
          <v-list-item v-if="subtitlesStyling">
            <v-list-item-content>
              <v-list-item-title
                v-text="'Highlight Color'"
              ></v-list-item-title>
            </v-list-item-content>
            <v-menu
              v-model="highlightColorPicker"
              :close-on-content-click="false"
              location="bottom"
            >
              <template v-slot:activator="{ props }">
                <div
                  style="position: relative"
                  v-bind="props"
                  @click="openHighlightColorPicker"
                >
                  <button class="icon-btn">
                    H
                    <span class="color-bar" :style="{backgroundColor:subtitlesStyles.highlight_color?subtitlesStyles.highlight_color:'yellow', border:subtitlesStyles.color ==='#FFFFFF'?'1px solid':'' }"></span>
                  </button>
                  <v-icon class="down-icon">mdi-chevron-down</v-icon>
                </div>
              </template>
              <v-card min-width="500">
                <ColorPicker :colors="highlightColors" :color="subtitlesStyles.color" @onSelectColor="onSelectHighlightColor"/>
              </v-card>
            </v-menu>
          </v-list-item>
          <v-list-item v-if="subtitlesStyling">
            <v-list-item-content>
              <v-list-item-title
                v-text="'Select Text Color'"
              ></v-list-item-title>
            </v-list-item-content>
            <v-menu
              v-model="textColorPicker"
              :close-on-content-click="false"
              location="bottom"
            >
              <template v-slot:activator="{ props }">
                <div
                  style="position: relative"
                  v-bind="props"
                  @click="openTextColorPicker"
                >
                  <button class="icon-btn">
                    A
                    <span class="color-bar" :style="{backgroundColor:subtitlesStyles.color?subtitlesStyles.color:'red', border:subtitlesStyles.color ==='#FFFFFF'?'1px solid':'' }"></span>
                  </button>
                  <v-icon class="down-icon">mdi-chevron-down</v-icon>
                </div>
              </template>
              <v-card min-width="500">
                <ColorPicker :colors="textColors" :color="subtitlesStyles.color" @onSelectColor="onSelectTextColor"/>
              </v-card>
            </v-menu>
          </v-list-item>
          <v-list-item v-if="subtitlesStyling">
            <v-list-item-content>
              <v-list-item-title
                v-text="'Select Text Background Color'"
              ></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="margin-bottom: 0">
              <v-menu
                v-model="backgrounColorPicker"
                :close-on-content-click="false"
                location="bottom"
                style="overflow-y: hidden !important;"
              >
                <template v-slot:activator="{ props }">
                  <span
                    style="width: 24px; height: 24px"
                    @click="openBackgroudColorPicker"
                  >
                    <Icon
                      iconName="cc-text-background"
                      style="margin-right: 6px"
                      v-bind="props"
                    ></Icon>
                    <span class="bg-color-bar" :style="{backgroundColor:subtitlesStyles.background_color?subtitlesStyles.background_color:'red'}"></span>
                    <v-icon class="down-icon-b">mdi-chevron-down</v-icon>
                  </span>
                </template>
                <v-card min-width="500">
                    <ColorPicker :colors="backgroundColors" :color="subtitlesStyles.background_color" @onSelectColor="onSelectBackgroundColor" />
                </v-card>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="subtitlesStyling">
            <v-list-item-content>
              <v-list-item-title
                v-text="'Select Text Style'"
              ></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="text-style">
              <div class="subtitles-wrapper__format--alignment"
                @click="subtitlesStyles.text_style_bold=!subtitlesStyles.text_style_bold">
                <v-icon :class="[{'selected':subtitlesStyles.text_style_bold}]" color="black">mdi-format-bold</v-icon>
              </div>
              <div class="subtitles-wrapper__format--alignment" 
                @click="subtitlesStyles.text_style_italic=!subtitlesStyles.text_style_italic">
                <v-icon :class="[{'selected':subtitlesStyles.text_style_italic}]" color="black">mdi-format-italic</v-icon>
              </div>
              <div class="subtitles-wrapper__format--alignment"
                @click="subtitlesStyles.text_style_underline=!subtitlesStyles.text_style_underline">
                <v-icon :class="[{'selected':subtitlesStyles.text_style_underline}]" color="black">mdi-format-underline</v-icon>
              </div>
            </v-list-item-action>
          </v-list-item>
              <div class="d-flex align-center">
                <v-list-item-content class="pl-4">
                  <v-list-item-title
                    v-text="'Text Border'"
                  ></v-list-item-title>
                </v-list-item-content>
                <div style="width: 25%;">
                <v-slider
                  class="stroke-slider"
                  v-model="subtitlesStyles.outline"
                  :max="4"
                  :min="0"
                  thumb-label
                  color="#7367f0"
                  height="10px"
                  hide-details
                ></v-slider>
              </div>
              </div>
        </v-list>
      </v-container>
    </v-card-text>
    <div class="d-flex justify-center">
      <button class="subtitles-wrapper__button" @click="onSave">Save</button>
    </div>
  </v-card>
</template>

<script lang="js">
import { mapActions, mapState} from "vuex"
import ColorPicker from "../../shared/ColorPicker"
import types from "../../store/types";
export default {
    components:{ ColorPicker },
    props: {
        fetchCurrentClip: Function
    },
    data() {
        return {
            folderName: "",
            subtitlesStyling: true,
            emojiSettings: false,
            textColorPicker: false,
            backgrounColorPicker: false,
            highlightColorPicker:false,
            subtitlesStyles: {
                color: "",
                background_color: "",
                text_style_bold: false,
                text_style_italic: false,
                text_style_underline: false,
                caption_highlight:false,
                highlight_color:"",
                outline:0
            },
            textColors: [
            "#7367F0",
            "#F06767",
            "#FAC94B",
            "#45C7FF",
            "#323232",
            "#FFFFFF",
            "#C1D5E2",
            "#CE9FFC",
            "#60A88D",
          ],
          highlightColors: [
            "#7367F0",
            "#F06767",
            "#FFFF00",
            "#45C7FF",
            "#323232",
            "#FFFFFF",
            "#C1D5E2",
            "#CE9FFC",
            "#60A88D",
          ],
          backgroundColors: [
            "#FFFFFF00",
            "#F06767",
            "#FAC94B",
            "#45C7FF",
            "#323232",
            "#FFFFFF",
            "#C1D5E2",
            "#CE9FFC",
            "#60A88D",
          ],
        };
    },
    computed: {
        ...mapState({
            clipStyle: (state) => state.project.clipStyle,
        })
    },
    methods: {
        ...mapActions({
            updateClipStyle: types.project.actions.UPDATE_CLIP_STYLE
        }),
        openTextColorPicker() {
            this.textColorPicker = true;
        },
        openHighlightColorPicker() {
            this.highlightColorPicker = true;
        },
        onSelectBackgroundColor(color) {
          this.subtitlesStyles.background_color = color;
          this.backgrounColorPicker = false;
        },
        onSelectTextColor(color) {
          this.subtitlesStyles.color = color;
          this.textColorPicker = false;
        },
        onSelectHighlightColor(color) {
          this.subtitlesStyles.highlight_color = color;
          this.highlightColorPicker = false;
        },
        openBackgroudColorPicker() {
            this.backgrounColorPicker = true;
        },
        onSelectTextStyle(style) {
            this.subtitlesStyles.textStyle = style;
        },
        
        close() {
            this.$emit("close");
        },
        async onSave() {
            const { clipUuid } = this.$route.params;
            await this.updateClipStyle({
                clipUuid: clipUuid,
                ...this.subtitlesStyles,
                color: this.subtitlesStyles.color,
                background_color: this.subtitlesStyles.background_color,
            });
            this.fetchCurrentClip();
            this.$emit("close");
        },
        setStylesValue() {
            this.subtitlesStyles.color = this.clipStyle.color;
            this.subtitlesStyles.background_color = this.clipStyle.background_color;
            this.subtitlesStyles.highlight_color = this.clipStyle.highlight_color;
            this.subtitlesStyles.text_style_bold = this.clipStyle.text_style_bold ? this.clipStyle.text_style_bold : false;
            this.subtitlesStyles.text_style_italic = this.clipStyle.text_style_italic ? this.clipStyle.text_style_italic : false;
            this.subtitlesStyles.text_style_underline = this.clipStyle.text_style_underline ? this.clipStyle.text_style_underline : false;
            this.subtitlesStyles.caption_highlight = this.clipStyle.caption_highlight ? this.clipStyle.caption_highlight : false;
            this.subtitlesStyles.outline = this.clipStyle.outline>0 ? this.clipStyle.outline : 0;
        },
        onChangeBorder(e){
          e === false ?this.subtitlesStyles.outline = 0:this.subtitlesStyles.outline = 2
        }
    },
    mounted() {
        this.setStylesValue();
    },
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/components/videos/subtitles.scss";

.icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #000;
  padding: 12px;
  position: relative;
  font-weight: 600;
}

.color-bar {
  position: absolute;
  bottom: 10px;
  left: 9px;
  height: 4px;
  width: 15px;
  background-color: #ff0000;
}
.bg-color-bar {
  position: absolute;
  bottom: 10px;
  right: 24px;
  height: 4px;
  width: 16px;
  background-color: #ff0000;
  top: 38px;
}

.down-icon {
  position: absolute;
  left: 25px;
  top: 18px;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.down-icon-b {
  position: absolute;
  top: 13px;
  right: 2px;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.text-style {
  flex-direction: row !important;
}
.selected {
  background-color: #d3d3d3;
}
.close-icon{
  position: absolute;
  top: 22px;
  right: 18px;
  cursor: pointer;
}
</style>
