<template>
  <div class="custom-wrapper">
    <p class="custom-wrapper__header">Custom</p>
    <p class="custom-wrapper__title">Let's talk</p>
    <p class="custom-wrapper__subtitle">
      Perfect for agencies, publishers, and enterprise content teams.
    </p>
    <div class="custom-wrapper__image">
      <img src="@/assets/images/icons/editLogo.svg" alt="logo" />
    </div>
    <v-btn
      class="custom-wrapper__cta"
      text
      :ripple="false"
      width="194"
      height="42"
      outlined
      @click="bookMeeting"
    >
      <span class="font-weight-normal text-none">Book a meeting</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["type"],
  methods: {
    bookMeeting() {
      window.open('https://www.chopcast.io/book-a-meeting', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/colors.scss";

.custom-wrapper {
  position: relative;
  width: 226px;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  background-color: $google-button-background;
  padding: 18px;
  &__header {
    font-size: 16px;
    position: relative;
    margin-bottom: 0;
    font-weight: 700;
    font-family: "Satoshi Bold";
  }
  &__title {
    position: relative;
    font-size: 36px;
    margin-bottom: 0;
    font-weight: bold;
  }
  &__subtitle {
    position: relative;
    font-size: 13px;
    font-weight: 700;
    font-family: "Satoshi Bold";
  }
  &__image {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100px;
    }
  }
  &__cta {
    position: absolute;
    bottom: 30px;
    left: 14px;
    font-size: 16px;
    background: $yellow;
    color: #2c2c2d;
    font-weight: 600;
    font-size: 16px;
  }
}
</style>
