import types from "../types";

const state = {
  isLoaderVisible: false,
  errorMessages: [],
  errorMessageTimeout: 5000,
  redirectPage: "",
  successMessage: "",
  successMessageTimeout: 5000,
  showButtonLoader: false,
  isDownloadDialogVisible:false,
  reloadEditorPage:false
};

const mutations = {
  [types.app.mutations.SET_IS_LOADER_VISIBLE]: (state, payload) => {
    state.isLoaderVisible = payload;
  },
  [types.app.mutations.SET_ERROR_MESSAGE]: (state, payload) => {
    state.errorMessages = [...Object.values(payload).flat()];
    setTimeout(() => {
      state.errorMessages = [];
    }, state.errorMessageTimeout);
  },
  [types.app.mutations.SET_REDIRECT_PAGE]: (state, page) => {
    state.redirectPage = page;
  },
  [types.app.mutations.SET_SUCCESS_MESSAGE]: (state, message) => {
    state.successMessage = message;
    setTimeout(() => {
      state.successMessage = "";
    }, state.successMessageTimeout);
  },
  [types.app.mutations.SET_SUCCESS_MESSAGE_TIME_OUT]: (state, message) => {
    state.successMessageTimeout = message;
  },
  [types.app.mutations.SET_BUTTON_LOADER]: (state, message) => {
    state.showButtonLoader = message;
  },
  [types.app.mutations.SET_DOWNLOAD_DIALOG_VISIBILITY]: (state, message) => {
    state.isDownloadDialogVisible = message;
  },
  [types.app.mutations.RELOAD_EDITOR_PAGE]: (state, message) => {
    state.reloadEditorPage = message;
  },
};

export default {
  state,
  mutations,
};
