<template>
  <div
    class="video-wrapper"
    :draggable="isHomePage"
    @dragend.prevent="setDraggedVideo"
  >
    <div class="video-wrapper__thumbnail">
      <v-chip
        class="video-wrapper__auto"
        color="#7367f0"
        label
        v-if="isClips && content.is_acg"
        >Auto</v-chip
      >
      <v-chip
        class="video-wrapper__auto"
        color="#7367f0"
        label
        v-if="!content.ready && !isClips"
        :style="{ right: 'unset', left: '16px', top: '16px' }"
        >Not ready</v-chip
      >
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
            class="video-wrapper__drop-down-menu"
          >
            <v-icon color="black">mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in viewedItems"
            :key="i"
            @click="handleClickAction(item.title)"
            :disabled="!isClips && !content.ready"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <img
        v-if="thumbnail && isClips"
        :src="thumbnail"
        class="video-wrapper__clip"
        alt="clip thumbnail"
      />
      <img
        v-else-if="content.thumbnail && !isClips"
        :src="content.thumbnail"
        alt="video thumbnail"
      />
      <img
        v-else-if="!content.ready && !isClips"
        src="@/assets/images/icons/Spin-1.3s-200px.svg"
        alt="video processing"
      />
      <img v-else src="@/assets/images/video-placeholder.png" alt="logo" />
      <v-chip class="video-wrapper__time" color="#fbd458" label>{{
        isClips
          ? secondsToTime(content.end_time - content.start_time)
          : secondsToTime(content.duration)
      }}</v-chip>
       <v-progress-circular
        v-if="!content.clips && content.deduct_credit && content.acg_status !== 'notrequested'"
        class="clip-progress"
        :size="50"
        color="amber"
        indeterminate
        >
      </v-progress-circular>
      
        <v-progress-circular
        v-if="content.deduct_credit && content.transcription_status !== 'success' && content.acg_status === 'notrequested'"
        class="clip-progress"
        :size="50"
        color="amber"
        indeterminate
        >
      </v-progress-circular>
      <div v-if="(content.ready && !isClips) || isClips">
        <div class="video-wrapper__overlay" v-if="!isClips">
          <div class="video-wrapper__overlay--view" @click="redirectTo('view')">
            <v-icon color="white">mdi-play-box-multiple</v-icon>
            <div class="subtitle-1">View</div>
          </div>
        </div>
        <div class="video-wrapper__overlay" v-else>
          <div class="video-wrapper__overlay--view" @click="redirectTo('view')">
            <v-icon color="white">mdi-play-box-multiple</v-icon>
            <div class="subtitle-1">View</div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="new-content-wrapper text-wrapper">
        <span
          :class="[
            'video-wrapper__video-name',
            { 'video-wrapper__video-name__clips': isClips },
          ]"
          @click="isEditMode = !isEditMode"
          v-if="!isEditMode"
          >{{ trimText(content.name) }}
          <v-icon size="24">mdi-pencil-outline</v-icon></span
        >
        <v-text-field
          v-else
          autofocus
          :label="`Type ${isClips ? 'Clip' : 'Video'} Name`"
          single-line
          outlined
          class="new-content-wrapper__edit-field"
          :error-messages="contentNameErrors"
          v-model.trim="contentName"
          @input="onFocus"
          @blur="onBlur"
          @keydown.enter="onBlur"
          @keydown.esc="isEditMode = !isEditMode"
          full-width
        ></v-text-field>
      </div>
      <v-icon
        v-if="!isClips"
        class="video-wrapper__video-icon ml-auto"
        size="20"
        color="#7367f0"
      >
        mdi-youtube-subscription
      </v-icon>
      <span class="video-wrapper__clips-count" v-if="!isClips && content.clips > 0">{{
        content.clips
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import types from "../../store/types";
import secondsToMinutes from "../../utils/SecondsToTime";
import trimString from "../../utils/TrimString";

const contentNameMinLength = 3;

export default {
  props: [
    "goToVideoClips",
    "goToViewVideo",
    "goToEditVideo",
    "content",
    "isClips",
    "thumbnail",
    "videoName",
    "clipsVideoUuid",
    "addClonnedClip",
  ],
  data: () => ({
    items: [{ title: "Edit" }, { title: "Delete" }],
    clipItems: [{ title: "Edit" }, { title: "Duplicate" }, { title: "Delete" }],
    isEditMode: false,
    contentName: null,
  }),
  computed: {
    ...mapState({
      draggedFolderUuid: (state) => state.project.draggedFolderUuid,
    }),
    contentNameErrors() {
      const errors = [];
      if (!this.$v.contentName.$dirty) return errors;
      !this.$v.contentName.required &&
        errors.push(`Type ${this.isClips ? "Clip" : "Video"} Name is required`);
      !this.$v.contentName.minLength &&
        errors.push(
          `${
            this.isClips ? "Clip" : "Video"
          } Name must contains at least ${contentNameMinLength} characters`
        );
      return errors;
    },
    viewedItems() {
      return this.isClips ? this.clipItems : this.items;
    },
    isHomePage() {
      return this.$route.name === "Home";
    },
  },
  methods: {
    ...mapActions({
      deleteClipAction: types.project.actions.DELETE_CLIP,
      deleteVideoAction: types.project.actions.DELETE_VIDEO,
      updateVideo: types.project.actions.UPDATE_VIDEO,
      updateClip: types.project.actions.UPDATE_CLIP,
      cloneClip: types.project.actions.CLONE_CLIP,
    }),
    ...mapMutations({
      setDraggedFolderUUid: types.project.mutations.SET_DRAGGED_FOLDER_UUID,
    }),
    handleClickAction(action) {
      if (action === "Edit") {
        this.goToEditVideo(
          this.isClips ? this.clipsVideoUuid : this.content.uuid,
          this.isClips ? this.content.uuid : null
        );
      } else if (action === "Delete") {
        this.handleDelete();
      } else if (action === "Duplicate") {
        this.cloneClip(this.content.uuid).then((response) => {
          this.addClonnedClip(response);
        });
      }
    },
    handleDelete() {
      if (this.isClips) {
        this.deleteClip();
      } else {
        this.deleteVideo();
      }
    },
    deleteClip() {
      this.deleteClipAction({
        uuid: this.content.uuid,
        videoUuid: this.content.video,
        folderUuid: this.$route.params.folderUuid,
      }).then(() => {
        this.$emit("deleted");
      });
    },
    deleteVideo() {
      this.deleteVideoAction({
        uuid: this.content.uuid,
        folderUuid: this.content.folder,
      }).then(() => {
        this.$emit("deleted");
      });
    },
    trimText(text) {
      return trimString(text, 60);
    },
    secondsToTime(seconds) {
      return secondsToMinutes(seconds);
    },
    redirectTo(destination, clipUuid) {
      if (destination === "clips") this.goToVideoClips(this.content.uuid);
      else if (destination === "view" && this.isClips)
        this.goToViewVideo(this.clipsVideoUuid, this.content.uuid);
      else if (destination === "view" && this.isVideoTranscripted())
        this.goToViewVideo(this.content.uuid);
      else if (destination === "view") this.goToVideoClips(this.content.uuid);
      else this.goToEditVideo(this.content.video, clipUuid);
    },
    isVideoTranscripted() {
      return (
        this.content.transcription_status == "success" ||
        this.content.transcription_status == "error"
      );
    },
    onFocus() {
      this.$v.contentName.$touch();
    },
    onBlur: async function () {
      if (this.content.name === this.contentName) {
        this.isEditMode = !this.isEditMode;
        return;
      }

      const folderUuid =
        this.$route.params[this.isClips ? "folderUuid" : "uuid"];
      const videoUuid = !this.isClips ? this.content.uuid : this.clipsVideoUuid;
      const clipUuid = this.isClips && this.content.uuid;

      this.$v.contentName.$touch();
      if (!this.$v.contentName.$invalid) {
        this.isEditMode = !this.isEditMode;
        this.content.name = this.contentName;
        try {
          let payload;
          if (this.isClips) {
            // Update Clip Name
            payload = {
              name: this.contentName,
              video: videoUuid,
              start_time: this.content.start_time,
              end_time: this.content.end_time,
            };
            await this.updateClip({ ...payload, clipUuid });
          } else {
            // Update Video Name
            payload = {
              content: {
                name: this.contentName,
                folder: folderUuid,
                uuid: videoUuid,
              },
              updateFolder: false,
            };
            await this.updateVideo(payload);
          }
        } catch (error) {
          // handled in store
        }
      }
    },
    setDraggedVideo() {
      if (this.isHomePage && this.draggedFolderUuid) {
        const payload = {
          content: {
            name: this.contentName,
            folder: this.draggedFolderUuid,
            uuid: this.content.uuid,
          },
          updateFolder: true,
        };
        this.updateVideo(payload);
        this.setDraggedFolderUUid("");
      }
    },
  },
  validations: {
    contentName: {
      required,
      minLength: minLength(contentNameMinLength),
    },
  },
  mounted() {
    this.contentName = this.content.name;
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/components/videos/video.scss";
.text-wrapper {
    max-width: calc(100% - 40px);
    flex: calc(100% - 40px);
}
.text-wrapper .v-icon {
    opacity: 0;
}
.text-wrapper span.video-wrapper__video-name{
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: inherit !important;
}


.text-wrapper span.video-wrapper__video-name:hover + .v-icon {
    opacity: 1;
}
.clip-progress {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50px;
  left: 43%;
}
</style>
