<template>
    <div>
        <v-card>
          <v-card-subtitle class="text-center">{{clip.audio_cleaned?'Audio has been enhanced &#128522;':'Enhance audio using AI?'}}</v-card-subtitle>
          <div class="d-flex justify-center">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!clip.audio_cleaned" class="error-action" @click="enhanceAudio">Enhance</v-btn>
            <v-btn v-if="clip.audio_cleaned" class="error-action" @click="undoEnhance">Undo</v-btn>
            <v-btn @click="close">Cancel</v-btn>
          </v-card-actions>
          </div>
        </v-card>
    </div>
  </template>
  
  <script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import types from "../.././store/types";
  import { patchClip } from "../../utils/APIs";
  export default {
    
    data() {
      return {
        showPopup: false,
        bugReport: '',
        intervalId: null
      };
    },
    computed:{
        ...mapState({
            clip: (state) => state.project.clip,
            showAutoCrop: (state) => state.autoCrop.showAutoCrop,
            autoCropClip: (state) => state.autoCrop.autoCropClip,
            autoCropping: (state) => state.autoCrop.autoCropping,
        }),
        audioFeatures() {
          if(this.clip.audio_features){
            let features = Object.keys(this.clip.audio_features)
            const cuts = []
                features.forEach(k=>{
                    this.clip.audio_features[k].forEach(feature=>{
                        if(feature){
                          if(this.clip.start_time + feature.start < this.clip.end_time && this.clip.start_time + feature.end < this.clip.end_time){
                            cuts.push({start:this.clip.start_time + feature.start, end:this.clip.start_time + feature.end})
                          }
                          
                        }
                    })
                })
            return cuts
          }
          return []
        },
    },
    methods: {
      ...mapActions({
        saveTrimedSubtitleDurations: types.project.actions.UPDATE_TRIMED_DURATIONS,
        cleanClipAudioAction: types.project.actions.CLEAN_CLIP_AUDIO,
        fetchClip: types.project.actions.FETCH_CLIP,
        undoCleanClipAudioAction:types.project.actions.UNDO_CLEAN_CLIP_AUDIO,
        updateAutoCropClip:types.autoCrop.actions.UPDATE_AUTO_CROP_CLIP
      }),
      ...mapMutations({
      openEnhanceAudio: types.project.mutations.OPEN_AUDIO_ENHANCE_DIALOG,
      setButtonLoader: types.app.mutations.SET_BUTTON_LOADER,
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE,
    }),
      async enhanceAudio() {
        this.setButtonLoader(true)
        this.openEnhanceAudio(false)
        const clipUuid = this.$route.params.clipUuid;
        const data = await this.cleanClipAudioAction(clipUuid)
        if(data.message === 'PROGRESS'){
          this.intervalId = setInterval(async ()=>{
            const data = await this.cleanClipAudioAction(clipUuid)
            if(data.message === 'PROGRESS'){
              return
            }
            else {
              await patchClip({clipUuid:clipUuid,audio_cleaned: true})
              await this.fetchClip({clipUuid:clipUuid})
              this.processAudioEnhancement()
            }
          }, 10000)
        }
        else {
          await patchClip({clipUuid:clipUuid,audio_cleaned: true})
          await this.fetchClip({clipUuid:clipUuid})
          if(this.showAutoCrop && this.clip.audio_features){
            const audioCleanCuts = this.mapAudioFeatureCuts()
            if(this.autoCropClip.clip_cuts){
             const cuts = audioCleanCuts.concat(this.autoCropClip.clip_cuts)
              this.updateAutoCropClip({clipUuid:this.$route.params.clipUuid, aspectRatio:this.autoCropping.dimension, clip_cuts:cuts})
            }
            else{
              this.updateAutoCropClip({clipUuid:this.$route.params.clipUuid, aspectRatio:this.autoCropping.dimension, clip_cuts:audioCleanCuts})
            }
          }
          this.processAudioEnhancement()
        }
      },
      async undoEnhance() {
        await this.undoCleanClipAudioAction(this.$route.params.clipUuid)
        if(this.autoCropClip && this.autoCropClip.clip_cuts){
          const audioCleanCuts = this.mapAudioFeatureCuts()
          const isInCuts = (element) => {
            return audioCleanCuts.some(cut => cut.start === element.start && cut.end === element.end);
          }
          const audioCleanFilterCuts = this.autoCropClip.clip_cuts.filter(cut => !isInCuts(cut));
          this.updateAutoCropClip({clipUuid:this.$route.params.clipUuid, aspectRatio:this.autoCropping.dimension, clip_cuts:audioCleanFilterCuts})
        }
        this.close()
      },
      close() {
        this.openEnhanceAudio(false)
      },
      mapAudioFeatureCuts(){
        // Calculate the duration of the first subclip and the second subclip
        const subClipDuration2 = this.autoCropClip.clip_end_time_relatedto_new_buffered_clip - this.autoCropClip.clip_start_time_relatedto_new_buffered_clip;

          // Initialize an array to hold the mapped cuts for the second subclip
          const mappedCuts = [];
            if(this.audioFeatures && this.audioFeatures.length){

          // Iterate over each cut in the first subclip
          for (const cut of this.audioFeatures) {
              const cutStart1 = cut.start;
              const cutEnd1 = cut.end;

              // Calculate the cut duration and its relative start position in the first subclip
              const cutDuration1 = cutEnd1 - cutStart1;
              const cutRelativeStart1 = cutStart1 - this.clip.start_time;

              // Calculate the relative start position of the cut in the second subclip
              const cutRelativeStart2 = (cutRelativeStart1 / this.clip.clip_duration_before_cuts) * subClipDuration2;

              // Calculate the start and end times of the cut in the second subclip
              const cutStart2 = this.autoCropClip.clip_start_time_relatedto_new_buffered_clip + cutRelativeStart2;
              const cutEnd2 = cutStart2 + cutDuration1;

              // Add the mapped cut to the array
              mappedCuts.push({ start: cutStart2, end: cutEnd2 });
          }
      }
      return mappedCuts;
    },
      async processAudioEnhancement() {
            const hasArrayWithLength = Object.values(this.clip.audio_features).some(property => Array.isArray(property) && property.length > 0);
            if(hasArrayWithLength){
              this.setButtonLoader(false)
              this.setSuccessMessage('Audio cleaning completed!')
              clearInterval(this.intervalId)
            }
            else {
              clearInterval(this.intervalId)
              this.setButtonLoader(false)
              this.setSuccessMessage('Audio cleaning completed. No issues were found.')
            }
      }
    },
  };
  </script>
  
  <style scoped>
    .error-action{
      background-color: #fbd458 !important;
    }
  </style>
  