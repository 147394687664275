<template>
    <div class="floating-upload-window-wrapper">
      <v-expansion-panels
        accordion
        class="floating-upload-window-wrapper__item"
        @change="showTotalProgress"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div :style="{ width: '95%' }">
              <p>Downloading...</p>
            </div></v-expansion-panel-header
          >
          <v-expansion-panel-content
            v-for="(video, index) in allExports"
            :key="video.name"
          >
            <strong>{{ index+1 }}</strong> : {{ trimText(video.clipName) }}
          </v-expansion-panel-content>
          <!-- <v-expansion-panel-content>
            Video name
            <div class="d-flex align-center">
              <v-progress-linear
                value="100"
                color="red"
                background-color="white"
                rounded
              ></v-progress-linear>
              <v-icon color="white" class="ml-2">mdi-redo</v-icon>
            </div>
          </v-expansion-panel-content> -->
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from "vuex";
  import types from "../../store/types";
  import trimString from "../../utils/TrimString";
  
  export default {
    data() {
      return {
        isTotalProgressVisible: true,
        totalProgress: 0,
      };
    },
    computed: {
      ...mapState({
        allExports: (state) => state.download.allExports,
        isUploadingYoutubeVideo: (state) => state.video.isUploadingYoutubeVideo
      }),
    },
    methods: {
      ...mapActions({
        cancelUploadVideo: types.project.actions.CANCEL_UPLOAD_VIDEO,
      }),
      cancelUpload(videoName) {
        this.cancelUploadVideo(videoName);
      },
      trimText(text) {
        return trimString(text, 40);
      },
      showTotalProgress(id) {
        this.isTotalProgressVisible = id === undefined;
      },
      setTotalPercentage() {
        let percentage = 0;
        this.uploadingVideos.forEach((video) => {
          percentage = percentage + video.percentage;
        });
        this.totalProgress = percentage / this.uploadingVideos.length;
      },
    },
    watch: {
      uploadingVideos() {
        this.setTotalPercentage();
      },
    },
    mounted() {
      this.setTotalPercentage();
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/styles/components/videos/floating-upload-window.scss";
  </style>
  