<template>
  <GoogleLogin :params="params" :onSuccess="onSuccess"
    ><div class="google-btn">
      <img src="@/assets/images/icons/google.svg" alt="google" />
      <p>Sign-in with Google</p>
    </div>
    </GoogleLogin
  >
</template>

<script>
import GoogleLogin from "vue-google-login";
import { mapActions, mapMutations } from "vuex";
import types from "../store/types";
import {
  setUserTokenCookie,
  setUserRefreshTokenCookie,
} from "../utils/Cookies";

export default {
  data() {
    return {
      params: {
        client_id: process.env.VUE_APP_GOOGLE_AUTH_KEY,
      },
    };
  },
  components: {
    GoogleLogin,
  },
  methods: {
    ...mapActions({
      signInWithGoogle: types.user.actions.GOOGLE_AUTH_USER,
    }),
    ...mapMutations({
      setRefreshToken: types.user.mutations.SET_REFRESH_TOKEN,
      setUser: types.user.mutations.SET_USER,
    }),
    async onSuccess(googleUser) {
      const { id_token, access_token } = googleUser.getAuthResponse(true);
      const payload = { token: id_token, access_token };
      try {
        const { access_token, refresh_token, user } =
          await this.signInWithGoogle(payload);
        this.setRefreshToken(refresh_token);
        this.setUser(user);
        if (this.remember) {
          setUserTokenCookie(access_token);
          setUserRefreshTokenCookie(refresh_token);
        }
        this.$router.push("/").catch(() => {});
        
      } catch (err) {
        // handled in store
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.google-btn {
  filter: drop-shadow(0px 4px 3px rgba(170, 170, 170, 0.2));
  padding: 7px 10px;
  display: flex;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  width: 100% !important;
  p {
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.045em;
    color: #1b1c20;
    margin-left: 8px;
    margin-bottom: 0;
  }
}
button[id^="google-signin-btn"] {
  width: 100%;
  height: fit-content;
  margin-top: 20px;
  border: none;
}
</style>
