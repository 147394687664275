<template>
  <div class="pro-wrapper">
    <p class="pro-wrapper__header">{{ plan.s_name }}</p>
    <p class="pro-wrapper__title">${{ price }}</p>
    <p
      class="pro-wrapper__subtitle"
      :style="{ marginBottom: '10px', marginTop: '-5px' }"
      v-if="type === 'yearly'"
    >
      Paid annually
    </p>
    <p class="pro-wrapper__subtitle">
      Perfect for content teams serious about growth
    </p>
    <div class="pro-wrapper__features">
      <div class="pro-wrapper__features--point">
        <v-icon color="#fbd458">mdi-checkbox-marked-circle</v-icon>
        <span>{{ minutes }} mins / m</span>
      </div>
      <div class="pro-wrapper__features--point">
        <v-icon color="#fbd458">mdi-checkbox-marked-circle</v-icon>
        <span>{{ storage }} GB / m</span>
      </div>
      <div class="pro-wrapper__features--point">
        <v-icon color="#fbd458">mdi-checkbox-marked-circle</v-icon>
        <span>Unlimited exports</span>
      </div>
      <div class="pro-wrapper__features--point">
        <v-icon color="#fbd458">mdi-checkbox-marked-circle</v-icon>
        <span>Dedicated onboarding specialist</span>
      </div>
    </div>
    <v-btn
      class="pro-wrapper__cta"
      text
      :ripple="false"
      width="194"
      height="42"
      outlined
      @click="goToCheckout(planUuid)"
    >
      <span> Subscribe </span>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["plan", "goToCheckout", "userPlan", "type"],
  computed: {
    planUuid() {
      if (this.type === "yearly") {
        return this.plan.prices[1].uuid;
      }
      return this.plan.prices[0].uuid;
    },
    price() {
      if (this.type === "yearly") {
        return `${Number(
          (Math.ceil(this.plan.prices[1].s_unit_amount / 100) / 12).toFixed(1)
        )}/m`;
      }
      return `${Math.ceil(this.plan.prices[0].s_unit_amount / 100)}/m`;
    },
    storage() {
      if (this.type === "yearly") {
        return Math.ceil(this.plan.prices[1].storage_limit / (1024 * 12));
      }
      return Math.ceil(this.plan.prices[0].storage_limit / 1024);
    },
    minutes() {
      if (this.type === "yearly") {
        return Math.ceil(this.plan.prices[1].seconds_limit / (60 * 12));
      }
      return Math.ceil(this.plan.prices[0].seconds_limit / 60);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/colors.scss";

.pro-wrapper {
  position: relative;
  width: 226px;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  background-color: $google-button-background;
  padding: 18px 18px 80px;
  &__header {
    font-size: 16px;
    position: relative;
    margin-bottom: 0;
    font-weight: 700;
    font-family: "Satoshi Bold";
  }
  &__title {
    position: relative;
    font-size: 36px;
    margin-bottom: 0;
    font-weight: bold;
  }
  &__subtitle {
    position: relative;
    font-size: 13px;
    font-weight: 700;
    font-family: "Satoshi Bold";
  }
  &__features {
    margin-top: 35px;
    &--point {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      span {
        margin-left: 12px;
        font-weight: 600;
      }
    }
  }
  &__cta {
    position: absolute;
    font-size: 16px;
    bottom: 30px;
    left: 14px;
    font-weight: 600;
    background: $yellow;
    color: #2c2c2d;
    text-transform: capitalize;
    &--subscribed {
      background: transparent;
      border-color: black !important;
      color: black !important;
    }
  }
}
</style>
