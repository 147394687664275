<template>
  <v-row justify="center">
    <v-dialog
      v-model="isUploadDialogVisible"
      max-width="1000"
      :hide-overlay="true"
      content-class="elevation-0 upload-video-dialog"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="isUploading"
                icon
                v-bind="attrs"
                v-on="on"
                @click="onMinimizeClicked"
              >
                <v-icon>mdi-window-minimize</v-icon>
              </v-btn>
              <v-btn
                v-else
                icon
                v-bind="attrs"
                v-on="on"
                @click="onClose(false)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row v-if="!isUploading">
              <v-col
                cols="12"
                class="d-flex align-start justify-start mb-3 pt-0"
                :style="{ marginTop: '-28px' }"
              >
                <v-tabs
                  style="margin-top:20px;position: relative;"
                  v-model="tab"
                  color="deep-purple accent-4"
                  left
                >
                    <v-tab :href="`#tab-1`"><v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }"><v-img v-on="on" v-bind="attrs" src="@/assets/images/icons/YT_icon.svg" max-height="35" max-width="35"></v-img></template><span>Youtube Link</span></v-tooltip></v-tab>
                    <v-tab :href="`#tab-2`"><v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }"><v-img  v-on="on" v-bind="attrs" src="@/assets/images/icons/cloud-computing.png" max-height="33" max-width="33"></v-img></template> <span>File Upload</span></v-tooltip></v-tab>
                    <v-tab :href="`#tab-3`"><v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }"><v-img  v-on="on" v-bind="attrs" src="@/assets/images/icons/GD_icon.png" max-height="28" max-width="28"></v-img></template> <span>Google Drive Upload</span></v-tooltip></v-tab>
                    <div class="agc-action-wrapper">
                      <div class="upload-video-dialog__text mr-5">
                        <p class="mb-0">Auto-generate Clips</p>
                      </div>
                      <v-switch
                        v-model="autoGenerateClips"
                        color="success"
                        hide-details
                      ></v-switch>
                    </div>
                    <v-tabs-items v-model="tab">
                       <!--youtube upload tab-->
                      <v-tab-item
                        value="tab-1"
                      >
                        <v-container fluid>
                          <v-row>
                          <v-col cols="12" class="py-0">
                            <v-divider></v-divider>
                          </v-col>
                          <v-col cols="12" class="pt-5 pb-0">
                            <div
                              class="upload-video-dialog__text upload-video-dialog__text1 d-flex justify-space-between align-baseline mb-6"
                            >
                              <p class="mb-0">Upload Video from Youtube</p>
                              <span v-if="tab==='tab-1'" class="language-tooltip" data-title="What language is your video?"><v-select
                                label="Language"
                                :items="languages"
                                v-model="language"
                                @input="languageError=[]"
                                :error-messages="languageError"
                              ></v-select></span>
                            </div>
                            <div
                              v-for="(url, id) in urls"
                              :key="id"
                              :set="(v = $v.urls.$each[id])"
                            >
                              <label class="upload-video-dialog__label pb-2 d-inline-block">
                                Add Youtube link
                              </label>

                              <p
                                class="d-flex"
                                style="font-size: 14px"
                                v-if="autoGenerateClips"
                              >
                                Pro tip: Auto-clips work best with 15 min+ recordings like
                                webinars, events, and podcasts.
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-on="on" v-bind="attrs">
                                      <v-icon color="#323232" class="ml-1">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </div>
                                  </template>
                                  <p style="font-weight: 700">
                                    Chopcast currently only understands videos that are:<br />
                                    - Conversation-driven (like webinars, podcasts, event
                                    recordings, fireside chats).<br />
                                    - Not music videos, family videos, and other
                                    unstructured recordings.<br />
                                    - Tend to be 15 to 60 mins long on average.<br />
                                    - Are horizontal (16:9) in aspect ratio.<br />
                                    - Are not too short (e.g: less than 3 mins, making it
                                    too short to find clips from)
                                  </p>
                                </v-tooltip>
                              </p>
                              <v-text-field
                                label="link here"
                                single-line
                                outlined
                                :error="
                                  url.value !== '' && $v.urls.$each[firstUrlId].$invalid
                                "
                                v-model.trim="url.value"
                                @input="() => handleAddURL(id)"
                                class="upload-video-dialog__youtube-link"
                              >
                                <v-icon color="#323232" slot="prepend-inner">
                                  mdi-link
                                </v-icon>
                                <!-- <v-tooltip top v-if="(youtubeVideoLength / 60).toFixed(1) <5 && autoGenerateClips">
                                    <template v-slot:activator="{ on }">
                                    <div v-on="on">
                                      <v-btn
                                  depressed
                                  color="#fbd458"
                                  :disabled="
                                    $v.urls.$each[id].$invalid || !canUploadYoutube 
                                  "
                                  slot="append"
                                  v-if="
                                    !urlErrors(id)[0] || !$v.urls.$each[firstUrlId].$invalid
                                  "
                                  @click="uploadYoutubeURL"
                                >
                                  <span class="font-weight-bold text-capitalize">
                                    Upload
                                  </span>
                                </v-btn>
                                    </div>
                                    </template>
                                    <span>Turn off Auto-generate Clips to upload this video.</span>
                                  </v-tooltip> -->
                                <v-btn
                                  depressed
                                  color="#fbd458"
                                  :disabled="
                                    $v.urls.$each[id].$invalid || !canUploadYoutube || ((youtubeVideoLength / 60).toFixed(1) <5 && autoGenerateClips)
                                  " 
                                  slot="append"
                                  v-if="
                                    !urlErrors(id)[0]
                                  "
                                  @click="uploadYoutubeURL"
                                >
                                  <span class="font-weight-bold text-capitalize">
                                    Upload
                                  </span>
                                </v-btn>
                                <span slot="append" class="v-messages__message">{{
                                  urlErrors(id)[0]
                                }}</span>
                              </v-text-field>
                            </div>
                            <!-- <v-btn
                              plain
                              height="24"
                              @click="addYoutubeLink"
                              class="upload-video-dialog__another-link"
                            >
                              <v-icon color="#7367f0">mdi-plus-box</v-icon>
                              <span class="text-none">Add another link</span>
                            </v-btn> -->
                          </v-col>
                          </v-row>
                        </v-container>
                      </v-tab-item>
                       <!--youtube upload tab end-->
                        <!--local upload tab-->
                      <v-tab-item
                        value="tab-2"
                      >
                        <v-container fluid>
                          <v-row class="mt-0">
                            <v-col cols="12" class="pb-4">
                              <div
                                class="upload-video-dialog__text upload-video-dialog__text1 d-flex justify-space-between align-baseline"
                              >
                                <p class="mb-0">Upload from your device</p>
                                <span class="language-tooltip" data-title="What language is your video?"><v-select
                                label="Language"
                                :items="languages"
                                v-model="language"
                                @input="languageError=[]"
                                :error-messages="languageError"
                              ></v-select></span>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row
                            style="border: 3px dashed;
                                  border-radius: 5px;
                                  color: #938888;"
                            class="text-center video-upload-area"
                            :class="{ draggingUploadedFile: isDragging }"
                            @dragleave.stop.prevent="handleDragLeave"
                            @dragover.stop.prevent="handleDragOver"
                            @drop.stop.prevent="handleDroppedFile($event)"
                            
                          >
                            <v-col cols="12" v-if="!isLocalUpload">
                              <v-icon color="#000000" slot="append"
                                >mdi-cloud-upload-outline</v-icon
                              >
                              <div class="text-subtitle-1 font-weight-light mt-3 mb-3">
                                <label>Drag and drop your webinars, podcasts, and other long videos here or </label>
                              </div>
                              <v-btn
                                class="ma-2 mt-1 upload-video-dialog__button"
                                outlined
                                color="#90989C"
                                @click="$refs.file.click()"
                                :disabled="!$v.urls.$each[firstUrlId].$invalid"
                              >
                                <span
                                  class="text-subtitle-1 text-capitalize upload-video-dialog__text"
                                  :style="{
                                    color: !$v.urls.$each[firstUrlId].$invalid ? 'gray' : '',
                                  }"
                                  >Browse</span
                                >
                              </v-btn>
                              <input
                                v-show="false"
                                id="video"
                                type="file"
                                accept="video/*,audio/*"
                                ref="file"
                                multiple
                                @change="handleFileUpload"
                              />
                            </v-col>
                            
                            <v-col cols="12" v-if="isLocalUpload">
                              <div v-for="(item, index) in videoInfos" :key="'video'+index">
                              <div class="upload-video-dialog__video-info">
                                <v-btn
                                  class="upload-video-dialog__cancel"
                                  tile
                                  plain
                                  @click="resetVideoUrls(index)"
                                >
                                  <v-icon color="error" small> mdi-close </v-icon>
                                  <span
                                    class="text-subtitle-1 text-capitalize upload-video-dialog__text upload-video-dialog__text-error"
                                    >Cancel</span
                                  >
                                </v-btn>
                                <div class="text-h6 upload-video-dialog__text">
                                  {{ trimText(item.name) }}
                                </div>
                                <div class="d-flex align-center">
                                  <v-icon color="#28B446">mdi-checkbox-marked-circle</v-icon>
                                  <p class="ml-2 mb-0 subtitle-1">
                                    {{ (item.storage / (1000 * 1000)).toFixed(1) }} MB
                                  </p>
                                </div>
                                <div class="d-flex align-center">
                                  <v-icon color="#28B446">mdi-checkbox-marked-circle</v-icon>
                                  <p class="ml-2 mb-0 subtitle-1">
                                    {{ (item.seconds / 60).toFixed(1) }} min
                                  </p>
                                </div>
                              </div>
                              
                            </div>
                            </v-col>
                            <div v-for="(item, index) in filesLength" :key="index">
                            <video class="video d-none" :src="videoURLs[index]" :ref="'video_' + index"></video>
                          </div>
                          </v-row>
                        </v-container>
                      </v-tab-item>
                       <!--local upload tab end-->
                      <!--google drive upload tab-->
                      <v-tab-item
                        value="tab-3"
                      >
                        <v-container fluid>
                          <v-row class="mt-0">
                            <v-col cols="12" class="pb-4">
                              <div
                                class="upload-video-dialog__text upload-video-dialog__text1 d-flex justify-space-between align-baseline"
                              >
                                <p class="mb-0">Select from Google Drive</p>
                                <span class="language-tooltip" data-title="What language is your video?"><v-select
                                label="Language"
                                :items="languages"
                                v-model="language"
                                @input="languageError=[]"
                                :error-messages="languageError"
                              ></v-select></span>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row
                            class="text-center video-upload-area"
                          >
                            <v-col cols="12" v-if="!isGoogleFileUplaod">
                              <v-btn
                                v-if="!isDownloading"
                                class="ma-2 mt-1 upload-video-dialog__button"
                                outlined
                                color="#90989C" @click="driveIconClicked">Browse Google Drive</v-btn>
                              <p v-if="isDownloading">Downloading Selected Files</p>
                              <div style="position: absolute;left: 48%;top: 40%;">
                                <v-progress-circular
                                  v-if="isDownloading"
                                  :width="4"
                                  :size="40"
                                  color="#fbd458"
                                  indeterminate
                                ></v-progress-circular>
                            </div>
                            </v-col>
                            <v-col cols="12" v-if="isGoogleFileUplaod">
                              <div v-for="(item, index) in videoInfos" :key="'video'+index">
                              <div class="upload-video-dialog__video-info">
                                <v-btn
                                  class="upload-video-dialog__cancel"
                                  tile
                                  plain
                                  @click="resetVideoUrls"
                                >
                                  <v-icon color="error" small> mdi-close </v-icon>
                                  <span
                                    class="text-subtitle-1 text-capitalize upload-video-dialog__text upload-video-dialog__text-error"
                                    >Cancel</span
                                  >
                                </v-btn>
                                <div class="text-h6 upload-video-dialog__text">
                                  {{ trimText(item.name) }}
                                </div>
                                <div class="d-flex align-center">
                                  <v-icon color="#28B446">mdi-checkbox-marked-circle</v-icon>
                                  <p class="ml-2 mb-0 subtitle-1">
                                    {{ (item.storage / (1000 * 1000)).toFixed(1) }} MB
                                  </p>
                                </div>
                                <div class="d-flex align-center">
                                  <v-icon color="#28B446">mdi-checkbox-marked-circle</v-icon>
                                  <p class="ml-2 mb-0 subtitle-1">
                                    {{ (item.seconds / 60).toFixed(1) }} min
                                  </p>
                                </div>
                              </div>
                              Only videos longer than 5 minutes can auto-generate clips.
                             
                            </div>
                            </v-col>
                            <div v-for="(item, index) in filesLength" :key="index">
                            <video class="video d-none" :src="videoURLs[index]" :ref="'video_' + index"></video>
                          </div>
                          </v-row>
                        </v-container>
                      </v-tab-item>
                       <!--google drive upload tab end-->
                  </v-tabs-items>
                </v-tabs>
              </v-col>
            </v-row>
            <v-expansion-panels>
            <v-expansion-panel v-if="!isUploading">
              <v-expansion-panel-header style="font-size: 20px;font-weight: bold;">Advanced Settings <v-divider inset></v-divider></v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>Find me the parts</p>
                <textarea
                  v-model="parts_to_find"
                  rows="3"
                  style="border: 1px solid;
                  border-radius: 5px;
                  background-color: #F3F5F7;
                  width: 100%;"
              />
              <div class="d-flex justify-space-between">
                  <div>
                    <p style="margin-bottom:0;margin-top: 16px;">Video for platform</p>
                    <multiselect v-model="video_for_platform" placeholder="Platform" label="title" track-by="title" :options="videoForPlatForms" :option-height="104"  :show-labels="true" style="width: 350px;">
                      <template slot="option" slot-scope="props"><img class="option__image" :src="props.option.img">
                        <div class="option__desc"><span class="option__small">{{ props.option.desc }}</span></div>
                      </template>
                    </multiselect>
                  </div>
                  <div>
                    <p style="margin-bottom:0;margin-top: 16px;">Avg. clip duration (seconds)</p>
                    <input v-model="length_of_clip" type="number" class="number-input" style="width: 350px;" />
                  </div>
              </div>
              <div class="d-flex justify-space-between">
                  <div class="chips-container">
                    <p style="margin-bottom:0;margin-top: 16px;">Video topics</p>
                    <input v-model="videoTopic" placeholder="Add topics and press enter" class="number-input" @blur="onBlurTopics" @keydown="videoTopicsKeydown"/>
                    <v-chip
                      v-for="(item, index) in videoTopics"
                      :key="index"
                      class="ma-1"
                      :color="'#fbd458'"
                      label
                      close
                      @click:close="removeChip(index)"
                    >
                    {{ item }}
                  </v-chip>
                  </div>
              </div>
              <div class="pt-6">
              <v-btn outlined class="ma-2" style="font-weight: 600;" @click="onClose(false)">
                Cancel
              </v-btn>
              <v-btn depressed color="orange" class="ma-2" style="font-weight: 600;" @click="done">
                Done
              </v-btn>
            </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
            <v-row
              v-for="video in uploadingVideos"
              :key="video.name"
              class="d-block mt-10"
            >
              <div class="text-h6 upload-video-dialog__text">
                {{ trimText(video.name) }}
              </div>
              <div class="d-flex align-center">
                <v-progress-linear
                  background-color="gray"
                  color="#fbd458"
                  :value="video.percentage"
                ></v-progress-linear>
                <v-icon
                  color="red"
                  class="ml-2"
                  @click="cancelUpload(video.name)"
                  v-if="video.percentage < 100"
                  >mdi-close</v-icon
                >
                <v-icon v-else class="ml-2">mdi-loading</v-icon>
              </div>
              <p class="ml-0 mb-0 subtitle-1">
                {{
                  `(${convertToXByte(video.uploadedSize)} / ${convertToXByte(
                    video.size
                  )})`
                }}
              </p>
            </v-row>
            <div class="d-flex justify-center align-center upload-video-dialog__action-button mt-5" v-if="!isUploading && videoInfos.length">
            <v-btn
                class="upload-video-dialog__action-button--active ml-2"
                text
                :ripple="false"
                width="214"
                height="48"
                @click="upload"
                >
                  <span class="font-weight-normal text-capitalize"
                    >Upload</span
                  >
              </v-btn>
              <v-btn
                  class="mr-2"
                  text
                  :ripple="false"
                  width="214"
                  height="48"
                  outlined
                  @click="onClose(false)"
                  :disabled="isUploading"
                >
                  <span class="font-weight-normal text-capitalize"
                    >Cancel</span
                  > </v-btn>
                </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import types from "../../store/types";
import trimString from "../../utils/TrimString";
import convertToXBytes from "../../utils/ConvertToXBytes";
import { required, helpers } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect'
import { videoForPlatForms, languages } from '../../constants/dropdownOptitons'

const regexYoutubeURL = helpers.regex(
  "regex",
  /(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/
);

export default {
  components:{ Multiselect },
  props: [
    "isUploadVideoDialogVisible",
    "showUploadDialog",
    "showFloatingUploadWindow",
  ],
  data() {
    return {
      options: ['list', 'of', 'options'],
      isDragging: false,
      firstUrlId: 0,
      autoGenerateClips: true,
      urls: [{ value: "" }],
      videoURL:"",
      videoURLs: [],
      videoInfos: [],
      videoInfo: {
        seconds: "",
        storage: "",
        name: "",
      },
      isUploading: false,
      file: [],
      uuid: this.$route.params.uuid,
      canUploadYoutube: false,
      canUploadFromDevice: false,
      youtubeUploading: false,
      tab:null,
      video_for_platform:'',
      length_of_clip:null,
      number_of_clips:null,
      language:'English',
      video_topic_list:[],
      videoForPlatForms:videoForPlatForms,
      videoTopic:"",
      videoTopics:[],
      parts_to_find:"",
      languages:languages,
      languageError:[],
      allowedExtensions:[".mp4", ".mov", ".avi", ".mp3"],
      pickerApiLoaded: false,
      scope: "https://www.googleapis.com/auth/drive.readonly",
      oauthToken: null,
      isDownloading: false,
      googleDriveFile: '',
      youtubeVideoLength:0,
      isLocalUpload : false,
      isGoogleFileUplaod: false,
      filesLength:0,
      filesFormData:[],
      filesAgcsStatus:[],
      clipsAgcStatuses:[]
    };
  },
  mounted(){
    let gDrive = document.createElement("script");
    gDrive.setAttribute("type", "text/javascript");
    gDrive.setAttribute("src", "https://apis.google.com/js/api.js");
    document.head.appendChild(gDrive);
  },
  computed: {
    ...mapState({
      uploadingVideos: (state) => state.project.uploadingVideos,
    }),
    isUploadDialogVisible: {
      get() {
        return this.isUploadVideoDialogVisible;
      },
      set(val) {
        if (this.isUploading) {
          this.showFloatingUploadWindow(true);
        }
        return this.showUploadDialog(val);
      },
    },
  },
  methods: {
    async driveIconClicked() {
      /* eslint-disable */
      await gapi.load("auth2", () => {
        /* eslint-disable */
        if(gapi.auth2.getAuthInstance()){
          gapi.auth2.getAuthInstance().signIn().then((res) => {
            this.handleAuthResult(res.Tc.access_token);
          });
        }
        else{
          gapi.auth2.authorize(
          {
            client_id: `${process.env.VUE_APP_GOOGLE_CLIENT_ID}`,
            scope: this.scope,
            immediate: false
          },
          this.handleAuthResult2
        );
        }
        
      });
      /* eslint-disable */
      gapi.load("picker", () => {
        this.pickerApiLoaded = true;
        this.createPicker();
      });
    },
    handleAuthResult2(authResult) {
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        this.createPicker();
      }
    },
    handleAuthResult(access_token) {
        this.oauthToken = access_token;
        this.createPicker();
    },
    createPicker() {
      if (this.pickerApiLoaded && this.oauthToken) {
        var picker = new google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .addView(google.picker.ViewId.DOCS)
          .setOAuthToken(this.oauthToken)
          .setDeveloperKey(`${process.env.VUE_APP_GOOGLE_DEV_KEY}`)
          .setCallback(this.pickerCallback)
          .build();
        picker.setVisible(true);
      }
    },
    async pickerCallback(data) {
      if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
        // Array of Picked Files data.docs
        try {
          const downloadedFiles = []
          for (const doc of data.docs) {
            const fileId = doc.id;
            const fileName = doc.name;
            const fileExtension = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
            
            if (!this.allowedExtensions.includes("." + fileExtension.toLowerCase())) {
              this.setErrorMessage(["Only mp4, mov, avi files are allowed"]);
              return;
            }
            
            this.isDownloading = true;
            const file = await this.downloadFile(fileId, fileName);
            downloadedFiles.push(file);
      }
          this.isDownloading = false;
          this.isGoogleFileUplaod = true
          await this.handleGoogleDriveFileUpload(downloadedFiles);
        } catch (error) {
          console.error('Error handling file:', error.message);
        } 
      }
    },
    onClose() {
      this.showUploadDialog(false)
      this.isLocalUpload = false;
      this.isGoogleFileUplaod = false
    },
    downloadFile(fileId, name) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';

        xhr.onload = () => {
          const file = new File([xhr.response], name, { type: xhr.response.type });

          resolve(file);
        };

        xhr.onerror = () => {
          reject(new Error('Failed to download file'));
        };

        xhr.open('GET', `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`);
        xhr.setRequestHeader('Authorization', `Bearer ${this.oauthToken}`);
        xhr.send();
      });
    },
    ...mapMutations({
      addUploadingVideos: types.project.mutations.ADD_UPLOADING_VIDEOS,
      resetVideos: types.project.mutations.RESET_VIDEOS,
      setErrorMessage: types.app.mutations.SET_ERROR_MESSAGE,
    }),
    ...mapActions({
      uploadVideo: types.project.actions.UPLOAD_VIDEO,
      uploadYoutubeVideo: types.project.actions.UPLOAD_YOUTUBE_VIDEO,
      cancelUploadVideo: types.project.actions.CANCEL_UPLOAD_VIDEO,
      preUploadVideo: types.project.actions.PRE_UPLOAD_VIDEO,
      fetchVideos: types.project.actions.FETCH_VIDEOS,
    }),
    handleDroppedFile(e) {
      e.preventDefault();
        const files = e.dataTransfer.files
        this.filesLength =  files.length
        this.file = e.dataTransfer.files
        for (let index = 0; index < this.filesLength; index++) {
          const fileExtension = this.file[index].name.slice((this.file[index].name.lastIndexOf(".") - 1 >>> 0) + 2);
          if(!this.allowedExtensions.includes("." + fileExtension.toLowerCase())){
            this.setErrorMessage(["Only mp4, mov, avi files are allowed"]);
            return
          }
        const formData = new FormData();
        formData.append("video_file", this.file[index]);
        if (this.uuid) formData.append("folder", this.uuid);
          this.filesFormData.push(formData)
        }
        this.file.forEach((file)=>{
          this.videoURLs.push(window.URL.createObjectURL(file))
        })
        this.isLocalUpload = true
        this.$nextTick(() => {
          for (let i = 0; i < this.file.length; i++) {
            const videoElement = this.$refs['video_' + i]
            if (Array.isArray(videoElement)){
              videoElement.forEach(video => {
              video.addEventListener('loadedmetadata', () => {
                  // Your logic for handling loaded metadata goes here
                  const info = {
                    seconds :  video.duration,
                    storage : this.filesFormData[i].get("video_file").size,
                    name : this.filesFormData[i].get("video_file").name,
                  }
                   // Check if the new object already exists in the array
                   let exists = this.videoInfos.some(item => JSON.stringify(item) === JSON.stringify(info));

                    // If the object doesn't exist, push it into the array
                    if (!exists) {
                      this.videoInfos.push(info);
                    }
                this.$nextTick(()=>{
                  if((info.seconds / 60).toFixed(1) <5 ){
                    this.clipsAgcStatuses.push({status:false})
                    this.setErrorMessage(["Only videos longer than 5 minutes can auto-generate clips."]);
                  }
                  else{
                    this.clipsAgcStatuses.push({status: true})
                  }
                })
                });
              });
            }
          }
        });

      this.isDragging = false;
    },
    handleDraggedEnterFile() {
      this.isDragging = true;
    },
    handleDragLeave() {
      this.isDragging = false;
    },
    handleDragOver() {
      console.log("in herererereer")
      this.isDragging = true;
    },
    cancelUpload(videoName) {
      this.cancelUploadVideo(videoName);
    },
    trimText(text) {
      return trimString(text, 59);
    },
    convertToXByte(bytes) {
      return convertToXBytes(bytes);
    },
    urlErrors(id) {
      const errors = [];
      if (!this.$v.urls.$each[id].value.$dirty) return errors;
      if (!this.$v.urls.$each[id].value["regexYoutubeURL"])
        errors.push("Invalid link try again");
        if (this.youtubeVideoLength>0 && ((this.youtubeVideoLength/60) < 5) && this.autoGenerateClips){
          errors.push("Turn off Auto-generate Clips to upload this video.");
        }
      return errors;
    },
    addYoutubeLink() {
      this.urls.push([{ value: "" }]);
    },
    onMinimizeClicked() {
      this.showUploadDialog(false);
      this.showFloatingUploadWindow(true);
    },
    handleFileUpload() { 
      setTimeout(()=>{
        this.filesLength = this.$refs.file.files.length
        this.file = this.$refs.file.files
        for (let index = 0; index < this.file.length; index++) {
          const fileExtension = this.file[index].name.slice((this.file[index].name.lastIndexOf(".") - 1 >>> 0) + 2);
          if(!this.allowedExtensions.includes("." + fileExtension.toLowerCase())){
            this.setErrorMessage(["Only mp4, mov, avi files are allowed"]);
            return
          }
        const formData = new FormData();
        formData.append("video_file", this.file[index]);
        if (this.uuid) formData.append("folder", this.uuid);
          this.filesFormData.push(formData)
        }
        this.file.forEach((file)=>{
          this.videoURLs.push(window.URL.createObjectURL(file))
        })
        this.isLocalUpload = true
        this.$nextTick(() => {
          for (let i = 0; i < this.file.length; i++) {
            const videoElement = this.$refs['video_' + i]
            if (Array.isArray(videoElement)){
              videoElement.forEach(video => {
              video.addEventListener('loadedmetadata', () => {
                  // Your logic for handling loaded metadata goes here
                  const info = {
                    seconds :  video.duration,
                    storage : this.filesFormData[i].get("video_file").size,
                    name : this.filesFormData[i].get("video_file").name,
                  }
                  // Check if the new object already exists in the array
                  let exists = this.videoInfos.some(item => JSON.stringify(item) === JSON.stringify(info));

                  // If the object doesn't exist, push it into the array
                  if (!exists) {
                    this.videoInfos.push(info);
                  }
                this.$nextTick(()=>{
                  if((info.seconds / 60).toFixed(1) <5 ){
                    this.clipsAgcStatuses.push({status:false})
                    this.setErrorMessage(["Only videos longer than 5 minutes can auto-generate clips."]);
                  }
                  else{
                    this.clipsAgcStatuses.push({status: this.autoGenerateClips})
                  }
                })
                });
              });
            }
          }
        });

          this.focusUploadModal();
        })
      
    },

    handleGoogleDriveFileUpload(file) {
      setTimeout(()=>{
        this.filesLength = file.length
        this.file = file
        for (let index = 0; index < this.file.length; index++) {
          const fileExtension = this.file[index].name.slice((this.file[index].name.lastIndexOf(".") - 1 >>> 0) + 2);
          if(!this.allowedExtensions.includes("." + fileExtension.toLowerCase())){
            this.setErrorMessage(["Only mp4, mov, avi files are allowed"]);
            return
          }
        const formData = new FormData();
        formData.append("video_file", this.file[index]);
        if (this.uuid) formData.append("folder", this.uuid);
          this.filesFormData.push(formData)
        }
        this.file.forEach((file)=>{
          this.videoURLs.push(window.URL.createObjectURL(file))
        })
        this.isLocalUpload = true
        this.$nextTick(() => {
          for (let i = 0; i < this.file.length; i++) {
            const videoElement = this.$refs['video_' + i]
            if (Array.isArray(videoElement)){
              videoElement.forEach(video => {
              video.addEventListener('loadedmetadata', () => {
                  // Your logic for handling loaded metadata goes here
                  const info = {
                    seconds :  video.duration,
                    storage : this.filesFormData[i].get("video_file").size,
                    name : this.filesFormData[i].get("video_file").name,
                  }
                   // Check if the new object already exists in the array
                   let exists = this.videoInfos.some(item => JSON.stringify(item) === JSON.stringify(info));

                    // If the object doesn't exist, push it into the array
                    if (!exists) {
                      this.videoInfos.push(info);
                    }
                this.$nextTick(()=>{
                  if((info.seconds / 60).toFixed(1) <5 ){
                    this.clipsAgcStatuses.push({status:false})
                  }
                  else{
                    this.clipsAgcStatuses.push({status: true})
                  }
                })
                });
              });
            }
          }
        });

          this.focusUploadModal();
        })
    },
    done() {
      if(!this.language.length){
        this.languageError.push('Langauge is required')
        return
      }
      if(this.urls.length && this.urls[0].value.length){
        this.uploadYoutubeURL()
      }
      else{
        this.upload()
      }
      this.videoTopics = [];
      this.videoTopic = "";
      this.parts_to_find = "";
      this.videoForPlatForms = ""
      this.length_of_clip = null
    },
    upload: async function () {
      const payloadArray = []
      if(!this.language.length){
        this.languageError.push('Langauge is required')
        return
      }
      this.focusUploadModal();

      this.isUploading = true;
      for (let index = 0; index < this.file.length; index++) {
        this.addUploadingVideos([
          ...this.uploadingVideos,
          {
            name: this.videoInfos[index].name,
            size: this.videoInfos[index].storage,
            percentage: 0,
            uploadedSize: 0,
          },
        ]);
        const payload = {
          file: this.filesFormData[index],
        };
        this.filesFormData[index].append("folderUuid", this.uuid)
        this.filesFormData[index].append("with_acg", this.clipsAgcStatuses[index].status)
        this.filesFormData[index].append("video_for_platform", this.video_for_platform?this.video_for_platform.title:'')
        this.filesFormData[index].append("number_of_clips", this.number_of_clips?this.number_of_clips:10)
        this.filesFormData[index].append("video_topic_list", JSON.stringify(this.videoTopics.length?this.videoTopics: []))
        this.filesFormData[index].append("length_of_clip", this.length_of_clip?this.length_of_clip:0)
        this.filesFormData[index].append("parts_to_find", this.parts_to_find)
        payloadArray.push(payload)

      }
        try {
        await this.uploadVideo(payloadArray);
        this.isLocalUpload = false;
        this.isGoogleFileUplaod = false
        this.isUploading = false;
        this.videoURLs = []
        this.videoInfos = []
        this.showUploadDialog(false);
        this.resetVideos();
        const { uuid } = this.$route.params;
        if (uuid) this.$emit("videoUploaded");
        else await this.fetchVideos();
      } catch (error) {
        this.isUploading = false;
      }
        
     
    },
    focusUploadModal() {
      document.getElementsByClassName("v-dialog__content--active")[0].focus();
    },
    videoTopicsKeydown(event){
      if (event.key === "Enter" && this.videoTopic.trim() !== "") {
          this.videoTopics.push(this.videoTopic.trim())
          this.videoTopic = "";
        }
    },
    onBlurTopics(){
      if ( this.videoTopic.trim() !== "") {
          this.videoTopics.push(this.videoTopic.trim())
          this.videoTopic = "";
        }
    },

    removeChip(index) {
      this.videoTopics.splice(index, 1);
    },

    uploadYoutubeURL: async function () {
      this.youtubeUploading = true;
      if(!this.language.length){
        this.languageError.push('Langauge is required')
        return
      }
      let payload = {
        youtube_url: this.urls[0].value,
        with_acg: this.autoGenerateClips,
        video_for_platform:this.video_for_platform?this.video_for_platform.title:'',
        number_of_clips:this.length_of_clip?this.length_of_clip:0,
        video_topic_list:this.videoTopics.length?this.videoTopics: [],
        length_of_clip:this.length_of_clip?this.length_of_clip:0,
        parts_to_find:this.parts_to_find,
        language:this.language
      };
      if (this.uuid) payload = { ...payload, folder: this.uuid };
      try {
        //setting urlbase video upload status in store
        this.$store.commit("video/setYoutubeVideoUploadStatus", true)
        // calling prop function to open floating window
        this.showFloatingUploadWindow(true);
        // close upload dialog box
        this.showUploadDialog(false);

        await this.uploadYoutubeVideo(payload);

        this.$store.commit("video/setYoutubeVideoUploadStatus", false)
        if(!this.uploadingVideos.length){
          this.showFloatingUploadWindow(false)
        }
        
        this.youtubeUploading = false;
        this.resetVideos();
        const { uuid } = this.$route.params;
        if (uuid) this.$emit("videoUploaded");
        else await this.fetchVideos();
      } catch (error) {
        this.youtubeUploading = false;
      }
    },
    resetVideoUrls(i) {
      this.videoURLs.splice(i, 1)
      this.videoInfos.splice(i, 1)
      if(!this.videoInfos.length){
        this.isLocalUpload = false
        this.isGoogleFileUplaod = false
      }
    },
    handleAddURL(id) {
      this.$v.urls.$each[id].$touch();

      if (this.urls[0].value) {
        this.preUploadVideo({
          youtube_url: this.urls[0].value,
        })
          .then((r) => {
            this.youtubeVideoLength = r.data.video_duration
            this.canUploadYoutube = true;
            if((this.youtubeVideoLength/60) < 5){
              this.autoGenerateClips = false
            }
          })
          .catch(() => {
            this.canUploadYoutube = false;
          });
      }
    },
    handleSelectVideo(index) {
      setTimeout(() => {
          this.preUploadVideo({
          video_seconds: Number(this.$refs['video_'+index].duration).toFixed(2),
      })
        .then(() => {
          this.canUploadFromDevice = true;
        })
        .catch(() => {
          this.canUploadFromDevice = false;
        });
          
        
      }, 1000);
    },
  },
  validations: {
    urls: {
      $each: { value: { required, regexYoutubeURL } },
    },
  },
  watch: {
    isUploadVideoDialogVisible() {
      if (!this.isUploadVideoDialogVisible) {
        this.urls = [{ value: "" }];
        this.videoURLs = "";
        this.videoInfo = {
          seconds: "",
          storage: "",
          name: "",
        };
        this.language = '';
        this.videoForPlatForms = '',
        this.videoTopics = [],
        this.video_seconds
        this.parts_to_find = "",
        this.length_of_clip = null
      }
    },
    uploadingVideos() {
      if (this.uploadingVideos.length === 0) this.isUploading = false;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "@/assets/styles/components/videos/upload-video-dialog.scss";
.select-dropdown {
  width: 350px;
  border: 1px solid;
  height: 40px;
  padding: 5px;
  background-color: #F3F5F7;
}
.agc-action-wrapper {
  position: absolute;
    display: flex;
    right: 0;
    top: 11px;
    align-items: center;
}
.multiselect-custom{
  .multiselect__tags{
    background: #F3F5F7;
    input{
      background: #F3F5F7;
    }
    .multiselect__single{
      background:#F3F5F7 ;
    }
  
    .multiselect__tag{
      background: #e4e8ff;
      border-color: #7367f0;
      border: 1px solid #7367f0;
        span{
          color: #7367f0;
        }
        .multiselect__tag-icon{
          color: #7367f0;
          background: #e4e8ff;
        }
      }
    }
  }
  .multiselect__content-wrapper{
    .multiselect__content{
      li{
        .multiselect__option{
          display: flex;
          align-items: center;
        }
          span{
              img{
                  height: 25px;
              }
          }
      }
  }
      li{
        span{
          color: black;
          padding-left: 10px;
        }
        span:after{
          content:''
        }
        .multiselect__option.multiselect__option--highlight{
          background: #fbd458;
        }
        .multiselect__option--selected.multiselect__option--highlight:after{
          content: '';
        }
      }
    }
.number-input {
    width: 350px;
    height: 41px;
    background-color: #F3F5F7;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    padding-left: 12px;
  }
  .chips-container {
    padding: 5px;
    display: inline-block;
    width:350px
  }

  .chip {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    margin-top: 2px;
    margin-right: 5px;
    padding: 3px 8px;
    cursor: pointer;
  }
  .language-tooltip {
      position: relative;
      display: inline-block;
    }

    .language-tooltip::after {
      content: attr(data-title);
      position: absolute;
      background-color: #373333;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease-in-out;
      top: -20px;
    }

    .language-tooltip:hover::after {
      opacity: 0.6;
    }
</style>
