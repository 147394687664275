<template>
  <div>
  <v-list class="side-menu-wrapper">
    <v-subheader class="side-menu-wrapper__title">Settings</v-subheader>
    <v-list-item-group v-model="selectedItemIndex" color="primary">
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        class="side-menu-wrapper__item"
        :href="item.url"
      >
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        class="side-menu-wrapper__item"
        @click="onCancelSubscription"
      >
        <v-list-item-content>
          <v-list-item-title v-text="'Cancel Subscription'"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
    <v-dialog v-model="showCancelWarning" max-width="300px">
        <Warning :message="'Are you sure you want to cancel your subscription?'" :yes="onWarningYes" :no="onWarningNo"/>
    </v-dialog>
  </div>
</template>

<script>
import Warning from '../../shared/Warning.vue';
import types from '../../store/types';
import { mapActions, mapMutations } from 'vuex';
import formatDate from '../../utils/DateFormatter.js';


export default {
  props: ["selectedItem"],
  components:{Warning},
  data: () => ({
    selectedItemIndex: 0,
    items: [
      { text: "Account", url: "/profile" },
      // { text: "Invite a friend", url: "/invite-a-friend" },
      { text: "Plans", url: "/upgrade" },
      { text: "API", url: "/api-key" },
    ],
    showCancelWarning:false
  }),
  methods:{
    openDocs() {
      window.open("https://chopcast.mintlify.app/api-reference/introduction", "_blank")
    },
    ...mapActions({
      cancelSubscription: types.project.actions.CANCEL_SUBSCRIPTION,
      logoutUser: types.user.actions.USER_LOGOUT,
    }),
    ...mapMutations({
      setSuccessMessage: types.app.mutations.SET_SUCCESS_MESSAGE,
    }),
    onCancelSubscription(){
    this.showCancelWarning = true
    },
    async onWarningYes(){
      const res = await this.cancelSubscription()
      const formattedDate = formatDate(res.end_date)
      this.setSuccessMessage(`Your subscription has been canceled and will end on ${formattedDate}.`)
      this.showCancelWarning = false
      
    },
    onWarningNo(){
      this.showCancelWarning = false
    }
  },
  watch: {
    selectedItem() {
      this.selectedItemIndex = this.selectedItem;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";

.side-menu-wrapper {
  &__title {
    color: $black;
    padding-left: 24px;
    font-size: 24px;
    font-family: "Satoshi Black" !important;
  }
  &__item {
    padding-left: 24px;
    color: $black;
    font-size: 18px;
    font-weight: 700;
    height: 56px;
    &:hover {
      background: $google-button-background;
    }
    &::before {
      background: transparent;
    }
  }
  .v-item--active {
    background-color: $yellow;
    opacity: 1;
  }
}
</style>
